import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from 'src/app/common/tailwind/button-secondary/button-secondary.component';
import { ColumnsService } from 'src/app/services/columns.service';

@Component({
  selector: 'app-column-settings',
  standalone: true,
  imports: [AsyncPipe, ButtonPrimaryComponent, ButtonSecondaryComponent, RouterLinkActive, RouterLink, RouterOutlet],
  templateUrl: './column-settings.component.html',
  styleUrl: './column-settings.component.scss'
})
export class ColumnSettingsComponent {

  columnsService = inject(ColumnsService)

  addMode = true

  addColumn(name: string) {

    this.addMode = false

    this.columnsService.createColumn(name, "text")

  }  

}
