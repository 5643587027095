<!-- <div class="grid grid-cols-[1fr_3fr]">
    <ul role="list" class="divide-y divide-gray-200 m-2 p-4 shadow-md" >
        <li class="flex py-4 hover:bg-slate-50 cursor-pointer" *ngFor="let script of scripts" (click)="currentScript = script">
            <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">{{script.file}}</p>
            </div>
        </li>

    </ul>
    <pre *ngIf="currentScript">{{currentScript | json}}</pre>
</div> -->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-white">
  <body class="h-full overflow-hidden">
  ```
-->
<div class="flex h-[100vh]">

    <div class="sm:flex flex-shrink-0 hidden">
        <div class="flex w-64 flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100">
                <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                    <div class="flex flex-shrink-0 items-center px-4">
                        <img class="h-8 w-auto" src="assets/logos/annex-tracker.textmark.svg" alt="Your Company">
                    </div>
                    <nav class="mt-5 flex-1" aria-label="Sidebar">
                        <div class="space-y-1 px-2">
                            <!-- Current: "bg-gray-200 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                            <a (click)="currentScript = script" *ngFor="let script of scripts"
                                [ngClass]="{'bg-gray-200': currentScript == script, 'text-gray-900': currentScript == script}"
                                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium cursor-pointer">
                                <fa-icon class="mr-2" [icon]="iconMail"></fa-icon>
                                {{script.file}}
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
        
        <main class="relative z-0 flex-1 overflow-y-auto focus:outline-none bg-slate-200" *ngIf="currentScript">
            <div class="w-full border-b-slate-50 shadow-md">
                <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-sm font-medium text-slate-400">
                        cc:
                    </div>
                    <input [(ngModel)]="cc_recipients" type="email" (change)="onChange($event)" name="recipients"
                        class="block w-full border-0 bg-white py-1.5 pl-10 pr-3 text-gray-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        placeholder="Recipients (space separated)">
                </div>
                <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-sm font-medium text-slate-400">
                        bcc:
                    </div>
                    <input [(ngModel)]="bcc_recipients" type="email" (change)="onChange($event)" name="recipients"
                        class="block w-full border-0 bg-white py-1.5 pl-10 pr-3 text-gray-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        placeholder="Recipients (space separated)">
                </div>
            </div>
            <div class="p-2 max-w-3xl mx-auto">
                <div class="overflow-hidden bg-white shadow sm:rounded-md">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li (sendMail)="send(mail)" *ngFor="let mail of currentScript.emailData" class="hover:bg-gray-50 transition-colors" app-mail [mail]="mail">
                            
                        </li>
                    </ul>
                </div>


            </div>
        </main>
    </div>
</div>