import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrModule } from 'ngx-toastr';
import { AdminModule } from './app/admin/admin.module';
import { PrinterModule } from './app/printer/printer.module';
import { CommonLibraryModule } from './app/common/common-library.module';
import { ModalModule } from './app/modal/modal.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, FontAwesomeModule,
            NgxExtendedPdfViewerModule, ModalModule, CommonLibraryModule, PrinterModule, AdminModule, ToastrModule.forRoot(), NgOtpInputModule),
        provideAnimations(),
        provideMatomo({
            disabled: !environment.production,
            trackerUrl: 'https://matomo.fundraisingassistant.de',
            siteId: '3'
        }, withRouter()),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.error(err));
