<span #element tabindex="1"
    class="bg-white rounded-full border px-2 cursor-pointer hover:bg-gray-200 transition-all group relative whitespace-nowrap"
    (focus)="visible = true" 
    (blur)="visible = false">

    <ng-container *ngIf="workstream$ | async; else elseBlock">
        {{(workstream$ | async)?.properties?.['reference'] || 'SPA'}} 
    </ng-container>
    <ng-template #elseBlock>
        -
    </ng-template>

    <span class="group-hover:inline-block" [class.hidden]="!visible">
        <fa-icon [icon]="iconDropdown"></fa-icon>
    </span>

    <div *ngIf="visible"
        class="absolute top-6 left-0 max-w-xs bg-white rounded shadow-xl z-20 max-h-96 overflow-y-auto my-scrollbar">
        <ul class="divide-y relative">
            <li (click)="addMain()" class="sticky top-0 text-blue-600 bg-white hover:underline px-2 py-1 text-sm truncate max-w-xs hover:bg-gray-200 transition-colors" (click)="select(null)">
                <fa-icon class="mr-2"
                    [icon]="iconAdd"></fa-icon>
                Add as new workstream ...
            </li>
            <li class="px-2 py-1 text-sm truncate max-w-xs hover:bg-gray-200 transition-colors" (click)="select(null)">
                <fa-icon class="mr-2" [class.invisible]="file.workstreamId != null"
                    [icon]="iconCheck"></fa-icon>
                (not assigned)
            </li>
            <li (click)="select(workstream)" *ngFor="let workstream of workstreamsService.workstreams$ | async"
                class="px-2 py-1 text-sm truncate max-w-xs hover:bg-gray-200 transition-colors">
                <fa-icon class="mr-2" [class.invisible]="file.workstreamId != workstream.id"
                    [icon]="iconCheck"></fa-icon><app-pretty-print-workstream
                    [workstream]="workstream"></app-pretty-print-workstream>
            </li>
        </ul>
    </div>
</span>