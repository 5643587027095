<div class="flex-1 overflow-y-auto my-scrollbar">
    <div class="flex-1 text-sm leading-6 overflow-hidden">
        <table class="flex-1 text-sm leading-6 overflow-hidden table-fixed mx-auto border mt-4">
            <thead>
                <th colspan="2" class="bg-slate-100 box-border text-center p-2">
                    Where should we insert the new workstream?
                </th>
            </thead>
            <tbody class="divide-y">
                <tr *ngFor="let item of insertItems; let first = first; let last = last; let index = index">
                    <td class="box-border px-2 py-1.5 whitespace-nowrap overflow-visible"
                        [class.bg-stripes]="item.hasControls">
                        {{item.label}}
                        <span class="float-right px-1.5  rounded space-x-2" *ngIf="item.hasControls">
                            <button [attr.disabled]="first ? '': null" (click)="move(index, -1)"
                                class="hover:bg-slate-300 px-1 cursor-pointer disabled:text-gray-400 disabled:cursor-not-allowed rounded">
                                <fa-icon [icon]="iconUp"></fa-icon>
                            </button>
                            <button [attr.disabled]="last  ? '': null" (click)="move(index,  1)"
                                class="hover:bg-slate-300 px-1 cursor-pointer disabled:text-gray-400 disabled:cursor-not-allowed rounded">
                                <fa-icon [icon]="iconDown"></fa-icon>
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>