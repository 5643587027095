import { Component, Input, inject } from '@angular/core';
import { faArrowDown, faArrowLeft, faArrowUp, faCloudUpload, faDebug, faDeleteLeft, faDiagramSubtask, faDownload, faEllipsisVertical, faPencilSlash, faTrashCanUndo, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { Workstream } from 'annex-tracker-backend';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { DropdownOutletService } from 'src/app/common/dropdown-outlet.service';
import { BackendService } from 'src/app/services/backend.service';
import { UploadService } from 'src/app/services/upload.service';
import { WorkstreamPropertyService } from 'src/app/services/workstream-property.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: '[app-row-context]',
  templateUrl: './row-context.component.html',
  styleUrls: ['./row-context.component.scss'],
  standalone: true,
  imports: [FaIconComponent, NgIf, RouterLink
  ]
})
export class RowContextComponent {

  iconDots = faEllipsisVertical
  iconDownload = faDownload
  iconUpload = faCloudUpload
  iconRemove = faPencilSlash
  iconRestore = faTrashCanUndo
  iconBackspace = faDeleteLeft
  iconUp = faArrowUp
  iconDown = faArrowDown
  iconParentToOther = faDiagramSubtask
  iconBack = faArrowLeft
  iconDebug = faDebug

  accept = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ].join(",")

  @Input()
  workstream!: HWorkstream

  parentToMode = false
  siblings?: HWorkstream[]

  debugMode = false

  constructor(
    public dropdownService: DropdownOutletService,
    public workstreamService: WorkstreamsService,
    private uploadService: UploadService,
    public propertyService: WorkstreamPropertyService,
    private backend: BackendService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  showUploadFileDialog(event: MouseEvent) {

    const inputElement = document.createElement("input")
    inputElement.type = "file"
    inputElement.accept = this.accept
    inputElement.onchange = (event) => {
      this.uploadFile(event)

      console.log(this.route.snapshot.url.join("/"))
      // this.router.navigate()
    }
    inputElement.click()



  }

  async uploadFile(event: Event) {
    const inputElement = (event.target as HTMLInputElement)

    await lastValueFrom(
      this.uploadService.upload(inputElement.files![0], this.workstream.id)
    )

    // reset input[type=file]
    inputElement.value = ""

  }

  async checkDebug(event: MouseEvent) {
    // check if CTRL key is pressed
    this.debugMode = event.ctrlKey || event.shiftKey
  }

  async openDebug() {
    await this.router.navigate([{ outlets: { modal: ['center-panel', 'debug', this.workstream.id] } }])
  }

  async moveUp() {

    this.backend.workstream.patch(this.workstream.id, {
      index: this.workstream.index - 1.5
    })

  }

  async moveDown() {

    this.backend.workstream.patch(this.workstream.id, {
      index: this.workstream.index + 1.5
    })

  }

  async init(event: Event) {
    event.stopPropagation()
    this.parentToMode = true

    const workstreams = await firstValueFrom(this.workstreamService.workstreams$)

    // find all workstreams that have the same parent and are not the current workstream
    this.siblings = workstreams.filter(ws => ws.parentId == this.workstream.parentId && ws.id != this.workstream.id)

  }

  async parentTo(sibling: HWorkstream) {

    this.backend.workstream.patch(this.workstream.id, {
      parentId: sibling.id
    })

    this.parentToMode = false
    this.dropdownService.close()
  }

  async unparent() {

    this.backend.workstream.patch(this.workstream.id, {
      parentId: this.workstream.parent?.parentId
    })
  }

}
