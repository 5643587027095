<div class="flex flex-col h-full">
  <div class="flex">
    <div class="flex items-center justify-center gap-6">
      <div>
        <div>Current Workstreams {{docxAnalysisService.leftFile?.filename}}</div>
        <div (click)="myLeftMatch = match" *ngFor="let match of docxAnalysisService.leftAnalysis">
          <span class="text-gray-600">{{match.prefix}}</span>&nbsp;<span class="font-medium">{{match.reference}}</span>
        </div>
      </div>
      <div>
        <fa-icon [icon]="iconRight"></fa-icon>
      </div>
      <div>
        <div>Updated Workstreams {{docxAnalysisService.rightFile?.filename}}</div>
        <div (click)="myRightMatch = match" *ngFor="let match of docxAnalysisService.rightAnalysis"
          [title]="match.fullMatches.join('\n\n')">
          <span class="text-gray-600">{{match.prefix}}</span>&nbsp;<span class="font-medium">{{match.reference}}</span>
        </div>
      </div>
    </div>
    <table>
      <tr>
        <td></td>
        <td class="px-1 font-medium bg-gray-100" *ngFor="let match of docxAnalysisService.rightAnalysis">
          {{match.reference}}</td>
      </tr>
      <tr *ngFor="let row of docxAnalysisService.matrix; let leftIndex = index">
        <td class="px-1 font-medium bg-gray-100">{{docxAnalysisService!.leftAnalysis?.[leftIndex]?.reference}}</td>
        <td class="px-1 hover:outline outline-1 hover:outline-black cursor-pointer transition-color"
        [class.bg-green-200]="cell == 0"
          [class.bg-gray-200]="leftIndex == rightIndex" *ngFor="let cell of row; let rightIndex = index"
          (click)="myLeftMatch = docxAnalysisService.leftAnalysis![leftIndex];myRightMatch = docxAnalysisService.rightAnalysis![rightIndex]">
          {{cell | number:'1.0-0'}}</td>
      </tr>
    </table>
  </div>
  <div class="flex flex-nowrap text-sm gap-1">

    <pre class="flex-1 whitespace-pre-wrap">{{myLeftMatch | json}}</pre>
    <pre class="flex-1 whitespace-pre-wrap">{{myRightMatch | json}}</pre>

  </div>
</div>