import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faFilePlus } from '@fortawesome/pro-thin-svg-icons';
import { File } from 'annex-tracker-backend';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-file-icon',
    templateUrl: './file-icon.component.html',
    styleUrls: ['./file-icon.component.scss'],
    standalone: true,
    imports: [FaIconComponent]
})
export class FileIconComponent implements OnChanges {

  iconFile = faFile
  iconProgress = faSpinnerThird

  @Input()
  file?: File | null

  @Input()
  loading = false

  @Input()
  size: SizeProp = 'lg'

  iconAddFile = faFilePlus

  constructor() {
    this.setIcon()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["file"]) {
      this.setIcon()
    }
  }

  setIcon() {

    if (this.file == null) {
      this.iconFile = faFilePlus
    }

    const fileparts = this.file?.filename?.split(".")
    if (fileparts == null) {
      return
    }

    const ext = fileparts[fileparts?.length - 1]

    switch (ext.toLowerCase()) {
      case "pdf":
        this.iconFile = faFilePdf
        break;
      case "xls":
      case "xlsx":
        this.iconFile = faFileExcel
        break;
      case "doc":
      case "docx":
        this.iconFile = faFileWord
        break;
      case "ppt":
      case "pptx":
      case "ppsx":
        this.iconFile = faFilePowerpoint
        break
      default:
        this.iconFile = faFile
        break;
    }

  }

}
