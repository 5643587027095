import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { faClipboard, faPen } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';
import { ProjectAliasService } from 'src/app/services/project-alias.service';
import { Observable, ReplaySubject, combineLatest, firstValueFrom, map, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';

@Component({
    selector: 'app-list-email-display',
    templateUrl: './list-email-display.component.html',
    styleUrls: ['./list-email-display.component.scss'],
    standalone: true,
    imports: [FaIconComponent, AsyncPipe]
})
export class ListEmailDisplayComponent {

  iconCopy = faClipboard
  iconEdit = faPen

  anchor: Observable<string>

  showEmail = false

  constructor(
    public projectService: ProjectsService,
    public auth: AuthenticationService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private router: Router,
    public aliasService: ProjectAliasService) {

    this.anchor = combineLatest([
      this.configService.config,
      this.aliasService.alias
    ]).pipe(
      tap(([config, alias]) => this.showEmail = alias?.alias != null),
      map(([config, alias]) => `${alias?.alias}@${config.emailDomain}`)
    
    )
  }

  async copyToClipboard() {

    // Copy to clipboard using navigator.clipboard
    if (navigator.clipboard) {
      navigator.clipboard.writeText(await firstValueFrom(this.anchor))

      this.toastr.info("Copied to clipboard", undefined, { positionClass: 'toast-top-center', timeOut: 1500 })
    }

  }
  
  async editAlias() {
    console.log("Edit alias")
    // open sidebar
    this.router.navigate([{ outlets: { modal: ['side-panel', 'list-settings'] } }], { queryParams: {editAlias: true}})

  }

}
