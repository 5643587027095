<header class="w-full shadow-sm z-10 border-b-2">

    <div class="px-4 py-4 text-sm">
        <app-pretty-print-workstream
            [workstream]="workstreamService.currentWorkstream | async"></app-pretty-print-workstream>
    </div>

    <nav class="px-4 pb-2 space-x-2 xl:space-x-10 text-gray-500 text-sm font-medium">
        <a routerLink='preview' class="hover:text-gray-700 px-0.5 py-2 transition-colors"
            routerLinkActive="border-b-2 border-gray-500 text-gray-900 whitespace-nowrap">
            <fa-icon class="hidden xl:inline-block" [icon]="iconPreview"></fa-icon>
            Preview
        </a>
        <a routerLink='emails' class="hover:text-gray-700 px-0.5 py-2 transition-all"
            routerLinkActive="border-b-2 border-gray-500 text-gray-900 whitespace-nowrap">
            <fa-icon class="hidden xl:inline-block" [icon]="iconEmail"></fa-icon>
            Emails
        </a>
        <a routerLink='files' class="hover:text-gray-700 px-0.5 py-2 transition-all"
            routerLinkActive="border-b-2 border-gray-500 text-gray-900 whitespace-nowrap">
            <fa-icon class="hidden xl:inline-block" [icon]="iconFiles"></fa-icon>
            Files
        </a>
    </nav>

</header>