import { Component, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { ProjectAliasService } from 'src/app/services/project-alias.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-edit-email-alias',
    templateUrl: './edit-email-alias.component.html',
    styleUrls: ['./edit-email-alias.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, AsyncPipe]
})
export class EditEmailAliasComponent implements OnDestroy {

  emailAlias: string = "tjmaul@gmail.com"
  message?: string

  subscription?: Subscription

  constructor(
    public configService: ConfigService,
    public aliasService: ProjectAliasService,
    public toastr: ToastrService
  ) {

    this.subscription = this.aliasService.alias.subscribe(alias => this.emailAlias = alias?.alias ?? "")

  }

  async changeEmailAlias() {

    this.message = undefined

    if (this.emailAlias?.length <= 2) {
      this.message = "Email alias too short"
      return
    }


    try {
      await this.aliasService.changeAlias(this.emailAlias)
      this.toastr.success("Email alias changed")
    } catch (e) {

      const message = (e as any).message
      const uniqueConstraintFailed = message.includes("UNIQUE constraint failed")

      if (uniqueConstraintFailed) {
        this.message = "The email alias already exists for another project"
      } else {
        this.toastr.error("Error changing email alias")
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

}
