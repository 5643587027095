import { Component, Inject } from '@angular/core';
import { ActiveModal } from 'src/app/modal/modal.service';
import { ButtonDangerComponent } from '../../../../../common/tailwind/button-danger/button-danger.component';
import { ButtonSecondaryComponent } from '../../../../../common/tailwind/button-secondary/button-secondary.component';
import { BadgeComponent } from '../../../../../common/badge/badge.component';

@Component({
    selector: 'app-delete-label-modal',
    templateUrl: './delete-label-modal.component.html',
    styleUrls: ['./delete-label-modal.component.scss'],
    standalone: true,
    imports: [BadgeComponent, ButtonSecondaryComponent, ButtonDangerComponent]
})
export class DeleteLabelModalComponent {

  constructor(
    public activeModal: ActiveModal,
    @Inject('context') public config: any

    ) { 
    console.log(config)
  }

}
