import { Component, OnInit } from '@angular/core';
import { faCheck, faCircleArrowRight, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BackendService } from 'src/app/services/backend.service';
import { MailComponent } from './mail/mail.component';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-send-mail',
    templateUrl: './send-mail.component.html',
    styleUrls: ['./send-mail.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, FaIconComponent, NgIf, FormsModule, MailComponent]
})
export class SendMailComponent implements OnInit {

  iconTo = faCircleArrowRight
  iconMail = faEnvelope
  iconCheck = faCheck

  scripts: any;

  cc_recipients?: string | null
  bcc_recipients?: string | null

  currentScript: any

  constructor(private backend: BackendService, private auth: AuthenticationService) { }

  async ngOnInit() {
    this.scripts = await this.backend.sendMail.find();

    this.currentScript = this.scripts[0];

    this.cc_recipients = localStorage.getItem("ADMIN_SEND_MAIL_RECIPIENTS_CC")
    this.bcc_recipients = localStorage.getItem("ADMIN_SEND_MAIL_RECIPIENTS_BCC")
  }

  onChange(event: Event) {
    console.log(event)

    localStorage.setItem("ADMIN_SEND_MAIL_RECIPIENTS_CC", this.cc_recipients || "")
    localStorage.setItem("ADMIN_SEND_MAIL_RECIPIENTS_BCC", this.bcc_recipients || "")
  }

  send(mail: any) {
    mail.sent = true

    console.log(mail)

    this.backend.sendMail.create({
      cc: this.cc_recipients?.split(" ").map((r: string) => r.trim()).filter((r: string) => r.length > 0)!,
      bcc: this.bcc_recipients?.split(" ").map((r: string) => r.trim()).filter((r: string) => r.length > 0)!,
      scriptIndex: this.scripts.indexOf(this.currentScript),
      mailIndex: this.currentScript.emailData.indexOf(mail)
    })
  }
}
