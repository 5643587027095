import { HostListener, Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownOutletService {

  templateRef: TemplateRef<any> | null = null
  parentElement: HTMLElement | null = null

  showDropdown = false

  top?: number
  left?: number
  width?: number
  height?: number

  goUp = false
  goLeft = false
  
  constructor() {
    document.addEventListener('scroll', () => {
      console.log("scroll")
      this.setPosition()  
    })

    document.addEventListener('resize', () => {
      console.log("resize")
      this.setPosition()
    })

    document.addEventListener('click', () => {
      this.close()
    })

    document.addEventListener('keydown', ($event: KeyboardEvent) => {
      if ($event.key == "Escape") {
        this.close()
      }
    })
  }

  setPosition() {

    if (this.parentElement == null) return

    const rect = this.parentElement.getBoundingClientRect()

    this.width = this.parentElement.clientWidth
    this.height = this.parentElement.clientHeight

    this.top = rect.top//this.parentElement.offsetTop
    this.left = rect.left; //this.parentElement.offsetLeft

    // if the dropdown is in the lower third of the screen, we want to show it upwards
    this.goUp = window.innerHeight - rect.bottom < window.innerHeight / 3

    /// if the dropdown is in the right third of the screen, we want to show it to the left
    this.goLeft = window.innerWidth - rect.right < window.innerWidth / 3
    
  }

  public toggle(templateRef: TemplateRef<any>, parent: HTMLElement, event: Event) {

    if (this.parentElement == parent) {
      this.close()
      return
    }

    this.parentElement = parent
    this.templateRef = templateRef
    this.showDropdown = true
    event.stopPropagation()

    this.setPosition()
  }

  close() {
    this.parentElement = null
    this.templateRef = null
    this.showDropdown = false

  }
}
