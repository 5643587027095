import { ViewportScroller } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-list-detail',
    templateUrl: './list-detail.component.html',
    styleUrls: ['./list-detail.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet]
})
export class ListDetailComponent {

  @HostBinding("class")
  classes = 'flex flex-1 flex-col overflow-hidden h-full'


  constructor(
    private project: ProjectsService,
    private scroller: ViewportScroller) {}

  ngOnDestroy(): void {
    this.project.loadProject(null)
  }

  onActivate(e: any) {
    this.scroller.scrollToPosition([0, 0])

  }
}
