import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendMailComponent } from './send-mail/send-mail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { CommonLibraryModule } from '../common/common-library.module';
import { MailComponent } from './send-mail/mail/mail.component';



@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        CommonLibraryModule,
        SendMailComponent,
        MailComponent
    ]
})
export class AdminModule { }
