@if (data) {

<div class="font-medium text-lg">
    @if(languages.length > 1) {
    Bilingual document uploaded
    <p class="text-sm font-normal">You uploaded a bilingual document containing the following workstreams.
    </p>
    } @else {
    Document uploaded
    <p class="text-sm font-normal">You uploaded a document containing the following workstreams.
    </p>
    }
</div>


<table class="table-fixed w-full divide-y text-sm mt-4">
    @if (languages.length > 1) {
        <thead>
            <tr>
                @for (language of languages; track $index) {
    
                <th class="py-1 text-left" [class.text-gray-500]="selectedLanguage != null && selectedLanguage != language">{{languageStrings[language]}}</th>
    
                }
            </tr>
        </thead>
    }
    <tbody class="divide-y">
        @for (entry of languagesOverview; track $index) {
        <tr>
            @for (item of entry; track idx; let idx = $index;) {

            <td class="py-1" 
                [class.bg-stripes]="searchTerm == (item?.prefix + ' ' + item?.reference)"
                [class.text-gray-500]="selectedLanguage != null && selectedLanguage != languages[idx]"
                [class.font-medium]="searchTerm == (item?.prefix + ' ' + item?.reference)"
                (click)="changeSearchTerm(item)"
                >
                <span class="cursor-pointer hover:underline">{{item?.prefix}} {{item?.reference}}</span>
            </td>
            }

        </tr>
        }
    </tbody>
</table>

} @else {
    <fa-icon [icon]="iconLoading" [spin]="true"></fa-icon>
}