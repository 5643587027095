<div class="mb-4 text-2xl">{{'print_executive_summary_header' | translate:data.project.language}}</div>
<div class="">

  <table class="mt-4 w-full">
    @for(interestingColumn of interestingColumns; track interestingColumn.name; let interestingColumnIdx = $index) {
      <tr>
        <td colspan="3" class="h-4"></td>
      </tr>
      <tr class="border-b">
        <th class="text-left py-2">{{interestingColumn.label}}</th>
        <th></th>
        <th class="text-left py-2">
          <div class="flex gap-2">
            @for (column of data.columns; track column.id) {
              @if(column.type == 'enum-dropdown' && column.name == interestingColumn.name) {
                @for (badge of column.configuration; track badge.id) {
                  <app-badge-dropdown [badges]="column.configuration" [value]="badge.value"></app-badge-dropdown>
                }
              }
            }
          </div>
        </th>
      </tr>

      @for (ws of parentWorkstreams; track ws.id; let idx = $index; let last = $last) {
        <tr>
          <td [class.pb-3]="last" class="text-left py-1.5 pr-2">{{ws.properties["name"]}}</td>
          <td class="px-1.5 whitespace-nowrap w-1">{{((ws | amountChildrenRecurse) || 0) + 1}} *</td>
          <td [class.pb-3]="last" class="text-left py-1.5 relative w-[65%] whitespace-nowrap">

            <app-bars *ngIf="barDataPerMain" [data]="barDataPerMain[interestingColumnIdx][idx]"></app-bars>

          </td>
        </tr>
      }
      <tr class="mb-4 border-t-4 border-double">
        <td class="py-2 pt-3 italic">{{'overall_label' | translate:data.project.language}}</td>
        <td class="px-1.5">{{data.workstreams.length}} *</td>
        <td class="py-2 pt-3">
          <app-bars *ngIf="aggregateBarData" [data]="aggregateBarData[interestingColumnIdx]"></app-bars>
        </td>
      </tr>
    }
  </table>
  <p class="mt-2 text-xs text-gray-700 text-right">* {{'sum_of_workstreams_explanation' | translate:data.project.language}}</p>
  
</div>