  import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoverPageToProject, CoverPages } from 'annex-tracker-backend';
import { Observable } from 'rxjs';
import { ModalService } from 'src/app/modal/modal.service';
import { BackendService } from 'src/app/services/backend.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { SelectorModalComponent } from './selector-modal/selector-modal.component';

@Component({
  selector: 'app-cover-page-selector',
  standalone: true,
  imports: [FormsModule, AsyncPipe],
  templateUrl: './cover-page-selector.component.html',
  styleUrl: './cover-page-selector.component.scss'
})
export class CoverPageSelectorComponent implements OnInit {

  backend = inject(BackendService)
  projectService = inject(ProjectsService)
  modalService = inject(ModalService)

  coverPages = [] as CoverPages[]

  currentCoverPageId$?: Observable<string | null>

  @Input()
  currentCoverPage?: CoverPages | null

  @Output()
  currentCoverPageChange = new EventEmitter<CoverPages | null>()

  coverPageToProject: CoverPageToProject | undefined

  async ngOnInit() {

  }


  async openModal() {
    try {
      const res = await this.modalService.openModal(SelectorModalComponent, this.currentCoverPage) as CoverPages | null   
      if (res) {
        this.currentCoverPageChange.emit(res)
      } else {
        this.currentCoverPageChange.emit(null)
      }
    } catch {
      // noop, to be expected when user cancels modal
    }
  }


  async changeCoverPage(coverPageId: any) {

    if (this.coverPageToProject) {
      await this.backend.coverPageToProjects.patch(this.coverPageToProject.id, {
        coverPageId
      })
    } else {
      await this.backend.coverPageToProjects.create({
        coverPageId,
        projectId: this.projectService.currentProject!.id
      })
    }

  }

}
