import { Directive, HostBinding, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[appQueryHighlight]',
  standalone: true,
  host: {
    class: 'transition-colors duration-500'
  }
})
export class QueryHighlightDirective implements OnInit {

  @Input() appQueryHighlight?: string

  @HostBinding('class.bg-purple-200') isHighlighted = false

  route = inject(ActivatedRoute)

  constructor() { 
    
  }

  ngOnInit() {
    
    if (this.appQueryHighlight != null) {
      const shouldHighlight = this.route.snapshot.queryParamMap.has(this.appQueryHighlight)
      if (shouldHighlight) {
        this.doHighlight()
      }
    }

  }

  private doHighlight() {
    setTimeout(() => {
      this.isHighlighted = true
    }, 300)
    
    setTimeout(() => {
      this.isHighlighted = false
    }, 2500)
  }

}
