<div class="space-y-6 p-5">
    <div class="space-y-1">
        <label for="listName" class="block text-sm font-medium text-gray-700">Email Alias</label>
        <div class="mt-1 flex rounded-md border border-gray-300 overflow-hidden bg-white">
            <input id="listName" [(ngModel)]="emailAlias" name="listName" type="text" (keydown.enter)="changeEmailAlias()"
                class="block text-right w-full appearance-none outline-0 border-0 pl-3 pr-0 py-2 placeholder-gray-400 shadow-sm sm:text-sm focus:ring-0">
            <span
                class="inline-flex items-center border-l-0 pl-0 pr-3 text-gray-500 whitespace-nowrap sm:text-sm">&#64;{{(configService.config
                | async)?.emailDomain}}</span>
            
            <button class="px-4 border-l text-sm font-medium hover:bg-gray-200"
                (click)="changeEmailAlias()">Change</button>
        </div>
        <p class="text-xs text-red-500" *ngIf="message">{{message}}</p>
    </div>

</div>