import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appCursorChangeField]',
    standalone: true
})
export class CursorChangeFieldDirective {

  @Output()
  changeFieldRight = new EventEmitter<void>();

  @Output()
  changeFieldLeft = new EventEmitter<void>();

  constructor() { }
  
  
  @HostListener("keydown.arrowLeft", ['$event'])
  @HostListener("keydown.arrowRight", ['$event'])
  onArrow(event: KeyboardEvent) {

    
        
    let selection = window.getSelection();
    const length = selection?.anchorNode?.textContent?.length

    if ((selection?.anchorOffset || 0 )== 0 && event.key == 'ArrowLeft') {
      console.log("left")
      event.stopPropagation()
      event.preventDefault()
      this.changeFieldLeft.emit()
    }
    if ((selection?.anchorOffset || 0) == length && event.key == 'ArrowRight') {
      console.log("right")
      event.stopPropagation()
      event.preventDefault()
      this.changeFieldRight.emit()
    }

  }

}
