import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faAngleDown, faCheck, faPlus, faTimesHexagon } from '@fortawesome/pro-regular-svg-icons';
import { File, Workstream } from 'annex-tracker-backend';
import { map, Observable } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { EmailService } from 'src/app/services/email.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { PrettyPrintWorkstreamComponent } from '../pretty-print-workstream/pretty-print-workstream.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-file-workstream-badge',
    templateUrl: './file-workstream-badge.component.html',
    styleUrls: ['./file-workstream-badge.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent, NgFor, PrettyPrintWorkstreamComponent, AsyncPipe]
})
export class FileWorkstreamBadgeComponent {

  iconCheck = faCheck
  iconAdd = faPlus

  visible = false

  iconDropdown = faAngleDown

  @ViewChild('element')
  element!: ElementRef<HTMLElement>

  @Input()
  file!: File

  workstream$: Observable<Workstream | undefined>

  constructor(
    public workstreamsService: WorkstreamsService,
    public backend: BackendService,
    public emailService: EmailService,
    public route: ActivatedRoute,
    public router: Router
    ) {
    this.workstream$ = workstreamsService.workstreams$.pipe(map(wss => wss.find(ws => ws.id === this.file.workstreamId)))
  }

  select(workstream: Workstream | null) {

    console.log("select", workstream)
    this.element.nativeElement.blur()

    if (workstream == null) {
      this.backend.files.patch(this.file.id, { workstreamId: null })
    } else {

      if (this.file.workstreamId == workstream.id) {
        return
      }
  
      this.backend.files.patch(this.file.id, { workstreamId: workstream.id })
      this.emailService.addWorkstreamToEmail(workstream.id, this.file.emailId!)
    }


  }

  async addMain() {
    
    await this.router.navigate(['../doclist'], { relativeTo: this.route })

    // navigate to modal with file id
    await this.router.navigate([{ outlets: { modal: ['fullscreen-panel', 'add-main', this.file.id] } }])

  }
}
