import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ChartDataLabels)

@Directive({
    selector: '[appPieChart]canvas',
    standalone: true
})
export class PieChartDirective implements OnInit, OnChanges {

  @Input()
  title!: string

  @Input()
  labels!: string[]

  @Input()
  colors!: string[]

  @Input()
  data!: number[]

  @Input()
  fontFamily?: string

  chart?: Chart<'doughnut'>

  constructor(private elementRef: ElementRef<HTMLCanvasElement>) { }

  async ngOnInit() {

    Chart.defaults.color = '#000'

    this.chart = new Chart(this.elementRef.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          backgroundColor: this.colors,
          hoverOffset: 4
        }]
      },
      options: {
        font: {
          family: this.fontFamily
        },
        animation: false,
        responsive: true,
        devicePixelRatio: 2,
        plugins: {
          datalabels: {
            formatter: (value: number, ctx: any) => {

              // sum of this.data
              const sum = ctx.chart.data.datasets[0].data.reduce((a: number, b: number) => a + b, 0)

              const label = ctx.chart.data.labels[ctx.dataIndex]
              if (value == 0) return ''
              return `${label}\n${Math.round(value / sum * 100)}%`
            },
            font: {
              family: this.fontFamily
            },
          },
          title: {
            display: true,
            text: this.title,
            align: 'center',
            fullSize: false,
            font: {
              size: 26,
              family: this.fontFamily
            }
          },
          legend: {
            position: 'right',
            title: {
              display: true,
              text: this.title,
              font: {
                family: this.fontFamily
              }
            },
            labels: {
              font: {
                family: this.fontFamily
              }
            }
          },


        }
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["data"].firstChange && this.chart != null) {
      (this.chart.options as any).animation = true
    }
    this.chart?.data.datasets.forEach(dataset => {
      dataset.data = this.data
    })
    this.chart?.update()
  }

}
