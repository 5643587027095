<div class="space-y-1">
    <label for="listName" class="block text-sm font-medium text-gray-700">Invite colleagues</label>
    <div class="mt-1 flex rounded-md border border-gray-300 overflow-hidden divide-x">
        <input [(ngModel)]="invitee" type="email" [placeholder]="inviteePlaceholder" autocomplete="off"
            class="block w-full appearance-none outline-0 border-0 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm">
        <button [disabled]="!isMail(invitee)"
            class="disabled:cursor-not-allowed px-4 text-sm font-medium hover:bg-gray-200 disabled:text-gray-300"
            (click)="invite()">Invite</button>
    </div>
    <div class="text-xs text-gray-500 prose p-1">
        You can only add users that already have an account. Added users will be able to see and modify this annex tracker.
        Added users can invite other users. Only you can remove users and close the tracker.

    </div>
</div>
<div class="divide-y text-sm">

    <div *ngFor="let user of usersService.currentUsers | async" class="pl-2 py-2 flex justify-between group">
        <a class="hover:underline" href="mailto:{{user.email}}">{{user.email}}</a>
        <app-badge *ngIf="user.role == 'creator'" [color]="'gray'"><fa-icon [icon]="iconCreator"></fa-icon></app-badge>

        <div *ngIf="(usersService.loggedInUser | async)?.role != 'creator' && user.userId == (usersService.loggedInUser | async)?.userId">
            <span title="Leave this list" (click)="leaveList()" *ngIf="user.role != 'creator'"
                class="cursor-pointer text-red-400 hover:text-red-600">
                <fa-icon [icon]="iconLeaveList"></fa-icon>
            </span>
        </div>

        <!-- Admin / User switch -->
        <ng-container *ngIf="(usersService.loggedInUser | async)?.role == 'creator'">
            <span (click)="removeUser(user)" *ngIf="user.role != 'creator'"
                class="cursor-pointer text-red-500 group-hover:inline-block hidden">
                <fa-icon [icon]="iconRemoveUser"></fa-icon>
            </span>
        </ng-container>
    </div>
    


</div>