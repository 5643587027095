import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Columns } from 'annex-tracker-backend';
import { Observable, combineLatest, map } from 'rxjs';
import { ColumnsService } from 'src/app/services/columns.service';

@Component({
  selector: 'app-column-detail',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './column-detail.component.html',
  styleUrl: './column-detail.component.scss'
})
export class ColumnDetailComponent implements OnInit {

  route = inject(ActivatedRoute)
  columnsService = inject(ColumnsService)

  column: Observable<Columns | undefined>

  constructor() {

    const columnName = this.route.paramMap.pipe(map(params => params.get('columnName')))
      
    this.column = combineLatest([columnName, this.columnsService.columns]).pipe(
      map(([columnName, columns]) => columns.find(x => x.name == columnName))
    )
  }

  ngOnInit(): void {


  }



}
