import { Component, inject } from '@angular/core';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from 'src/app/common/tailwind/button-secondary/button-secondary.component';
import { ActiveModal } from 'src/app/modal/modal.service';

@Component({
  selector: 'app-confirm-ai-titles',
  standalone: true,
  imports: [ButtonSecondaryComponent, ButtonPrimaryComponent],
  templateUrl: './confirm-ai-titles.component.html',
  styleUrl: './confirm-ai-titles.component.scss'
})
export class ConfirmAiTitlesComponent {

  activeModal = inject(ActiveModal)

}
