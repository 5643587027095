import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { faArrowsCross, faCheck, faRight, faSeedling, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Email, File } from 'annex-tracker-backend';
import { firstValueFrom, Subscription } from 'rxjs';
import { DocxAnalysisService, Mapping } from 'src/app/services/docx-analysis.service';
import { EmailService } from 'src/app/services/email.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { DiffPipe } from '../../../pipes/diff.pipe';
import { MailPreviewComponent } from '../../../multi-column/list-detail/doc-list/subview/inbox/mail-preview/mail-preview.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    host: {
        class: 'h-full flex-1 flex overflow-hidden'
    },
    styleUrls: ['./review.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, FormsModule, FaIconComponent, MailPreviewComponent, RouterOutlet, AsyncPipe, DiffPipe]
})
export class ReviewComponent {

  iconRemoval = faTrash
  iconCreation = faSeedling
  iconRight = faRight
  iconCheck = faCheck
  iconNewReference = faArrowsCross

  currentIndex?: number

  subscription?: Subscription

  leftString?: string
  rightString?: string

  email?: Email

  constructor(
    public docxAnalysis: DocxAnalysisService,
    public workstreamsService: WorkstreamsService,
    public emailService: EmailService,
    public router: Router,
    private route: ActivatedRoute
  ) { 

  }

  async show(mapping: Mapping) {
    console.log(mapping)

    const mappings = await firstValueFrom(this.docxAnalysis.mappings)

    this.currentIndex = mappings.indexOf(mapping)

    const left = this.docxAnalysis.leftAnalysis![mapping.leftIndex]
    const right = this.docxAnalysis.rightAnalysis![mapping.rightIndex]

    this.leftString = left?.fullMatches.join('\n\n[...]\n\n')
    this.rightString = right?.fullMatches.join('\n\n[...]\n\n')

  }

  async fileClicked(file: File) {
    console.log("file clicked", file)
    this.router.navigate(['file', file.id], { relativeTo: this.route })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }
}
