import { Component, HostListener, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { faLock, faLockOpen, faRightLongToLine } from '@fortawesome/pro-regular-svg-icons';
import { IPDFViewerApplication, NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService, NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AnalysisResult } from 'src/app/services/model/docx-analysis';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent, NgxExtendedPdfViewerModule]
})
export class PdfViewerComponent implements OnChanges, OnInit {

  iconTab = faRightLongToLine
  iconSearchAutomatically = faLockOpen
  iconNoSearchAutomatically = faLock

  @Input()
  searchTerm?: string | null

  @Input()
  pdfUrl!: string | null;

  @Input()
  analysisResult?: AnalysisResult | null

  searchAutomatically = true

  pdfLoaded = false;
  matchResult: any;

  @ViewChild(NgxExtendedPdfViewerComponent, { static: false })
  pdfViewer!: NgxExtendedPdfViewerComponent

  textContents: any[] = []

  dontFind = [] as string[]

  @HostListener("document:keydown.meta.arrowUp", ["$event"])
  @HostListener("document:keydown.meta.arrowDown", ["$event"])
  @HostListener("document:keydown.control.arrowUp", ['$event'])
  @HostListener("document:keydown.control.arrowDown", ['$event'])
  scroll(e: KeyboardEvent) {

    e.stopPropagation()

    const mainContainer = this.pdfViewer.root.nativeElement as HTMLElement;
    const scrollContainer = mainContainer.querySelector<HTMLDivElement>('#viewerContainer');

    scrollContainer?.scrollBy({
      top: e.key == 'ArrowUp' ? -100 : 100,
      behavior: "smooth"
    })
  }

  @HostListener("document:keydown.tab", ['$event'])
  nextSearch(event: KeyboardEvent) {
    event.preventDefault()
    this.findNext()
  }

  @HostListener("document:keydown.shift.tab", ['$event'])
  previousSearch(event: KeyboardEvent) {
    event.preventDefault()
    this.findPrevious()
  }

  constructor(
    private pdf: NgxExtendedPdfViewerService,
    private zone: NgZone,
  ) {}


  async search() {
    if (this.searchTerm == null) return
    if (!this.pdfLoaded) return

    // We might change the search term if we have aditional information, like the docx analysis

    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;

    this.updateDontFinds()


    PDFViewerApplication.eventBus.dispatch('find', {
      source: this,
      type: 'find',
      query: this.searchTerm,
      highlightAll: true,
      dontFind: this.dontFind
    })


  }

  async updateDontFinds() {
    if (this.analysisResult == null) {
      return
    }
    
    const thisMatch = this.analysisResult.analysis.find(analysis => `${analysis.prefix} ${analysis.reference}` == this.searchTerm)

    if (thisMatch == null) {
      return;
    }

    this.dontFind = this.analysisResult.analysis
      .filter(analysis => analysis.reference.startsWith(thisMatch.reference) && analysis.prefix == thisMatch.prefix)
      .filter(analysis => analysis != thisMatch)
      .map(analysis => `${analysis.prefix} ${analysis.reference}`)

      console.log(this.dontFind)

  }

  async onPdfLoaded() {
    this.pdfLoaded = true
    const viewerElement = (this.pdfViewer.root.nativeElement.querySelector("#viewerContainer") as HTMLElement)
    viewerElement.classList.add('my-scrollbar')
    this.search()
  }


  ngOnInit(): void {

  }

  async ngOnChanges(changes: SimpleChanges) {

    if (this.pdfLoaded && this.searchAutomatically) {
      this.search()
    }

  }

  public findPrevious() {
    //this.pdf.findPrevious()
  }

  public findNext() {
    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;

    PDFViewerApplication.eventBus.dispatch('find', {
      source: this,
      type: 'again',
      query: this.searchTerm,
      highlightAll: true,
      dontFind: this.dontFind
    })
    
  }

  updateFindMatchesCount(event: any) {
    this.zone.run(() => {
      this.matchResult = event
    })
  }

  updateFindState(event: any) {
    console.log(event)
  }

  

}
