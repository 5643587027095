<div class="divide-y">
    <div class="p-5">
        Change columns
    </div>
    <div class="p-5 text-sm flex gap-2 overflow-x-auto my-scrollbar">

        @for (column of columnsService.columns | async; track $index) {
        <div class="px-2 cursor-pointer border rounded-md whitespace-nowrap" [routerLink]="[column.name]" routerLinkActive="bg-indigo-600 text-white">
            {{ column.label }}
        </div>
        }

    </div>
    <router-outlet></router-outlet>
    <div class="p-5 space-y-2">
        @if (addMode) {
        <div class="border rounded-md flex w-full">
            <input #newColumnInput type="text" class="w-32 grow py-0 px-2 border-none border-r-1 rounded-md" placeholder="New column name">
        </div>
        <div class="flex justify-end gap-2">
            
            <app-button-secondary (click)="addMode = false">
                Cancel
            </app-button-secondary>
            <app-button-primary [disabled]="newColumnInput.value.length < 3" (click)="addColumn(newColumnInput.value)">
                Add
            </app-button-primary>
        </div>
        
        }
    </div>
</div>