import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NullableId } from '@feathersjs/feathers';
import { map, Subscription, tap } from 'rxjs';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { SubviewHeaderComponent } from './subview-header/subview-header.component';

@Component({
    selector: 'app-subview',
    templateUrl: './subview.component.html',
    host: {
        class: 'flex-1 min-h-full flex flex-col'
    },
    styleUrls: ['./subview.component.scss'],
    standalone: true,
    imports: [SubviewHeaderComponent, RouterOutlet]
})
export class SubviewComponent implements OnDestroy {

  subscription: Subscription

  lastComponentPath?: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workstreamsService: WorkstreamsService,
  ) { 

    this.subscription = this.route.paramMap.pipe(
      map(params => (Number(params.get("workstreamId")) || null)),
    ).subscribe(this.workstreamsService.currentWorkstreamId)
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
