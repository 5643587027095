<table id="print-table" class="w-full rounded-lg overflow-hidden text-xs page-break-after" *ngIf="data">

    <thead class="divide-y divide-gray-100">
        <tr class="bg-gray-300 text-gray-900" [style.background-color]="tableHeaderBackgroundColor"
            [style.color]="tableHeaderTextColor">
            <th colspan="1" class="w-0  text-right  p-1">#</th>
            <th colspan="2" class="w-0  text-center px-2">{{'reference_column_label' | translate:data.project.language}}</th>
            <th colspan="1" class="w-64 text-left   px-2">{{'title_column_label' | translate:data.project.language}}</th>
            <ng-container *ngFor="let column of data.columns | filterHidden">

                <ng-container [ngSwitch]="column.type">

                    <th *ngSwitchDefault class="text-center px-2">
                        {{ column.label }}
                    </th>
                    <th *ngSwitchCase="'enum-dropdown'" class="w-0 text-center p-2 relative">
                        <a class="cursor-pointer hover:underline">{{ column.label }}</a>
                        <app-horizontal-bar-chart class="absolute bottom-0 w-full left-0 px-1"
                            [columnName]="column.name"></app-horizontal-bar-chart>
                    </th>

                </ng-container>
            </ng-container>

        </tr>
    </thead>

    <tbody class="">
        <tr [attr.id]="ws.id" *ngFor="let ws of hierarchicalWorkstreams; let index = index; let isFirst = first"
            class="odd:bg-white even:bg-gray-50 text-gray-900"
            [class.page-break-before]="ws.parentId == null && !isFirst" [class.font-bold]="ws.parentId == null">

            <!-- Number : always only one row so this defined the minimum row height -->
            <td class="w-0 py-1 px-2 whitespace-nowrap text-right">{{index+1}}</td>

            <!-- Prefix -->
            <td [style.padding-left.px]="(ws.depth || 0) * 10" class="w-0 px-1 whitespace-nowrap text-left">{{ws.properties['prefix']}}</td>

            <!-- Reference -->
            <td class="w-0 px-1 whitespace-nowrap font-medium">{{ws.properties['reference']}}</td>

            <!-- Title -->
            <td class="w-64 px-2 py-1 whitespace-pre-wrap">{{ws.properties['name']}}</td>

            <ng-container *ngFor="let column of data.columns | filterHidden">

                <!-- switch statement -->
                <ng-container [ngSwitch]="column.type">

                    <td *ngSwitchCase="'text'" class="w-64 px-2 py-1 whitespace-pre-wrap"
                        [innerHTML]="ws.properties?.[column.name]">
                    </td>

                    <td *ngSwitchCase="'enum-dropdown'" class="w-0 text-center p-1">
                        <app-badge-dropdown [badges]="column.configuration"
                            [value]="ws.properties?.[column.name]"></app-badge-dropdown>
                    </td>

                </ng-container>

            </ng-container>
        </tr>
    </tbody>

</table>