import { Injectable, Injector, Type, ViewContainerRef } from '@angular/core';

export class ActiveModal {

  constructor(public close: any, public dismiss: any) { }

}

export interface ModalOptions {
  escapeCloses: boolean,
  addModalContentClass: boolean
}

export interface ModalStackItem {
  injector?: Injector,
  active: boolean
  component: Type<any>,
  modalOptions: ModalOptions
  activeModal: ActiveModal
}

export class ModalContext {
  [value: string]: any
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  defaults = {
    escapeCloses: true,
    addModalContentClass: true
  }

  public viewContainer: ViewContainerRef | unknown;
  public isActive = false;

  public activeModal: ActiveModal | undefined;

  public modalStack = [] as ModalStackItem[]

  private modalCount = 0

  constructor(private injector: Injector) {
    window.addEventListener("keydown", (event) => {
      if (event.key == "Escape") {

        if (this.modalStack.length > 0) {

          if (this.modalStack[this.modalStack.length - 1].modalOptions.escapeCloses) {
            this.close(this.modalStack[this.modalStack.length - 1].activeModal)
          }
        }

      }
    })
  }

  public async openModal<T>(component: Type<T>, context: any = {}, options?: Partial<ModalOptions>) {

    options = Object.assign({}, this.defaults, options)
    console.log(options)

    this.modalCount++
    return new Promise((res, rej) => {

      const activeModal = new ActiveModal((answer: any) => {
        this.close(activeModal)
        res(answer)
      }, (reason: any) => {
        this.close(activeModal)
        rej(reason)
      })

      const thisInjector = Injector.create({
        providers: [
          {
            useValue: context,
            provide: 'context'
          },
          {
            provide: ActiveModal, useValue: activeModal
          }],
        parent: this.injector,
        name: 'Modal injector'
      });

      const modalStackItem = {
        injector: thisInjector,
        component,
        active: false,
        modalOptions: options,
        activeModal
      } as ModalStackItem

      setTimeout(() => {
        modalStackItem.active = true
      }, 1)

      this.modalStack.push(modalStackItem)

      this.checkScrollbar()

    })

  }

  public close(modal: ActiveModal) {
    this.modalCount--

    if (this.modalStack.length > 0) {

      const index = this.modalStack.findIndex(modalStackItem => modalStackItem.activeModal == modal)
      this.modalStack[index].active = false
      setTimeout(() => {
        const index = this.modalStack.findIndex(modalStackItem => modalStackItem.activeModal == modal)
        this.modalStack.splice(index, 1)
      }, 300)
    }

    this.checkScrollbar()
  }

  private checkScrollbar() {
    if (this.modalCount > 0) {
      this.hideScrollbar()
    } else {
      this.showScrollbar()
    }

  }

  private hideScrollbar() {
    const html = document.querySelector("html")
    if (html != null) {
      html.classList.add("is-clipped")
    }
  }

  private showScrollbar() {
    const html = document.querySelector("html")
    if (html != null) {
      html.classList.remove("is-clipped")
    }
  }


}
