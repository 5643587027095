"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkstreamChildrenRecursive = void 0;
function getWorkstreamChildrenRecursive(ws) {
    const recursiveChildren = (ws) => {
        if (ws.children == null || ws.children.length === 0) {
            return [ws];
        }
        else {
            return [ws, ...ws.children.flatMap(recursiveChildren)];
        }
    };
    if (ws.children == null || ws.children.length === 0) {
        return [];
    }
    return ws.children.flatMap(recursiveChildren);
}
exports.getWorkstreamChildrenRecursive = getWorkstreamChildrenRecursive;
