<div class="h-full divide divide-y flex flex-col">
    <div class="flex justify-between">
        <div class="p-5 font-medium text-lg">

            <span class="bg-slate-200 rounded px-1.5 py-1">{{file?.filename}}</span>
        </div>
    </div>

    <div class="overflow-hidden flex-1 flex p-5 pb-0 pr-0 pt-0 divide-x">

        <div class="w-1/2">
            <app-pdf-viewer [pdfUrl]="pdfUrl" [searchTerm]="searchTerm"></app-pdf-viewer>
        </div>

        <div class="w-1/2 flex flex-col h-full divide-y">

            <div class="overflow-y-auto my-scrollbar grow p-5">
                @switch (step) {
                @case ('select-language') {
                <app-select-analysis-language [(searchTerm)]="searchTerm" [data]="analysis"
                    [selectedLanguage]="selectedLanguage"></app-select-analysis-language>
                }
                @case ('check-insert-position') {
                <app-check-insert-position [(insertItems)]="insertItems"></app-check-insert-position>
                }
                }
            </div>

            <nav class="p-4 flex justify-between items-end">

                @switch (step) {
                @case ('select-language') {

                @if((this.analysis?.languages.length || 0) > 1) {
                <div class="flex justify-center items-start">
                    <div class="flex-col items-end pr-4">
                        <dt class="text-sm text-right py-0.5">Language</dt>
                        <dt class="text-xs py-2 max-w-64 text-right">
                            Please select the preferred language for the list of workstreams in your tracker
                        </dt>
                    </div>
                    <app-language-selector class="flex flex-row gap-2"
                        [(language)]="selectedLanguage"></app-language-selector>
                </div>
                }
                <div class="flex gap-2 items-end flex-wrap">
                    <button class="border button-secondary rounded-md px-2 py-1 hover:bg-slate-100 transition-colors text-xs"
                        (click)="close()">Cancel</button>
                    @if(selectedLanguage != null) {
                    @if((workstreams?.length || 0) > 0) {

                    <button class="button-primary" (click)="step = 'check-insert-position'; process()">
                        Next
                    </button>
                    } @else {
                    <button class="button-primary" (click)="process()">
                        Create Workstreams <fa-icon *ngIf="loading" class="ml-2" [spin]="true"
                            [icon]="iconLoading"></fa-icon>
                    </button>
                    }
                    }
                </div>
                }


                @case ('check-insert-position') {
                @if(!loading) {
                <button class="border rounded-md px-2 py-1 hover:bg-slate-100 transition-colors text-xs"
                    (click)="step = 'select-language'">Previous</button>
                }

                <button [attr.disabled]="loading ? '' : null" class="button-primary" (click)="apply()">
                    Add workstreams <fa-icon *ngIf="loading" class="ml-2" [spin]="true"
                        [icon]="iconLoading"></fa-icon></button>
                }
                }

            </nav>

        </div>

    </div>

</div>