<div class="divide divide-y">
    <div class="px-5 pb-5 font-medium text-lg">User profile</div>
    <div class="space-y-6 p-5">
        <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
            <div class="mt-1">
                <input id="email" disabled [value]="email" name="email" type="email" 
                    required
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            </div>
        </div>

        <div class="space-y-1">
            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
            <div class="mt-1 flex rounded-md border border-gray-300 overflow-hidden divide-x">
                <input disabled id="password" value="adsfadslkfjsad" name="password" type="password"
                    autocomplete="current-password" required
                    class="block w-full appearance-none outline-0 border-0 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm">
                <button class="px-4 text-sm font-medium hover:bg-gray-200" (click)="changePassword()">Change</button>
            </div>
        </div>
    </div>

    <div class="p-5">
        <div>Two-factor authentication</div>
        
        <p class="text-sm mt-2">
            <ng-container *ngIf="twoFa?.enabled; else: notEnabled">
            The two factor authentication is enabled. Would you like to <a (click)="activateTwoFa()" class="cursor-pointer text-blue-500 underline">disable</a> it?
            </ng-container>
            <ng-template #notEnabled>
            <a (click)="activateTwoFa()" class="cursor-pointer text-blue-500 underline">Activate</a> two factor authentication.
            </ng-template>

        </p>    
    </div>

    <app-imanage-connect></app-imanage-connect>

    <div class="p-5">
        <a class="underline hover:no-underline" href="assets/legal/230208_terms_annex_lawyer.pdf"
            target="_blank">Terms and Conditions</a>
        <a class="underline hover:no-underline float-right" href="assets/legal/230208_privacy_annex_lawyer.pdf"
            target="_blank">Privacy Policy</a>
    </div>

    <div class="p-5 flex justify-between">
        <app-button-secondary (click)="logout()">Logout</app-button-secondary>
        <app-button-danger (click)="deleteUserModal()">Delete Account</app-button-danger>
    </div>

    <div class="p-5 text-xs ">
        <a class="cursor-pointer hover:underline" (click)="showChangelog()">Version {{environment.version}}</a>
    </div>

</div>