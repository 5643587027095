import { Component, Input } from '@angular/core';
import { PrintData } from '../print.component';
import { MyDatePipe } from '../../../pipes/my-date.pipe';
import { NgSwitch, NgSwitchDefault, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-cover-sheet',
    templateUrl: './cover-sheet.component.html',
    styleUrls: ['./cover-sheet.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchDefault, NgSwitchCase, MyDatePipe]
})
export class CoverSheetComponent {

  @Input() data!: PrintData

}
