import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, timer } from 'rxjs';
import { format } from 'timeago.js';

@Pipe({
    name: 'timeAgo',
    standalone: true
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string | number | Date, ...args: unknown[]): Observable<string> {

    const date = new Date(value)

    return timer(0, 15000).pipe(map(() => {
      return format(date)
    }));
  }

}
