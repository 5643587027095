<div class="flex items-stretch h-full divide-x outline-none" appAutoFocus tabindex="-1">
  <main class="flex-1 flex flex-col h-full">

    <div class="p-0 pt-0 flex-1 overflow-y-auto my-scrollbar">
      <ul role="list" class="p-4">
        <li *ngFor="let mail of emailService.populatedEmails$ | async; let isLast = last" app-mail-preview [mail]="mail"
          [currentWorkstreamId]="null" [isLast]="isLast" (fileClicked)="fileClicked($event)">
        </li>
      </ul>

    </div>

  </main>
  <router-outlet></router-outlet>
</div>