<div class="page-break-after m-0 h-[100vh]" *ngIf="true">

    <app-cover-sheet *ngIf="data" [data]="data"></app-cover-sheet>
    
</div>

<section class="page-break-after" *ngIf="data?.hasComparison">

    <app-tracker-diff *ngIf="data" [data]="data"></app-tracker-diff>
    
</section>

<div class="page-break-after">
    <app-executive-summary *ngIf="data"
        [data]="data"
        [fontFamily]="fontFamily"
        [summary]="summary">
    </app-executive-summary>
</div>

<div class="page-break-after">
    <app-workstream-table *ngIf="data" 
        [data]="data"
        [tableHeaderTextColor]="tableHeaderTextColor"
        [tableHeaderBackgroundColor]="tableHeaderBackgroundColor">
    </app-workstream-table>
</div>