import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, RouterOutlet } from '@angular/router';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { WsTableComponent } from 'src/app/multi-column/list-detail/doc-list/workstreams-table/ws-table.component';
import { HeaderButtonsService } from 'src/app/header-buttons.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { WorkstreamPropertyService } from 'src/app/services/workstream-property.service';
import { Workstream } from 'annex-tracker-backend';
import { lastValueFrom } from 'rxjs';
import { UploadService } from 'src/app/services/upload.service';
import { WsTableComponent as WsTableComponent_1 } from './workstreams-table/ws-table.component';
import { ZipExportComponent } from '../../../modals/list-settings/zip-export/zip-export.component';
import { ExportCompareButtonComponent } from './export-compare-button/export-compare-button.component';
import { AddMainButtonComponent } from './add-main-button/add-main-button.component';
import { ToggleComponent } from '../../../common/tailwind/toggle/toggle.component';
import { ListEmailDisplayComponent } from '../../../common/list-email-display/list-email-display.component';
import { ModalService } from 'src/app/modal/modal.service';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { SelectAnalysisLanguageComponent } from '../../modals/add-main-modal/select-analysis-language/select-analysis-language.component';

@Component({
    selector: 'app-doc-list',
    templateUrl: './doc-list.component.html',
    host: {
        class: 'flex-1 min-h-0'
    },
    styleUrls: ['./doc-list.component.scss'],
    standalone: true,
    imports: [ListEmailDisplayComponent, ToggleComponent, AddMainButtonComponent, ExportCompareButtonComponent, ZipExportComponent, WsTableComponent_1, RouterOutlet]
})
export class DocListComponent implements OnInit, OnDestroy {

  iconInfo = faInfoCircle

  showDeleted = false

  @ViewChild("template", { static: true })
  templateRef!: TemplateRef<any>

  @ViewChild(WsTableComponent)
  wsTable!: WsTableComponent

  constructor(
    public buttonService: HeaderButtonsService,
    private router: Router,
    private route: ActivatedRoute,
    private workstreamService: WorkstreamsService,
    public propertyService: WorkstreamPropertyService,
    private uploadService: UploadService,
    private modalService: ModalService,
    private docxAnalysisService: DocxAnalysisService
  ) { }

  ngOnInit(): void {
    this.buttonService.template = this.templateRef
  }

  ngOnDestroy(): void {
    this.buttonService.template = null
  }

  async wsSelected(workstream: Workstream) {

    if (workstream == null) {
      this.router.navigate(['..'], { relativeTo: this.route })
    } else {

      // We'll modifiy the URL directly, because we want to keep the sub-navigation. This will
      // only work if the URL already contains a workstream ID.
      const tree = this.router.parseUrl(this.router.url)
      const g = tree.root.children[PRIMARY_OUTLET]
      if (g.segments.length < 4) {
        this.router.navigate(['workstream', workstream.id], { relativeTo: this.route })
      } else {
        g.segments[4].path = `${workstream.id}`;
        this.router.navigateByUrl(tree.toString())
      }
    }

  }

  async addMain(file: File) {
    
    const uploadedFile = await this.uploadService.simpleUpload(file)
    // const analysis = await this.docxAnalysisService.getOrCreateAnalysis(uploadedFile.id)

    // let language = undefined as 'de' | 'en' | undefined
    // if (analysis.languages.length > 1) {
    //   language = await this.modalService.openModal(SelectAnalysisLanguageComponent, analysis, { escapeCloses: false }) as 'de' | 'en'
    // }

    await this.router.navigate(['./'], { relativeTo: this.route })

    // navigate to modal with file id
    await this.router.navigate([{ outlets: { modal: ['fullscreen-panel', 'add-main', uploadedFile.id] } }])

  }

}
