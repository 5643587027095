import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { ProjectsService } from './projects.service';
import { makeObservableList } from './helpers/observable-service';
import { combineLatest, debounceTime, firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { sortDescending } from './model/base-entity';
import { WorkstreamsService } from './workstreams.service';
import { Id } from '@feathersjs/feathers';
import { Contact, ContactToEmail, Email, Workstream, WorkstreamToEmail } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  populatedEmails$: Observable<Email[]>
  contacts$: Observable<Contact[]>
  unassignedAttachmentsCount$: Observable<number>
  
  private emails$: Observable<Email[]>
  private emailsToContacts$: Observable<ContactToEmail[]>
  private emailsToWorkstreams$: Observable<WorkstreamToEmail[]>

  constructor(
    private backend: BackendService,
    projectService: ProjectsService,
    private workstreamService: WorkstreamsService
  ) {

    this.emails$ = makeObservableList(backend.email, async x => x, projectService.projectIdQuery, projectService.projectIdFilter).pipe(
      map(emails => emails.sort(sortDescending))
    )
    this.contacts$ = makeObservableList(backend.emailContact, async x => x, projectService.projectIdQuery, projectService.projectIdFilter)
    this.emailsToContacts$ = makeObservableList(backend.emailsToEmailContacts, async x => x, projectService.projectIdQuery, projectService.projectIdFilter)
    this.emailsToWorkstreams$ = makeObservableList(backend.emailsToWorkstreams, async x => x, projectService.projectIdQuery, projectService.projectIdFilter)

    this.populatedEmails$ = combineLatest([
      this.emails$,
      this.contacts$,
      this.emailsToContacts$,
      this.emailsToWorkstreams$,
      this.workstreamService.workstreams$,
      this.workstreamService.files
    ]).pipe(debounceTime(100), map(([emails, contacts, emailsToContacts, emailsToWorkstreams, workstreams, files]) => {
      // console.log( { emails, contacts, emailsToContacts, emailsToWorkstreams, workstreams, files })
      // console.time("popuplateEmails")
      emails.forEach(email => {
        email.from = emailsToContacts
          .filter(x => x.emailId == email.id && x.type == 'from')
          .map(e2c => contacts.find(contact => contact.id == e2c.contactId))[0]

        email.to = emailsToContacts
          .filter(x => x.emailId == email.id && x.type == 'to')
          .map(e2c => contacts.find(contact => contact.id == e2c.contactId)!)

        email.cc = emailsToContacts
          .filter(x => x.emailId == email.id && x.type == 'cc')
          .map(e2c => contacts.find(contact => contact.id == e2c.contactId)!)

        email.workstreams = emailsToWorkstreams
          .filter(x => x.emailId == email.id)
          .map(e2w => {
            let ws = workstreams.find(w => w.id == e2w.workstreamId)

            if (ws != null) {
              ws.emailToWorkstream = e2w
            }

            return ws
          })
          .filter(x => x != null) as Workstream[]

        email.files = files.filter(x => x.emailId == email.id)

      })
      return emails
    }),
    map(emails => emails.filter(email => email.hidden != true)),
    shareReplay(1))

    this.unassignedAttachmentsCount$ = this.populatedEmails$.pipe(
      map(emails => emails.map(x => x.files).flat()),
      map(files => files.filter(x => x?.workstreamId == null).length), shareReplay(1)
    )
    
    this.init()
  }

  async init() {

  }

  async deleteEmail(email: Email) {
    return this.backend.email.remove(email.id)
  }

  async addWorkstreamToEmail(workstreamId: number, emailId: number) {
    return this.backend.emailsToWorkstreams.create({
      workstreamId: workstreamId,
      emailId: emailId
    })
  }

  async removeWorkstreamFromEmail(workstreamId: Id, emailId: Id) {

    const emailsToWorkstreams = await firstValueFrom(this.emailsToWorkstreams$)
    const emailToWorkstream = emailsToWorkstreams.find(x => x.emailId == emailId && x.workstreamId == workstreamId)

    if (emailToWorkstream != null) {
      try {
        console.log(emailToWorkstream)
        await this.backend.emailsToWorkstreams.remove(emailToWorkstream.id)
      } catch (e) {
        console.log(e)
      }
    } else {
      console.error("Could not find email to workstream")
      throw new Error("Could not find email to workstream")
    }

  }
}
