import { Component, HostBinding, OnInit } from '@angular/core';
import { faArrowLeft, faList } from '@fortawesome/pro-regular-svg-icons';
import { ModalService } from 'src/app/modal/modal.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { AddListModalComponent } from '../modals/add-list-modal/add-list-modal.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-no-list-selected',
    templateUrl: './no-list-selected.component.html',
    styleUrls: ['./no-list-selected.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent, AsyncPipe]
})
export class NoListSelectedComponent implements OnInit {

  iconList = faList
  iconArrow = faArrowLeft

  show = false

  @HostBinding("class")
  classes = 'flex-1 flex items-center justify-center'

  constructor(public projects: ProjectsService, private modalService: ModalService) { }

  async showModal() {
    try {
      await this.modalService.openModal(AddListModalComponent)
    } catch (e) {
      console.log(e)
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.show = true
    }, 500)
  }

}
