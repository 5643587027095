import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faHousePersonReturn } from '@fortawesome/pro-thin-svg-icons';
import { Columns } from 'annex-tracker-backend';
import { ToastrService } from 'ngx-toastr';
import { Subscription, map, switchMap } from 'rxjs';
import { ColumnsService } from 'src/app/services/columns.service';
import { EditEnumRadioComponent } from './edit-enum-radio/edit-enum-radio.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgSwitch } from '@angular/common';

@Component({
    selector: 'app-edit-column',
    templateUrl: './edit-column.component.html',
    styleUrls: ['./edit-column.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgSwitch, EditEnumRadioComponent]
})
export class EditColumnComponent implements OnDestroy {

  column?: Columns

  subscription: Subscription

  constructor(
    public columnsService: ColumnsService,
    public route: ActivatedRoute,
    public toastService: ToastrService
  ) {

    this.subscription = this.route.paramMap.pipe(
      map(params => +(params.get('columnId') || 0)),
      switchMap(columnId =>
        this.columnsService.columns.pipe(map(columns => columns.find(column => column.id == columnId)))
      ))
      .subscribe(column => this.column = column)

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async changeLabel(newLabel: string) {
    if (this.column == null) {
      return
    }

    if (newLabel?.length < 2) {
      this.toastService.info("Label must be at least 2 characters long")
      return
    }

    try {
      await this.columnsService.changeLabel(this.column.id, newLabel)
      this.toastService.success("Label changed")
    } catch {
      this.toastService.error("Could not change label")
    }
  }

}
