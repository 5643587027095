import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WorkstreamPropertyService } from '../services/workstream-property.service';
import { Workstream } from 'annex-tracker-backend';

@Directive({
    selector: '[appWorkstreamInlineEdit]',
    standalone: true
})
export class WorkstreamInlineEditDirective implements OnInit {

  @Input("appWorkstreamInlineEdit")
  workstream!: Workstream

  @Input()
  propertyName!: string

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private propertyService: WorkstreamPropertyService
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.innerText = this.workstream.properties?.[this.propertyName] ?? ""
  }

  @HostListener("blur", ['$event'])
  onBlur(event: KeyboardEvent) {

    event.preventDefault()

    let text = (event.target as HTMLElement).innerText;

    if (text == null)
      return

    if (this.elementRef.nativeElement.innerText == this.workstream.properties?.[this.propertyName]) {
      return
    }

    this.propertyService.setTo(this.propertyName, text.trim(), this.workstream)

  }

  @HostListener("keydown.escape")
  blur() {
    this.elementRef.nativeElement.blur()
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    let paste = event.clipboardData?.getData('text');

    const selection = window.getSelection();

    if (selection == null) return
    if (!selection.rangeCount) return;
    if (paste == null) return

    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    selection.collapseToEnd();
  }

}
