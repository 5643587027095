import { Injectable, inject } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, firstValueFrom, map, shareReplay } from 'rxjs';
import { Columns } from 'annex-tracker-backend';
import { ProjectsService } from './projects.service';
import { makeObservableList } from './helpers/observable-service';
import { ToastrService } from 'ngx-toastr';
import slugify from 'slugify';

@Injectable({
  providedIn: 'root'
})
export class ColumnsService {

  notificationService = inject(ToastrService)

  public columns: Observable<Columns[]>

  constructor(private backend: BackendService, private projectService: ProjectsService) {

    this.columns = makeObservableList(
      backend.columns,
      x => Promise.resolve(x),
      projectService.projectIdQuery,
      projectService.projectIdFilter
    ).pipe(
      map(columns => columns.sort((a, b) => a.index - b.index)),
      shareReplay(1)
    )
  }

  async createColumn(label: string, type: 'text' | 'enum-dropdown') {

    if (label == "new") {
      this.notificationService.error("Invalid column name ('new' is reserved)")
      return
    }

    const projectId = await this.projectService.getCurrentProjectId()

    if (projectId == null) {
      return
    }

    const currentColumns = await firstValueFrom(this.columns)

    const name = slugify(label, {lower: true})

    if (currentColumns.find(x => x.name == name)) {
      this.notificationService.error("Column already exists")
      return
    }

    const newColumn = await this.backend.columns.create({
      index: currentColumns.length,
      name: label,
      label,
      projectId,
      type
    })

    this.notificationService.success("Column created")

    return newColumn
  }

  async changeColumn(columnId: number, title: string) {

    // return await this.backend.columns.patch(columnId, {
    //   title,
    //   configuration: { foo: 'bar'}
    // })
  }

  async changeLabel(columnId: number, label: string) {

    console.log("change label", columnId, label)

    return await this.backend.columns.patch(columnId, {
      label
    })
  }

  async changeConfiguration(columnId: number, configuration: any) {

    return await this.backend.columns.patch(columnId, {
      configuration
    })
  }

  async setColumnHidden(columnId: number, hidden: boolean) {

    return await this.backend.columns.patch(columnId, {
      hidden
    })
    
  }

}
