import { JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { SanitizePipe } from 'src/app/pipes/sanitize.pipe';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { FilesService } from 'src/app/services/files.service';
import { AnalysisResult } from 'src/app/services/model/docx-analysis';
import { WorkstreamsService } from 'src/app/services/workstreams.service';

@Component({
  selector: 'app-debug',
  standalone: true,
  imports: [JsonPipe, SanitizePipe],
  templateUrl: './debug.component.html',
  styleUrl: './debug.component.scss'
})
export class DebugComponent implements OnInit {

  activeRoute = inject(ActivatedRoute)
  workstreamService = inject(WorkstreamsService)
  analysisService = inject(DocxAnalysisService)
  fileService = inject(FilesService)
  httpClient = inject(HttpClient)

  workstream?: HWorkstream
  root?: HWorkstream
  analysis?: AnalysisResult
  txtFileUrl?: string
  completeText?: string
  references?: any[]

  showMatches = false;
  showTxt = false

  async ngOnInit() {
    
    const workstreamId = this.activeRoute.snapshot.paramMap.get('workstreamId')

    if (workstreamId == null) {
      return
    }


    const wss = await firstValueFrom(this.workstreamService.workstreams$)

    this.workstream = wss.find(ws => ws.id == +workstreamId)

    let rootWorkstream = this.workstream

    // parent by going up the tree
    while (rootWorkstream?.parentId != null) {
      rootWorkstream = wss.find(ws => ws.id == rootWorkstream?.parentId)
    }

    this.root = rootWorkstream

    // get property based on analysis
    const analysisId = this.root?.properties.basedOnAnalysis
    
    this.analysis = await this.analysisService.getOrCreateAnalysis(analysisId)


    this.references = this.analysis.analysis.filter(occurance => occurance.reference == this.workstream?.properties.reference)

    this.txtFileUrl = await this.fileService.fileToTextUrl(+analysisId)
    
    if (this.txtFileUrl) {

      this.completeText = await lastValueFrom(this.httpClient.get(this.txtFileUrl, {responseType: 'text'}))
    }

        

  }


}
