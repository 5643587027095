import { Component, HostBinding, NgZone, OnInit } from '@angular/core';
import { Columns, Projects, Workstream } from 'annex-tracker-backend';
import { BadgeDescription } from 'src/app/common/badge-dropdown/badge-dropdown.component';
import { ownerOptions, statusOptions } from 'src/app/multi-column/list-detail/doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { ColorService } from 'src/app/services/color.service';
import { sortWorkstreamHierarchical } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical'
import { ActivatedRoute } from '@angular/router';
import { WorkstreamTableComponent } from './workstream-table/workstream-table.component';
import { ExecutiveSummaryComponent } from './executive-summary/executive-summary.component';
import { TrackerDiffComponent } from './tracker-diff/tracker-diff.component';
import { CoverSheetComponent } from './cover-sheet/cover-sheet.component';
import { NgIf } from '@angular/common';

const appearanceCustomizations = {
  'trlegal.de': {
    fontFamily: 'Palatino Linotype',
    tableHeaderBackgroundColor: '#0a7a8cff',
    tableHeaderTextColor: '#ffffff',
  },
  'taylorwessing.com': {
    fontFamily: 'Arial',
  }
} as { [key: string]: { fontFamily: string, tableHeaderBackgroundColor?: string, tableHeaderTextColor?: string } }

export interface PrintData 
  {
  project: Projects
  organization ?: string
  columns: Columns[]
  hasComparison: boolean
  workstreams: Workstream[]
  added: Workstream[]
  removed: Workstream[]
  renamed: {
    before: Workstream
    after: Workstream
  } []
  firstDate: number
  secondDate: number
}

export interface Summary {
  column: Columns,
  data: any[]
  statusLabels: string[]
  statusColors: string[]
}

@Component({
    selector: 'app-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
    standalone: true,
    imports: [NgIf, CoverSheetComponent, TrackerDiffComponent, ExecutiveSummaryComponent, WorkstreamTableComponent]
})
export class PrintComponent implements OnInit {

  @HostBinding('style.font-family') 
  fontFamily?: string

  tableHeaderBackgroundColor?: string
  tableHeaderTextColor?: string

  summary?: Summary[]

  filteredByStatus?: {
    color: string
    badgeDescription: BadgeDescription
    workstreams: Workstream[]
  }[]
  filteredByOwner?: {
    color: string
    badgeDescription: BadgeDescription
    workstreams: Workstream[]
  }[]

  data?: PrintData

  constructor(
    private ngZone: NgZone,
    private colorService: ColorService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {

    let w = window as any

    w.setData = (data: any) => {
      this.ngZone.run(() => {
        this.data = data
        this.process()
      })
    }

    if (this.route.snapshot.queryParamMap.get("dev")) {
      setTimeout(() => {
        w.setData(data)
      }, 300)
    }

  }

  process() {

    // appearance customizations
    if (this.data?.organization != null && appearanceCustomizations[this.data.organization] != null) {
      const customization = appearanceCustomizations[this.data.organization]
      this.fontFamily = customization.fontFamily
      this.tableHeaderBackgroundColor = customization.tableHeaderBackgroundColor
      this.tableHeaderTextColor = customization.tableHeaderTextColor
    } else {
      this.fontFamily = undefined
      this.tableHeaderBackgroundColor = undefined
      this.tableHeaderTextColor = undefined
    }

    this.data!.workstreams = sortWorkstreamHierarchical(this.data!.workstreams)

    this.summary = this.data!.columns
      .filter(c => c.type == 'enum-dropdown')
      .map(column => {

        const columnProperties = this.data!.workstreams.map(row => row.properties[column.name])
        const data = column.configuration.map((o: any) => columnProperties.filter(p => p == o.value).length)

        return {
          column,
          data,
          statusLabels: column.configuration.map((o: any) => o.label as string),
          statusColors: column.configuration.map((o: any) => this.colorService.getColor(o.color, 300) as string)
        }

      })

    // filter by status
    this.filteredByStatus = statusOptions.map(o => ({
      color: this.colorService.getColor(o.color, 100),
      badgeDescription: o,
      workstreams: this.data!.workstreams.filter(row => row.properties['status'] == o.value)
    }))

    // filter by owner
    this.filteredByOwner = ownerOptions.map(o => ({
      color: this.colorService.getColor(o.color, 100),
      badgeDescription: o,
      workstreams: this.data!.workstreams.filter(row => row.properties['owner'] == o.value)
    }))

  }

}

const data = {
  project: {
    id: 179,
    shortId: null,
    name: "Annex List 1",
    createdAt: 1712214673505,
    updatedAt: 1712214673505,
    archived: 0,
    language: "en",
  },
  columns: [
    {
      id: 713,
      label: "Notifications",
      name: "notifications",
      type: "text",
      index: 0,
      hidden: 0,
      projectId: 179,
      createdAt: 1712214673546,
      updatedAt: 1712214673546,
      configuration: null,
    },
    {
      id: 714,
      label: "Comments",
      name: "comments",
      type: "text",
      index: 1,
      hidden: 0,
      projectId: 179,
      createdAt: 1712214673546,
      updatedAt: 1712214673546,
      configuration: null,
    },
    {
      id: 715,
      label: "Owner",
      name: "owner",
      type: "enum-dropdown",
      index: 2,
      hidden: 0,
      projectId: 179,
      createdAt: 1712214673546,
      updatedAt: 1712214673546,
      configuration: [
        {
          color: "gray",
          label: "tbd",
          value: "tbd",
        },
        {
          color: "green",
          label: "Seller",
          value: "seller",
        },
        {
          color: "blue",
          label: "Buyer",
          value: "buyer",
        },
        {
          color: "purple",
          label: "Notary",
          value: "notary",
        },
        {
          color: "yellow",
          label: "Client",
          value: "client",
        },
      ],
    },
    {
      id: 716,
      label: "Status",
      name: "status",
      type: "enum-dropdown",
      index: 3,
      hidden: 0,
      projectId: 179,
      createdAt: 1712214673546,
      updatedAt: 1712214673546,
      configuration: [
        {
          color: "orange",
          label: "Open",
          value: "open",
        },
        {
          color: "gray",
          label: "Draft",
          value: "draft",
        },
        {
          color: "emerald",
          label: "Agreed",
          value: "agreed",
        },
        {
          color: "purple",
          label: "Signed",
          value: "signed",
        },
        {
          color: "indigo",
          label: "Notarized",
          value: "notarized",
        },
      ],
    },
  ],
  workstreams: [
    {
      id: 3352,
      index: 0,
      projectId: 179,
      parentId: null,
      createdAt: null,
      updatedAt: null,
      properties: {
        basedOnAnalysis: "397",
        name: "GESSI-Exit-Muster-SPA",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3353,
      index: 0,
      projectId: 179,
      parentId: 3354,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Allocation of Euros to Individual Sellers",
        reference: "4.2.1",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3354,
      index: 0,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        notifications: "",
        name: "Escrow Distribution Conditions",
        reference: "4.4",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3355,
      index: 1,
      projectId: 179,
      parentId: 3354,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Escrow Agreement",
        reference: "4.7",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3356,
      index: 1,
      projectId: 179,
      parentId: 3354,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "",
        reference: "6.2)",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3357,
      index: 1,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Seller Accounts",
        reference: "7.1",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3358,
      index: 2,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Account Definition",
        reference: "7.2",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3359,
      index: 3,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Closing Memorandum",
        reference: "10.3",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3360,
      index: 4,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Business Guarantees",
        reference: "11.2",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3361,
      index: 5,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Disclosure Documents and Material Review",
        reference: "16",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3362,
      index: 6,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Seller Indemnification Agreement",
        reference: "23.1",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3363,
      index: 7,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "List of Agreements to be Maintained Post-Closing",
        reference: "24.4",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3364,
      index: 8,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Permitted Leakage",
        reference: "25.2",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3365,
      index: 9,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Definition of Sellers",
        reference: "30.2",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
    {
      id: 3366,
      index: 10,
      projectId: 179,
      parentId: 3352,
      createdAt: null,
      updatedAt: null,
      properties: {
        name: "Address for Notice to Acquirer",
        reference: "30.3",
        prefix: "Annex",
        owner: "tbd",
        status: "open",
      },
    },
  ],
  hasComparison: false,
  added: [
  ],
  removed: [
  ],
  renamed: [
  ],
  firstDate: 1712313259373,
  secondDate: 1712313259373,
  organization: "taylorwessing.com",
}