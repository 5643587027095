import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Observable, firstValueFrom, from, map, switchMap } from 'rxjs';
import { FilesService } from 'src/app/services/files.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PdfViewerComponent } from '../../doc-list/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'app-side-view',
    templateUrl: './side-view.component.html',
    host: {
        class: 'flex-1 min-h-full flex flex-col',
    },
    styleUrls: ['./side-view.component.scss'],
    standalone: true,
    imports: [PdfViewerComponent, RouterLink, FaIconComponent, AsyncPipe]
})
export class SideViewComponent {

  iconClose = faXmark

  @HostBinding("class.inset-0")
  @HostBinding("class.absolute")
  isAbsolute: boolean = false

  pdfUrl$?: Observable<string | null>

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    filesService: FilesService,
    workstreamsService: WorkstreamsService
  ) {

    firstValueFrom(this.route.data).then(data => {
      this.isAbsolute = data['isAbsolute']
    })

    this.pdfUrl$ = this.route.paramMap.pipe(
      map(params => (Number(params.get("fileId")) || null)),
      switchMap(fileId => {
        return workstreamsService.files.pipe(map(files => files.find(file => file.id == fileId)))
      }),
      switchMap(file => {
        return from(filesService.fileToPdfUrl(file))
      }))

  }

  close() {
    // this.router.navigate(['..', '..'], { relativeTo: this.route })
  }



}
