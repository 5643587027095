import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import resolveConfig from 'tailwindcss/resolveConfig'
// @ts-ignore
import tailwindConfig from '../../../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig)

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  colors = fullConfig.theme!["colors"] as {
    [key: string]: {
      [key: number]: string
    }
  }

  bgColors = fullConfig.theme!["backgroundColor"]! as {
    [key: string]: {
      [key: number]: string
    }
  }

  colorList = [
    "gray",
    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuchsia",
    "pink",
  ]

  getColor(color: string, shade: number): string {
    return this.colors[color][shade]
  }

}
