import { Pipe, PipeTransform } from '@angular/core';
import { getWorkstreamChildrenRecursive } from 'annex-tracker-backend/lib/helpers/getWorkstreamChildrenRecursive';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';

@Pipe({
  name: 'amountChildrenRecurse',
  standalone: true
})
export class AmountChildrenRecursePipe implements PipeTransform {

  transform(value: HWorkstream): number {

    const children = getWorkstreamChildrenRecursive(value)
    return children.length

  }

}
