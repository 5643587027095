import { JsonPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-shareholder-resolution',
  standalone: true,
  imports: [ButtonPrimaryComponent, JsonPipe],
  templateUrl: './shareholder-resolution.component.html',
  styleUrl: './shareholder-resolution.component.scss'
})
export class ShareholderResolutionComponent {

  // access docx and pdf mime types
  acceptMimeTypes = [
    'application/pdf', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/xml',
  ].join(",")

  uploadFile?: File

  backend: BackendService = inject(BackendService)

  res: any

  isLoading = false

  async send() {
    
    this.isLoading = true
    const res = await this.backend.shareHolderResolution.create({
      fileBuffer: await this.uploadFile?.arrayBuffer(),
      filename: this.uploadFile?.name
    })

    console.log(res.resolution)

    // res.resolution is a buffer containing a docx. create a blob from it and download
    const blob = new Blob([res.resolution], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'})

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'shareholder-resolution.docx'
    a.click()
    window.URL.revokeObjectURL(url)

    this.isLoading = false
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files) {
      this.uploadFile = input.files[0]
    }
  }



}
