<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">Select cover page</div>

    <div class="max-h-[50vh] overflow-y-auto my-scrollbar">
        <div class="p-5 grid gap-2 grid-cols-3">

        <figure (click)="currentCoverPage = null" (dblclick)="activeModal.close(currentCoverPage)"
            class="aspect-[4/3] overflow-clip relative rounded-md cursor-pointer border-2 hover:border-indigo-400" 
            [class.border-transparent]="null != currentCoverPage?.id"
            [class.border-indigo-700]="null == currentCoverPage?.id">

            <img class="h-full w-full object-cover" src="assets/print/backgrounds/pexels-aleksandar-pasaric-325185_reduced.jpg">
            
            <span class="bg-[rgba(255,255,255,0.7)] italic px-1.5 py-px w-full absolute left-0 bottom-0 block text-sm leading-6 text-gray-900">
                Default
            </span> 
        </figure>
            

        @for (page of coverPageService.coverPages$| async; track page.id) {

            <figure (click)="currentCoverPage = page" (dblclick)="activeModal.close(currentCoverPage)"
                class="aspect-[4/3] overflow-clip relative rounded-md cursor-pointer border-2 hover:border-indigo-400" 
                [class.border-transparent]="page.id != currentCoverPage?.id"
                [class.border-indigo-700]="page.id == currentCoverPage?.id">

                <img class="h-full w-full object-cover" src="{{page.preview}}">

                <span class="bg-[rgba(255,255,255,0.7)] px-1.5 py-px w-full absolute left-0 bottom-0 block text-sm leading-6 text-gray-900">
                    {{page.name}}
                </span> 
            </figure>
            
        }
        </div>
    </div>

    <div class="p-5 flex justify-end gap-2">
        <app-button-secondary (click)="activeModal.dismiss()">Cancel</app-button-secondary>
        <app-button-primary (click)="activeModal.close(currentCoverPage)">Select</app-button-primary>
    </div>

</div>