import { Component, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AutoFocusDirective } from '../../common/auto-focus.directive';

@Component({
    selector: 'app-fullscreen-panel',
    templateUrl: './fullscreen-panel.component.html',
    styleUrls: ['./fullscreen-panel.component.scss'],
    standalone: true,
    imports: [AutoFocusDirective, RouterOutlet]
})
export class FullscreenPanelComponent {

  @HostListener('keydown.escape', ['$event'])
  close(event?: KeyboardEvent) {
    this.router.navigate([{ outlets: { modal: null } }])
    event?.stopPropagation()
  }

  constructor(
    private router: Router
  ) { }

}
