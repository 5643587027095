import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckLoginComponent } from './pages/check-login/check-login.component';
import { RegisterComponent } from './pages/authentication/register/register.component';
import { SignInComponent } from './pages/authentication/sign-in/sign-in.component';
import { MultiColumnComponent } from './multi-column/multi-column.component';
import { DocListComponent } from './multi-column/list-detail/doc-list/doc-list.component';
import { ListDetailComponent } from './multi-column/list-detail/list-detail.component';
import { ReportingComponent } from './multi-column/list-detail/reporting/reporting.component';
import { PrintComponent } from './printer/print/print.component';
import { SubviewComponent } from './multi-column/list-detail/doc-list/subview/subview.component';
import { NoListSelectedComponent } from './multi-column/no-list-selected/no-list-selected.component';
import { ContractComponent } from './multi-column/list-detail/doc-list/subview/contract/contract.component';
import { WorkstreamDetailComponent } from './multi-column/list-detail/doc-list/subview/workstream-detail/workstream-detail.component';
import { InboxComponent } from './multi-column/list-detail/doc-list/subview/inbox/inbox.component';
import { PrintTestComponent } from './printer/print-test/print-test.component';
import { PdfIframeViewComponent } from './pages/pdf-iframe-view/pdf-iframe-view.component';
import { TestComponent } from './pages/test/test.component';
import { ProjectResolver } from './services/resolvers/project.resolver';
import { GlobalInboxComponent } from './multi-column/list-detail/global-inbox/global-inbox.component';
import { SendMailComponent } from './admin/send-mail/send-mail.component';
import { AnalysisComponent } from './modals/analysis/analysis.component';
import { PreviewComponent } from './modals/analysis/preview/preview.component';
import { ReviewComponent } from './modals/analysis/review/review.component';
import { PdfQuickViewComponent } from './modals/pdf-quick-view/pdf-quick-view.component';
import { SideViewComponent } from './multi-column/list-detail/global-inbox/side-view/side-view.component';
import { ListSettingsComponent } from './modals/list-settings/list-settings.component';
import { SidePanelComponent } from './modals/side-panel/side-panel.component';
import { DomainsComponent } from './multi-column/list-detail/domains/domains.component';
import { UserProfileModalComponent } from './multi-column/modals/user-profile-modal/user-profile-modal.component';
import { ForgotComponent } from './pages/authentication/forgot/forgot.component';
import { ResetComponent } from './pages/authentication/reset/reset.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { ExportDiffModalComponent } from './multi-column/modals/export-diff-modal/export-diff-modal.component';
import { CenterPanelComponent } from './modals/center-panel/center-panel.component';
import { AddMainModalComponent } from './multi-column/modals/add-main-modal/add-main-modal.component';
import { FullscreenPanelComponent } from './modals/fullscreen-panel/fullscreen-panel.component';
import { EditColumnComponent } from './multi-column/modals/edit-column/edit-column.component';
import { AddListModalComponent } from './multi-column/modals/add-list-modal/add-list-modal.component';
import { loggedInGuard } from './guards/logged-in.guard';
import { ShareholderResolutionComponent } from './ai/shareholder-resolution/shareholder-resolution.component';
import { DebugComponent } from './multi-column/modals/debug/debug.component';
import { CallbackComponent } from './imanage/oauth/callback/callback.component';
import { ColumnSettingsComponent } from './multi-column/modals/column-settings/column-settings.component';
import { ColumnDetailComponent } from './multi-column/modals/column-settings/column-detail/column-detail.component';
import { ColumnNewComponent } from './multi-column/modals/column-settings/column-new/column-new.component';
import { DebugImportComponent } from './multi-column/debug-import/debug-import.component';

const routes: Routes = [
  {
    path: '',
    component: CheckLoginComponent
  },
  {
    path: 'ai/shareholder-resolution',
    component: ShareholderResolutionComponent
  },
  {
    path: 'send-mail-tutorial',
    component: SendMailComponent
  },
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'pdf-viewer',
    component: PdfIframeViewComponent
  },
  {
    path: 'print',
    component: PrintComponent
  },
  {
    path: 'print-test',
    component: PrintTestComponent
  },
  {
    path: 'lists',
    component: MultiColumnComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        component: NoListSelectedComponent
      },
      {
        path: 'debugImport',
        component: DebugImportComponent
      },
      {
        path: ':listId',
        component: ListDetailComponent,
        resolve: {
          project: ProjectResolver
        },
        children: [
          {
            path: '',
            redirectTo: 'doclist',
            pathMatch: 'full'
          },
          {
            path: 'doclist',
            component: DocListComponent,
            children: [{
              path: 'workstream/:workstreamId',
              component: SubviewComponent,
              children: [
                {
                  path: '',
                  redirectTo: 'preview',
                  pathMatch: 'full'
                }, {
                  path: 'view/:fileId',
                  component: PdfQuickViewComponent,
                  outlet: 'subviewmodal',
                },
                {
                  path: 'preview',
                  component: ContractComponent
                }, {
                  path: 'emails',
                  component: InboxComponent,
                }, {
                  path: 'files',
                  component: WorkstreamDetailComponent
                }]
            }]
          },
          {
            path: 'reporting',
            component: ReportingComponent
          },
          {
            path: 'participants',
            component: DomainsComponent
          },
          {
            path: 'inbox',
            component: GlobalInboxComponent,
            children: [{
              path: 'file/:fileId',
              component: SideViewComponent,
              data: { isAbsolute: false }
            }]
          }, {
            path: 'test',
            component: TestComponent
          }
        ]
      }
    ]
  },
  {
    path: 'side-panel',
    component: SidePanelComponent,
    outlet: 'modal',
    children: [{
      path: 'list-settings',
      component: ListSettingsComponent
    },
    {
      path: 'user-profile',
      component: UserProfileModalComponent
    }, {
      path: 'edit-column/:columnId',
      component: EditColumnComponent
    }]
  },
  {
    path: 'center-panel',
    component: CenterPanelComponent,
    outlet: 'modal',
    children: [{
      path: 'exports',
      component: ExportDiffModalComponent,
    },
    {
      path: 'zip-export',
      loadChildren: () => import('./zip-export/zip-export.module').then(m => m.ZipExportModule)
    },
    {
      path: 'add-list',
      component: AddListModalComponent,
    }, {
      path: 'debug/:workstreamId',
      component: DebugComponent
    }, {
      path: 'column-settings',
      component: ColumnSettingsComponent,
      children: [
        {
          path: 'new',
          component: ColumnNewComponent
        },
        {
          path: ':columnName',
          component: ColumnDetailComponent
        }]
    }]
  },
  {
    path: 'fullscreen-panel',
    component: FullscreenPanelComponent,
    outlet: 'modal',
    children: [{
      path: 'comparison/:analysisId',
      component: AnalysisComponent,
      children: [{
        path: '',
        redirectTo: 'review',
        pathMatch: 'full'
      }, {
        path: 'preview',
        component: PreviewComponent
      }, {
        path: 'review',
        component: ReviewComponent,
        children: [{
          path: 'file/:fileId',
          component: SideViewComponent,
          data: { isAbsolute: true }
        }]
      }]
    },
    {
      path: 'add-main/:fileId',
      component: AddMainModalComponent
    }]
  },
  {
    path: 'signin',
    component: SignInComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'reset/:token',
    component: ResetComponent
  },
  {
    path: 'changelog',
    component: ChangelogComponent
  }, {
    path: 'oauth2/callback',
    component: CallbackComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
