import { Component, Input } from '@angular/core';
import { PrintData } from '../print.component';
import { faArrowRight, faCircleArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { MyDatePipe } from '../../../pipes/my-date.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor } from '@angular/common';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
    selector: 'app-tracker-diff',
    templateUrl: './tracker-diff.component.html',
    styleUrl: './tracker-diff.component.scss',
    standalone: true,
    imports: [NgFor, FaIconComponent, MyDatePipe, TranslatePipe]
})
export class TrackerDiffComponent {

  iconChange = faCircleArrowDown
  iconRight = faArrowRight

  @Input() data!: PrintData

}
