"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepCopyRemoveParents = exports.getChildrenRecursive = exports.getRootWorkstream = void 0;
function getRootWorkstream(workstream) {
    if (workstream.parent != null) {
        return getRootWorkstream(workstream.parent);
    }
    return workstream;
}
exports.getRootWorkstream = getRootWorkstream;
function getChildrenRecursive(workstream) {
    if (workstream.children != null && workstream.children.length > 0) {
        return [...(workstream.children || []), ...workstream.children.map(child => getChildrenRecursive(child)).flat()];
    }
    return [];
}
exports.getChildrenRecursive = getChildrenRecursive;
function deepCopyRemoveParents(workstreams) {
    return JSON.parse(JSON.stringify(workstreams, (key, value) => {
        if (key === 'parent') {
            return undefined;
        }
        return value;
    }, 2));
}
exports.deepCopyRemoveParents = deepCopyRemoveParents;
