import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ModalService } from 'src/app/modal/modal.service';
import { BackendService } from 'src/app/services/backend.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { DeleteListModalComponent } from '../delete-list-modal/delete-list-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UsersService } from 'src/app/services/users.service';
import { ConfigService } from 'src/app/services/config.service';
import { ButtonDangerComponent } from '../../common/tailwind/button-danger/button-danger.component';
import { ButtonSecondaryComponent } from '../../common/tailwind/button-secondary/button-secondary.component';
import { CollaborationComponent } from './collaboration/collaboration.component';
import { EditEmailAliasComponent } from './edit-email-alias/edit-email-alias.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoverPages, Projects } from 'annex-tracker-backend';
import { LanguageSelectorComponent } from 'src/app/common/language-selector/language-selector.component';
import { CoverPageSelectorComponent } from 'src/app/common/cover-page-selector/cover-page-selector.component';
import { CoverPageService } from 'src/app/services/cover-page.service';
import { QueryHighlightDirective } from 'src/app/common/query-highlight.directive';

@Component({
    selector: 'app-list-settings',
    templateUrl: './list-settings.component.html',
    styleUrls: ['./list-settings.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, EditEmailAliasComponent, CollaborationComponent, ButtonSecondaryComponent, ButtonDangerComponent, AsyncPipe, LanguageSelectorComponent, CoverPageSelectorComponent, QueryHighlightDirective]
})
export class ListSettingsComponent implements OnInit {

  project?: Projects

  @HostListener("keydown.escape", ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.router.navigate([{ outlets: { modal: null } }])
  }

  constructor(
    private router: Router,
    public projectService: ProjectsService,
    public authService: AuthenticationService,
    private backend: BackendService,
    private toastr: ToastrService,
    private modalService: ModalService,
    public usersService: UsersService,
    public configService: ConfigService,
    public coverPageService: CoverPageService
  ) { }

  async ngOnInit() {
    const p = await firstValueFrom(this.projectService.project$)

    if (p != null) {
      this.project = Object.assign({}, p)
    }
    

  }

  async changeListName() {
    const project = await firstValueFrom(this.projectService.project$)
    if (project != null) {
      await this.backend.projects.patch(project?.id, { name: this.project?.name })
      this.toastr.success('List name changed')
    }


  }

  async changeLanguage() {

    try {
      await this.backend.projects.patch(this.project!.id, { language: this.project?.language })
      this.toastr.success('Language changed')

    } catch {

    }

  }

  async changeCoverPage(coverPage: CoverPages | null) {
    this.coverPageService.changeCoverPageOfCurrentProject(coverPage)
  }

  async deleteList() {

    const project = await firstValueFrom(this.projectService.project$)

    if (project != null) {

      try {
        const res = await this.modalService.openModal(DeleteListModalComponent)
        if (res == 'delete') {
          await this.backend.projects.remove(project?.id)
          this.router.navigate(['/lists'])
        }
      } catch {
        // no op
      }
    }
  }

  async archiveList() {
    const project = await firstValueFrom(this.projectService.project$)

    if (project != null) {

      try {
        const res = await this.modalService.openModal(DeleteListModalComponent, 'Archive')
        if (res == 'yes') {
          await this.backend.projects.patch(project?.id, { archived: true })
          await this.router.navigate(['/lists'])
          await this.router.navigate([{ outlets: { modal: null } }])

        }
      } catch {
        // no op
      }
    }
  }

}
