<div class="flex items-center">
    <input id="sms" name="language" type="radio" value="de" [(ngModel)]="language"
        (ngModelChange)="languageChange.emit($event)"
        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
    <label for="sms" class="ml-3 block text-sm leading-6 text-gray-900">German</label>
</div>
<div class="flex items-center">
    <input id="email" name="language" type="radio" value="en" [(ngModel)]="language"
        (ngModelChange)="languageChange.emit($event)"
        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
    <label for="email" class="ml-3 block text-sm leading-6 text-gray-900">English</label>
</div>