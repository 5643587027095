import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-if-query-url',
  standalone: true,
  imports: [],
  templateUrl: './show-if-query-url.component.html',
  styleUrl: './show-if-query-url.component.scss'
})
export class ShowIfQueryUrlComponent implements OnDestroy, OnInit {

  @Input() 
  query?: string

  @Input()
  value?: string

  route = inject(ActivatedRoute)

  show = false

  subscription?: Subscription

  ngOnInit(): void {
    this.subscription = this.route.queryParams.subscribe(params => {
      console.log(params, this.query, this.value)
      if (this.query == null || this.value == null) {
        return
      }
      if (params[this.query] == this.value) {
        console.log("showing")
        this.show = true
      } else {
        console.log("hiding")
        this.show = false
      }
    })    
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }


}
