import { Injectable } from '@angular/core';
import { debounceTime, map } from 'rxjs';
import { WorkstreamPropertyService } from './workstream-property.service';
import { Property } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private propertiesService: WorkstreamPropertyService) { }

  getPropertyStatistics(propertyKey: string) { 

    return this.propertiesService.properties.pipe(
      debounceTime(100),
      map(properties => {

      // filter properties by name
      const filteredProperties = properties.filter(p => p.key == propertyKey && p.value != null)

      // group properties by value
      const groupedProperties = filteredProperties.reduce((acc, p) => {
        acc[p.value!] = acc[p.value!] || []
        acc[p.value!].push(p)
        return acc
      }, {} as Record<string, Property[]>)

      // count properties by value
      const countedProperties = Object.keys(groupedProperties).reduce((acc, key) => {
        acc[key] = groupedProperties[key].length
        return acc
      }, {} as Record<string, number>)

      return countedProperties

    }))

  }
}
