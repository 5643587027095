import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActiveModal } from 'src/app/modal/modal.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BackendService } from 'src/app/services/backend.service';
import { ButtonSecondaryComponent } from '../../../common/tailwind/button-secondary/button-secondary.component';
import { ButtonPrimaryComponent } from '../../../common/tailwind/button-primary/button-primary.component';
import { PasswordStrengthComponent } from '../../../password-strength/password-strength.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
    standalone: true,
    imports: [FormsModule, PasswordStrengthComponent, ButtonPrimaryComponent, ButtonSecondaryComponent]
})
export class ChangePasswordModalComponent {

  password?: string
  passwordRepeat?: string

  get canChange() {
    return (this.password != null) && this.password == this.passwordRepeat
  }

  constructor(
    public activeModal: ActiveModal, 
    public backend: BackendService,
    public auth: AuthenticationService,
    public toastr: ToastrService) {}

  async changePassword() {

    const user = await this.auth.awaitableUser()
    
    this.backend.users.patch(user.id, {
      password: this.password
    }).then(() => {
      this.toastr.success('Password changed')
    }).catch(() => {
      this.toastr.error('Failed to change password')
    })

    this.activeModal.close('change')
  }
}
