import { Component, Inject } from '@angular/core';
import { ActiveModal } from 'src/app/modal/modal.service';
import { ButtonDangerComponent } from '../../common/tailwind/button-danger/button-danger.component';
import { ButtonSecondaryComponent } from '../../common/tailwind/button-secondary/button-secondary.component';

@Component({
    selector: 'app-delete-list-modal',
    templateUrl: './delete-list-modal.component.html',
    styleUrls: ['./delete-list-modal.component.scss'],
    standalone: true,
    imports: [ButtonSecondaryComponent, ButtonDangerComponent]
})
export class DeleteListModalComponent {

  constructor(public activeModal: ActiveModal, @Inject("context") public buttonLabel: string) {}

}
