import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { map, Subject, switchMap, ReplaySubject, Subscription, Observable, shareReplay, BehaviorSubject, filter } from 'rxjs';
import { ownerOptions, statusOptions } from 'src/app/multi-column/list-detail/doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { ColorService } from 'src/app/services/color.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { BadgeDescription } from '../badge-dropdown/badge-dropdown.component';
import { ColumnsService } from 'src/app/services/columns.service';
import { NgFor, AsyncPipe } from '@angular/common';

const mapper = (options: BadgeDescription[]) => (data: Record<string, number>) => {

  return options.map(option => ({
    value: data[option.value],
    label: option.label
  }))

}

@Component({
    selector: 'app-horizontal-bar-chart',
    templateUrl: './horizontal-bar-chart.component.html',
    styleUrls: ['./horizontal-bar-chart.component.scss'],
    standalone: true,
    imports: [NgFor, AsyncPipe]
})
export class HorizontalBarChartComponent implements OnChanges, OnDestroy {

  trackByFn = (index: number, _: any) => index

  _columnName$ = new ReplaySubject<string>(1)

  @Input()
  columnName!: string

  public localData$: Observable<{
    label: string
    c: string
    c2: string
    d: number
  }[]>

  subscription?: Subscription

  constructor(
    private color: ColorService,
    statsService: StatisticsService,
    private columnsService: ColumnsService) {

    this.localData$ =
      this.columnsService.columns.pipe(
        switchMap(columns =>
          this._columnName$.pipe(map(columnName => columns.find(column => column.name == columnName)))
        )).pipe(
          filter(column => column != null),
          map(column => column!),
          switchMap(column => {

            return statsService.getPropertyStatistics(column.name).pipe(
              map(mapper(column.configuration)),
              map(data => {

                const total = data.reduce((a, b) => a + (b.value || 0), 0)

                return column.configuration.map((option: BadgeDescription, i: number) => ({
                  label: data?.[i].label ? `${data[i].label} (${data![i].value || 0})` : ``,
                  c: this.color.getColor(option.color, 500),
                  c2: this.color.getColor(option.color, 700),
                  d: (data![i].value || 0) / total * 100
                }))

              }))
          }),
          shareReplay(1)
        )

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['columnName']) {
      this._columnName$.next(this.columnName)
    }

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }


}
