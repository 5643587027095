import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { Match } from 'src/app/services/model/docx-analysis';
import { ProjectsService } from 'src/app/services/projects.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';

@Component({
  selector: 'app-debug-spa-update',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './debug-spa-update.component.html',
  styleUrl: './debug-spa-update.component.scss'
})
export class DebugSpaUpdateComponent {

  doxcAnalysisService = inject(DocxAnalysisService)
  workstreamService = inject(WorkstreamsService)
  projectService = inject(ProjectsService)

  cleanAnalysis(analysis: Match[]) {
    return analysis.map(a => ({ ... a, fullMatches: [] as string[] }))
  }

  async debugInfo() {
    const object = await this.doxcAnalysisService.exportForDebugging()

    object.mappings = object.mappings.map(mapping => ({
      ...mapping,
      leftWorkstream: undefined,
      leftMatch: { ...mapping.leftMatch, fullMatches: [] as string[]} as Match,
      rightMatch: { ...mapping.rightMatch, fullMatches: [] as string[]} as Match,
      rightWorkstream: undefined,
    }))

    object.leftAnalysis = this.cleanAnalysis(object.leftAnalysis!)
    object.rightAnalysis = this.cleanAnalysis(object.rightAnalysis!)

    let workstreams = await firstValueFrom(this.workstreamService.workstreams$)

    workstreams = workstreams.map(ws => ({ ...ws, children: undefined, parent: undefined, properties: {
      prefix: ws.properties.prefix,
      reference: ws.properties.reference,
    } }))

    let assembledObject=  {
      ... object,
      workstreams
    }
    
    // convert object to string an then download
    const data = JSON.stringify(assembledObject, null, 2)
    const blob = new Blob([data], { type: 'application/json' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'debug.json'
    a.click()
    

  }

  async loadDebugJson() {

    this.doxcAnalysisService.mappings.next(obj.mappings as any)
    this.doxcAnalysisService.matrix = obj.matrix
    this.doxcAnalysisService.leftAnalysis = obj.leftAnalysis as any
    this.doxcAnalysisService.rightAnalysis = obj.rightAnalysis as any

    console.log(obj)

  } 

}

const obj = {
  "language": "de",
  "mappings": [
    {
      "leftIndex": 1,
      "rightIndex": 0,
      "distance": 8.24372759856631,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "2.11",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "2.10",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 2,
      "rightIndex": 1,
      "distance": 5.566218809980806,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "3.14",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "3.14",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 3,
      "rightIndex": 2,
      "distance": 0.6968641114982579,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "6.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "6.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 4,
      "rightIndex": 3,
      "distance": 3.571428571428571,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "7.9",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "7.9",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 5,
      "rightIndex": 4,
      "distance": 0.48465266558966075,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "8.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "8.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 5,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "9.2",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 6,
      "rightIndex": 6,
      "distance": 7.495741056218058,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "9.8",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "9.7",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 7,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "12.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 8,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "12.2",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 9,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "12.11",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 7,
      "rightIndex": 10,
      "distance": 15.277777777777779,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "12.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "13.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 8,
      "rightIndex": 11,
      "distance": 21.052631578947366,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "12.2",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "13.2",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 13,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "13.2(ii)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 9,
      "rightIndex": 12,
      "distance": 2.030456852791878,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "12.3",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "13.3",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 10,
      "rightIndex": 13,
      "distance": 34.812921217755054,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "12.3(i)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "13.3(i)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 11,
      "rightIndex": 14,
      "distance": 28.599735799207398,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "13.2(i)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "14.2(i)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 12,
      "rightIndex": 15,
      "distance": 39.48635634028893,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "13.2",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "14.2",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 16,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "14.2(ii)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 14,
      "rightIndex": 17,
      "distance": 24.00564174894217,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "14",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "15",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 18,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xiii)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 19,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xiv)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 22,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(f)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 15,
      "rightIndex": 18,
      "distance": 49.887133182844245,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(a)(ix)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(a)(ix)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 16,
      "rightIndex": 19,
      "distance": 32.926829268292686,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(a)(x)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(a)(x)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 17,
      "rightIndex": 20,
      "distance": 60.83916083916085,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xi)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(a)(xi)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 21,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(a)(xiii)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 22,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(a)(xiv)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 20,
      "rightIndex": 23,
      "distance": 9.743589743589745,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(b)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 21,
      "rightIndex": 25,
      "distance": 0.8064516129032258,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(d)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(d)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 23,
      "rightIndex": 26,
      "distance": 1.6339869281045754,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(i)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(f)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 24,
      "rightIndex": 27,
      "distance": 43.96887159533074,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(j)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(g)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 25,
      "rightIndex": 24,
      "distance": 0.5422153369481022,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.3(k)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.3(i)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 26,
      "rightIndex": 28,
      "distance": 12.903225806451612,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.4(b)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.4(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 27,
      "rightIndex": 29,
      "distance": 19.491525423728813,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.8(e)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.8(e)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 30,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.10(d)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 28,
      "rightIndex": 31,
      "distance": 0.29985007496251875,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.12",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 29,
      "rightIndex": 32,
      "distance": 1.4285714285714286,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "15.13",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "16.13",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 30,
      "rightIndex": 33,
      "distance": 70.4515272244356,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 31,
      "rightIndex": 34,
      "distance": 9.16030534351145,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.2",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.3",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 32,
      "rightIndex": 35,
      "distance": 1.694915254237288,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.3",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.4",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 33,
      "rightIndex": 36,
      "distance": 30.303030303030305,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.4",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.5",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 34,
      "rightIndex": 37,
      "distance": 1.1261261261261262,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.7",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.7",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 35,
      "rightIndex": 38,
      "distance": 27.225806451612904,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "17.8",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "18.8",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 36,
      "rightIndex": 39,
      "distance": 1.662049861495845,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "18.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "19.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 40,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "19.2",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 39,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "21.8(a)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 40,
      "rightIndex": 41,
      "distance": 0.10626992561105207,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "21.12",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "21.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 37,
      "rightIndex": 42,
      "distance": 27.672209026128264,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "21.1",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "22.1",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 38,
      "rightIndex": 43,
      "distance": 1.6260162601626018,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "21.4",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "22.4",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 47,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.9(a)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 50,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 41,
      "rightIndex": 44,
      "distance": 0.37735849056603776,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.2(l)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.2(l)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 45,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.2(n)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 42,
      "rightIndex": 46,
      "distance": 9.996017522899244,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.3(b)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.3(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 43,
      "rightIndex": 47,
      "distance": 0.6535947712418301,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.3(c)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.3(c)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 44,
      "rightIndex": 48,
      "distance": 0.1941747572815534,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.5(b)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.5(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 45,
      "rightIndex": 49,
      "distance": 0.2050580997949419,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.6(a)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.6(a)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 46,
      "rightIndex": 50,
      "distance": 0.2050580997949419,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.6(b)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.6(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 0,
      "rightIndex": 51,
      "distance": 44.026776519052525,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "0",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.8",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 48,
      "rightIndex": 52,
      "distance": 10.291595197255575,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.9",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.9",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 49,
      "rightIndex": 53,
      "distance": 0.18083182640144665,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.10",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.10",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 54,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 51,
      "rightIndex": 55,
      "distance": 0.11363636363636363,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.13",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.13",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 52,
      "rightIndex": 56,
      "distance": 28.055555555555557,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.14(e)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.14(e)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 53,
      "rightIndex": 57,
      "distance": 0.5988023952095809,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.15(c)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.15(c)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 54,
      "rightIndex": 58,
      "distance": 0.3115264797507788,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.15(d)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.15(d)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 55,
      "rightIndex": 59,
      "distance": 0.24875621890547264,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.15(e)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.15(e)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 56,
      "rightIndex": 60,
      "distance": 0.5780346820809248,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.15(g)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.15(g)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 57,
      "rightIndex": 61,
      "distance": 0.4273504273504274,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "22.15(h)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "23.15(h)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 58,
      "rightIndex": 62,
      "distance": 0.4662004662004662,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "23.7",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "24.7",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 59,
      "rightIndex": 63,
      "distance": 0.1072961373390558,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "23.11(e)",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "24.11(e)",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 62,
      "rightIndex": -1,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "25.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "rightMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": 60,
      "rightIndex": 64,
      "distance": 0.5154639175257731,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "25.9",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "26.9",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": 61,
      "rightIndex": 65,
      "distance": 0.6920415224913495,
      "leftMatch": {
        "prefix": "Anlage",
        "reference": "25.10",
        "language": "de",
        "fullMatches": []
      },
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "26.10",
        "language": "de",
        "fullMatches": []
      },
      "done": false
    },
    {
      "leftIndex": -1,
      "rightIndex": 66,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "26.12",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 67,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "31.(a)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 68,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "31.(b)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 69,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "31.(c)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 70,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "31.(d)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    },
    {
      "leftIndex": -1,
      "rightIndex": 71,
      "rightMatch": {
        "prefix": "Anlage",
        "reference": "31.(e)",
        "language": "de",
        "fullMatches": []
      },
      "done": false,
      "leftMatch": {
        "fullMatches": []
      }
    }
  ],
  "leftAnalysis": [
    {
      "prefix": "Anlage",
      "reference": "0",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "2.11",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "3.14",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "6.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "7.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "8.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "9.8",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.3",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.3(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.2(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.2(ii)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "14",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(a)(ix)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(a)(x)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(a)(xi)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(a)(xiii)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(a)(xiv)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(f)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(j)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.3(k)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.4(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.8(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15.13",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.3",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.4",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.7",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "17.8",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "21.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "21.4",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "21.8(a)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "21.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.2(l)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.3(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.3(c)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.5(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.6(a)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.6(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.9(a)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.10",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.13",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.14(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.15(c)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.15(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.15(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.15(g)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.15(h)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.7",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.11(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "25.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "25.10",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "25.12",
      "language": "de",
      "fullMatches": []
    }
  ],
  "rightAnalysis": [
    {
      "prefix": "Anlage",
      "reference": "2.10",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "3.14",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "6.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "7.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "8.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "9.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "9.7",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "12.11",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.3",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "13.3(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "14.2(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "14.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "14.2(ii)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "15",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(a)(ix)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(a)(x)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(a)(xi)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(a)(xiii)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(a)(xiv)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(i)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(f)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.3(g)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.4(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.8(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.10(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "16.13",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.3",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.4",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.5",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.7",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "18.8",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "19.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "19.2",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "21.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.1",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "22.4",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.2(l)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.2(n)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.3(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.3(c)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.5(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.6(a)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.6(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.8",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.10",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.13",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.14(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.15(c)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.15(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.15(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.15(g)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "23.15(h)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "24.7",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "24.11(e)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "26.9",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "26.10",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "26.12",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "31.(a)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "31.(b)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "31.(c)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "31.(d)",
      "language": "de",
      "fullMatches": []
    },
    {
      "prefix": "Anlage",
      "reference": "31.(e)",
      "language": "de",
      "fullMatches": []
    }
  ],
  "matrix": [
    [
      76.45502645502646,
      75.66137566137566,
      76.3668430335097,
      74.23780487804879,
      75.13227513227513,
      80.24691358024691,
      77.68959435626103,
      86.1552028218695,
      88.44797178130511,
      74.86772486772487,
      82.18694885361552,
      85.97883597883597,
      86.5079365079365,
      81.01324657215896,
      73.05151915455747,
      75.28089887640449,
      73.05151915455747,
      73.36293007769146,
      85.3615520282187,
      78.3068783068783,
      89.59435626102292,
      88.09523809523809,
      86.86067019400353,
      72.39858906525573,
      76.06506584043377,
      90.47619047619048,
      70.63492063492063,
      86.331569664903,
      91.005291005291,
      90.91710758377425,
      90.91710758377425,
      73.80952380952381,
      74.42680776014109,
      72.57636122177955,
      90.74074074074075,
      76.45502645502646,
      85.80246913580247,
      78.04232804232805,
      75.92592592592592,
      80.51146384479718,
      76.02138220694921,
      70.63492063492063,
      71.34038800705468,
      90.8289241622575,
      81.48148148148148,
      84.74426807760142,
      82.45718837116686,
      87.21340388007054,
      68.07760141093475,
      73.13738892686261,
      67.3274094326726,
      44.026776519052525,
      70.19400352733686,
      71.25220458553791,
      70.63492063492063,
      63.31569664902999,
      83.86243386243386,
      87.47795414462081,
      79.36507936507937,
      72.39858906525573,
      86.06701940035273,
      84.21516754850089,
      77.24867724867724,
      73.44420600858369,
      74.07952871870398,
      83.42151675485009,
      71.54605263157895,
      96.91358024691358,
      96.91358024691358,
      97.35449735449735,
      96.11992945326278,
      98.2363315696649
    ],
    [
      8.24372759856631,
      55.376344086021504,
      73.9247311827957,
      48.2469512195122,
      73.9247311827957,
      78.94265232974911,
      39.247311827956985,
      85.66308243727599,
      87.90322580645162,
      31.899641577060933,
      81.54121863799283,
      84.85663082437276,
      85.21505376344086,
      81.40831977689984,
      73.97622192866578,
      74.75922953451042,
      73.97622192866578,
      75.21272660007399,
      85.12544802867383,
      77.77777777777779,
      89.42652329749103,
      87.81362007168458,
      86.02150537634408,
      73.47670250896059,
      74.28350116189002,
      90.14336917562724,
      71.14695340501792,
      86.37992831541219,
      90.50179211469535,
      90.14336917562724,
      90.14336917562724,
      72.31182795698925,
      72.1326164874552,
      74.16998671978752,
      90.68100358422939,
      72.84946236559139,
      85.12544802867383,
      75.80645161290323,
      74.82078853046595,
      77.95698924731182,
      75.94501718213058,
      74.01433691756273,
      71.59498207885304,
      90.32258064516128,
      82.97491039426524,
      86.37992831541219,
      83.31342094782956,
      87.72401433691756,
      74.10394265232975,
      77.10184552289815,
      72.18045112781954,
      72.45108135942327,
      73.65591397849462,
      74.10394265232975,
      74.01433691756273,
      72.84946236559139,
      82.52688172043011,
      87.18637992831542,
      80.64516129032258,
      77.24014336917563,
      86.82795698924731,
      84.49820788530465,
      76.88172043010752,
      73.33690987124464,
      73.41678939617083,
      82.79569892473118,
      71.10745614035088,
      96.68458781362007,
      96.95340501792114,
      97.31182795698925,
      96.23655913978494,
      98.11827956989248
    ],
    [
      54.38931297709924,
      5.566218809980806,
      75.26132404181185,
      68.4451219512195,
      72.37479806138933,
      76.3915547024952,
      61.82795698924731,
      78.88675623800384,
      79.46257197696737,
      57.97546012269939,
      71.78502879078695,
      75.43186180422265,
      75.81573896353166,
      89.44922147339066,
      78.26948480845442,
      83.78812199036918,
      78.26948480845442,
      84.94265630780615,
      77.15930902111324,
      73.89635316698656,
      82.14971209213053,
      79.65451055662189,
      75.62380038387715,
      74.23076923076923,
      76.52982184353215,
      83.30134357005758,
      72.87581699346404,
      77.92706333973129,
      83.49328214971209,
      82.91746641074856,
      82.91746641074856,
      72.86356821589204,
      73.42857142857143,
      78.15405046480744,
      83.87715930902111,
      79.66101694915254,
      76.96737044145874,
      75.04798464491363,
      77.77777777777779,
      70.63339731285988,
      84.9942726231386,
      74.0701381509033,
      72.56532066508314,
      83.10940499040306,
      74.4721689059501,
      78.1190019193858,
      90.84030266825965,
      79.46257197696737,
      77.92706333973129,
      85.95352016404648,
      77.99043062200957,
      81.92584963954685,
      74.27101200686106,
      76.85352622061484,
      74.0701381509033,
      74.20454545454545,
      73.89635316698656,
      78.69481765834932,
      72.7447216890595,
      71.59309021113243,
      78.88675623800384,
      76.00767754318618,
      74.28023032629558,
      81.22317596566523,
      77.31958762886599,
      74.28023032629558,
      80.48245614035088,
      94.04990403071018,
      94.43378119001919,
      94.6257197696737,
      92.7063339731286,
      96.1612284069098
    ],
    [
      73.2824427480916,
      75.4816112084063,
      0.6968641114982579,
      75.15243902439023,
      17.60904684975767,
      74.25569176882661,
      73.29749103942652,
      74.25569176882661,
      73.02977232924694,
      72.9038854805726,
      73.20490367775831,
      76.88266199649738,
      77.05779334500875,
      88.6590750639089,
      77.80713342140027,
      83.42696629213484,
      77.80713342140027,
      84.27672955974843,
      79.33450087565674,
      74.25569176882661,
      83.36252189141857,
      81.9614711033275,
      78.80910683012259,
      72.94871794871794,
      76.29744384198295,
      84.23817863397548,
      79.08496732026144,
      80.21015761821366,
      85.46409807355516,
      84.41330998248687,
      84.58844133099825,
      73.01349325337331,
      72.57142857142857,
      78.68525896414343,
      83.0122591943958,
      77.40805604203153,
      76.18213660245185,
      75.4816112084063,
      80.73555166374781,
      71.97898423817864,
      84.15425735013365,
      74.28267800212541,
      72.09026128266032,
      84.93870402802102,
      74.6059544658494,
      80.03502626970229,
      90.32258064516128,
      81.61120840630473,
      77.5831873905429,
      85.61175666438824,
      77.99043062200957,
      81.15345005149331,
      79.58833619210978,
      78.98423817863399,
      74.28267800212541,
      73.86363636363636,
      75.4816112084063,
      81.61120840630473,
      75.30647985989492,
      73.73029772329247,
      81.08581436077058,
      77.40805604203153,
      73.20490367775831,
      80.95493562231759,
      76.73048600883654,
      72.15411558669001,
      79.87938596491229,
      94.22066549912435,
      94.57092819614711,
      95.09632224168126,
      93.52014010507881,
      96.6725043782837
    ],
    [
      45.23809523809524,
      68.60119047619048,
      75.22321428571429,
      3.571428571428571,
      70.98214285714286,
      81.17559523809523,
      29.836309523809522,
      86.16071428571429,
      89.21130952380952,
      39.211309523809526,
      83.85416666666666,
      87.20238095238095,
      87.35119047619048,
      78.41041134092494,
      73.9101717305152,
      72.27126805778491,
      73.9101717305152,
      72.51202367739549,
      87.35119047619048,
      80.43154761904762,
      90.99702380952381,
      89.80654761904762,
      88.39285714285714,
      73.06547619047619,
      76.11607142857143,
      91.74107142857143,
      73.51190476190477,
      88.39285714285714,
      92.03869047619048,
      91.88988095238095,
      91.81547619047619,
      73.28869047619048,
      73.21428571428571,
      75.0996015936255,
      92.03869047619048,
      77.30654761904762,
      87.12797619047619,
      79.38988095238095,
      77.52976190476191,
      81.25,
      73.76861397479955,
      71.20535714285714,
      70.68452380952381,
      91.96428571428571,
      84.52380952380952,
      88.16964285714286,
      80.34647550776583,
      90.10416666666666,
      76.41369047619048,
      73.2740943267259,
      73.2740943267259,
      70.64881565396497,
      75.29761904761905,
      76.5625,
      71.20535714285714,
      71.72619047619048,
      85.11904761904762,
      89.43452380952381,
      82.96130952380952,
      79.24107142857143,
      89.0625,
      86.60714285714286,
      79.53869047619048,
      71.62017167381974,
      77.46686303387335,
      84.74702380952381,
      71.76535087719299,
      97.17261904761905,
      97.32142857142857,
      97.61904761904762,
      96.875,
      98.4375
    ],
    [
      72.70992366412213,
      72.6094003241491,
      17.828200972447323,
      70.42682926829268,
      0.48465266558966075,
      74.23014586709886,
      68.10035842293907,
      66.93679092382496,
      74.87844408427877,
      71.57464212678937,
      72.6094003241491,
      76.49918962722853,
      77.63371150729336,
      87.82244945386938,
      76.68428005284017,
      82.5040128410915,
      76.68428005284017,
      83.35183129855716,
      79.74068071312804,
      74.39222042139384,
      84.60291734197732,
      82.6580226904376,
      80.06482982171799,
      72.94871794871794,
      75.44539116963594,
      85.41329011345219,
      79.902755267423,
      81.19935170178282,
      86.06158833063209,
      84.92706645056725,
      85.08914100486223,
      75.41229385307346,
      75,
      77.75564409030544,
      84.11669367909238,
      75.68881685575364,
      77.79578606158833,
      75.68881685575364,
      78.44408427876823,
      71.15072933549432,
      83.27605956471936,
      72.90116896918171,
      73.51543942992875,
      85.89951377633712,
      75.20259319286872,
      81.52350081037277,
      89.6057347670251,
      82.82009724473258,
      76.33711507293354,
      84.72317156527683,
      76.62337662337663,
      79.6086508753862,
      78.93030794165315,
      76.33711507293354,
      72.90116896918171,
      73.4090909090909,
      75.68881685575364,
      82.6580226904376,
      75.52674230145867,
      73.41977309562398,
      82.33387358184766,
      79.2544570502431,
      71.79902755267423,
      79.93562231759657,
      75.62592047128129,
      73.58184764991897,
      79.16666666666666,
      94.6515397082658,
      94.97568881685575,
      95.62398703403565,
      94.16531604538088,
      96.92058346839546
    ],
    [
      36.79727427597956,
      64.22487223168655,
      73.67972742759795,
      30.335365853658537,
      68.39863713798978,
      79.21635434412265,
      7.495741056218058,
      85.00851788756388,
      87.98977853492333,
      22.31686541737649,
      81.94207836456559,
      85.60477001703578,
      85.77512776831345,
      80.36253776435045,
      73.18361955085865,
      73.83627608346708,
      73.18361955085865,
      74.54679985201628,
      85.8603066439523,
      78.44974446337308,
      89.77853492333901,
      88.24531516183987,
      86.96763202725724,
      71.7206132879046,
      75.29047250193648,
      90.71550255536627,
      71.80579216354343,
      87.05281090289608,
      91.05621805792164,
      90.63032367972743,
      90.63032367972743,
      71.97614991482112,
      71.80579216354343,
      74.7011952191235,
      90.9710391822828,
      75.04258943781942,
      85.68994889267462,
      77.42759795570699,
      75.29812606473595,
      79.98296422487223,
      75.0668193967163,
      72.31686541737649,
      71.03918228279386,
      90.88586030664395,
      82.87904599659285,
      86.71209540034071,
      82.33771405814416,
      88.67120954003407,
      74.53151618398637,
      75.66643882433355,
      72.59056732740943,
      71.52420185375901,
      73.85008517887563,
      74.36115843270869,
      72.31686541737649,
      72.91311754684838,
      83.56047700170357,
      88.07495741056218,
      81.17546848381602,
      77.68313458262351,
      87.47870528109028,
      85.17887563884156,
      77.51277683134583,
      72.90772532188842,
      73.5640648011782,
      83.47529812606473,
      71.65570175438597,
      97.01873935264055,
      97.10391822827938,
      97.44463373083475,
      96.5076660988075,
      98.29642248722317
    ],
    [
      82.25190839694656,
      72.97830374753451,
      74.91289198606272,
      85.13719512195121,
      74.63651050080774,
      72.1938775510204,
      83.15412186379928,
      76.11336032388664,
      68.42105263157895,
      80.87934560327199,
      15.277777777777779,
      53.84615384615385,
      42.51012145748988,
      94.53869393446432,
      86.59180977542933,
      91.01123595505618,
      86.59180977542933,
      91.78690344062154,
      76.11336032388664,
      72.11538461538461,
      74.89878542510121,
      74.08906882591093,
      78.54251012145748,
      79.23076923076923,
      85.5151045701007,
      75.30364372469636,
      76.63398692810458,
      75.30364372469636,
      76.92307692307693,
      69.63562753036437,
      69.63562753036437,
      76.01199400299849,
      76.42857142857142,
      86.65338645418326,
      77.7327935222672,
      75.14124293785311,
      76.92307692307693,
      73.87387387387388,
      75.13227513227513,
      66.20498614958449,
      91.48529973272241,
      81.40276301806588,
      79.80997624703087,
      76.51821862348179,
      73.20754716981132,
      75.7085020242915,
      95.36041417761848,
      74.89878542510121,
      74.1747572815534,
      92.41285030758715,
      86.53451811346548,
      89.18640576725025,
      76.32933104631218,
      75.04520795660035,
      81.40276301806588,
      81.02272727272727,
      78.07692307692308,
      74.49392712550608,
      73.83177570093457,
      71.64179104477611,
      73.27935222672065,
      78.94736842105263,
      71.56177156177156,
      88.73390557939913,
      85.56701030927834,
      73.01038062283737,
      88.65131578947368,
      89.47368421052632,
      90.2834008097166,
      91.09311740890689,
      88.66396761133603,
      92.3076923076923
    ],
    [
      86.54580152671755,
      77.12031558185404,
      79.79094076655052,
      88.94817073170732,
      79.15993537964458,
      76.53061224489795,
      87.18637992831542,
      73.85321100917432,
      73.21428571428571,
      85.58282208588957,
      54.861111111111114,
      21.052631578947366,
      30.456852791878177,
      96.28166395537997,
      90.02642007926023,
      93.65971107544141,
      90.02642007926023,
      94.19163891971884,
      75.33039647577093,
      76.92307692307693,
      77.38095238095238,
      82.73809523809523,
      76.55502392344498,
      84.23076923076923,
      89.31061192873742,
      73.80952380952381,
      80.71895424836602,
      78.97435897435898,
      75,
      59.523809523809526,
      60.11904761904761,
      80.20989505247377,
      80.71428571428572,
      90.1062416998672,
      76.78571428571429,
      79.84934086629002,
      76.19047619047619,
      77.25225225225225,
      78.83597883597884,
      68.69806094182826,
      93.81443298969072,
      85.86609989373008,
      84.44180522565321,
      74.40476190476191,
      74.71698113207546,
      73.19587628865979,
      96.81401831939466,
      73.80952380952381,
      79.6116504854369,
      94.70266575529733,
      90.29391660970609,
      92.12152420185376,
      81.30360205831903,
      79.38517179023508,
      85.86609989373008,
      85.68181818181819,
      73.46153846153847,
      80.95238095238095,
      71.96261682242991,
      77.363184079602,
      78.61271676300578,
      75.21367521367522,
      77.15617715617715,
      92.00643776824035,
      89.10162002945509,
      72.31833910034602,
      91.77631578947368,
      86.30952380952381,
      86.90476190476191,
      88.09523809523809,
      80.95238095238095,
      89.88095238095238
    ],
    [
      84.44656488549617,
      75.14792899408283,
      77.35191637630662,
      87.04268292682927,
      77.7059773828756,
      72.1938775510204,
      85.30465949820788,
      76.60550458715596,
      69.38775510204081,
      83.74233128834356,
      50.34722222222222,
      45.933014354066984,
      2.030456852791878,
      95.65419474785034,
      88.63936591809775,
      92.61637239165329,
      88.63936591809775,
      93.22974472807991,
      77.97356828193833,
      74.75961538461539,
      72.95918367346938,
      76.53061224489795,
      79.42583732057416,
      82.43589743589743,
      87.6839659178931,
      71.93877551020408,
      79.24836601307189,
      81.63265306122449,
      75,
      62.755102040816325,
      63.26530612244898,
      78.41079460269866,
      78.14285714285715,
      88.64541832669323,
      75.51020408163265,
      77.5894538606403,
      77.05627705627705,
      75.67567567567568,
      77.07231040564373,
      70.3601108033241,
      92.89805269186712,
      84.16578108395323,
      82.3040380047506,
      73.46938775510205,
      72.45283018867924,
      78.57142857142857,
      96.29629629629629,
      72.95918367346938,
      77.08737864077669,
      93.84825700615174,
      88.79015721120984,
      90.98867147270855,
      78.73070325900514,
      77.75768535262206,
      84.16578108395323,
      83.63636363636363,
      73.46153846153847,
      76.0204081632653,
      70.7165109034268,
      73.6318407960199,
      77.55102040816327,
      77.35042735042735,
      74.35897435897436,
      90.87982832618026,
      87.62886597938144,
      70.24221453287197,
      90.625,
      87.75510204081633,
      89.28571428571429,
      89.28571428571429,
      84.6938775510204,
      90.81632653061224
    ],
    [
      77.00742836929606,
      85.92147152458438,
      84.43579766536965,
      73.43473646975592,
      83.69296073576228,
      88.8928192430138,
      75.76936681995048,
      92.96073576229217,
      94.55252918287937,
      77.71489211177928,
      90.97983728333922,
      93.2083480721613,
      93.49133356915458,
      34.812921217755054,
      73.39936328263177,
      74.38981252210824,
      73.39936328263177,
      74.84966395472232,
      92.81924301379554,
      88.04386275203396,
      95.18924655111427,
      94.51715599575522,
      93.59745313052706,
      80.79235939158119,
      74.17757339936328,
      95.79059073222498,
      83.51609480014149,
      93.77431906614785,
      96.00282985496993,
      96.0382030420941,
      96.0382030420941,
      82.95012380615493,
      82.52564556066501,
      73.75309515387336,
      95.89671029359745,
      85.24938096922533,
      92.81924301379554,
      87.15953307392996,
      84.78952953661125,
      89.38804386275203,
      76.01697912981959,
      78.03325079589672,
      79.41280509373895,
      95.82596391934914,
      91.12133003183587,
      93.42058719490626,
      72.0031859816806,
      94.65864874425186,
      84.75415634948709,
      74.7095010252905,
      70.74637424831978,
      72.23204810753448,
      83.30385567739653,
      84.54191722674213,
      78.03325079589672,
      78.06862398302087,
      91.75804740007074,
      94.37566324725857,
      89.9540148567386,
      87.15953307392996,
      94.0926777502653,
      92.43013795542979,
      87.1241598868058,
      72.58577997877609,
      74.14220021223912,
      90.97983728333922,
      72.79801910152105,
      98.65581888928192,
      98.69119207640608,
      98.90343119915104,
      98.44357976653697,
      99.25716307039264
    ],
    [
      73.07949231796927,
      78.08951235804943,
      77.35470941883767,
      74.08149632598531,
      75.88510354041416,
      83.23313293253173,
      73.34669338677354,
      88.17635270541082,
      90.31396125584502,
      72.812291249165,
      85.03674014696058,
      88.17635270541082,
      88.57715430861724,
      78.01533813618406,
      28.599735799207398,
      50.64205457463884,
      28.599735799207398,
      73.76988531261561,
      88.10955243820975,
      81.62992651970607,
      91.98396793587175,
      90.51436205744822,
      88.97795591182364,
      74.34869739478958,
      75.1503006012024,
      92.45156980627922,
      76.0187040748163,
      89.44555778223113,
      92.71877087508351,
      92.85237140948564,
      92.85237140948564,
      75.01670006680027,
      75.01670006680027,
      80.74369189907038,
      92.78557114228457,
      78.22311289245157,
      88.10955243820975,
      81.1623246492986,
      77.0875083500334,
      82.83233132932531,
      75.21954944635357,
      72.27788911155645,
      73.81429525718103,
      92.85237140948564,
      85.57114228456913,
      88.77755511022045,
      79.70927917164477,
      90.71476285905143,
      78.08951235804943,
      73.88926862611072,
      78.28991315965264,
      73.94438722966015,
      76.95390781563127,
      78.22311289245157,
      72.27788911155645,
      73.54709418837675,
      85.77154308617234,
      90.5811623246493,
      83.96793587174348,
      81.09552438209752,
      89.91315965263861,
      87.5751503006012,
      80.62792251169004,
      73.22961373390558,
      74.88309953239813,
      85.50434201736807,
      73.62938596491229,
      97.46158984635939,
      97.52839011356046,
      97.79559118236473,
      97.1943887775551,
      98.59719438877755
    ],
    [
      73.54066985645933,
      82.4401913875598,
      81.29186602870814,
      71.91387559808614,
      80.23923444976077,
      86.22009569377991,
      73.7799043062201,
      91.05263157894737,
      92.72727272727272,
      74.88038277511961,
      88.66028708133972,
      91.14832535885168,
      91.43540669856459,
      74.08784568905415,
      43.20574162679426,
      39.48635634028893,
      43.20574162679426,
      72.91897891231964,
      90.7177033492823,
      84.88038277511963,
      93.92344497607655,
      92.82296650717703,
      91.38755980861244,
      77.5598086124402,
      72.67942583732058,
      94.44976076555024,
      79.76076555023923,
      91.91387559808612,
      94.64114832535884,
      94.688995215311,
      94.688995215311,
      79.33014354066987,
      79.18660287081339,
      74.68899521531101,
      94.35406698564593,
      82.24880382775119,
      90.86124401913875,
      84.64114832535886,
      80.86124401913875,
      86.36363636363636,
      74.95227185948835,
      74.44976076555024,
      76.84210526315789,
      94.44976076555024,
      88.94736842105263,
      91.57894736842105,
      75.74671445639187,
      93.01435406698565,
      82.10526315789474,
      72.31715652768284,
      72.20095693779905,
      76.9377990430622,
      80.7177033492823,
      81.86602870813398,
      74.44976076555024,
      76.26794258373207,
      89.0909090909091,
      92.77511961722487,
      87.17703349282296,
      84.64114832535886,
      92.4401913875598,
      90.28708133971291,
      84.688995215311,
      75.74162679425838,
      72.48803827751196,
      88.80382775119617,
      76.45933014354067,
      98.13397129186603,
      98.18181818181819,
      98.32535885167464,
      97.89473684210527,
      98.99521531100478
    ],
    [
      73.07949231796927,
      78.08951235804943,
      77.35470941883767,
      74.08149632598531,
      75.88510354041416,
      83.23313293253173,
      73.34669338677354,
      88.17635270541082,
      90.31396125584502,
      72.812291249165,
      85.03674014696058,
      88.17635270541082,
      88.57715430861724,
      78.01533813618406,
      28.599735799207398,
      50.64205457463884,
      28.599735799207398,
      73.76988531261561,
      88.10955243820975,
      81.62992651970607,
      91.98396793587175,
      90.51436205744822,
      88.97795591182364,
      74.34869739478958,
      75.1503006012024,
      92.45156980627922,
      76.0187040748163,
      89.44555778223113,
      92.71877087508351,
      92.85237140948564,
      92.85237140948564,
      75.01670006680027,
      75.01670006680027,
      80.74369189907038,
      92.78557114228457,
      78.22311289245157,
      88.10955243820975,
      81.1623246492986,
      77.0875083500334,
      82.83233132932531,
      75.21954944635357,
      72.27788911155645,
      73.81429525718103,
      92.85237140948564,
      85.57114228456913,
      88.77755511022045,
      79.70927917164477,
      90.71476285905143,
      78.08951235804943,
      73.88926862611072,
      78.28991315965264,
      73.94438722966015,
      76.95390781563127,
      78.22311289245157,
      72.27788911155645,
      73.54709418837675,
      85.77154308617234,
      90.5811623246493,
      83.96793587174348,
      81.09552438209752,
      89.91315965263861,
      87.5751503006012,
      80.62792251169004,
      73.22961373390558,
      74.88309953239813,
      85.50434201736807,
      73.62938596491229,
      97.46158984635939,
      97.52839011356046,
      97.79559118236473,
      97.1943887775551,
      98.59719438877755
    ],
    [
      79.49224259520452,
      87.92665726375176,
      87.30606488011283,
      75.9379407616361,
      86.51622002820875,
      90.97320169252468,
      78.47672778561355,
      94.49929478138223,
      95.59943582510579,
      80.42313117066291,
      92.63751763046545,
      94.5839210155148,
      94.8095909732017,
      71.92656286311876,
      75.8815232722144,
      71.96050775740478,
      75.8815232722144,
      24.00564174894217,
      94.1607898448519,
      89.76022566995768,
      96.27644569816644,
      95.57122708039493,
      94.69675599435826,
      83.24400564174894,
      77.40479548660085,
      96.61495063469675,
      85.13399153737659,
      94.86600846262341,
      96.69957686882934,
      96.75599435825106,
      96.72778561354019,
      85.24682651622003,
      85.049365303244,
      75.5148095909732,
      96.64315937940762,
      87.75740479548661,
      94.21720733427362,
      89.3935119887165,
      87.30606488011283,
      91.1706629055007,
      72.8913963328632,
      78.30747531734838,
      82.25669957686883,
      96.69957686882934,
      92.77856135401976,
      94.64033850493652,
      68.27956989247312,
      95.76868829337094,
      86.6854724964739,
      63.18758815232722,
      70.71932299012694,
      66.65726375176305,
      85.52891396332863,
      86.48801128349788,
      78.30747531734838,
      80.1410437235543,
      93.06064880112835,
      95.5148095909732,
      91.50916784203102,
      89.19605077574047,
      95.2327221438646,
      93.82228490832159,
      89.36530324400564,
      72.94781382228491,
      75.8815232722144,
      92.94781382228491,
      72.69393511988717,
      98.84344146685471,
      98.89985895627645,
      99.06911142454162,
      98.75881523272214,
      99.37940761636106
    ],
    [
      76.14503816793892,
      76.92307692307693,
      74.21602787456446,
      79.95426829268293,
      75.12116316639742,
      79.23250564334086,
      77.5089605734767,
      76.52370203160271,
      79.23250564334086,
      75.15337423312883,
      73.58916478555305,
      77.42663656884876,
      77.42663656884876,
      91.0992330931908,
      81.37384412153237,
      85.87479935794543,
      81.37384412153237,
      87.12541620421753,
      49.887133182844245,
      59.36794582392777,
      78.1038374717833,
      78.1038374717833,
      69.3002257336343,
      72.05128205128204,
      79.78311386522076,
      82.84424379232506,
      73.52941176470588,
      77.42663656884876,
      79.23250564334086,
      82.61851015801355,
      82.61851015801355,
      74.5127436281859,
      74.14285714285714,
      81.47410358565737,
      83.29571106094808,
      76.08286252354048,
      77.42663656884876,
      82.43243243243244,
      76.19047619047619,
      75.84650112866818,
      87.2852233676976,
      76.30180658873539,
      76.00950118764845,
      82.61851015801355,
      74.04063205417607,
      78.3295711060948,
      92.23416965352449,
      80.1354401805869,
      77.47572815533981,
      87.8673957621326,
      80.38277511961722,
      83.98558187435633,
      75.47169811320755,
      75.9493670886076,
      76.30180658873539,
      75.79545454545455,
      74.94356659142211,
      80.1354401805869,
      76.07223476297969,
      77.65237020316027,
      77.87810383747178,
      76.2979683972912,
      79.23250564334086,
      83.90557939914163,
      79.52871870397644,
      76.2979683972912,
      83.60745614035088,
      93.45372460496614,
      93.67945823927766,
      94.35665914221218,
      91.87358916478556,
      95.93679458239278
    ],
    [
      74.04580152671755,
      77.87456445993031,
      81.70731707317073,
      76.90548780487805,
      78.8368336025848,
      75.26132404181185,
      74.46236559139786,
      78.22299651567944,
      81.8815331010453,
      74.13087934560328,
      74.21602787456446,
      78.9198606271777,
      78.9198606271777,
      88.6590750639089,
      77.87318361955086,
      83.42696629213484,
      77.87318361955086,
      83.94376618571957,
      72.64808362369338,
      32.926829268292686,
      81.8815331010453,
      79.44250871080139,
      76.13240418118467,
      73.33333333333333,
      76.83965917893106,
      85.36585365853658,
      74.83660130718954,
      79.96515679442508,
      82.92682926829268,
      84.49477351916377,
      84.49477351916377,
      77.66116941529235,
      77.28571428571429,
      77.68924302788844,
      85.01742160278745,
      78.22299651567944,
      78.397212543554,
      75.78397212543554,
      80.31358885017421,
      73.69337979094077,
      84.077892325315,
      72.68862911795961,
      73.75296912114014,
      85.71428571428571,
      74.73867595818815,
      78.74564459930313,
      90.02389486260454,
      81.01045296167247,
      74.91289198606272,
      84.79152426520848,
      75.93984962406014,
      80.1750772399588,
      78.73070325900514,
      77.87456445993031,
      72.68862911795961,
      71.13636363636363,
      75.26132404181185,
      79.96515679442508,
      74.0418118466899,
      72.29965156794425,
      79.61672473867596,
      76.65505226480836,
      71.25435540069687,
      80.36480686695279,
      76.3622974963181,
      76.13240418118467,
      79.60526315789474,
      94.5993031358885,
      94.77351916376307,
      95.29616724738676,
      93.55400696864112,
      97.0383275261324
    ],
    [
      91.41221374045801,
      84.41814595660749,
      87.2822299651568,
      93.0640243902439,
      88.20678513731825,
      83.16326530612244,
      91.84587813620072,
      78.44036697247707,
      78.78787878787878,
      90.8997955010225,
      78.81944444444444,
      75.11961722488039,
      72.08121827411168,
      97.72251917267023,
      93.9894319682959,
      96.107544141252,
      93.9894319682959,
      96.41139474657787,
      74.44933920704845,
      81.25,
      60.83916083916085,
      67.26190476190477,
      65.07177033492823,
      89.74358974358975,
      93.33849728892332,
      76.61290322580645,
      86.60130718954248,
      75.38461538461539,
      61.29032258064516,
      72.03389830508475,
      71.42857142857143,
      88.30584707646177,
      88.85714285714286,
      94.09030544488712,
      77.86259541984732,
      86.25235404896422,
      77.05627705627705,
      84.68468468468468,
      86.59611992945327,
      82.82548476454294,
      96.41084383352425,
      91.28586609989372,
      90.61757719714964,
      80.48780487804879,
      79.62264150943396,
      75.77319587628865,
      98.06849860613302,
      77.12418300653596,
      85.63106796116506,
      96.71907040328092,
      93.91660970608339,
      95.21112255406797,
      87.82161234991423,
      87.34177215189874,
      91.28586609989372,
      90.11363636363636,
      79.61538461538461,
      77.24550898203593,
      80.37383177570094,
      83.83084577114428,
      75.14450867052022,
      80.34188034188034,
      84.38228438228438,
      95.33261802575107,
      93.59351988217968,
      80.62283737024222,
      94.95614035087719,
      84.46601941747572,
      82.52427184466019,
      82.52427184466019,
      81.55339805825243,
      86.40776699029125
    ],
    [
      83.01526717557252,
      76.52859960552269,
      77.70034843205575,
      85.51829268292683,
      78.35218093699515,
      75.51020408163265,
      83.51254480286738,
      74.4,
      76.4,
      82.0040899795501,
      76.04166666666666,
      78,
      76.4,
      94.49221473390658,
      87.0541611624835,
      91.01123595505618,
      87.0541611624835,
      91.56492785793563,
      70.8,
      69.23076923076923,
      67.2,
      59.199999999999996,
      51.6,
      78.46153846153847,
      85.59256390395042,
      77.2,
      76.79738562091504,
      76,
      57.599999999999994,
      76.4,
      76.4,
      79.16041979010495,
      79.85714285714286,
      86.91899070385126,
      76,
      77.02448210922788,
      80,
      77.7027027027027,
      77.42504409171076,
      75.62326869806094,
      91.71439480717831,
      81.82784272051009,
      80.64133016627079,
      77.60000000000001,
      78.8679245283019,
      76,
      95.24093986459577,
      72.8,
      76.11650485436893,
      92.31032125768968,
      86.73957621326042,
      89.13491246138003,
      75.8147512864494,
      77.39602169981917,
      81.82784272051009,
      81.36363636363636,
      79.23076923076923,
      75.6,
      73.5202492211838,
      74.6268656716418,
      74.8,
      78,
      73.65967365967366,
      89.10944206008584,
      85.64064801178203,
      78.20069204152249,
      88.87061403508771,
      89.2,
      89.60000000000001,
      91.60000000000001,
      87.6,
      93.60000000000001
    ],
    [
      91.22137404580153,
      84.81262327416174,
      86.58536585365853,
      92.91158536585365,
      87.7221324717286,
      79.33673469387756,
      91.84587813620072,
      75.68807339449542,
      78.18181818181819,
      90.79754601226993,
      78.81944444444444,
      76.55502392344498,
      73.60406091370558,
      97.60632117127585,
      93.59313077939234,
      95.98715890850723,
      93.59313077939234,
      96.26341102478727,
      62.11453744493392,
      79.8076923076923,
      60.83916083916085,
      43.452380952380956,
      49.282296650717704,
      89.1025641025641,
      92.71882261812549,
      74.19354838709677,
      86.76470588235294,
      77.43589743589745,
      33.87096774193548,
      80.50847457627118,
      80.67226890756302,
      87.85607196401799,
      88.42857142857142,
      93.82470119521913,
      78.62595419847328,
      85.12241054613936,
      77.05627705627705,
      83.33333333333334,
      86.06701940035273,
      80.88642659279779,
      96.25811378388698,
      90.54197662061637,
      90.14251781472684,
      78.86178861788618,
      79.24528301886792,
      74.22680412371135,
      97.94902429311031,
      74.50980392156863,
      86.01941747572816,
      96.65071770334929,
      93.77990430622009,
      95.00514933058703,
      86.62092624356775,
      86.79927667269439,
      90.54197662061637,
      89.88636363636363,
      78.07692307692308,
      77.24550898203593,
      79.43925233644859,
      83.08457711442786,
      76.30057803468208,
      78.2051282051282,
      83.91608391608392,
      94.7961373390558,
      93.00441826215022,
      81.31487889273356,
      94.73684210526315,
      85.98130841121495,
      85.04672897196261,
      85.04672897196261,
      82.2429906542056,
      87.85046728971963
    ],
    [
      72.99618320610686,
      73.73595505617978,
      74.29775280898876,
      73.6280487804878,
      74.01685393258427,
      75.98314606741573,
      72.31182795698925,
      80.75842696629213,
      83.84831460674157,
      73.72188139059304,
      77.3876404494382,
      81.46067415730337,
      81.17977528089888,
      86.59075063908901,
      75.0330250990753,
      80.21669341894061,
      75.0330250990753,
      81.05808361080281,
      79.63483146067416,
      73.31460674157303,
      85.25280898876404,
      83.42696629213484,
      80.89887640449437,
      9.743589743589745,
      72.81177381874517,
      86.23595505617978,
      73.45505617977528,
      81.74157303370787,
      86.93820224719101,
      86.65730337078652,
      86.65730337078652,
      77.52808988764045,
      78.51123595505618,
      75.0996015936255,
      86.93820224719101,
      72.75280898876404,
      80.0561797752809,
      73.73595505617978,
      72.3314606741573,
      76.26404494382022,
      81.67239404352806,
      72.4760892667375,
      75.05938242280284,
      87.21910112359551,
      76.96629213483146,
      81.60112359550563,
      87.95300677021108,
      84.5505617977528,
      71.48876404494382,
      82.29665071770334,
      74.23103212576898,
      77.0854788877446,
      73.87640449438202,
      72.75280898876404,
      72.4760892667375,
      72.61363636363637,
      77.24719101123596,
      83.56741573033707,
      75.98314606741573,
      72.75280898876404,
      82.02247191011236,
      79.49438202247191,
      72.3314606741573,
      77.19957081545064,
      70.47128129602356,
      77.9494382022472,
      76.91885964912281,
      95.50561797752809,
      95.78651685393258,
      96.20786516853933,
      94.9438202247191,
      97.3314606741573
    ],
    [
      89.50381679389314,
      82.84023668639054,
      84.3205574912892,
      91.61585365853658,
      85.46042003231018,
      80.10204081632652,
      90.32258064516128,
      76.14678899082568,
      76.96969696969697,
      89.05930470347649,
      77.08333333333334,
      75.11961722488039,
      72.08121827411168,
      97.16476876597723,
      92.47027741083224,
      95.30497592295345,
      92.47027741083224,
      95.56048834628191,
      76.65198237885463,
      81.25,
      81.11888111888112,
      74.40476190476191,
      76.55502392344498,
      87.05128205128206,
      91.32455460883037,
      0.8064516129032258,
      84.15032679738562,
      73.84615384615385,
      82.25806451612904,
      77.41935483870968,
      76.61290322580645,
      85.75712143928035,
      86.28571428571429,
      92.69588313413014,
      86.25954198473282,
      83.42749529190208,
      76.62337662337663,
      81.98198198198197,
      83.9506172839506,
      79.77839335180056,
      95.41809851088202,
      89.26673751328374,
      88.24228028503563,
      77.41935483870968,
      74.71698113207546,
      73.19587628865979,
      97.59060135404222,
      69.28104575163398,
      83.10679611650485,
      96.00136705399864,
      92.54955570745045,
      93.97528321318228,
      84.04802744425386,
      83.7251356238698,
      89.26673751328374,
      88.52272727272728,
      72.6923076923077,
      69.46107784431138,
      76.94704049844236,
      79.35323383084577,
      73.41040462427746,
      70.94017094017094,
      79.95337995337995,
      93.8304721030043,
      91.8262150220913,
      76.8166089965398,
      93.8048245614035,
      81.45161290322581,
      83.06451612903226,
      82.25806451612904,
      79.83870967741935,
      85.48387096774194
    ],
    [
      80.82061068702289,
      74.16173570019724,
      75.9581881533101,
      83.76524390243902,
      75.92891760904685,
      74.48979591836735,
      81.54121863799283,
      75.50335570469798,
      74.49664429530202,
      80.3680981595092,
      78.18791946308725,
      73.15436241610739,
      71.47651006711409,
      93.67882872414594,
      85.27080581241744,
      89.96789727126806,
      85.27080581241744,
      90.41805401405846,
      77.18120805369128,
      71.15384615384616,
      77.85234899328859,
      78.18791946308725,
      75.16778523489933,
      78.2051282051282,
      83.88845855925639,
      77.51677852348993,
      75.16339869281046,
      71.47651006711409,
      79.19463087248322,
      78.18791946308725,
      78.18791946308725,
      75.56221889055472,
      76.14285714285714,
      85.72377158034529,
      80.53691275167785,
      74.76459510357816,
      76.84563758389261,
      74.54954954954955,
      75.48500881834215,
      75.06925207756233,
      90.56891943489882,
      79.17109458023378,
      78.74109263657957,
      78.52348993288591,
      71.47651006711409,
      77.18120805369128,
      94.60374352847471,
      76.51006711409396,
      71.84466019417476,
      91.21667805878332,
      84.89405331510595,
      87.74459320288362,
      73.58490566037736,
      73.23688969258589,
      79.17109458023378,
      78.75,
      77.18120805369128,
      74.49664429530202,
      75.70093457943925,
      72.13930348258707,
      75.83892617449665,
      75.83892617449665,
      75.29137529137529,
      87.76824034334764,
      84.09425625920471,
      81.20805369127517,
      87.4451754385965,
      91.2751677852349,
      91.94630872483222,
      92.28187919463086,
      89.59731543624162,
      94.29530201342283
    ],
    [
      70.22900763358778,
      71.89542483660131,
      79.08496732026144,
      73.32317073170732,
      79.64458804523424,
      75.65359477124183,
      71.415770609319,
      79.73856209150327,
      81.86274509803921,
      69.6319018404908,
      75.16339869281046,
      78.26797385620915,
      78.92156862745098,
      87.89216825470602,
      75.56142668428005,
      81.98234349919743,
      75.56142668428005,
      81.57602663706992,
      78.10457516339869,
      72.22222222222221,
      82.35294117647058,
      82.35294117647058,
      79.57516339869281,
      72.3076923076923,
      75.13555383423703,
      83.98692810457517,
      1.6339869281045754,
      78.92156862745098,
      85.7843137254902,
      83.82352941176471,
      83.82352941176471,
      78.26086956521739,
      77.71428571428571,
      76.49402390438247,
      84.9673202614379,
      74.67320261437908,
      77.94117647058823,
      73.69281045751634,
      76.9607843137255,
      73.8562091503268,
      82.32149675448645,
      68.65037194473965,
      71.61520190023754,
      85.13071895424837,
      73.69281045751634,
      77.61437908496733,
      88.72958980485862,
      78.26797385620915,
      68.30065359477125,
      82.50170881749828,
      72.65892002734108,
      76.31307929969104,
      67.48366013071896,
      70.09803921568627,
      68.65037194473965,
      68.75,
      74.83660130718954,
      80.3921568627451,
      70.91503267973856,
      67.97385620915033,
      77.45098039215686,
      74.50980392156863,
      72.22222222222221,
      79.02360515021459,
      74.74226804123711,
      76.9607843137255,
      77.35745614035088,
      94.6078431372549,
      94.6078431372549,
      95.42483660130719,
      93.30065359477125,
      96.73202614379085
    ],
    [
      81.77480916030534,
      73.37278106508876,
      77.00348432055749,
      84.90853658536585,
      77.86752827140549,
      73.46938775510205,
      82.97491039426524,
      77.431906614786,
      71.59533073929961,
      81.0838445807771,
      75,
      77.04280155642024,
      72.76264591439688,
      94.39925633279107,
      86.12945838837517,
      90.52969502407706,
      86.12945838837517,
      91.41694413614503,
      78.59922178988327,
      75,
      74.70817120622569,
      76.65369649805449,
      78.59922178988327,
      79.1025641025641,
      84.58559256390396,
      76.26459143968872,
      75.32679738562092,
      43.96887159533074,
      79.37743190661479,
      74.31906614785993,
      74.70817120622569,
      78.41079460269866,
      79.28571428571428,
      86.78618857901726,
      77.82101167315176,
      75.51789077212806,
      76.26459143968872,
      74.54954954954955,
      76.01410934744268,
      73.13019390581718,
      91.14165712103856,
      80.65887353878853,
      80.16627078384798,
      77.431906614786,
      75.47169811320755,
      78.21011673151752,
      95.04181600955795,
      75.4863813229572,
      72.03883495145631,
      91.96855775803144,
      86.1244019138756,
      88.6714727085479,
      72.89879931389366,
      75.9493670886076,
      80.65887353878853,
      79.77272727272727,
      76.92307692307693,
      75.4863813229572,
      76.94704049844236,
      72.636815920398,
      70.03891050583657,
      77.431906614786,
      72.49417249417249,
      88.73390557939913,
      84.97790868924889,
      77.16262975778547,
      88.10307017543859,
      90.6614785992218,
      90.27237354085604,
      91.05058365758755,
      88.715953307393,
      92.99610894941634
    ],
    [
      73.89277389277389,
      76.6122766122766,
      76.14607614607614,
      76.67682926829268,
      75.36907536907536,
      81.04118104118105,
      74.2035742035742,
      87.1017871017871,
      89.27738927738928,
      73.34887334887334,
      84.14918414918415,
      87.41258741258741,
      87.64568764568764,
      79.22379735068557,
      74.57067371202115,
      73.79614767255217,
      74.57067371202115,
      74.25083240843507,
      87.1017871017871,
      80.4972804972805,
      90.9090909090909,
      89.74358974358975,
      87.87878787878788,
      72.41647241647242,
      0.5422153369481022,
      91.2975912975913,
      75.13597513597513,
      87.87878787878788,
      91.84149184149184,
      91.91919191919192,
      91.99689199689199,
      73.58197358197359,
      73.50427350427351,
      76.02921646746348,
      91.84149184149184,
      76.53457653457653,
      86.86868686868688,
      77.77777777777779,
      74.43667443667444,
      81.81818181818183,
      74.91408934707904,
      72.49417249417249,
      72.96037296037296,
      91.99689199689199,
      83.37218337218337,
      88.26728826728827,
      81.60095579450419,
      89.82128982128982,
      77.07847707847708,
      75.2904989747095,
      75.25632262474367,
      72.24510813594233,
      75.52447552447552,
      76.37917637917639,
      72.49417249417249,
      71.87257187257188,
      84.77078477078477,
      89.82128982128982,
      82.82828282828282,
      79.64257964257965,
      88.73348873348873,
      86.32478632478633,
      78.86557886557887,
      72.1030042918455,
      75.62592047128129,
      84.22688422688422,
      72.80701754385966,
      97.28049728049729,
      97.43589743589743,
      97.66899766899768,
      96.96969696969697,
      98.52369852369853
    ],
    [
      90.17175572519083,
      83.23471400394477,
      85.71428571428571,
      91.9969512195122,
      86.59127625201938,
      77.8061224489796,
      90.86021505376344,
      75.68807339449542,
      79.39393939393939,
      89.57055214723927,
      78.81944444444444,
      77.51196172248804,
      74.11167512690355,
      97.39716476876598,
      93.06472919418758,
      95.6661316211878,
      93.06472919418758,
      95.93044765075842,
      61.23348017621145,
      79.08653846153845,
      69.23076923076923,
      34.523809523809526,
      55.50239234449761,
      88.33333333333333,
      92.25406661502711,
      80.64516129032258,
      86.11111111111111,
      79.48717948717949,
      12.903225806451612,
      87.28813559322035,
      86.5546218487395,
      87.40629685157421,
      87.71428571428571,
      93.22709163346613,
      79.38931297709924,
      83.80414312617702,
      76.19047619047619,
      82.43243243243244,
      85.5379188712522,
      78.67036011080333,
      95.79992363497519,
      89.798087141339,
      88.95486935866982,
      82.92682926829268,
      78.49056603773586,
      74.74226804123711,
      97.78972520908005,
      77.12418300653596,
      85.04854368932038,
      96.34313055365688,
      93.09637730690362,
      94.49021627188465,
      85.76329331046311,
      86.07594936708861,
      89.798087141339,
      89.31818181818181,
      78.46153846153847,
      77.84431137724552,
      79.12772585669782,
      82.33830845771143,
      78.03468208092485,
      77.77777777777779,
      82.51748251748252,
      94.20600858369099,
      92.12076583210603,
      80.27681660899654,
      94.24342105263158,
      83.89830508474576,
      83.89830508474576,
      84.7457627118644,
      83.89830508474576,
      89.83050847457628
    ],
    [
      91.22137404580153,
      84.22090729783037,
      85.36585365853658,
      92.98780487804879,
      86.4297253634895,
      83.92857142857143,
      91.84587813620072,
      75.22935779816514,
      75.75757575757575,
      90.69529652351737,
      76.73611111111111,
      67.94258373205741,
      68.52791878172589,
      97.79223797350686,
      93.92338177014531,
      96.26805778491172,
      93.92338177014531,
      96.48538660747317,
      77.5330396475771,
      81.97115384615384,
      67.13286713286713,
      76.19047619047619,
      76.55502392344498,
      89.35897435897436,
      93.33849728892332,
      72.58064516129032,
      86.11111111111111,
      75.8974358974359,
      80.64516129032258,
      19.491525423728813,
      22.689075630252102,
      88.00599700149925,
      88.28571428571429,
      93.95750332005312,
      76.33587786259542,
      85.49905838041431,
      77.05627705627705,
      84.23423423423422,
      85.89065255731923,
      78.67036011080333,
      96.37266132111493,
      90.86078639744952,
      89.90498812351544,
      73.98373983739837,
      77.35849056603774,
      71.64948453608247,
      98.14814814814815,
      69.28104575163398,
      85.04854368932038,
      96.85577580314423,
      93.77990430622009,
      95.15962924819773,
      87.13550600343054,
      85.35262206148282,
      90.86078639744952,
      90.68181818181819,
      78.46153846153847,
      70.05988023952095,
      77.88161993769471,
      81.09452736318407,
      71.09826589595376,
      74.35897435897436,
      82.05128205128204,
      95.17167381974248,
      93.29896907216495,
      80.27681660899654,
      94.90131578947368,
      82.29166666666666,
      79.16666666666666,
      79.16666666666666,
      68.75,
      82.29166666666666
    ],
    [
      72.32824427480917,
      72.71364317841079,
      73.16341829085458,
      72.78963414634147,
      75.41229385307346,
      75.71214392803599,
      71.86379928315412,
      80.0599700149925,
      82.3088455772114,
      71.67689161554193,
      73.31334332833583,
      77.36131934032984,
      78.56071964017991,
      87.28793864745526,
      74.9009247027741,
      81.54093097913324,
      74.9009247027741,
      82.09396966333703,
      79.61019490254873,
      74.5127436281859,
      85.45727136431785,
      83.65817091454272,
      81.25937031484258,
      75,
      73.58636715724245,
      85.75712143928035,
      78.41079460269866,
      81.70914542728636,
      86.80659670164917,
      86.20689655172413,
      86.3568215892054,
      0.29985007496251875,
      5.857142857142858,
      75.4980079681275,
      86.05697151424287,
      72.26386806596702,
      79.46026986506747,
      71.81409295352323,
      75.71214392803599,
      72.11394302848576,
      82.62695685376099,
      73.21997874601487,
      72.20902612826603,
      86.20689655172413,
      75.86206896551724,
      81.40929535232384,
      88.86897650338511,
      82.75862068965517,
      73.61319340329835,
      83.21941216678059,
      75.66643882433355,
      78.57878475798145,
      75.71214392803599,
      74.2128935532234,
      73.21997874601487,
      71.47727272727272,
      76.61169415292353,
      82.90854572713643,
      76.16191904047976,
      73.31334332833583,
      81.25937031484258,
      79.01049475262369,
      71.51424287856072,
      77.8969957081545,
      73.78497790868926,
      75.86206896551724,
      77.90570175438597,
      95.05247376311844,
      95.50224887556223,
      95.80209895052474,
      94.45277361319341,
      97.30134932533733
    ],
    [
      72.04198473282443,
      72.97687861271676,
      72.6878612716763,
      72.78963414634147,
      75,
      75.86705202312139,
      71.68458781362007,
      80.49132947976878,
      82.36994219653178,
      71.57464212678937,
      73.55491329479769,
      77.45664739884393,
      77.89017341040463,
      87.03230304438763,
      74.63672391017172,
      81.34028892455859,
      74.63672391017172,
      81.76100628930818,
      80.20231213872833,
      74.71098265895954,
      85.83815028901735,
      84.2485549132948,
      81.9364161849711,
      75.12820512820512,
      73.43144848954299,
      86.1271676300578,
      78.17919075144508,
      82.22543352601156,
      87.28323699421965,
      86.1271676300578,
      86.1271676300578,
      4.76878612716763,
      1.4285714285714286,
      75.1660026560425,
      86.41618497109826,
      72.39884393063583,
      80.05780346820809,
      72.25433526011561,
      75.72254335260115,
      71.24277456647398,
      82.35967926689575,
      73.53878852284804,
      74.34679334916865,
      86.70520231213872,
      76.30057803468208,
      82.08092485549133,
      88.68976503385106,
      83.52601156069365,
      73.69942196531792,
      83.0827067669173,
      75.52973342447027,
      78.52729145211123,
      75.14450867052022,
      73.84393063583815,
      73.53878852284804,
      72.5,
      76.878612716763,
      83.52601156069365,
      76.73410404624278,
      74.27745664739885,
      82.08092485549133,
      79.62427745664739,
      71.96531791907515,
      77.36051502145924,
      73.5640648011782,
      76.73410404624278,
      77.85087719298247,
      95.23121387283237,
      95.66473988439307,
      95.95375722543352,
      94.65317919075144,
      97.39884393063583
    ],
    [
      71.94656488549617,
      75.82608695652175,
      81.73913043478261,
      75.45731707317073,
      77.54442649434571,
      75.47826086956522,
      72.93906810035843,
      80,
      81.3913043478261,
      72.39263803680981,
      74.43478260869566,
      77.56521739130436,
      77.73913043478261,
      88.77527306530327,
      77.93923381770145,
      83.26645264847512,
      77.93923381770145,
      83.79578246392897,
      77.21739130434783,
      74.08695652173914,
      82.78260869565217,
      81.56521739130434,
      79.65217391304348,
      72.6923076923077,
      75.67776917118513,
      84.34782608695653,
      75.98039215686273,
      80.34782608695652,
      85.73913043478261,
      84,
      84.17391304347827,
      75.26236881559221,
      75.42857142857143,
      70.4515272244356,
      83.82608695652173,
      79.13043478260869,
      77.39130434782608,
      75.82608695652175,
      78.6086956521739,
      73.73913043478261,
      83.08514700267278,
      72.15727948990435,
      73.04038004750593,
      84.34782608695653,
      74.78260869565217,
      79.47826086956522,
      90.20310633213859,
      81.73913043478261,
      74.78260869565217,
      84.89405331510595,
      77.03349282296651,
      79.50566426364573,
      79.7598627787307,
      75.13043478260869,
      72.15727948990435,
      73.52272727272727,
      76.52173913043478,
      80.8695652173913,
      72.34782608695653,
      73.73913043478261,
      80.52173913043478,
      77.21739130434783,
      72.69565217391303,
      80.25751072961373,
      75.84683357879234,
      76.34782608695653,
      79.27631578947368,
      94.78260869565217,
      95.30434782608695,
      95.47826086956522,
      93.73913043478261,
      96.69565217391303
    ],
    [
      90.07633587786259,
      83.4319526627219,
      83.10104529616724,
      91.84451219512195,
      84.32956381260097,
      81.88775510204081,
      90.59139784946237,
      73.85321100917432,
      76.96969696969697,
      89.57055214723927,
      76.38888888888889,
      73.20574162679426,
      74.11167512690355,
      97.23448756681385,
      92.7344782034346,
      95.30497592295345,
      92.7344782034346,
      95.78246392896781,
      76.2114537444934,
      81.49038461538461,
      77.62237762237763,
      74.40476190476191,
      77.99043062200957,
      87.94871794871794,
      91.86676994577847,
      85.48387096774194,
      85.13071895424837,
      76.92307692307693,
      83.06451612903226,
      77.23577235772358,
      77.23577235772358,
      85.90704647676162,
      86.71428571428571,
      92.62948207171314,
      9.16030534351145,
      84.36911487758945,
      73.16017316017316,
      82.65765765765765,
      84.12698412698413,
      80.33240997229917,
      95.34173348606339,
      89.69181721572795,
      87.88598574821853,
      83.73983739837398,
      78.49056603773586,
      75.77319587628865,
      97.69016328156113,
      74.50980392156863,
      82.91262135922331,
      96.13807245386192,
      92.75461380724539,
      94.23274974253347,
      84.39108061749572,
      84.08679927667269,
      89.69181721572795,
      89.0909090909091,
      78.46153846153847,
      77.24550898203593,
      77.57009345794393,
      81.34328358208955,
      75.72254335260115,
      76.49572649572649,
      80.88578088578089,
      94.09871244635193,
      92.19440353460972,
      76.8166089965398,
      93.85964912280701,
      86.1788617886179,
      84.5528455284553,
      86.1788617886179,
      83.73983739837398,
      90.2439024390244
    ],
    [
      72.04198473282443,
      78.3427495291902,
      77.35191637630662,
      76.98170731707317,
      75.60581583198707,
      73.25800376647834,
      74.46236559139786,
      77.77777777777779,
      79.47269303201506,
      72.80163599182005,
      74.57627118644068,
      77.96610169491525,
      77.40112994350282,
      89.47246107366954,
      78.5336856010568,
      84.26966292134831,
      78.5336856010568,
      85.09064002959674,
      78.15442561205273,
      75.14124293785311,
      83.42749529190208,
      80.22598870056498,
      80.0376647834275,
      72.43589743589743,
      76.60728117738186,
      83.2391713747646,
      75,
      80.7909604519774,
      83.42749529190208,
      84.18079096045197,
      84.36911487758945,
      72.26386806596702,
      72.42857142857143,
      79.5484727755644,
      84.36911487758945,
      1.694915254237288,
      75.32956685499059,
      17.13747645951036,
      78.1305114638448,
      70.62146892655367,
      84.95609011072929,
      73.96386822529225,
      72.80285035629454,
      84.18079096045197,
      74.76459510357816,
      79.09604519774011,
      90.900039824771,
      80.60263653483992,
      79.09604519774011,
      86.22693096377307,
      77.78537252221463,
      81.97734294541709,
      77.01543739279589,
      80.28933092224231,
      73.96386822529225,
      73.75,
      75.89453860640302,
      81.54425612052731,
      75.89453860640302,
      75.51789077212806,
      79.84934086629002,
      77.5894538606403,
      73.06967984934086,
      81.22317596566523,
      76.14138438880707,
      74.57627118644068,
      80.64692982456141,
      93.97363465160076,
      94.16195856873823,
      94.53860640301318,
      92.8436911487759,
      96.23352165725048
    ],
    [
      87.30916030534351,
      80.86785009861933,
      77.70034843205575,
      89.78658536585365,
      80.61389337641357,
      78.31632653061224,
      88.08243727598565,
      72.93577981651376,
      77.57575757575758,
      86.80981595092024,
      73.61111111111111,
      74.64114832535886,
      74.61928934010153,
      96.32814315593772,
      90.75297225891677,
      93.86035313001605,
      90.75297225891677,
      94.48760636330003,
      76.2114537444934,
      79.08653846153845,
      79.39393939393939,
      83.33333333333334,
      77.03349282296651,
      84.87179487179488,
      89.46553059643688,
      75.15151515151514,
      82.18954248366013,
      78.97435897435898,
      77.57575757575758,
      77.57575757575758,
      77.57575757575758,
      82.3088455772114,
      83.57142857142857,
      91.03585657370517,
      71.51515151515152,
      79.66101694915254,
      30.303030303030305,
      78.6036036036036,
      80.24691358024691,
      76.73130193905817,
      94.04352806414661,
      87.24760892667375,
      85.2731591448931,
      75.75757575757575,
      73.20754716981132,
      77.83505154639175,
      96.87375547590601,
      81.81818181818183,
      81.35922330097087,
      94.9419002050581,
      90.77238550922762,
      92.58496395468589,
      80.61749571183533,
      81.55515370705244,
      87.24760892667375,
      86.47727272727272,
      75,
      83.23353293413174,
      74.45482866043614,
      78.1094527363184,
      80.34682080924856,
      74.78632478632478,
      79.02097902097903,
      92.32832618025752,
      89.91163475699558,
      73.35640138408304,
      91.83114035087719,
      88.48484848484848,
      87.87878787878788,
      89.0909090909091,
      87.27272727272727,
      90.9090909090909
    ],
    [
      75,
      75.7396449704142,
      75.60975609756098,
      78.8109756097561,
      75.76736672051696,
      78.6036036036036,
      76.52329749103941,
      76.12612612612612,
      76.8018018018018,
      74.64212678936605,
      73.1981981981982,
      76.12612612612612,
      75.67567567567568,
      90.89007669068093,
      80.84544253632761,
      86.5569823434992,
      80.84544253632761,
      86.79245283018868,
      77.47747747747748,
      79.27927927927928,
      81.75675675675676,
      78.37837837837837,
      78.82882882882883,
      74.74358974358975,
      77.7691711851278,
      81.98198198198197,
      73.69281045751634,
      78.6036036036036,
      81.98198198198197,
      82.43243243243244,
      82.65765765765765,
      71.81409295352323,
      72.57142857142857,
      81.67330677290838,
      83.1081081081081,
      17.325800376647834,
      74.54954954954955,
      1.1261261261261262,
      69.66490299823633,
      74.77477477477478,
      87.24704085528828,
      75.55791710945803,
      74.94061757719716,
      83.1081081081081,
      72.97297297297297,
      77.92792792792793,
      92.25408203902828,
      79.05405405405406,
      75.33980582524272,
      87.93574846206425,
      80.10936431989063,
      83.67662203913491,
      74.27101200686106,
      76.31103074141049,
      75.55791710945803,
      75.11363636363636,
      75.67567567567568,
      80.18018018018019,
      74.54954954954955,
      78.15315315315316,
      78.6036036036036,
      77.02702702702703,
      77.02702702702703,
      83.20815450643777,
      78.93961708394698,
      75.90090090090091,
      83.05921052631578,
      93.46846846846847,
      93.69369369369369,
      94.14414414414415,
      92.11711711711712,
      95.94594594594594
    ],
    [
      74.14122137404581,
      71.87096774193549,
      73.41935483870968,
      74.54268292682927,
      73.16129032258064,
      75.48387096774194,
      71.415770609319,
      81.54838709677419,
      84.38709677419355,
      72.69938650306749,
      76.51612903225806,
      80.64516129032258,
      81.29032258064515,
      85.7541250290495,
      74.63672391017172,
      79.41412520064205,
      74.63672391017172,
      80.3921568627451,
      81.29032258064515,
      74.45161290322581,
      86.83870967741936,
      84.64516129032258,
      82.96774193548387,
      78.07692307692308,
      72.96669248644461,
      86.96774193548387,
      75.35483870967742,
      82.96774193548387,
      87.61290322580645,
      87.61290322580645,
      87.87096774193547,
      74.83870967741936,
      76.51612903225806,
      75.69721115537848,
      87.61290322580645,
      72.7741935483871,
      80,
      70.3225806451613,
      27.225806451612904,
      73.29032258064517,
      81.061473844979,
      75.23910733262487,
      77.67220902612827,
      87.09677419354838,
      78.06451612903226,
      82.83870967741936,
      87.71405814416568,
      85.16129032258064,
      73.80645161290322,
      82.2282980177717,
      75.25632262474367,
      77.75489186405767,
      75.2258064516129,
      73.41935483870968,
      75.23910733262487,
      76.36363636363637,
      78.45161290322581,
      84.38709677419355,
      77.41935483870968,
      74.45161290322581,
      84,
      80.90322580645162,
      74.19354838709677,
      77.36051502145924,
      74.0058910162003,
      77.93548387096774,
      76.48026315789474,
      95.48387096774194,
      95.48387096774194,
      95.87096774193549,
      94.96774193548387,
      97.67741935483872
    ],
    [
      77.19465648854961,
      70.41420118343196,
      71.95121951219512,
      81.25,
      70.59773828756059,
      79.08163265306123,
      79.56989247311827,
      75.20891364902506,
      73.81615598885793,
      77.40286298568508,
      67.96657381615599,
      65.73816155988858,
      70.75208913649024,
      92.54008831048107,
      83.15719947159842,
      88.08186195826644,
      83.15719947159842,
      89.12319644839067,
      74.65181058495823,
      76.92307692307693,
      79.10863509749304,
      75.76601671309191,
      75.20891364902506,
      77.05128205128206,
      81.87451587916344,
      79.94428969359332,
      74.01960784313727,
      76.6016713091922,
      79.38718662952647,
      75.20891364902506,
      75.4874651810585,
      72.11394302848576,
      71,
      83.66533864541833,
      80.22284122562674,
      70.05649717514125,
      73.81615598885793,
      74.54954954954955,
      73.72134038800705,
      1.662049861495845,
      89.00343642611683,
      78.21466524973432,
      76.60332541567696,
      77.71587743732591,
      73.53760445682451,
      75.76601671309191,
      93.56829948227798,
      78.55153203342618,
      74.75728155339806,
      89.84962406015038,
      83.25358851674642,
      86.50875386199795,
      74.78559176672384,
      75.22603978300181,
      78.21466524973432,
      78.63636363636364,
      75.76601671309191,
      76.88022284122563,
      79.10863509749304,
      76.61691542288557,
      75.76601671309191,
      75.76601671309191,
      71.0955710955711,
      85.99785407725322,
      81.81148748159058,
      73.25905292479109,
      85.47149122807018,
      91.08635097493037,
      91.64345403899722,
      92.75766016713092,
      90.80779944289694,
      95.26462395543176
    ],
    [
      71.66030534351145,
      74.38825448613376,
      75.85644371941272,
      74.23780487804879,
      77.05977382875606,
      76.3458401305057,
      71.415770609319,
      78.14029363784665,
      80.75040783034257,
      70.75664621676891,
      73.4094616639478,
      78.95595432300163,
      77.97716150081567,
      88.03160585637927,
      76.42007926023778,
      82.74478330658106,
      76.42007926023778,
      83.05586385497595,
      78.62969004893964,
      74.38825448613376,
      83.03425774877651,
      82.05546492659053,
      80.0978792822186,
      74.87179487179488,
      74.74825716498839,
      84.82871125611744,
      74.71451876019576,
      79.9347471451876,
      85.48123980424144,
      85.64437194127244,
      85.64437194127244,
      74.81259370314842,
      74.71428571428571,
      76.36122177954847,
      84.33931484502448,
      75.20391517128874,
      78.79282218597064,
      74.06199021207178,
      77.65089722675367,
      74.06199021207178,
      83.00878197785414,
      71.413390010627,
      27.672209026128264,
      83.68678629690048,
      72.75693311582381,
      78.95595432300163,
      89.56590999601752,
      81.07667210440457,
      73.08319738988581,
      83.86876281613124,
      75.05126452494873,
      78.32131822863028,
      72.92006525285481,
      75.69331158238172,
      71.413390010627,
      69.88636363636364,
      76.18270799347472,
      81.72920065252855,
      73.7357259380098,
      71.94127243066885,
      80.4241435562806,
      78.30342577487765,
      67.86296900489397,
      78.91630901287554,
      74.52135493372607,
      76.3458401305057,
      76.69956140350878,
      94.29037520391516,
      94.61663947797716,
      95.10603588907016,
      93.6378466557912,
      96.73735725938009
    ],
    [
      89.59923664122137,
      82.44575936883629,
      84.66898954703834,
      91.92073170731707,
      85.78352180936996,
      80.86734693877551,
      90.68100358422939,
      70.18348623853211,
      77.57575757575758,
      89.16155419222905,
      76.04166666666666,
      73.20574162679426,
      74.11167512690355,
      97.25772716709272,
      92.7344782034346,
      95.26484751203851,
      92.7344782034346,
      95.81945985941547,
      76.65198237885463,
      82.6923076923077,
      79.72027972027972,
      74.40476190476191,
      75.11961722488039,
      88.07692307692308,
      91.9442292796282,
      77.41935483870968,
      85.29411764705883,
      73.84615384615385,
      83.87096774193549,
      77.23577235772358,
      77.23577235772358,
      86.20689655172413,
      86.71428571428571,
      92.69588313413014,
      81.67938931297711,
      83.80414312617702,
      76.19047619047619,
      82.88288288288288,
      83.5978835978836,
      77.8393351800554,
      95.41809851088202,
      89.47927736450585,
      87.29216152019002,
      1.6260162601626018,
      78.11320754716982,
      75.25773195876289,
      97.65033851055357,
      79.08496732026144,
      84.07766990291262,
      96.20642515379357,
      92.75461380724539,
      94.129763130793,
      84.73413379073756,
      84.81012658227847,
      89.47927736450585,
      89.43181818181817,
      77.3076923076923,
      76.64670658682635,
      78.50467289719626,
      80.59701492537313,
      75.14450867052022,
      76.06837606837607,
      80.88578088578089,
      94.09871244635193,
      91.8262150220913,
      75.4325259515571,
      93.91447368421053,
      82.92682926829268,
      84.5528455284553,
      86.1788617886179,
      82.92682926829268,
      87.8048780487805
    ],
    [
      76.45502645502646,
      75.66137566137566,
      76.3668430335097,
      74.23780487804879,
      75.13227513227513,
      80.24691358024691,
      77.68959435626103,
      86.1552028218695,
      88.44797178130511,
      74.86772486772487,
      82.18694885361552,
      85.97883597883597,
      86.5079365079365,
      81.01324657215896,
      73.05151915455747,
      75.28089887640449,
      73.05151915455747,
      73.36293007769146,
      85.3615520282187,
      78.3068783068783,
      89.59435626102292,
      88.09523809523809,
      86.86067019400353,
      72.39858906525573,
      76.06506584043377,
      90.47619047619048,
      70.63492063492063,
      86.331569664903,
      91.005291005291,
      90.91710758377425,
      90.91710758377425,
      73.80952380952381,
      74.42680776014109,
      72.57636122177955,
      90.74074074074075,
      76.45502645502646,
      85.80246913580247,
      78.04232804232805,
      75.92592592592592,
      80.51146384479718,
      76.02138220694921,
      70.63492063492063,
      71.34038800705468,
      90.8289241622575,
      81.48148148148148,
      84.74426807760142,
      82.45718837116686,
      87.21340388007054,
      68.07760141093475,
      73.13738892686261,
      67.3274094326726,
      44.026776519052525,
      70.19400352733686,
      71.25220458553791,
      70.63492063492063,
      63.31569664902999,
      83.86243386243386,
      87.47795414462081,
      79.36507936507937,
      72.39858906525573,
      86.06701940035273,
      84.21516754850089,
      77.24867724867724,
      73.44420600858369,
      74.07952871870398,
      83.42151675485009,
      71.54605263157895,
      96.91358024691358,
      96.91358024691358,
      97.35449735449735,
      96.11992945326278,
      98.2363315696649
    ],
    [
      75.47709923664122,
      74.38894792773645,
      74.49521785334751,
      71.11280487804879,
      73.00743889479277,
      77.78958554729012,
      73.2078853046595,
      84.16578108395323,
      86.07863974495218,
      77.0961145194274,
      79.80871413390011,
      83.63443145589798,
      84.16578108395323,
      83.19776899837322,
      72.5231175693527,
      76.76565008025682,
      72.5231175693527,
      74.21383647798741,
      83.52816153028692,
      75.77045696068014,
      88.31030818278427,
      86.7162592986185,
      84.59086078639744,
      72.68862911795961,
      72.57939581719597,
      89.26673751328374,
      68.75664187035069,
      85.01594048884165,
      89.69181721572795,
      89.26673751328374,
      89.26673751328374,
      73.21997874601487,
      73.75132837407014,
      72.44355909694555,
      89.69181721572795,
      73.96386822529225,
      83.42189160467588,
      75.55791710945803,
      74.38894792773645,
      78.00212539851222,
      78.27415043909889,
      0.10626992561105207,
      73.75132837407014,
      89.47927736450585,
      76.62061636556854,
      82.35919234856536,
      83.8510553564317,
      85.33475026567481,
      64.29330499468651,
      75.11961722488039,
      66.71223513328776,
      68.64057672502575,
      65.46227417640807,
      67.48140276301807,
      0.10626992561105207,
      72.36981934112646,
      81.08395324123273,
      85.97236981934113,
      75.45164718384697,
      70.56323060573858,
      82.99681190223167,
      80.44633368756642,
      74.38894792773645,
      73.6587982832618,
      69.73490427098675,
      81.50903294367694,
      73.02631578947368,
      96.59936238044634,
      96.59936238044634,
      97.02444208289054,
      95.64293304994686,
      97.87460148777896
    ],
    [
      81.96564885496184,
      74.95069033530572,
      74.73867595818815,
      84.22256097560977,
      75.2827140549273,
      72.44897959183673,
      82.34767025089606,
      75.47169811320755,
      71.69811320754717,
      81.0838445807771,
      75.34722222222221,
      75.47169811320755,
      73.58490566037736,
      94.07390192888683,
      85.66710700132101,
      90.40930979133226,
      85.66710700132101,
      90.64002959674436,
      76.60377358490567,
      71.39423076923077,
      75.09433962264151,
      74.71698113207546,
      76.9811320754717,
      77.82051282051282,
      83.50116189000775,
      74.71698113207546,
      74.01960784313727,
      70.94339622641509,
      78.11320754716982,
      75.84905660377359,
      76.22641509433963,
      75.86206896551724,
      76.57142857142857,
      86.32138114209828,
      78.8679245283019,
      74.76459510357816,
      77.35849056603774,
      72.97297297297297,
      75.30864197530865,
      73.13019390581718,
      90.91256204658266,
      76.62061636556854,
      77.43467933491686,
      78.11320754716982,
      0.37735849056603776,
      74.71698113207546,
      94.84269215452011,
      73.9622641509434,
      67.57281553398057,
      91.45591250854409,
      84.27887901572112,
      87.74459320288362,
      68.26758147512865,
      71.60940325497288,
      76.62061636556854,
      76.93181818181817,
      77.73584905660378,
      75.09433962264151,
      74.76635514018692,
      66.91542288557214,
      70.56603773584905,
      72.45283018867924,
      72.26107226107226,
      88.19742489270385,
      83.94698085419735,
      77.50865051903114,
      87.5548245614035,
      91.32075471698113,
      92.0754716981132,
      92.45283018867924,
      89.43396226415095,
      93.9622641509434
    ],
    [
      83.62741075092327,
      90.78785391875257,
      90.02872384078785,
      80.26261797291752,
      89.37217890849405,
      92.90110791957325,
      82.62207632334838,
      95.87607714402955,
      96.75830939679935,
      84.30447271235126,
      94.50143619203939,
      95.99917931883463,
      96.18383258104227,
      73.12269183422241,
      78.88797702092737,
      72.79441936807551,
      78.88797702092737,
      68.77308165777596,
      95.54780467788264,
      92.1830118998769,
      97.23020106688551,
      96.67624128026262,
      96.040213377103,
      86.52031185884283,
      81.30898645876077,
      97.51743947476406,
      88.48994665572425,
      96.18383258104227,
      97.55847353303241,
      97.64054164956914,
      97.64054164956914,
      88.63356585966352,
      88.4283955683217,
      79.81124333196553,
      97.49692244562988,
      90.58268362741076,
      95.67090685268774,
      91.99835863766927,
      90.06975789905621,
      93.33196553139106,
      73.32786212556421,
      83.483791546984,
      85.94583504308576,
      97.51743947476406,
      94.64505539597866,
      96.040213377103,
      9.996017522899244,
      96.86089454247025,
      89.86458760771441,
      66.8444809191629,
      76.48748461222816,
      74.18957734919984,
      89.26959376282313,
      89.7620024620435,
      83.483791546984,
      84.20188756668034,
      94.85022568732047,
      96.61469019286008,
      93.7217890849405,
      91.97784160853509,
      96.51210504718917,
      95.363151415675,
      91.87525646286417,
      76.26179729175216,
      80.13951579811244,
      94.56298727944194,
      76.91834222404596,
      99.15880180549857,
      99.2203528929011,
      99.32293803857202,
      99.09725071809602,
      99.58965941731637
    ],
    [
      87.11832061068702,
      78.69822485207101,
      81.70731707317073,
      89.9390243902439,
      82.87560581583199,
      80.35714285714286,
      88.17204301075269,
      75.22935779816514,
      78.18181818181819,
      86.1963190184049,
      75.69444444444444,
      72.72727272727273,
      73.09644670050761,
      96.49082035788985,
      90.81902245706736,
      94.0609951845907,
      90.81902245706736,
      94.48760636330003,
      73.568281938326,
      77.64423076923077,
      80.3921568627451,
      79.16666666666666,
      74.64114832535886,
      85.64102564102564,
      89.85282726568552,
      69.28104575163398,
      78.26797385620915,
      78.46153846153847,
      79.08496732026144,
      72.54901960784314,
      71.89542483660131,
      82.75862068965517,
      83.71428571428572,
      90.83665338645417,
      75.81699346405229,
      80.7909604519774,
      74.89177489177489,
      79.05405405405406,
      82.01058201058201,
      78.67036011080333,
      94.4253531882398,
      85.22848034006375,
      84.91686460807601,
      79.08496732026144,
      73.9622641509434,
      50,
      96.95340501792114,
      0.6535947712418301,
      73.7864077669903,
      94.77101845522898,
      89.81544771018454,
      92.27600411946447,
      78.55917667238423,
      77.21518987341773,
      85.22848034006375,
      84.20454545454545,
      70.38461538461539,
      65.86826347305389,
      68.84735202492212,
      69.90049751243781,
      52.02312138728323,
      61.965811965811966,
      77.62237762237763,
      92.4892703862661,
      90.35346097201767,
      75.4325259515571,
      92.05043859649122,
      84.31372549019608,
      85.62091503267973,
      85.62091503267973,
      81.04575163398692,
      88.88888888888889
    ],
    [
      73.37786259541986,
      78.05825242718447,
      77.70034843205575,
      76.29573170731707,
      76.41357027463651,
      76.50485436893204,
      74.10394265232975,
      78.25242718446603,
      80.58252427184466,
      72.29038854805727,
      74.1747572815534,
      78.64077669902912,
      77.28155339805825,
      89.33302347199628,
      76.81638044914135,
      83.54735152487962,
      76.81638044914135,
      83.46281908990011,
      77.08737864077669,
      75.14563106796116,
      81.16504854368932,
      80.97087378640776,
      79.6116504854369,
      71.66666666666667,
      77.14949651432997,
      83.10679611650485,
      68.13725490196079,
      76.11650485436893,
      84.07766990291262,
      81.55339805825243,
      81.55339805825243,
      73.61319340329835,
      73.42857142857143,
      77.88844621513944,
      82.91262135922331,
      79.09604519774011,
      77.28155339805825,
      75.33980582524272,
      77.60141093474427,
      74.75728155339806,
      85.14700267277587,
      64.29330499468651,
      70.54631828978623,
      84.07766990291262,
      67.57281553398057,
      71.6504854368932,
      90.20310633213859,
      73.7864077669903,
      0.1941747572815534,
      84.44976076555024,
      72.45386192754614,
      77.8063851699279,
      64.32246998284734,
      72.15189873417721,
      64.29330499468651,
      59.77272727272728,
      74.75728155339806,
      76.69902912621359,
      70.29126213592232,
      58.44660194174758,
      73.20388349514563,
      74.9514563106796,
      74.36893203883496,
      80.95493562231759,
      76.4359351988218,
      76.69902912621359,
      80.42763157894737,
      93.98058252427185,
      94.1747572815534,
      95.14563106796116,
      92.23300970873787,
      96.50485436893203
    ],
    [
      77.81954887218046,
      86.19275461380724,
      85.61175666438824,
      73.75256322624743,
      84.72317156527683,
      89.50786056049213,
      76.24743677375257,
      93.54066985645933,
      94.8051948051948,
      78.67395762132604,
      91.42173615857826,
      93.60902255639097,
      93.91660970608339,
      69.78851963746223,
      73.34244702665755,
      73.4107997265892,
      73.34244702665755,
      64.73000683526999,
      93.02802460697197,
      88.17498291182501,
      95.59125085440876,
      94.77101845522898,
      93.81408065618592,
      80.65618591934381,
      75.32467532467533,
      96.00136705399864,
      82.60423786739575,
      93.98496240601504,
      96.1038961038961,
      96.17224880382776,
      96.20642515379357,
      83.21941216678059,
      83.11688311688312,
      73.75256322624743,
      96.06971975393029,
      86.26110731373889,
      93.23308270676691,
      87.96992481203007,
      85.61175666438824,
      89.78127136021872,
      74.81203007518798,
      75.11961722488039,
      80.00683526999316,
      96.13807245386192,
      91.45591250854409,
      93.67737525632262,
      66.94544006371963,
      94.83937115516062,
      84.44976076555024,
      0.2050580997949419,
      50.10252904989747,
      64.21736158578264,
      83.52699931647301,
      84.44976076555024,
      75.11961722488039,
      77.1701982228298,
      92.07108680792892,
      94.60013670539986,
      90.12303485987697,
      87.14969241285031,
      94.2241968557758,
      92.72043745727956,
      87.52563226247437,
      71.73615857826384,
      73.71838687628161,
      91.59261790840738,
      70.98427887901572,
      98.73547505126452,
      98.80382775119617,
      99.0088858509911,
      98.53041695146958,
      99.35064935064936
    ],
    [
      72.31715652768284,
      78.67395762132604,
      77.99043062200957,
      72.5222146274778,
      76.62337662337663,
      83.11688311688312,
      72.24880382775119,
      88.31168831168831,
      90.29391660970609,
      71.9753930280246,
      85.37252221462748,
      88.5850991114149,
      88.99521531100478,
      76.15617011387404,
      76.94848084544253,
      71.86998394863564,
      76.94848084544253,
      66.59267480577137,
      87.90157211209842,
      80.65618591934381,
      91.72932330827066,
      90.63568010936433,
      89.06356801093644,
      73.2740943267259,
      75.46138072453861,
      92.54955570745045,
      72.59056732740943,
      89.20027341079972,
      92.82296650717703,
      92.4812030075188,
      92.61790840738209,
      75.66643882433355,
      75.5980861244019,
      76.49402390438247,
      92.75461380724539,
      77.85372522214628,
      87.96992481203007,
      80.17771701982228,
      78.19548872180451,
      83.18523581681477,
      73.6540664375716,
      66.71223513328776,
      71.22351332877649,
      92.61790840738209,
      84.27887901572112,
      88.17498291182501,
      76.86180804460375,
      89.81544771018454,
      72.45386192754614,
      50.06835269993165,
      0.2050580997949419,
      65.80844490216272,
      72.72727272727273,
      74.7095010252905,
      66.71223513328776,
      65.00341763499658,
      85.78263841421736,
      89.95215311004785,
      83.18523581681477,
      76.69172932330827,
      89.06356801093644,
      86.32946001367054,
      79.42583732057416,
      72.63948497854076,
      74.16267942583733,
      85.91934381408065,
      72.09429824561403,
      97.5393028024607,
      97.676008202324,
      98.08612440191388,
      97.2658920027341,
      98.76965140123035
    ],
    [
      72.9602510460251,
      81.48535564853556,
      80.75313807531381,
      70.0836820083682,
      79.65481171548117,
      85.61715481171548,
      72.07112970711297,
      90.53347280334728,
      92.4163179916318,
      73.32635983263597,
      87.60460251046025,
      90.63807531380753,
      90.95188284518828,
      73.83221008598652,
      73.06485355648536,
      71.91011235955057,
      73.06485355648536,
      55.678875323714394,
      90.01046025104603,
      83.2112970711297,
      93.41004184100419,
      92.4163179916318,
      91.05648535564853,
      75.57531380753139,
      72.85564853556485,
      93.93305439330544,
      76.09832635983264,
      91.0041841004184,
      94.24686192468619,
      94.08995815899581,
      94.08995815899581,
      78.39958158995816,
      78.24267782426779,
      73.48326359832636,
      94.14225941422593,
      81.22384937238493,
      90.1673640167364,
      83.2112970711297,
      80.38702928870293,
      85.77405857740585,
      72.35586101565484,
      69.76987447698745,
      74.84309623430963,
      94.14225941422593,
      87.55230125523012,
      90.32426778242679,
      73.17801672640383,
      92.20711297071131,
      77.4581589958159,
      63.77306903622693,
      60.98326359832637,
      70.70030895983523,
      76.25523012552301,
      78.5041841004184,
      69.76987447698745,
      69.40376569037657,
      88.33682008368201,
      92.05020920502092,
      85.30334728033473,
      80.85774058577407,
      91.26569037656904,
      89.06903765690377,
      83.15899581589959,
      76.6213389121339,
      71.44351464435147,
      88.23221757322176,
      75.36610878661088,
      98.01255230125523,
      98.06485355648536,
      98.3786610878661,
      97.7510460251046,
      98.90167364016736
    ],
    [
      73.95038167938931,
      73.71428571428571,
      77.35191637630662,
      76.29573170731707,
      76.25201938610662,
      75.04761904761905,
      74.19354838709677,
      77.90476190476191,
      80.57142857142857,
      73.51738241308794,
      74.85714285714286,
      77.71428571428571,
      76.95238095238095,
      89.19358587032303,
      78.26948480845442,
      83.94863563402889,
      78.26948480845442,
      83.46281908990011,
      77.33333333333333,
      73.71428571428571,
      82.66666666666667,
      80.38095238095238,
      78.47619047619048,
      72.17948717948718,
      76.60728117738186,
      83.04761904761905,
      68.62745098039215,
      76.76190476190476,
      84.76190476190476,
      84,
      84,
      74.06296851574213,
      73.57142857142858,
      77.55644090305445,
      82.85714285714286,
      78.3427495291902,
      76.38095238095238,
      75.23809523809524,
      78.83597883597884,
      75.04761904761905,
      84.87972508591065,
      66.7375132837407,
      69.0023752969121,
      83.61904761904762,
      66.28571428571428,
      75.42857142857143,
      90.34249303066507,
      76.38095238095238,
      66.0952380952381,
      84.688995215311,
      73.95762132604237,
      78.52729145211123,
      10.291595197255575,
      73.59855334538878,
      66.7375132837407,
      67.1590909090909,
      74.47619047619047,
      79.04761904761905,
      68.76190476190476,
      65.9047619047619,
      75.80952380952381,
      73.90476190476191,
      72.76190476190476,
      81.06223175965665,
      75.40500736377025,
      74.09523809523809,
      79.22149122807018,
      94.0952380952381,
      94.28571428571428,
      94.85714285714286,
      92.95238095238095,
      96.57142857142857
    ],
    [
      73.47328244274809,
      76.31103074141049,
      78.9198606271777,
      76.29573170731707,
      76.41357027463651,
      73.77938517179024,
      73.9247311827957,
      77.9385171790235,
      80.47016274864376,
      72.9038854805726,
      74.14104882459313,
      78.11934900542495,
      77.75768535262206,
      89.07738786892865,
      78.26948480845442,
      83.70786516853933,
      78.26948480845442,
      83.05586385497595,
      78.30018083182641,
      73.05605786618446,
      83.00180831826401,
      81.73598553345388,
      78.11934900542495,
      72.43589743589743,
      76.45236250968242,
      83.7251356238698,
      70.26143790849673,
      79.38517179023508,
      85.53345388788426,
      83.7251356238698,
      83.90596745027125,
      74.2128935532234,
      74.14285714285714,
      77.62284196547145,
      84.08679927667269,
      80.28933092224231,
      77.39602169981917,
      76.31103074141049,
      79.71781305114638,
      75.22603978300181,
      84.3069873997709,
      67.48140276301807,
      71.85273159144893,
      84.81012658227847,
      71.60940325497288,
      75.04520795660035,
      90.10354440461967,
      77.21518987341773,
      72.15189873417721,
      84.44976076555024,
      74.7095010252905,
      78.57878475798145,
      76.32933104631218,
      0.18083182640144665,
      67.48140276301807,
      69.20454545454545,
      74.86437613019892,
      76.49186256781194,
      66.90777576853526,
      68.71609403254972,
      72.875226039783,
      70.70524412296564,
      72.51356238698011,
      80.31115879828327,
      76.21502209131074,
      75.5877034358047,
      79.87938596491229,
      94.03254972875226,
      94.2133815551537,
      94.75587703435805,
      92.58589511754069,
      96.56419529837251
    ],
    [
      75.47709923664122,
      74.38894792773645,
      74.49521785334751,
      71.11280487804879,
      73.00743889479277,
      77.78958554729012,
      73.2078853046595,
      84.16578108395323,
      86.07863974495218,
      77.0961145194274,
      79.80871413390011,
      83.63443145589798,
      84.16578108395323,
      83.19776899837322,
      72.5231175693527,
      76.76565008025682,
      72.5231175693527,
      74.21383647798741,
      83.52816153028692,
      75.77045696068014,
      88.31030818278427,
      86.7162592986185,
      84.59086078639744,
      72.68862911795961,
      72.57939581719597,
      89.26673751328374,
      68.75664187035069,
      85.01594048884165,
      89.69181721572795,
      89.26673751328374,
      89.26673751328374,
      73.21997874601487,
      73.75132837407014,
      72.44355909694555,
      89.69181721572795,
      73.96386822529225,
      83.42189160467588,
      75.55791710945803,
      74.38894792773645,
      78.00212539851222,
      78.27415043909889,
      0.10626992561105207,
      73.75132837407014,
      89.47927736450585,
      76.62061636556854,
      82.35919234856536,
      83.8510553564317,
      85.33475026567481,
      64.29330499468651,
      75.11961722488039,
      66.71223513328776,
      68.64057672502575,
      65.46227417640807,
      67.48140276301807,
      0.10626992561105207,
      72.36981934112646,
      81.08395324123273,
      85.97236981934113,
      75.45164718384697,
      70.56323060573858,
      82.99681190223167,
      80.44633368756642,
      74.38894792773645,
      73.6587982832618,
      69.73490427098675,
      81.50903294367694,
      73.02631578947368,
      96.59936238044634,
      96.59936238044634,
      97.02444208289054,
      95.64293304994686,
      97.87460148777896
    ],
    [
      74.42748091603053,
      74.88636363636364,
      73.97727272727272,
      71.72256097560977,
      73.52272727272727,
      77.72727272727272,
      73.11827956989248,
      82.72727272727273,
      85.9090909090909,
      75.56237218813907,
      79.6590909090909,
      83.63636363636363,
      83.52272727272727,
      83.68580060422961,
      71.53236459709379,
      77.6083467094703,
      71.53236459709379,
      76.39659637439881,
      82.5,
      75.11363636363636,
      87.72727272727273,
      85.68181818181819,
      84.54545454545455,
      74.0909090909091,
      71.8822618125484,
      88.52272727272728,
      68.97727272727273,
      83.97727272727272,
      88.86363636363637,
      88.4090909090909,
      88.4090909090909,
      71.47727272727272,
      72.72727272727273,
      73.04116865869854,
      89.0909090909091,
      73.75,
      83.18181818181817,
      75.11363636363636,
      74.0909090909091,
      78.52272727272728,
      78.84688812523865,
      72.36981934112646,
      75.22727272727273,
      89.43181818181817,
      76.93181818181817,
      81.5909090909091,
      84.52807646356032,
      84.20454545454545,
      59.65909090909091,
      77.1701982228298,
      65.00341763499658,
      71.00926879505664,
      66.47727272727273,
      69.20454545454545,
      72.36981934112646,
      0.11363636363636363,
      79.6590909090909,
      85.11363636363637,
      76.5909090909091,
      67.38636363636363,
      83.18181818181817,
      81.02272727272727,
      74.6590909090909,
      73.8197424892704,
      71.64948453608247,
      79.54545454545455,
      73.79385964912281,
      96.13636363636363,
      96.13636363636363,
      96.81818181818181,
      95.45454545454545,
      97.8409090909091
    ],
    [
      77.95801526717557,
      72.38658777120315,
      72.99651567944251,
      81.32621951219512,
      73.82875605815832,
      78.31632653061224,
      79.12186379928315,
      77.22222222222223,
      76.38888888888889,
      76.99386503067484,
      72.22222222222221,
      72.5,
      74.44444444444444,
      92.33093190797118,
      82.62879788639366,
      87.7207062600321,
      82.62879788639366,
      88.27229004809472,
      74.16666666666667,
      75.24038461538461,
      80.27777777777779,
      78.05555555555556,
      75.27777777777777,
      73.84615384615385,
      81.09992254066614,
      77.22222222222223,
      71.24183006535948,
      75.83333333333333,
      81.94444444444444,
      80,
      80,
      73.16341829085458,
      73.57142857142858,
      82.27091633466136,
      80.55555555555556,
      74.95291902071564,
      75.27777777777777,
      75.90090090090091,
      74.07407407407408,
      81.7174515235457,
      88.43069873997709,
      76.30180658873539,
      75.41567695961994,
      80.83333333333333,
      72.77777777777777,
      72.5,
      93.1899641577061,
      74.44444444444444,
      72.42718446601941,
      89.33697881066303,
      81.95488721804512,
      85.22142121524202,
      72.38421955403086,
      72.33273056057867,
      76.30180658873539,
      75.56818181818183,
      28.055555555555557,
      73.88888888888889,
      73.05555555555556,
      72.38805970149254,
      71.11111111111111,
      69.16666666666667,
      73.65967365967366,
      85.03218884120172,
      80.70692194403534,
      76.11111111111111,
      84.3201754385965,
      91.66666666666666,
      91.94444444444444,
      92.5,
      90.55555555555556,
      94.72222222222221
    ],
    [
      86.73664122137404,
      78.30374753451676,
      81.70731707317073,
      89.40548780487805,
      82.7140549273021,
      77.04081632653062,
      87.81362007168458,
      77.06422018348624,
      81.437125748503,
      85.88957055214725,
      75,
      77.03349282296651,
      76.14213197969542,
      96.21194515454334,
      90.68692206076618,
      93.82022471910112,
      90.68692206076618,
      94.11764705882352,
      76.65198237885463,
      76.20192307692307,
      73.65269461077844,
      83.33333333333334,
      79.42583732057416,
      84.48717948717949,
      89.85282726568552,
      70.05988023952095,
      80.71895424836602,
      76.41025641025641,
      77.84431137724552,
      69.46107784431138,
      69.46107784431138,
      82.90854572713643,
      83.71428571428572,
      90.43824701195219,
      79.04191616766467,
      81.73258003766477,
      75.75757575757575,
      80.18018018018019,
      81.83421516754849,
      76.73130193905817,
      94.11989308896526,
      85.97236981934113,
      84.91686460807601,
      76.64670658682635,
      75.09433962264151,
      61.855670103092784,
      96.73436877737953,
      65.86826347305389,
      76.69902912621359,
      94.60013670539986,
      89.95215311004785,
      91.96704428424304,
      80.96054888507719,
      76.49186256781194,
      85.97236981934113,
      85.11363636363637,
      68.46153846153847,
      0.5988023952095809,
      65.73208722741433,
      69.40298507462687,
      65.3179190751445,
      59.401709401709404,
      77.62237762237763,
      92.38197424892704,
      90.279823269514,
      73.35640138408304,
      91.8859649122807,
      85.02994011976048,
      85.62874251497006,
      86.22754491017965,
      81.437125748503,
      89.82035928143712
    ],
    [
      79.77099236641222,
      73.17554240631164,
      75.26132404181185,
      82.69817073170732,
      75.60581583198707,
      72.95918367346938,
      80.28673835125448,
      72.58566978193146,
      74.76635514018692,
      78.52760736196319,
      76.01246105919003,
      73.20872274143302,
      71.02803738317756,
      92.9584011155008,
      84.08190224570674,
      88.92455858747994,
      84.08190224570674,
      89.12319644839067,
      71.96261682242991,
      76.4423076923077,
      77.88161993769471,
      76.01246105919003,
      73.83177570093457,
      76.66666666666667,
      82.88148721920992,
      77.25856697819314,
      71.24183006535948,
      76.01246105919003,
      78.50467289719626,
      76.32398753894081,
      76.32398753894081,
      76.16191904047976,
      77,
      83.79814077025233,
      78.19314641744548,
      76.08286252354048,
      76.01246105919003,
      74.54954954954955,
      74.07407407407408,
      78.94736842105263,
      89.3470790378007,
      75.45164718384697,
      77.07838479809976,
      78.50467289719626,
      74.76635514018692,
      66.97819314641744,
      93.9068100358423,
      68.84735202492212,
      70.29126213592232,
      90.12303485987697,
      83.18523581681477,
      85.8908341915551,
      68.26758147512865,
      66.90777576853526,
      75.45164718384697,
      76.5909090909091,
      71.33956386292834,
      65.73208722741433,
      0.3115264797507788,
      67.16417910447761,
      61.6822429906542,
      67.28971962616822,
      74.12587412587412,
      86.37339055793991,
      82.9160530191458,
      76.94704049844236,
      85.96491228070175,
      90.65420560747664,
      91.27725856697819,
      91.90031152647975,
      88.1619937694704,
      94.70404984423676
    ],
    [
      76.24045801526718,
      72.18934911242604,
      73.86759581881533,
      78.96341463414635,
      73.50565428109854,
      79.8507462686567,
      77.32974910394266,
      76.11940298507463,
      77.61194029850746,
      75.05112474437627,
      71.8905472636816,
      74.6268656716418,
      73.88059701492537,
      91.12247269346967,
      81.24174372523117,
      86.35634028892456,
      81.24174372523117,
      86.20051794302627,
      75.3731343283582,
      75.24038461538461,
      79.1044776119403,
      79.1044776119403,
      77.61194029850746,
      73.46153846153847,
      79.70565453137102,
      79.60199004975125,
      68.13725490196079,
      74.87562189054727,
      82.08955223880598,
      79.60199004975125,
      80.09950248756219,
      73.31334332833583,
      74.28571428571429,
      80.81009296148738,
      81.34328358208955,
      75.51789077212806,
      74.37810945273633,
      78.15315315315316,
      74.25044091710758,
      76.86567164179104,
      87.24704085528828,
      70.56323060573858,
      73.15914489311164,
      80.59701492537313,
      66.91542288557214,
      67.41293532338308,
      92.23416965352449,
      69.90049751243781,
      58.44660194174758,
      87.14969241285031,
      76.69172932330827,
      82.6982492276004,
      67.23842195540308,
      68.71609403254972,
      70.56323060573858,
      67.38636363636363,
      68.90547263681593,
      69.40298507462687,
      67.16417910447761,
      0.24875621890547264,
      67.66169154228857,
      63.18407960199005,
      76.68997668997669,
      83.58369098712446,
      80.04418262150222,
      74.12935323383084,
      82.78508771929825,
      92.28855721393035,
      92.53731343283582,
      93.28358208955224,
      91.04477611940298,
      95.27363184079603
    ],
    [
      86.25954198473282,
      78.30374753451676,
      81.18466898954703,
      88.94817073170732,
      82.39095315024232,
      77.04081632653062,
      87.00716845878136,
      76.60550458715596,
      80.34682080924856,
      85.2760736196319,
      74.30555555555556,
      73.20574162679426,
      77.15736040609137,
      96.09574715314896,
      89.82826948480846,
      93.37881219903691,
      89.82826948480846,
      93.74768775434703,
      77.09251101321586,
      76.4423076923077,
      78.03468208092485,
      83.8150289017341,
      73.68421052631578,
      83.07692307692308,
      88.76839659178931,
      73.98843930635837,
      77.61437908496733,
      77.94871794871796,
      79.1907514450867,
      69.36416184971098,
      69.94219653179191,
      81.25937031484258,
      82.28571428571428,
      89.57503320053121,
      75.72254335260115,
      80.0376647834275,
      75.75757575757575,
      78.6036036036036,
      80.59964726631394,
      75.34626038781164,
      93.73806796487209,
      82.99681190223167,
      84.32304038004752,
      75.14450867052022,
      70.56603773584905,
      54.63917525773196,
      96.61489446435682,
      52.601156069364166,
      73.20388349514563,
      94.2241968557758,
      89.06356801093644,
      91.45211122554068,
      77.53001715265866,
      72.875226039783,
      82.99681190223167,
      83.18181818181817,
      66.92307692307692,
      65.3179190751445,
      61.6822429906542,
      67.66169154228857,
      0.5780346820809248,
      44.871794871794876,
      76.68997668997669,
      91.63090128755364,
      89.46980854197349,
      74.74048442906575,
      91.39254385964912,
      86.70520231213872,
      87.28323699421965,
      87.28323699421965,
      81.5028901734104,
      89.59537572254335
    ],
    [
      83.68320610687023,
      75.93688362919131,
      77.52613240418118,
      86.4329268292683,
      79.32148626817448,
      74.48979591836735,
      84.49820788530465,
      82.47863247863248,
      74.35897435897436,
      82.719836400818,
      77.08333333333334,
      79.05982905982906,
      77.35042735042735,
      94.81756913781084,
      87.51651254953765,
      91.6131621187801,
      87.51651254953765,
      92.04587495375509,
      79.05982905982906,
      73.3173076923077,
      76.92307692307693,
      77.35042735042735,
      80.76923076923077,
      80.8974358974359,
      86.36715724244772,
      71.36752136752136,
      74.67320261437908,
      77.77777777777779,
      77.35042735042735,
      72.64957264957265,
      73.07692307692307,
      79.01049475262369,
      79.85714285714286,
      87.58300132802125,
      76.92307692307693,
      77.5894538606403,
      83.33333333333334,
      77.02702702702703,
      78.3068783068783,
      75.62326869806094,
      91.9434898816342,
      80.44633368756642,
      81.59144893111639,
      76.06837606837607,
      72.45283018867924,
      70.51282051282051,
      95.49980087614496,
      61.965811965811966,
      74.9514563106796,
      92.72043745727956,
      86.32946001367054,
      89.34088568486096,
      74.95711835334477,
      70.70524412296564,
      80.44633368756642,
      81.02272727272727,
      69.61538461538461,
      59.401709401709404,
      67.28971962616822,
      63.18407960199005,
      44.871794871794876,
      0.4273504273504274,
      74.12587412587412,
      89.91416309012875,
      87.18703976435935,
      76.12456747404845,
      89.14473684210526,
      88.46153846153845,
      89.31623931623932,
      89.74358974358975,
      85.04273504273505,
      92.3076923076923
    ],
    [
      76.14503816793892,
      74.95069033530572,
      73.17073170731707,
      79.1920731707317,
      71.89014539579968,
      76.68997668997669,
      76.7921146953405,
      75.75757575757575,
      77.3892773892774,
      75.05112474437627,
      70.62937062937063,
      73.89277389277389,
      74.35897435897436,
      91.05275389263305,
      80.64729194187582,
      85.95505617977528,
      80.64729194187582,
      86.75545689974102,
      75.05827505827506,
      77.85547785547784,
      80.65268065268066,
      77.3892773892774,
      77.15617715617715,
      73.33333333333333,
      78.85360185902401,
      79.95337995337995,
      72.54901960784314,
      77.15617715617715,
      82.05128205128204,
      80.65268065268066,
      80.88578088578089,
      71.51424287856072,
      72,
      80.81009296148738,
      81.58508158508158,
      73.06967984934086,
      75.75757575757575,
      76.8018018018018,
      74.07407407407408,
      71.32867132867133,
      86.63612065673921,
      74.38894792773645,
      71.85273159144893,
      80.88578088578089,
      72.26107226107226,
      75.05827505827506,
      92.05495818399044,
      77.3892773892774,
      74.36893203883496,
      87.49145591250854,
      79.35748462064251,
      83.3676622039135,
      72.04116638078902,
      72.51356238698011,
      74.38894792773645,
      74.6590909090909,
      72.26107226107226,
      77.3892773892774,
      74.12587412587412,
      76.68997668997669,
      76.45687645687646,
      74.12587412587412,
      0.4662004662004662,
      83.36909871244636,
      78.35051546391753,
      71.7948717948718,
      82.34649122807018,
      93.00699300699301,
      93.24009324009323,
      93.93939393939394,
      91.84149184149184,
      95.8041958041958
    ],
    [
      73.33690987124464,
      81.38412017167383,
      80.95493562231759,
      71.72746781115879,
      79.93562231759657,
      85.51502145922747,
      73.17596566523605,
      90.50429184549357,
      92.43562231759657,
      74.67811158798283,
      87.71459227467811,
      90.55793991416309,
      90.87982832618026,
      75.69137810829653,
      73.49785407725322,
      72.59229534510433,
      73.49785407725322,
      71.40214576396596,
      89.75321888412017,
      84.33476394849787,
      93.61587982832617,
      92.22103004291846,
      91.25536480686695,
      75.9656652360515,
      72.15665236051501,
      93.8304721030043,
      79.07725321888412,
      91.25536480686695,
      93.99141630901288,
      94.09871244635193,
      94.09871244635193,
      77.8969957081545,
      77.41416309012875,
      74.94635193133047,
      94.04506437768241,
      81.22317596566523,
      89.8068669527897,
      83.20815450643777,
      80.79399141630901,
      85.94420600858369,
      73.34860633829706,
      73.6587982832618,
      75.42918454935622,
      94.20600858369099,
      88.19742489270385,
      90.98712446351931,
      76.48347272003187,
      92.4892703862661,
      80.90128755364807,
      71.73615857826384,
      72.63948497854076,
      76.98249227600412,
      79.82832618025752,
      80.31115879828327,
      73.6587982832618,
      73.76609442060087,
      88.41201716738198,
      92.38197424892704,
      86.37339055793991,
      83.58369098712446,
      91.63090128755364,
      89.91416309012875,
      83.36909871244636,
      0.1072961373390558,
      71.78111587982833,
      88.2510729613734,
      78.48712446351931,
      97.96137339055794,
      98.12231759656652,
      98.28326180257511,
      97.85407725321889,
      98.87339055793991
    ],
    [
      72.4188790560472,
      77.5811209439528,
      76.91740412979351,
      76.32743362831859,
      75.73746312684366,
      81.78466076696165,
      73.30383480825958,
      87.75811209439527,
      89.97050147492625,
      72.86135693215338,
      84.43952802359883,
      87.53687315634218,
      87.61061946902655,
      78.80548454566582,
      74.43857331571995,
      72.6725521669342,
      74.43857331571995,
      72.95597484276729,
      87.02064896755162,
      79.9410029498525,
      91.1504424778761,
      89.52802359882006,
      88.42182890855457,
      70.50147492625368,
      75.88495575221239,
      91.8141592920354,
      74.77876106194691,
      88.64306784660766,
      91.88790560471976,
      91.88790560471976,
      91.96165191740413,
      73.74631268436578,
      73.67256637168141,
      76.56042496679947,
      92.2566371681416,
      76.03244837758112,
      87.31563421828909,
      78.98230088495575,
      75.4424778761062,
      81.71091445427729,
      73.84497899961818,
      69.69026548672566,
      72.34513274336283,
      91.88790560471976,
      83.92330383480827,
      88.42182890855457,
      80.46594982078852,
      90.33923303834808,
      76.40117994100295,
      73.71838687628161,
      74.16267942583733,
      72.65705458290422,
      74.04129793510324,
      76.17994100294986,
      69.69026548672566,
      71.60766961651917,
      85.10324483775811,
      90.2654867256637,
      82.89085545722715,
      80.01474926253688,
      89.45427728613569,
      87.16814159292035,
      78.31858407079646,
      71.78111587982833,
      0.5154639175257731,
      84.58702064896755,
      72.47807017543859,
      97.27138643067846,
      97.49262536873157,
      97.71386430678466,
      97.12389380530973,
      98.59882005899705
    ],
    [
      81.96564885496184,
      73.96449704142012,
      72.47386759581882,
      84.52743902439023,
      73.50565428109854,
      74.23469387755102,
      82.79569892473118,
      71.280276816609,
      72.66435986159169,
      80.98159509202453,
      79.93079584775087,
      72.31833910034602,
      70.24221453287197,
      93.7253079247037,
      85.53500660501982,
      90.24879614767255,
      85.53500660501982,
      91.04698483166852,
      75.4325259515571,
      75.72115384615384,
      77.16262975778547,
      77.16262975778547,
      77.16262975778547,
      78.97435897435898,
      84.27575522850503,
      76.8166089965398,
      77.12418300653596,
      74.0484429065744,
      80.27681660899654,
      77.50865051903114,
      77.85467128027682,
      75.86206896551724,
      77,
      86.45418326693228,
      77.85467128027682,
      74.57627118644068,
      75.08650519031141,
      75.90090090090091,
      74.95590828924162,
      72.85318559556787,
      90.64528445971744,
      81.50903294367694,
      79.45368171021377,
      76.12456747404845,
      77.50865051903114,
      76.12456747404845,
      94.70330545599363,
      75.4325259515571,
      76.69902912621359,
      91.66097060833903,
      85.9876965140123,
      88.51699279093718,
      74.95711835334477,
      75.5877034358047,
      81.50903294367694,
      79.54545454545455,
      78.54671280276817,
      73.35640138408304,
      76.94704049844236,
      74.12935323383084,
      74.74048442906575,
      76.12456747404845,
      71.7948717948718,
      88.2510729613734,
      84.5360824742268,
      0.6920415224913495,
      87.39035087719299,
      90.65743944636678,
      91.00346020761245,
      92.04152249134948,
      89.27335640138409,
      93.77162629757785
    ],
    [
      81.87022900763358,
      73.37278106508876,
      77.00348432055749,
      85.0609756097561,
      78.02907915993538,
      77.29591836734694,
      82.79569892473118,
      73.14487632508833,
      76.67844522968198,
      80.67484662576688,
      80.20833333333334,
      73.85159010600707,
      74.20494699646643,
      94.12038112944458,
      86.32760898282696,
      90.6099518459069,
      86.32760898282696,
      91.23196448390676,
      75.97173144876325,
      73.07692307692307,
      76.67844522968198,
      76.67844522968198,
      75.61837455830388,
      80.12820512820514,
      85.05034856700232,
      76.67844522968198,
      75.49019607843137,
      74.91166077738515,
      80.21201413427562,
      75.26501766784452,
      75.26501766784452,
      77.96101949025487,
      78.71428571428571,
      86.38778220451528,
      78.4452296819788,
      76.64783427495291,
      77.03180212014135,
      75.45045045045045,
      77.60141093474427,
      77.00831024930747,
      91.02710958381061,
      81.08395324123273,
      78.97862232779097,
      78.09187279151944,
      77.03180212014135,
      78.09187279151944,
      94.92234169653524,
      72.08480565371025,
      75.53398058252428,
      91.83185235816815,
      85.30416951469583,
      88.56848609680742,
      73.07032590051458,
      76.31103074141049,
      81.08395324123273,
      81.02272727272727,
      79.85865724381625,
      76.67844522968198,
      78.81619937694704,
      74.6268656716418,
      71.73144876325088,
      72.08480565371025,
      72.49417249417249,
      88.73390557939913,
      85.49337260677467,
      81.31487889273356,
      84.59429824561403,
      90.10600706713781,
      90.81272084805654,
      91.51943462897526,
      87.98586572438163,
      93.63957597173145
    ]
  ],
  "workstreams": [
    {
      "id": 1086,
      "index": 0,
      "projectId": 42,
      "parentId": null,
      "createdAt": null,
      "updatedAt": null,
      "properties": {},
      "depth": 0
    },
    {
      "id": 1087,
      "index": 0,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "0"
      },
      "depth": 1
    },
    {
      "id": 1088,
      "index": 1,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "2.11"
      },
      "depth": 1
    },
    {
      "id": 1089,
      "index": 2,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "3.14"
      },
      "depth": 1
    },
    {
      "id": 1090,
      "index": 3,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "6.1"
      },
      "depth": 1
    },
    {
      "id": 1091,
      "index": 4,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "7.9"
      },
      "depth": 1
    },
    {
      "id": 1092,
      "index": 5,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "8.1"
      },
      "depth": 1
    },
    {
      "id": 1093,
      "index": 6,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "9.8"
      },
      "depth": 1
    },
    {
      "id": 1094,
      "index": 7,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "12.1"
      },
      "depth": 1
    },
    {
      "id": 1095,
      "index": 8,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "12.2"
      },
      "depth": 1
    },
    {
      "id": 1096,
      "index": 9,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "12.3"
      },
      "depth": 1
    },
    {
      "id": 1097,
      "index": 10,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "12.3(i)"
      },
      "depth": 1
    },
    {
      "id": 1098,
      "index": 11,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "13.2(i)"
      },
      "depth": 1
    },
    {
      "id": 1099,
      "index": 12,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "13.2"
      },
      "depth": 1
    },
    {
      "id": 1100,
      "index": 13,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "13.2(ii)"
      },
      "depth": 1
    },
    {
      "id": 1101,
      "index": 14,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "14"
      },
      "depth": 1
    },
    {
      "id": 1102,
      "index": 15,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(a)(ix)"
      },
      "depth": 1
    },
    {
      "id": 1103,
      "index": 16,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(a)(x)"
      },
      "depth": 1
    },
    {
      "id": 1104,
      "index": 17,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xi)"
      },
      "depth": 1
    },
    {
      "id": 1105,
      "index": 18,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xiii)"
      },
      "depth": 1
    },
    {
      "id": 1106,
      "index": 19,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(a)(xiv)"
      },
      "depth": 1
    },
    {
      "id": 1107,
      "index": 20,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(b)"
      },
      "depth": 1
    },
    {
      "id": 1108,
      "index": 21,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(d)"
      },
      "depth": 1
    },
    {
      "id": 1109,
      "index": 22,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(f)"
      },
      "depth": 1
    },
    {
      "id": 1110,
      "index": 23,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(i)"
      },
      "depth": 1
    },
    {
      "id": 1111,
      "index": 24,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(j)"
      },
      "depth": 1
    },
    {
      "id": 1112,
      "index": 25,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.3(k)"
      },
      "depth": 1
    },
    {
      "id": 1113,
      "index": 26,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.4(b)"
      },
      "depth": 1
    },
    {
      "id": 1114,
      "index": 27,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.8(e)"
      },
      "depth": 1
    },
    {
      "id": 1115,
      "index": 28,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.12"
      },
      "depth": 1
    },
    {
      "id": 1116,
      "index": 29,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "15.13"
      },
      "depth": 1
    },
    {
      "id": 1117,
      "index": 30,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.1"
      },
      "depth": 1
    },
    {
      "id": 1118,
      "index": 31,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.2"
      },
      "depth": 1
    },
    {
      "id": 1119,
      "index": 32,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.3"
      },
      "depth": 1
    },
    {
      "id": 1120,
      "index": 33,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.4"
      },
      "depth": 1
    },
    {
      "id": 1121,
      "index": 34,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.7"
      },
      "depth": 1
    },
    {
      "id": 1122,
      "index": 35,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "17.8"
      },
      "depth": 1
    },
    {
      "id": 1123,
      "index": 36,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "18.1"
      },
      "depth": 1
    },
    {
      "id": 1124,
      "index": 37,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "21.1"
      },
      "depth": 1
    },
    {
      "id": 1125,
      "index": 38,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "21.4"
      },
      "depth": 1
    },
    {
      "id": 1126,
      "index": 39,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "21.8(a)"
      },
      "depth": 1
    },
    {
      "id": 1127,
      "index": 40,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "21.12"
      },
      "depth": 1
    },
    {
      "id": 1128,
      "index": 41,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.2(l)"
      },
      "depth": 1
    },
    {
      "id": 1129,
      "index": 42,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.3(b)"
      },
      "depth": 1
    },
    {
      "id": 1130,
      "index": 43,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.3(c)"
      },
      "depth": 1
    },
    {
      "id": 1131,
      "index": 44,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.5(b)"
      },
      "depth": 1
    },
    {
      "id": 1132,
      "index": 45,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.6(a)"
      },
      "depth": 1
    },
    {
      "id": 1133,
      "index": 46,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.6(b)"
      },
      "depth": 1
    },
    {
      "id": 1134,
      "index": 47,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.9(a)"
      },
      "depth": 1
    },
    {
      "id": 1135,
      "index": 48,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.9"
      },
      "depth": 1
    },
    {
      "id": 1136,
      "index": 49,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.10"
      },
      "depth": 1
    },
    {
      "id": 1137,
      "index": 50,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.12"
      },
      "depth": 1
    },
    {
      "id": 1138,
      "index": 51,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.13"
      },
      "depth": 1
    },
    {
      "id": 1139,
      "index": 52,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.14(e)"
      },
      "depth": 1
    },
    {
      "id": 1140,
      "index": 53,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.15(c)"
      },
      "depth": 1
    },
    {
      "id": 1141,
      "index": 54,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.15(d)"
      },
      "depth": 1
    },
    {
      "id": 1142,
      "index": 55,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.15(e)"
      },
      "depth": 1
    },
    {
      "id": 1143,
      "index": 56,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.15(g)"
      },
      "depth": 1
    },
    {
      "id": 1144,
      "index": 57,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "22.15(h)"
      },
      "depth": 1
    },
    {
      "id": 1145,
      "index": 58,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "23.7"
      },
      "depth": 1
    },
    {
      "id": 1146,
      "index": 59,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "23.11(e)"
      },
      "depth": 1
    },
    {
      "id": 1147,
      "index": 60,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "25.9"
      },
      "depth": 1
    },
    {
      "id": 1148,
      "index": 61,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "25.10"
      },
      "depth": 1
    },
    {
      "id": 1149,
      "index": 62,
      "projectId": 42,
      "parentId": 1086,
      "createdAt": null,
      "updatedAt": null,
      "properties": {
        "prefix": "Anlage",
        "reference": "25.12"
      },
      "depth": 1
    }
  ]
}
