import { Component, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faDownload, faPrint, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { File } from 'annex-tracker-backend';
import { NgxExtendedPdfViewerService, NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { from, map, Observable, shareReplay, Subscription, switchMap, tap } from 'rxjs';
import { FilesService } from 'src/app/services/files.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { environment } from 'src/environments/environment';
import { FileWorkstreamBadgeComponent } from '../../common/file-workstream-badge/file-workstream-badge.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { FileIconComponent } from '../../common/file-icon/file-icon.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AutoFocusDirective } from '../../common/auto-focus.directive';

@Component({
    selector: 'app-pdf-quick-view',
    templateUrl: './pdf-quick-view.component.html',
    styleUrls: ['./pdf-quick-view.component.scss'],
    standalone: true,
    imports: [AutoFocusDirective, FaIconComponent, FileIconComponent, NgIf, FileWorkstreamBadgeComponent, NgxExtendedPdfViewerModule, AsyncPipe]
})
export class PdfQuickViewComponent {

  file$: Observable<File | undefined>
  pdfUrl$: Observable<string | null>

  file?: File

  iconClose = faXmark
  iconDownload = faDownload
  iconPrint = faPrint

  @HostListener('keydown.escape', ['$event'])
  async close(event?: KeyboardEvent) {
    await this.router.navigate([{ outlets: { subviewmodal: null } }], { relativeTo: this.route.parent })
    event?.stopPropagation()
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private workstreamService: WorkstreamsService,
    filesService: FilesService,
    private pdfViewerService: NgxExtendedPdfViewerService
  ) {

    this.file$ = this.route.paramMap.pipe(
      tap(params => console.log("params", params)),
      map(params => (Number(params.get("fileId")) || null)),
      switchMap(fileId => {
        console.log("fileId", fileId)
        return this.workstreamService.files.pipe(
          tap(files => console.log("files", files)),
          map(files => files.find(file => file.id == fileId))
        )
      }),
      shareReplay(1)
    )

    this.file$.subscribe(file => this.file = file)

    this.pdfUrl$ = this.file$.pipe(switchMap(file => {
      console.log("file", file)
      return from(filesService.fileToPdfUrl(file))
    })
    )
  }

  download() {
    if (this.file != null) {
      this.workstreamService.download(this.file)
    }
  }

  print() {
    this.pdfViewerService.print()
  }

}
