import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faFileWord, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { ModalService } from 'src/app/modal/modal.service';
import { BackendService } from 'src/app/services/backend.service';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { UploadService } from 'src/app/services/upload.service';
import { CoverPages } from 'annex-tracker-backend'
import { ButtonPrimaryComponent } from '../../../common/tailwind/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from '../../../common/tailwind/button-secondary/button-secondary.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';
import { AutoFocusDirective } from '../../../common/auto-focus.directive';
import { FormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from 'src/app/common/language-selector/language-selector.component';
import { CoverPageSelectorComponent } from 'src/app/common/cover-page-selector/cover-page-selector.component';
import { CoverPageService } from 'src/app/services/cover-page.service';

@Component({
    selector: 'app-add-list-modal',
    templateUrl: './add-list-modal.component.html',
    styleUrls: ['./add-list-modal.component.scss'],
    standalone: true,
    imports: [FormsModule, AutoFocusDirective, NgIf, NgFor, FaIconComponent, ButtonSecondaryComponent, ButtonPrimaryComponent, LanguageSelectorComponent, CoverPageSelectorComponent]
})
export class AddListModalComponent {

  projectName?: string = "Annex List 1"

  currentCoverPage?: CoverPages | null

  files?: File[]
  iconFile = faFileWord
  iconUpload = faUpload
  submitted = false
  language: 'de' | 'en' = 'en'

  uploadProgress?: number;

  constructor(
    private uploadService: UploadService,
    private backend: BackendService,
    private router: Router,
    private httpClient: HttpClient,
    private docxAnalysisService: DocxAnalysisService,
    private coverPageService: CoverPageService,
    private modalService: ModalService
  ) { }

  onFileChanged(event: Event) {
    const files = (event.target as HTMLInputElement).files

    if (this.files == null) {
      this.files = [];
    }

    if (files?.length) {
      const thisFile = files?.item(0)
      if (thisFile) {
        this.files.push(thisFile)
      }
    }
  }

  removeFile(file: File) {

    const idx = this.files?.findIndex(f => f == file);
    if (idx != -1 && idx != null && this.files != null) {
      this.files?.splice(idx, 1);

      if (this.files.length == 0) {
        this.files = undefined
      }

    }


  }

  async submit() {

    if (this.files == null) return;

    const sourceFile = this.files[0]

    this.submitted = true;

    // wait for the project to be created. This will also change the location 
    // to the new project
    const project = await this.createProject()

    
    const file = await this.uploadService.simpleUpload(sourceFile)
    
    const analysis = await this.docxAnalysisService.getOrCreateAnalysis(file.id)
    
    
    //await this.docxAnalysisService.addMain(file, 0, language)
    
    this.coverPageService.changeCoverPageOfCurrentProject(this.currentCoverPage)

    await this.router.navigate([{ outlets: { modal: ['fullscreen-panel', 'add-main', file.id] } }])

    // this.close()

  }

  async createProject() {

    const newList = await this.backend.projects.create({
      name: this.projectName!,
      language: this.language,
    })
    await this.router.navigate(["lists", newList.id, "doclist"])

    return newList

  }

  async useTestFile() {

    this.httpClient.get('assets/mockups/transactions/GESSI-Exit-Muster-SPA.docx', { responseType: 'arraybuffer' }).subscribe(data => {
      const file = new File([data], 'GESSI-Exit-Muster-SPA.docx', { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
      this.files = [file]
    })

  }

  async close() {
    // here we should close the modal using the router
    this.router.navigate([{ outlets: { modal: null } }])
  }

}
