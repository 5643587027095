<div class="flex flex-col h-full divide-y">

  <div class="px-4 py-5 sm:p-0 overflow-auto flex-1 my-scrollbar">
    <dl class="">

      <div class="py-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-5 px-4" *ngIf="(files | async)?.length == 0">
        <dt class="text-sm font-medium text-gray-500">Versions of this workstream will show up here</dt>
      </div>
      <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6" *ngIf="(files | async)?.length != 0">
        <dt class="text-sm font-medium text-gray-500">Versions</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">

            <li *ngFor="let file of files | async" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
              [class.bg-red-100]="(workstreamsService.currentWorkstream | async)?.properties?.['basedOnAnalysis'] == file.id">
              <div class="flex w-0 flex-1 items-center">
                <app-file-icon [file]="file"></app-file-icon>
                <span class="ml-2 w-0 flex-1 truncate flex flex-col">
                  <a [routerLink]="['..', {outlets: { subviewmodal: ['view', file.id]}}]" class="truncate"
                    [attr.title]="file.filename">{{file.filename}}</a>
                  <time class="text-xs text-gray-400">{{file.createdAt | date:'short':undefined:'de-DE'}}</time>
                </span>
              </div>
              <div class="ml-4 flex-shrink-0 flex flex-col items-end">
                <a (click)="downloadFile(file)"
                  class="font-medium text-indigo-600 cursor-pointer hover:text-indigo-400">Download</a>
                <a *ngIf="(workstreamsService.currentWorkstream | async)?.properties?.['basedOnAnalysis'] < file.id && file.filename.endsWith('.docx')"
                  (click)="analyzeFile(file)"
                  class="font-medium text-indigo-600 cursor-pointer hover:text-indigo-400">Analysis</a>
              </div>
            </li>
          </ul>
        </dd>
      </div>

    </dl>
  </div>
</div>