import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faFileZip } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-zip-export',
    templateUrl: './zip-export.component.html',
    styleUrls: ['./zip-export.component.scss'],
    standalone: true
})
export class ZipExportComponent {

  zipIcon = faFileZip

  constructor(
    private router: Router
  ) { }

  async openZipExportModal() {
    this.router.navigate([{ outlets: { modal: ['center-panel', 'zip-export'] } }])
  }

}
