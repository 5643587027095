import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { ButtonPrimaryComponent } from '../button-primary/button-primary.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-button-secondary',
    templateUrl: './button-secondary.component.html',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        FaIconComponent,
    ],
})
export class ButtonSecondaryComponent extends ButtonPrimaryComponent {

}
