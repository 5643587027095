import { Component } from '@angular/core';
import { ProjectsService } from '../services/projects.service';
import { RouterOutlet } from '@angular/router';
import { NavigationLeftComponent } from './navigation-left/navigation-left.component';

@Component({
    selector: 'app-multi-column',
    templateUrl: './multi-column.component.html',
    styleUrls: ['./multi-column.component.scss'],
    standalone: true,
    imports: [NavigationLeftComponent, RouterOutlet]
})
export class MultiColumnComponent {

  constructor(public projects: ProjectsService) { }

}
