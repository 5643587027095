import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    standalone: true
})
export class TestComponent implements OnInit, OnDestroy {

  html = 'Hello world!';


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


}
