import { Pipe, PipeTransform } from '@angular/core';
import { getLocalizedString, i18nLanguage } from 'annex-tracker-backend/lib/helpers/i18n-messages/messages';

@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {

  transform(messageType: string, language?: i18nLanguage, values?: {[key: string]: string}): string {

    if (!language) {
      return '';
    }

    return getLocalizedString(language, messageType, values);
        
  }

}
