import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DropdownOutletService } from '../dropdown-outlet.service';
import { NgFor } from '@angular/common';
import { BadgeComponent } from '../badge/badge.component';

const closer = new Subject<void>();

export interface BadgeDescription {
  label: string
  value: any
  color: string
}

@Component({
    selector: 'app-badge-dropdown',
    templateUrl: './badge-dropdown.component.html',
    styleUrls: ['./badge-dropdown.component.scss'],
    standalone: true,
    imports: [BadgeComponent, NgFor]
})
export class BadgeDropdownComponent implements OnDestroy, OnChanges {

  @Input()
  badges?: BadgeDescription[] 

  @Input()
  value?: string

  @Output()
  valueChanged = new EventEmitter<any>()

  open = false

  currentDescription?: BadgeDescription

  subscription?: Subscription

  @HostListener("document:keydown.escape")
  @HostListener("click")
  onClick() {
    closer.next()
  }

  constructor(private changeDetector: ChangeDetectorRef, public dropdownService: DropdownOutletService) {
    this.subscription = closer.subscribe(() => {
      this.open = false;
      this.changeDetector.markForCheck()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentDescription = this.badges?.find(badge => badge.value == this.value)

    if (this.currentDescription == null) {
      this.currentDescription = {
        color: 'gray',
        label: '-',
        value: null
      }
    }

    this.changeDetector.markForCheck()
  }

  toggle(event: Event) {

    if (this.open) {
      this.open = false
      this.changeDetector.markForCheck()

      return;
    }

    closer.next();
    setTimeout(() => {

      this.open = true
      this.changeDetector.markForCheck()
    },1)

   
  }

  setBadge(badge: BadgeDescription | null) {

    if (badge == null) {
      this.valueChanged.emit(null)
      return
    }

    this.value = badge.value
    this.currentDescription = this.badges?.find(badge => badge.value == this.value)
    this.valueChanged.emit(badge.value)
    this.changeDetector.markForCheck()

  }

  ngOnDestroy(): void {
      this.subscription?.unsubscribe()
  }

}
