import { Component } from '@angular/core';
import { DropdownOutletService } from '../dropdown-outlet.service';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-dropdown-outlet',
    templateUrl: './dropdown-outlet.component.html',
    styleUrls: ['./dropdown-outlet.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class DropdownOutletComponent {

  constructor(public dropdownService: DropdownOutletService) {}

}
