import { DatePipe, NgFor, NgSwitch, NgSwitchDefault, NgSwitchCase, AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TrackByFunction, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subscription, switchMap, take, timeout } from 'rxjs';
import { ProjectsService } from 'src/app/services/projects.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { WorkstreamsTableRowComponent } from './workstreams-table-row/workstreams-table-row.component';
import { Columns, Workstream } from 'annex-tracker-backend';
import { ColumnsService } from 'src/app/services/columns.service';
import { Router } from '@angular/router';
import { HorizontalBarChartComponent } from '../../../../common/horizontal-bar-chart/horizontal-bar-chart.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { GuessTitleAiComponent } from './guess-title-ai/guess-title-ai.component';

export type ColumnLabels = 'title' | 'reference' | 'notification' | 'comment'

@Component({
    selector: 'app-ws-table',
    templateUrl: './ws-table.component.html',
    styleUrls: ['./ws-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [DatePipe],
    standalone: true,
    imports: [NgFor, NgSwitch, NgSwitchDefault, NgSwitchCase, HorizontalBarChartComponent, WorkstreamsTableRowComponent, AsyncPipe, TranslatePipe, FaIconComponent, GuessTitleAiComponent]
})
export class WsTableComponent implements OnDestroy, AfterViewInit, OnChanges {

  iconDots = faEllipsisVertical

  TableRowComponent = WorkstreamsTableRowComponent

  trackByFn: TrackByFunction<Workstream> = (_, workstream) => workstream.id

  @Input()
  showDeleted = false

  @Input()
  editable = false

  @Input()
  startIndex = 1

  @Input()
  workstream!: Workstream

  @Input()
  autoselect = false

  @Output()
  workstreamSelected = new EventEmitter<Workstream>()

  @Output()
  columnSelected = new EventEmitter<string>()

  @ViewChild("table", {static: true})
  table!: ElementRef<HTMLTableElement>

  selectedWorkstream?: Workstream | null
  currentlyFocusedColumn?: string

  workstreams: Observable<Workstream[]>
  showDeleted$ = new BehaviorSubject<boolean>(this.showDeleted);

  subscription?: Subscription

  @ViewChildren(WorkstreamsTableRowComponent)
  tableRows?: QueryList<WorkstreamsTableRowComponent>;

  @HostListener("document:keydown.arrowUp", ['$event'])
  onUpPressed(event: KeyboardEvent) {
    console.log("up")
    
    event.stopPropagation()
    this.selectAdjacentEntry(-1)
  }

  @HostListener("document:keydown.escape", ['$event'])
  onEscapePressed() {
    this.selectedWorkstream = undefined
    this.workstreamSelected.emit(this.selectedWorkstream)
  }

  @HostListener("document:keydown.enter", ['$event'])
  onEnterPressed(event: KeyboardEvent) {
    console.log("enter")

    event.preventDefault()
    this.selectAdjacentEntry(1)

  }

  @HostListener("document:keydown.arrowDown", ['$event'])
  onDownPressed(event: KeyboardEvent) {
    console.log("down")
    
    event.stopPropagation()
    this.selectAdjacentEntry(1)
  }

  constructor(
    private router: Router,
    public workstreamsService: WorkstreamsService,
    private datePipe: DatePipe,
    public projectService: ProjectsService,
    public columnsService: ColumnsService,
  ) { 

    this.subscription = this.workstreamsService.currentWorkstream.subscribe(ws => {
      this.selectedWorkstream = ws
    })

    this.workstreams = this.showDeleted$.pipe(switchMap(showDeleted => {
      return this.workstreamsService.workstreams$.pipe(map(workstreams => {
        return workstreams.filter(ws => showDeleted || (ws.properties?.['deleted'] != 'true'))
      }))
    }))
  }

  ngAfterViewInit(): void {
    if (!this.autoselect) {
      return
    }

    this.tableRows?.changes.pipe(
      filter(rowQueryList => rowQueryList.length > 0),
      take(1),
      timeout(5000)
    ).subscribe((rowQueryList: QueryList<WorkstreamsTableRowComponent>) => {
      console.log("table rows changed", rowQueryList.get(0))
      setTimeout(() => {
        rowQueryList.get(0)?.focus('title', 'left')
      }, 50)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["showDeleted"]) {
      this.showDeleted$.next(this.showDeleted)
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  focusin(column: string, workstream: Workstream) {

    this.workstreamSelected.emit(workstream)
    this.selectedWorkstream = workstream;
    this.currentlyFocusedColumn = column;

  }

  async selectAdjacentEntry(direction: number) {

    if (this.tableRows == null) return
    if (this.selectedWorkstream == null) return

    const tableRowArray = this.tableRows.toArray().sort((a, b) => a.index - b.index) 
    const currentIndex = tableRowArray.findIndex(tableRow => tableRow.workstream.id == this.selectedWorkstream!.id)
    const nextTableRow = tableRowArray[currentIndex + direction]

    if (nextTableRow == null) {
      return 
    };

    setTimeout(() => {
      nextTableRow.focus(this.currentlyFocusedColumn!, 'left')
    }, 10)

  }

  async openColumnEditor(column: Columns) {

    // navigate to outlet with column editor
    await this.router.navigate([{ outlets: { modal: ['side-panel', 'edit-column', column.id] } }])
  } 

  async openColumnSettings() {
    await this.router.navigate([{ outlets: { modal: ['center-panel', 'column-settings'] } }])
  }

}
