import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from 'src/app/services/upload.service';

@Component({
    selector: 'app-add-main-button',
    templateUrl: './add-main-button.component.html',
    styleUrls: ['./add-main-button.component.scss'],
    standalone: true
})
export class AddMainButtonComponent {

  @Output()
  fileSelected = new EventEmitter<File>()

  constructor(
    private uploadService: UploadService,
    private router: Router
  ) { }

  async changed(event: Event) {

    // get file from event
    const target = event.target as HTMLInputElement
    const file = target.files?.item(0)

    if (file == null) {
      return
    }

    this.fileSelected.emit(file)    

    // Reset input file
    target.value = ''
  }
}
