<div class="relative pb-8">
    <span *ngIf="!isLast" class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
    <div class="relative flex items-start space-x-3">
        <div class="relative">
            <app-email-contact-circle [contact]="mail.from"></app-email-contact-circle>
        </div>
        <div class="min-w-0 flex-1">
            <div>
                <div class="text-md flex justify-between">
                    <a class="font-medium text-gray-900">{{mail.subject}}</a>
                </div>
                <p class="mb-0.5 mt-2 text-sm text-gray-500">
                    
                    <a class="font-medium text-gray-900">{{mail.from?.name || mail.from?.email}}</a><span class="px-1">•</span>{{mail.date | date:'short':undefined:'de-DE'}}, {{mail.date | timeAgo | async}}</p>
                <div class="grid grid-cols-[auto_1fr] gap-x-2 gap-y-1">
                    <ng-container *ngFor="let file of mail.files"
                        [class.text-gray-500]="file.workstreamId != currentWorkstreamId" 
                        class="mt-0.5 text-sm ">
                        
                        <span class="cursor-pointer truncate text-sm group" [class.text-gray-400]="file.workstreamId != currentWorkstreamId" (click)="fileClicked.emit(file)">
                            <app-file-icon class="mr-1" [file]="file" size="lg"></app-file-icon> <span class="group-hover:underline" title="{{file.filename}}">{{file.filename}}</span>
                        </span>
                        <app-file-workstream-badge class="text-sm" [class.text-gray-400]="file.workstreamId != currentWorkstreamId"  [file]="file"></app-file-workstream-badge>
                    </ng-container>
                </div>
            </div>
            <div (click)="clampLines = !clampLines" [class.line-clamp-5]="clampLines" class="mt-2 text-sm text-gray-700 max-w-xl whitespace-pre-wrap prose prose-sm">
                <p>{{mail.text}}</p>
            </div>
        </div>
    </div>
</div>