import formsPlugin from '@tailwindcss/forms'
import typographyPlugin from '@tailwindcss/typography'
import scrollbarPlugin from 'tailwind-scrollbar'
import containerQueries from '@tailwindcss/container-queries'
/** @type {import('tailwindcss').Config} */

const safeColors = [
  'gray',
  'red',
  'yellow',
  'green',
  'blue',
  'indigo',
  'purple',
  'pink',
  'orange'
]

module.exports = {
  content: [
    "./src/**/*.{html,ts}",
  ],
  safelist: [
    ...safeColors.map(color => `bg-${color}-100`),
    ...safeColors.map(color => `text-${color}-800`)
  ],
  theme: {
    extend: {
      gridTemplateColumns: {
        "one-two": '1fr 2fr'
      }
    },
  },
  plugins: [
    formsPlugin,
    typographyPlugin,
    containerQueries,
    scrollbarPlugin({ nocompatible: true })
  ],
}
