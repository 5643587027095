import { Component, Input, OnInit } from '@angular/core';
import { PrintData, Summary } from '../print.component';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';
import { getWorkstreamChildrenRecursive } from 'annex-tracker-backend/lib/helpers/getWorkstreamChildrenRecursive';
import { BarData, BarsComponent } from './bars/bars.component';
import { NgIf } from '@angular/common';
import { BadgeDropdownComponent } from '../../../common/badge-dropdown/badge-dropdown.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Columns } from 'annex-tracker-backend';
import { AmountChildrenRecursePipe } from 'src/app/pipes/amount-children-recurse.pipe';

@Component({
    selector: 'app-executive-summary',
    templateUrl: './executive-summary.component.html',
    styleUrl: './executive-summary.component.scss',
    standalone: true,
    imports: [BadgeDropdownComponent, NgIf, BarsComponent, TranslatePipe, AmountChildrenRecursePipe]
})
export class ExecutiveSummaryComponent implements OnInit {

  @Input() data!: PrintData

  @Input() fontFamily?: string

  @Input() summary?: Summary[]

  barDataPerMain?: BarData[][]
  aggregateBarData?: BarData[]

  myColumns = [
    'owner', 'status'
  ]

  interestingColumns = [] as Columns[]

  parentWorkstreams: HWorkstream[] = []

  constructor() {}

  ngOnInit(): void {
    console.log(this.data)

    this.interestingColumns = this.myColumns.map(column => this.data.columns.find(c => c.name === column)!)

    this.parentWorkstreams = this.data.workstreams
      .filter(workstream => workstream.parentId == null)

    this.barDataPerMain = this.interestingColumns.map(column => {
      return this.parentWorkstreams.map(workstream => {
        return this.getStatisticalSummary(workstream, column.name)
      })  
    })

    this.aggregateBarData = this.interestingColumns.map(column => {

      return this.getStatisticalSummary(null, column.name)

    })

  }

  getStatisticalSummary(parent: HWorkstream | null, column: string) {
    
    // It's either all workstreams to analyze or just the children of the given parent
    let workstreamsToAnalyze = this.data.workstreams

    if (parent != null) {
      workstreamsToAnalyze = getWorkstreamChildrenRecursive(parent)
  
      // the statistics include the parent workstream
      workstreamsToAnalyze.push(parent)
    }

    // the the properties of the given column of the workstreams 
    const allValues = workstreamsToAnalyze.map(workstream => workstream.properties[column])

    // find the column by column name
    const columnDefinition = this.data.columns.find(columnDefinition => columnDefinition.name === column)

    const colors = columnDefinition?.configuration.map((c: any) => c.color)

    // reduce the values by counting the number of occurrences of each configuration value
    const values = columnDefinition?.configuration.map((c: any) => allValues.filter(v => v === c.value).length)
    console.log(colors, values)
    return {
      colors,
      values
    }
  }

}
