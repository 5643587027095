import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Config } from 'annex-tracker-backend';
import { AuthenticationService } from './authentication.service';
import { Observable, firstValueFrom, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: Observable<Config>

  constructor(backend: BackendService, auth: AuthenticationService) {
    
    this.config = auth.user$.pipe(switchMap(_ => backend.config.get()))
    
  }

  async awaitableConfig() {
    return await firstValueFrom(this.config)
  }

}
