import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, map, debounceTime } from 'rxjs';
import { zxcvbn } from 'zxcvbn3'
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.scss'],
    standalone: true,
    imports: [NgClass, AsyncPipe]
})
export class PasswordStrengthComponent implements OnInit, OnChanges {

  password$ = new BehaviorSubject<string | undefined>(undefined)
  assessment$ = new BehaviorSubject<{label: string, class: string} | undefined>(undefined)

  prompts = [{
    label: 'Super Weak!',
    class: 'text-red-800'
  },{
    label: 'Weak',
    class: 'text-red-800'
  },{
    label: 'Getting better',
    class: 'text-orange-500'
  },{
    label: 'Almost there',
    class: 'text-blue-800'
  },{
    label: 'Great!',
    class: 'text-green-500'
  }]

  @Input()
  password?: string

  constructor() { }

  ngOnInit(): void {
    this.password$.pipe(map(password => {
      if (password != null && password.length > 0) {
        return this.prompts[zxcvbn(password).score]
      } else {
        return undefined
      }
    })).subscribe(this.assessment$)
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.password$.next(this.password)
  }
}
