import { Component, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { firstValueFrom } from 'rxjs';
import { ShowIfAiEnabledComponent } from 'src/app/common/show-if-ai-enabled/show-if-ai-enabled.component';
import { ModalService } from 'src/app/modal/modal.service';
import { ConfirmAiTitlesComponent } from 'src/app/modals/confirm-ai-titles/confirm-ai-titles.component';
import { BackendService } from 'src/app/services/backend.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';

@Component({
  selector: 'app-guess-title-ai',
  standalone: true,
  imports: [FaIconComponent, ShowIfAiEnabledComponent],
  templateUrl: './guess-title-ai.component.html',
  styleUrl: './guess-title-ai.component.scss',
})
export class GuessTitleAiComponent {
  modalService = inject(ModalService);
  projects = inject(ProjectsService);
  workstreamsService = inject(WorkstreamsService);
  backend = inject(BackendService);

  iconAi = faSparkles;

  async showModal() {
    try {
      await this.modalService.openModal(ConfirmAiTitlesComponent);
      this.guessTitles();
    } catch (e) {
      // expected when cancelled
    }
  }

  async guessTitles() {
    console.log('guess titles');

    const workstreams = await firstValueFrom(
      this.workstreamsService.workstreams$
    );

    // get root workstreams, so parentId is null
    const rootWorkstreams = workstreams.filter((w) => !w.parentId);

    const projectId = await this.projects.getCurrentProjectId();
    if (!projectId || !rootWorkstreams) {
      return;
    }

    rootWorkstreams.forEach((ws) => {
      console.log("call guess titles with", ws)
      this.backend.aiGuessTitles.create({
        projectId,
        workstreamId: ws.id,
      });
    });
  }
}
