<ng-container *ngIf="show">
    <button type="button" (click)="showModal()" *ngIf="(projects.interestingProjects | async)?.length == 0"
        class="relative block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <fa-icon class="mx-auto h-12 w-12 text-gray-400" [icon]="iconList" size="2xl"></fa-icon>

        <span class="mt-2 block text-sm font-medium text-gray-900">Create your first list</span>
    </button>

    <div *ngIf="(projects.interestingProjects | async)?.length != 0"
        class="relative block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <fa-icon class="mx-auto h-12 w-12 text-gray-400" [icon]="iconArrow" size="2xl"></fa-icon>

        <span class="mt-2 block text-sm font-medium text-gray-900">Select list on the left</span>
    </div>
</ng-container>