<div class="divide-y">
    <h1 class="p-5 font-medium text-lg">Generate titles?</h1>
    <!-- <p class="p-5 text-sm">
        Please confirm to generate missing titles. This will 
    </p> -->
    <div class="p-5 flex justify-end gap-2">
        <app-button-secondary (click)="activeModal.dismiss()">Cancel</app-button-secondary>
        <app-button-primary (click)="activeModal.close()">Confirm</app-button-primary>
    </div>
</div>
