"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // Notifications
    'draft_provided': 'Draft provided by ${from} to ${to} via email dated ${date} at ${time}.',
    'workstream_created': 'Workstream created on ${date}.',
    'workstream_renamed': 'Formerly known as ${prefix} ${reference}, renamed on ${date}.',
    'workstream_deleted': 'Workstream deleted on ${date}.',
    // Column labels
    'owner_column_label': 'Owner',
    'status_column_label': 'Status',
    'comments_column_label': 'Comments',
    'notifications_column_label': 'Notifications',
    'print_executive_summary_header': 'Executive Summary',
    'reference_column_label': 'Reference',
    'title_column_label': 'Title',
    // Print page
    'overall_label': 'Overall',
    'tracker_compare_label': 'Tracker Compare',
    'added_label': 'Added',
    'removed_label': 'Removed',
    'renamed_label': 'Renamed',
    'sum_of_workstreams_explanation': 'total number of workstreams including main',
    // Project Setup columns
    'column_owner_tbd': 'tbd',
    'column_owner_seller': 'Seller',
    'column_owner_buyer': 'Buyer',
    'column_owner_notary': 'Notary',
    'column_owner_client': 'Client',
    'column_status_open': 'Open',
    'column_status_draft': 'Draft',
    'column_status_agreed': 'Agreed',
    'column_status_signed': 'Signed',
    'column_status_notarized': 'Notarized',
};
