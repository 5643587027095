import { Component } from '@angular/core';
import { faAdd, faEllipsisVertical, faUser } from '@fortawesome/pro-regular-svg-icons';
import { ModalService } from 'src/app/modal/modal.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { AddListModalComponent } from '../modals/add-list-modal/add-list-modal.component';
import { UserProfileModalComponent } from '../modals/user-profile-modal/user-profile-modal.component';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor, AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-navigation-left',
  templateUrl: './navigation-left.component.html',
  styleUrls: ['./navigation-left.component.scss'],
  standalone: true,
  imports: [FaIconComponent, NgFor, RouterLink, RouterLinkActive, AsyncPipe]
})
export class NavigationLeftComponent {

  iconAdd = faAdd
  iconUser = faUser
  iconDots = faEllipsisVertical

  constructor(
    public projects: ProjectsService,
    private router: Router,
    private modalService: ModalService
  ) { }

  async showModal() {
    this.router.navigate([{ outlets: { modal: ['center-panel', 'add-list'] } }])
  }

  async openUserProfile() {
    this.router.navigate([{ outlets: { modal: ['side-panel', 'user-profile'] } }])
  }

  async showListSettings() {
    this.router.navigate([{ outlets: { modal: ['side-panel', 'list-settings'] } }])
  }
}
