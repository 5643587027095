import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-check-insert-position',
  standalone: true,
  imports: [NgIf, NgFor, FaIconComponent],
  templateUrl: './check-insert-position.component.html',
  styleUrl: './check-insert-position.component.scss'
})
export class CheckInsertPositionComponent {

  iconUp = faArrowUp
  iconDown = faArrowDown

  @Input()
  insertItems?: {
    label: string
    index: number | null
    id: number | null
    hasControls: boolean
  }[]


  @Output()
  insertItemsChange = new EventEmitter<{
    label: string
    index: number | null
    id: number | null
    hasControls: boolean
  }[]>()

  move(index: number, direction: number) {
    // take element at index of insertItems and move it up or down
    const item = this.insertItems![index]
    this.insertItems!.splice(index, 1)
    this.insertItems!.splice(index + direction, 0, item)

    this.insertItemsChange.emit(this.insertItems!)

  }


}
