import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImanageService } from 'src/app/services/imanage.service';

@Component({
  selector: 'app-callback',
  standalone: true,
  imports: [],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})
export class CallbackComponent implements OnInit {

  route = inject(ActivatedRoute)
  iManageService = inject(ImanageService)

  code?: string

  async ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get('code') || undefined

    if (this.code) {
      console.log('Code:', this.code) 
    }

    if (this.code == null) {
      console.error('No code found in query params')
      return
    }

    await this.iManageService.sendOauthCode(this.code)
    
  }

}
