import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BadgeComponent } from './badge/badge.component';
import { BadgeDropdownComponent } from './badge-dropdown/badge-dropdown.component';
import { PieChartDirective } from './pie-chart.directive';
import { AddWorkstreamLabelComponent } from './add-workstream-label/add-workstream-label.component';
import { EmailContactCircleComponent } from './email-contact-circle/email-contact-circle.component';
import { HorizontalBarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StackedLineChartDirective } from './stacked-line-chart.directive';
import { FileWorkstreamBadgeComponent } from './file-workstream-badge/file-workstream-badge.component';
import { PrettyPrintWorkstreamComponent } from './pretty-print-workstream/pretty-print-workstream.component';
import { MyDatePipe } from '../pipes/my-date.pipe';
import { DiffPipe } from '../pipes/diff.pipe';
import { StackedBarChartDirective } from './stacked-bar-chart.directive';
import { SelectMenuComponent } from './select-menu/select-menu.component';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { PalettePipe } from '../pipes/palette.pipe';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        BadgeComponent,
        BadgeDropdownComponent,
        PieChartDirective,
        AddWorkstreamLabelComponent,
        EmailContactCircleComponent,
        HorizontalBarChartComponent,
        FileIconComponent,
        StackedLineChartDirective,
        FileWorkstreamBadgeComponent,
        PrettyPrintWorkstreamComponent,
        MyDatePipe,
        DiffPipe,
        StackedBarChartDirective,
        SelectMenuComponent,
        TimeAgoPipe,
        PalettePipe
    ],
    exports: [
        BadgeComponent,
        BadgeDropdownComponent,
        PieChartDirective,
        AddWorkstreamLabelComponent,
        EmailContactCircleComponent,
        HorizontalBarChartComponent,
        FileIconComponent,
        StackedLineChartDirective,
        FileWorkstreamBadgeComponent,
        PrettyPrintWorkstreamComponent,
        MyDatePipe,
        DiffPipe,
        StackedBarChartDirective,
        SelectMenuComponent,
        TimeAgoPipe,
        PalettePipe
    ],
    providers: [
        MyDatePipe,
        DatePipe
    ]
})
export class CommonLibraryModule { }
