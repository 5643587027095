<pdf-shy-button
  [cssClass]="show | responsiveCSSClass : 'hiddenLargeView'"
  title="First page"
  primaryToolbarId="primaryFirstPage"
  l10nId="pdfjs-first-page-button"
  (click)="firstPage()"
  [disabled]="disableFirstPage"
  l10nLabel="pdfjs-first-page-button-label"
  [order]="500"
  eventBusName="firstpage"
  [closeOnClick]="false"
  image="<svg style='width: 24px; height: 24px' viewBox='0 0 24 24'><path fill='currentColor' d='M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z' /></svg>"
>
</pdf-shy-button>
