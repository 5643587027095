import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Exports } from 'annex-tracker-backend';
import { TimeAgoPipe } from '../../pipes/time-ago.pipe';
import { MyDatePipe } from '../../pipes/my-date.pipe';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-select-menu',
    templateUrl: './select-menu.component.html',
    styleUrls: ['./select-menu.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe, MyDatePipe, TimeAgoPipe]
})
export class SelectMenuComponent {

  isOpen = false

  @Input()
  disabled = false

  @Input()
  exports?: Exports[] | null

  @Input()
  selectedExport?: Exports | null

  @Output()
  selectedExportChange = new EventEmitter<Exports>()

  @HostListener("focusin", ["$event"])
  onFocus(event: FocusEvent) {
    this.isOpen = true
  }

  @HostListener("focusout", ["$event"])
  onFocusout(event: FocusEvent) {
    this.isOpen = false
  }

  blur() {

    // blur currently selected element
    const activeElement = document.activeElement
    if (activeElement) {
      (activeElement as any).blur()
    }
    
    
  }

  clicked(event: Event) {
    event.stopPropagation()
    if (this.isOpen) {
      this.isOpen = false
    }
  }

}
