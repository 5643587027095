import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheck, faCircleArrowRight, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { File } from 'annex-tracker-backend';
import { ownerOptions } from 'src/app/multi-column/list-detail/doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { FileIconComponent } from '../../../common/file-icon/file-icon.component';
import { NgFor, NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BadgeComponent } from '../../../common/badge/badge.component';

@Component({
    selector: '[app-mail]',
    templateUrl: './mail.component.html',
    styleUrls: ['./mail.component.scss'],
    standalone: true,
    imports: [BadgeComponent, FaIconComponent, NgFor, FileIconComponent, NgIf]
})
export class MailComponent implements OnInit {

  iconTo = faCircleArrowRight
  iconMail = faEnvelope
  iconCheck = faCheck

  fromColor: any
  toColor: any

  @Input()
  mail: any

  @Output()
  sendMail = new EventEmitter<void>()

  attachments?: File[]

  constructor() { }

  ngOnInit(): void {
    this.fromColor = ownerOptions.find(o => o.value == this.mail.actor)?.color
    this.toColor = ownerOptions.find(o => o.value == this.mail.to)?.color
    console.log(this.mail)
    this.attachments = this.mail.attachment
      ?.split(",")
      .map((a: string) => a.trim())
      .filter((a: string) => a.length > 0)
      .map((a: string) => ({
        filename: a
      } as File))
  }

}
