import { Component, Input, OnChanges } from '@angular/core';
import { PrintData } from '../print.component';
import { BadgeDropdownComponent } from '../../../common/badge-dropdown/badge-dropdown.component';
import { HorizontalBarChartComponent } from '../../../common/horizontal-bar-chart/horizontal-bar-chart.component';
import { NgIf, NgFor, NgSwitch, NgSwitchDefault, NgSwitchCase } from '@angular/common';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { HWorkstream, sortWorkstreamHierarchical } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';
import { FilterHiddenPipe } from './filter-hidden.pipe';

@Component({
    selector: 'app-workstream-table',
    templateUrl: './workstream-table.component.html',
    styleUrl: './workstream-table.component.scss',
    standalone: true,
    imports: [NgIf, NgFor, NgSwitch, NgSwitchDefault, NgSwitchCase, HorizontalBarChartComponent, BadgeDropdownComponent, TranslatePipe, FilterHiddenPipe]
})
export class WorkstreamTableComponent implements OnChanges {

  @Input() data!: PrintData
  @Input() tableHeaderTextColor?: string
  @Input() tableHeaderBackgroundColor?: string

  hierarchicalWorkstreams?: HWorkstream[]

  ngOnChanges() {

    if (this.data.workstreams != null) {
      this.hierarchicalWorkstreams = sortWorkstreamHierarchical(this.data.workstreams)
    }

  }

}
