<pdf-shy-button
  title="Toggle Sidebar"
  primaryToolbarId="primarySidebarToggle"
  [cssClass]="show | responsiveCSSClass : 'always-visible'"
  [toggled]="sidebarVisible === true"
  l10nId="pdfjs-toggle-sidebar-button"
  l10nLabel="pdfjs-toggle-sidebar-button-label"
  [order]="4500"
  [closeOnClick]="true"
  [action]="onClick"
  image="<svg style='width: 24px; height: 24px' viewBox='0 0 24 24'> <path fill='currentColor' d='M3,9H17V7H3V9M3,13H17V11H3V13M3,17H17V15H3V17M19,17H21V15H19V17M19,7V9H21V7H19M19,13H21V11H19V13Z' /> </svg>"
>
</pdf-shy-button>
