<div class="container mx-auto grid grid-cols-2">
    <section class="bg-white m-2 p-2 rounded flex-1" *ngFor="let category of categories">
        <div class="">{{category.label}}</div>
        <div *ngFor="let contact of category.contacts$ | async">
            <p class="text-sm bg-slate-50 rounded p-2 flex justify-between">
                <span>{{contact.name}} {{contact.email}}</span>
                <app-badge-dropdown [badges]="ownerOptions" [value]="contact.domain"
                    (valueChanged)="changeDomain(contact, $event)"></app-badge-dropdown>
            </p>
        </div>
    </section>
</div>