<ng-container *ngFor="let thisModal of modalService.modalStack">
    <div class="relative z-10 ease-out duration-300 transition-opacity" [ngClass]="thisModal.active ? 'opacity-100' : 'opacity-0'" aria-labelledby="modal-title" role="dialog" aria-modal="true">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <!--
          Modal panel, show/hide based on modal state.
  
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
                <div [ngClass]="thisModal.active ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:w-full md:max-w-xl sm:max-w-sm">
                    <div>
                        <ng-template *ngComponentOutlet="thisModal.component; injector: thisModal.injector">

                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!-- <div class="modal is-active" [class.fade-in]="thisModal.active">
        <div class="modal-background" (click)="maybeClose(thisModal)"></div>
        <div [class.modal-content]="thisModal.modalOptions.addModalContentClass">
            <ng-template *ngComponentOutlet="thisModal.component; injector: thisModal.injector">

            </ng-template>
        </div>
    </div> -->