import { Component } from '@angular/core';
import { PdfViewerComponent } from '../../multi-column/list-detail/doc-list/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'app-pdf-iframe-view',
    templateUrl: './pdf-iframe-view.component.html',
    styleUrls: ['./pdf-iframe-view.component.scss'],
    standalone: true,
    imports: [PdfViewerComponent]
})
export class PdfIframeViewComponent {

  searchTerm: string = ""
  pdfUrl: string | null = null

  constructor() {

    if (window.frameElement == null) {
      throw new Error("PdfIframeViewComponent must be used in an iframe")
    }

    this.pdfUrl = window.frameElement!.getAttribute('data-pdf-url')!
    this.searchTerm = window.frameElement!.getAttribute('data-search-term')!

    new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName == 'data-search-term') {
          this.searchTerm = window.frameElement!.getAttribute('data-search-term')!
        }
        if (mutation.attributeName == 'data-pdf-url') {
          this.pdfUrl = window.frameElement!.getAttribute('data-pdf-url')!
        }
      })
    }).observe(window.frameElement!, {
      attributes: true
    })

  }

}
