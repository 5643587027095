import { Component, Input } from '@angular/core';
import { faCheck, faChevronDown, faChevronUp, faX } from '@fortawesome/pro-regular-svg-icons';
import { Columns } from 'annex-tracker-backend';
import { ModalService } from 'src/app/modal/modal.service';
import { ColumnsService } from 'src/app/services/columns.service';
import { WorkstreamPropertyService } from 'src/app/services/workstream-property.service';
import { DeleteLabelModalComponent } from './delete-label-modal/delete-label-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AutoFocusDirective } from '../../../../common/auto-focus.directive';
import { ColorSelectorComponent } from '../color-selector/color-selector.component';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-edit-enum-radio',
    templateUrl: './edit-enum-radio.component.html',
    styleUrls: ['./edit-enum-radio.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FaIconComponent, FormsModule, ColorSelectorComponent, AutoFocusDirective]
})
export class EditEnumRadioComponent {

  iconUp = faChevronUp
  iconDown = faChevronDown
  iconDelete = faX
  iconAdd = faCheck

  addElementMode = false

  newElementName = ''
  newElementColor = 'red'

  trackBy: any = (index: number, item: any) => index;

  @Input()
  column!: Columns

  constructor(
    private columnService: ColumnsService,
    private workstreamProperties: WorkstreamPropertyService,
    private modalService: ModalService,
    private toastService: ToastrService
  ) { }

  changeColor(newColor: string, index: number) {

    this.column.configuration[index].color = newColor
    this.columnService.changeConfiguration(this.column.id, this.column.configuration)
  }

  move(index: number, direction: number) {

    const newIndex = index + direction

    if (newIndex < 0 || newIndex >= this.column.configuration.length) return

    const temp = this.column.configuration[index]
    this.column.configuration[index] = this.column.configuration[newIndex]
    this.column.configuration[newIndex] = temp

    this.columnService.changeConfiguration(this.column.id, this.column.configuration)
    console.log(this.column.configuration)
  }

  async removeElement(index: number) {

    try {
      await this.modalService.openModal(DeleteLabelModalComponent, this.column.configuration[index])
      this.column.configuration.splice(index, 1)
      this.columnService.changeConfiguration(this.column.id, this.column.configuration)
    } catch {
      // user canceled modal 
    }

  }

  async changeLabel(newLabel: string, index: number) {
  
    const element = this.column.configuration[index]

    if (element.label != newLabel) {

      element.label = newLabel
      this.columnService.changeConfiguration(this.column.id, this.column.configuration)

      this.toastService.success("Label changed")
    }

  }

  async addElement(event: Event, addAnother: boolean = false) {
    event.stopPropagation()

    // Check all possible values for this column
    let values = await this.workstreamProperties.getAllPropertyValues(this.column.name)

    
    // Also add possible values from the configuration
    this.column.configuration.forEach((x: any) => values.push(x.value))

    // so for some reason, either the values returned at TW are not always strings but also undefined or null 
    // or something similar happens when adding the values of column.configuration.
    // This leads to an error in the following code, so I added a filter to remove all non-string values
    // see: https://gitlab.com/literacy-gmbh/annex-tracker/-/issues/62
    console.log(values)
    values = values.filter(x => typeof x === 'string')

    // filter all values that start with "custom_"
    const customValues = values.filter(x => x.startsWith('custom_'))


    // find the highest number
    const highestNumber = customValues.map(x => parseInt(x.split('_')[1])).reduce((a, b) => Math.max(a, b), 0)


    const nextNumber = highestNumber + 1

    const nextValue = `custom_${nextNumber}`

    if (this.newElementName == '' || this.newElementName.length < 1) return

    this.column.configuration.push({
      color: this.newElementColor,
      label: this.newElementName,
      value: nextValue,
    })

    this.columnService.changeConfiguration(this.column.id, this.column.configuration)

    this.newElementName = ''
    this.newElementColor = 'red'

    if (addAnother) {
      this.addElementMode = true
    } else {
      this.addElementMode = false
    }


  }

}
