<div class="flex flex-col h-full min-h-full relative">
  <div class="px-4 absolute w-full top-0 z-10 flex justify-between items-center" *ngIf="searchTerm != null">

    <p class="text-sm text-gray-700 font-medium drop-shadow-md cursor-pointer" (click)="findNext()">
      {{matchResult?.current}}
      <span class="font-normal">of</span>
      {{matchResult?.total}}
    </p>


    <span class="p-1 cursor-pointer text-gray-500 hover:text-gray-800"
      (click)="searchAutomatically = !searchAutomatically">
      <fa-icon class="drop-shadow-md" [icon]="searchAutomatically ? iconSearchAutomatically : iconNoSearchAutomatically"></fa-icon>
    </span>
    <!-- <button [attr.disabled]="(matchResult?.total || 0) <= 1 ? '' : null" (click)="findNext()"
          class="relative disabled:cursor-not-allowed disabled:text-gray-200 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1.5 text-xs font-medium text-gray-800 hover:bg-gray-50">Show next occurence (Tab)</button> -->


  </div>

  <ngx-extended-pdf-viewer *ngIf="pdfUrl" 
    zoom="page-width" 
    backgroundColor="white"
    [src]="pdfUrl"
    [showToolbar]="false"
    [textLayer]="true"
    [handTool]="false"
    [sidebarVisible]="false"
    [showBorders]="false" (pdfLoaded)="onPdfLoaded()"
    (updateFindMatchesCount)="updateFindMatchesCount($event)">
  </ngx-extended-pdf-viewer>

</div>