<div class="divide divide-y">
    <div class="px-5 pb-5 font-medium text-lg">
        Edit column <span class="bg-slate-200 p-1 rounded-sm">{{column?.label}}</span>
    </div>
    
    <div class="p-5" *ngIf="column">
        <label class="block text-sm font-medium text-gray-700">Title</label>
    
        <div class="mt-1 flex rounded-md border border-gray-300 overflow-hidden divide-x">
            <input #labelInput type="text" [ngModel]="column.label" name="label"
                class="block w-full appearance-none outline-0 border-0 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm">
            <button class="px-4 text-sm font-medium hover:bg-gray-200"
                (click)="changeLabel(labelInput.value)">Change</button>
        </div>
    
    </div>

    <ng-container [ngSwitch]="column?.type">
        
        <app-edit-enum-radio class="block" [column]="column!"></app-edit-enum-radio>

    </ng-container>

</div>