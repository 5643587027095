import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AutoFocusDirective } from '../../common/auto-focus.directive';

@Component({
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    standalone: true,
    imports: [AutoFocusDirective, RouterOutlet],
})
export class SidePanelComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute) {}

  @HostListener("keydown.escape", ['$event'])
  close() {
    this.router.navigate([{ outlets: { modal: null } }])
  }

}
