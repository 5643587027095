import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const loggedInGuard: CanActivateFn = async (route, state) => {

  const authService = inject(AuthenticationService)
  const router = inject(Router)

  try {
    await authService.awaitableUser()
    // if getting a user worked, we're logged in, so return true. If not, this will throw and go to the catch block
    return true
  }
  catch {
    return router.createUrlTree(['signin'])
  }

}
