import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { ProjectsService } from './projects.service';
import { Observable, ReplaySubject, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { makeObservableList } from './helpers/observable-service';
import { ProjectAlias } from 'annex-tracker-backend';


@Injectable({
  providedIn: 'root'
})
export class ProjectAliasService {

  alias: Observable<ProjectAlias | undefined>

  constructor(
    private backend: BackendService, 
    project: ProjectsService
  ) {

    this.alias = makeObservableList(this.backend.alias, async x => x, project.projectIdQuery, project.projectIdFilter)
      .pipe(
        map(x => x[0])
      )
          
  }

  async changeAlias(alias: string) {

    const currentAlias = await firstValueFrom(this.alias)
    if (currentAlias == null) {
      throw new Error("No alias found")
    }
    
    await this.backend.alias.patch(currentAlias!.id, {
      alias
    })

  }
}
