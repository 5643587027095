import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { CoverPages } from 'annex-tracker-backend';
import { ButtonPrimaryComponent } from '../../tailwind/button-primary/button-primary.component';
import { ActiveModal, ModalContext } from 'src/app/modal/modal.service';
import { ButtonSecondaryComponent } from '../../tailwind/button-secondary/button-secondary.component';
import { CoverPageService } from 'src/app/services/cover-page.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selector-modal',
  standalone: true,
  imports: [AsyncPipe, ButtonPrimaryComponent, ButtonSecondaryComponent, JsonPipe],
  templateUrl: './selector-modal.component.html',
  styleUrl: './selector-modal.component.scss'
})
export class SelectorModalComponent {

  activeModal = inject(ActiveModal)
  coverPageService = inject(CoverPageService)
  
  constructor(@Inject("context") public currentCoverPage: any) { }
  
}
