<pdf-shy-button
  [cssClass]="show | responsiveCSSClass : 'always-in-secondary-menu'"
  title="Use Vertical Scrolling"
  primaryToolbarId="scrollVertical"
  l10nId="pdfjs-scroll-vertical-button"
  [toggled]="scrollMode == 0 && pageViewMode !== 'book'"
  [action]="onClick"
  l10nLabel="pdfjs-scroll-vertical-button-label"
  [order]="3100"
  [closeOnClick]="false"
  image="<svg style='width: 24px; height: 24px; margin-top: 3px' viewBox='0 0 24 24'><path fill='currentColor' d='M9.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C5 4.5 5.5 4 6.5 4zM11 0v.5c0 1-.5 1.5-1.5 1.5h-3C5.5 2 5 1.5 5 .5V0h6zM11 16v-.5c0-1-.5-1.5-1.5-1.5h-3c-1 0-1.5.5-1.5 1.5v.5h6z' /></svg>"
>
</pdf-shy-button>
