import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-print-test',
    templateUrl: './print-test.component.html',
    styleUrls: ['./print-test.component.scss'],
    standalone: true,
    imports: [NgIf, NgxExtendedPdfViewerModule]
})
export class PrintTestComponent implements OnInit {
  buffer?: ArrayBuffer

  constructor(private backend: BackendService) { }

  async ngOnInit() {

    const res = await this.backend.pdfTable.create({
      projectId: 101,
      //organization: 'trlegal.de',
      //organization: 'hoganlovells.com'
      //organization: 'ebnerstolz.de'
      organization: 'gmail.com'
    }, {
      query: {
        firstDate: 1697448549810,
        secondDate: 1697448682183
      } as any

    })
    this.buffer = res.buffer
  }

}
