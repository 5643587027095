<div class="absolute block pointer-events-none" (click)="dropdownService.close()"
    [style.left.px]="dropdownService.left"
    [style.top.px]="dropdownService.top"
    [style.width.px]="dropdownService.width || 0 *2"
    [style.height.px]="dropdownService.height">
    
    <div class="relative pointer-events-auto overflow-visible" 
        [class.top-full]="!dropdownService.goUp"
        >
        <div class="absolute z-10" 
            [class.-translate-y-full]="dropdownService.goUp"
            [class.-translate-x-full]="dropdownService.goLeft">
            <ng-container *ngTemplateOutlet="dropdownService.templateRef"></ng-container>
        </div>
    </div>
    

</div>