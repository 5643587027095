<div class="divide divide-y h-[calc(100vh-35px)] flex flex-col">

    <div class="p-5 flex justify-between">
        <h1>Changelog</h1>
        <button type="button"
            class="rounded-md text-gray-400 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300"
            (click)="activeModal.dismiss()">
            <span class="sr-only">Close panel</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
    <div class="overflow-y-auto">
        <div class="prose p-5 prose-sm" [innerHTML]="html"></div>
    </div>

</div>
