<div class="text-gray-400 hover:text-gray-600 p-2 cursor-pointer focus:outline-none" #dropdownParent
    (click)="checkDebug($event); dropdownService.toggle(dropdownTemplate, dropdownParent, $event); parentToMode = false">
    <fa-icon [icon]="iconDots"></fa-icon>
</div>

<ng-template #dropdownTemplate>
    <div class="relative inline-block mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div *ngIf="parentToMode == false" class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a (click)="showUploadFileDialog($event)"
                class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                role="menuitem" tabindex="-1">
                <fa-icon class="mr-1" [icon]="iconUpload"></fa-icon>
                Upload
            </a>
            <!-- <a class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100" role="menuitem" tabindex="-1">
                <fa-icon class="mr-1" [icon]="iconDownload"></fa-icon>
                Download
            </a> -->
            <ng-container *ngIf="workstream.parentId == null">
                <a (click)="moveUp()"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconUp"></fa-icon>
                    Move up
                </a>
                <a (click)="moveDown()"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconDown"></fa-icon>
                    Move down
                </a>

            </ng-container>
            <ng-container *ngIf="workstream.parentId != null">
                @if (workstream.depth == 1) {
                <a (click)="init($event)"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconParentToOther"></fa-icon>
                    Attach to ..
                </a>
                }
                @if (workstream.depth == 2) {
                <a (click)="unparent()"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconParentToOther"></fa-icon>
                    Detach
                </a>
                }
                <a *ngIf="!(workstream.properties?.['deleted'] == 'true')"
                    (click)="propertyService.setTo('deleted', 'true', workstream)"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconRemove"></fa-icon>
                    Remove <span class="float-right text-xs border border-1 bg-gray-50 rounded px-2"><span>SHIFT</span>
                        + <fa-icon [icon]="iconBackspace"></fa-icon></span>
                </a>
                <a *ngIf="(workstream.properties?.['deleted'] == 'true')"
                    (click)="propertyService.setTo('deleted', 'false', workstream)"
                    class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                    role="menuitem" tabindex="-1">
                    <fa-icon class="mr-1" [icon]="iconRestore"></fa-icon>
                    Restore <span class="float-right text-xs border border-1 bg-gray-50 rounded px-2"><span>SHIFT</span>
                        + <fa-icon [icon]="iconBackspace"></fa-icon></span>
                </a>
            </ng-container>
        </div>
        <div *ngIf="parentToMode" class="py-1 max-h-60 overflow-y-auto  my-scrollbar">
            <a (click)="parentToMode = false; $event.stopPropagation()"
                class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                role="menuitem" tabindex="-1">
                <fa-icon class="mr-1" [icon]="iconBack"></fa-icon>
                Back
            </a>
            <a *ngIf="(workstream.depth || 0) > 1" (click)="unparent()"
                class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                role="menuitem" tabindex="-1">
                <fa-icon class="mr-1" [icon]="iconBack"></fa-icon>
                Unparent
            </a>
            <hr>
            @for (sibling of siblings; track $index) {
            <a (click)="parentTo(sibling)"
                class="cursor-pointer overflow-x-hidden whitespace-nowrap text-ellipsis text-gray-700 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100">
                {{sibling.properties?.["prefix"]}} <span
                    class="font-medium">{{sibling.properties?.["reference"]}}</span> - {{sibling.properties?.["name"]}}
            </a>
            }
        </div>
        @if (debugMode) {
        <div>
            <a (click)="openDebug()" class="cursor-pointer text-yellow-800 block px-4 py-2 text-sm hover:text-gray-900 hover:bg-gray-100"
                role="menuitem" tabindex="-1">
                <fa-icon class="mr-1" [icon]="iconDebug"></fa-icon>
                Debug 
            </a>
        </div>
        }
    </div>

</ng-template>