<div class="p-2 text-2xl">{{'tracker_compare_label' | translate:data.project.language}} {{data.firstDate | myDate}} vs {{data.secondDate | myDate}}</div>
<div class="flex divide-x">
    <div class="flex-1 p-2">

        <table class="table-fixed min-w-full overflow-hidden">
            <thead>
                <tr class="border-b-4 border-b-white">
                    <th colspan="2" class="p-1 bg-green-300">
                        {{'added_label' | translate:data.project.language}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ws of data?.added">
                    <td class="w-0"><a href="#{{ws.id}}">{{ws.properties['prefix'] || '&#8203;'}}</a></td>
                    <td class="w-0 whitespace-nowrap pr-2"><a class="text-blue-500 underline"
                            href="#{{ws.id}}">{{ws.properties['reference'] || ws.properties['name']}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="flex-1 p-2">

        <table class="table-fixed min-w-full overflow-hidden">
            <thead>
                <tr class="border-b-4 border-b-white">
                    <th colspan="2" class="p-1 bg-red-300">
                        {{'removed_label' | translate:data.project.language}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ws of data?.removed">
                    <td class="w-0"><a href="#{{ws.id}}">{{ws.properties['prefix'] || '&#8203;'}}</a></td>
                    <td class="w-0 whitespace-nowrap pr-2"><a class="text-blue-500 underline"
                            href="#{{ws.id}}">{{ws.properties['reference'] || ws.properties['name']}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="flex-1 p-2">

        <table class="table-fixed min-w-full overflow-hidden">
            <thead>
                <tr class="border-b-4 border-b-white">
                    <th colspan="2" class="p-1 bg-blue-300">
                        {{'renamed_label' | translate:data.project.language}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ws of data?.renamed">
                    <td class="w-0"><a href="#{{ws.before.id}}">{{ws.before.properties['prefix'] || '&#8203;'}}</a></td>
                    <td class="w-0 whitespace-nowrap pr-2">
                        {{ws.before.properties['reference'] || ws.before.properties['name']}} <fa-icon
                            [icon]="iconRight"></fa-icon>
                        <a class="text-blue-500 underline ml-2"
                            href="#{{ws.after.id}}">{{ws.after.properties['reference'] ||
                            ws.after.properties['name']}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>