import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { File } from 'annex-tracker-backend';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { FilesService } from 'src/app/services/files.service';
import { sortDescending } from 'src/app/services/model/base-entity';
import { ProjectsService } from 'src/app/services/projects.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { FileIconComponent } from '../../../../../common/file-icon/file-icon.component';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';
import { ShowIfAiEnabledComponent } from 'src/app/common/show-if-ai-enabled/show-if-ai-enabled.component';

@Component({
    selector: 'app-workstream-detail',
    templateUrl: './workstream-detail.component.html',
    host: {
        class: 'flex-1 overflow-y-auto my-scrollbar'
    },
    styleUrls: ['./workstream-detail.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FileIconComponent, RouterLink, AsyncPipe, DatePipe, ButtonPrimaryComponent, ShowIfAiEnabledComponent]
})
export class WorkstreamDetailComponent {

  files: Observable<File[]>
  latestFile: Observable<File>

  constructor(
    public workstreamsService: WorkstreamsService,
    public filesService: FilesService,
    public router: Router,
    public route: ActivatedRoute,
    public projects: ProjectsService,
    public backend: BackendService
  ) {

    this.files = this.workstreamsService.currentWorkstreamId.pipe(switchMap(id =>
      this.workstreamsService.files.pipe(map(files => files.filter(f => f.workstreamId == id).sort(sortDescending)))
    ))

    this.latestFile = this.files.pipe(map(files => files[0]))

  }

  async downloadFile(file: File) {
    this.workstreamsService.download(file)
  }

  async analyzeFile(file: File) {

    const navCmd = ['fullscreen-panel', 'comparison', file.id]
    this.router.navigate([{ outlets: { modal: navCmd}}])

  }

  async test(file: File) {
    try {

      const res = await this.backend.convertedFile.create({
        fileId: file.id,
        type: 'pdf'
      })
      console.log(res)
    } catch (e) {
      console.error(e)
    }
  }

  async testCompare() {
    console.log("testCompare")
    const files = await firstValueFrom(this.files)

    const leftFile = files[0]
    const rightFile = files[1]

    const comparedFile = await this.backend.comparedFiles.create({
      leftFileId: leftFile.id,
      rightFileId: rightFile.id
    })

    console.log(comparedFile)
    this.backend.comparedFiles.on('patched', (event) => {
      if (comparedFile.id == event.id) {
        console.log(event)
      }
    })
  }





}
