import { Component, Input } from '@angular/core';
import { Workstream } from 'annex-tracker-backend';

@Component({
    selector: 'app-pretty-print-workstream',
    templateUrl: './pretty-print-workstream.component.html',
    standalone: true
})
export class PrettyPrintWorkstreamComponent {

  @Input()
  workstream?: Workstream | null

}
