import { Component, OnInit, inject } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-show-if-ai-enabled',
  standalone: true,
  imports: [],
  templateUrl: './show-if-ai-enabled.component.html',
  styleUrl: './show-if-ai-enabled.component.scss'
})
export class ShowIfAiEnabledComponent implements OnInit {

  config = inject(ConfigService)

  isAiEnabled = false

  async ngOnInit() {
    const c = await this.config.awaitableConfig()
    this.isAiEnabled = c.ai.enabled
  }

}
