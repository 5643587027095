import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { map, take } from 'rxjs';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { DebugSpaUpdateComponent } from './review/debug-spa-update/debug-spa-update.component';
import { ShowIfQueryUrlComponent } from 'src/app/common/show-if-query-url/show-if-query-url.component';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from 'src/app/common/tailwind/button-secondary/button-secondary.component';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
  standalone: true,
  host: {
    class: 'h-full'
  },
  imports: [RouterOutlet, ShowIfQueryUrlComponent, DebugSpaUpdateComponent, ButtonPrimaryComponent, ButtonSecondaryComponent]
})
export class AnalysisComponent {

  iconClose = faXmark
  isApplying = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private docxAnalysisService: DocxAnalysisService) {

    this.route.paramMap.pipe(map(params => Number(params.get("analysisId")) || null), take(1)).subscribe(analysisId => {
      this.docxAnalysisService.loadAnalysis(analysisId)
    })

  }

  async apply() {
    this.isApplying = true
    await this.docxAnalysisService.apply()
    this.isApplying = false
    this.close()
  }

  close() {
    this.router.navigate([{ outlets: { modal: null } }])
  }

}
