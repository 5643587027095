<div class="divide divide-y">
  <div class="px-5 pb-5 font-medium text-lg">List settings</div>

  <div class="space-y-6 p-5">
    <div class="space-y-1">
      <label for="listName" class="block text-sm font-medium text-gray-700">Name</label>
      <div class="mt-1 flex rounded-md border border-gray-300 overflow-hidden divide-x">
        <input id="listName" *ngIf="project" [(ngModel)]="project.name" name="listName" type="text" maxlength="20"
          class="block w-full appearance-none outline-0 border-0 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm">
        <button class="px-4 text-sm font-medium hover:bg-gray-200" (click)="changeListName()">Change</button>
      </div>
    </div>
  </div>

  <app-edit-email-alias appQueryHighlight="editAlias" *ngIf="(configService.config | async)?.hookEnabled" class="block"></app-edit-email-alias>

  <app-collaboration class="block space-y-6 p-5">    
  </app-collaboration>

  <div class="space-y-6 p-5">
    <div class="space-y-2">
      <label for="listName" class="block text-sm font-medium text-gray-700">Language</label>
      <app-language-selector class="flex gap-6" *ngIf="project" [(language)]="project.language"
        (languageChange)="changeLanguage()">
      </app-language-selector>
    </div>
  </div>

  <div class="space-y-6 p-5">
    <div class="space-y-2">
      <label for="listName" class="block text-sm font-medium text-gray-700 mb-2">Cover Page</label>
      <app-cover-page-selector (currentCoverPageChange)="changeCoverPage($event)" [currentCoverPage]="coverPageService.currentCoverPage$ | async"></app-cover-page-selector>
    </div>
  </div>


  <div class="p-5" *ngIf="(usersService.loggedInUser | async)?.role == 'creator'">
    <label for="listName" class="block text-sm font-medium text-gray-700">Archive & Delete</label>
    <div class="mt-1 flex justify-between">
      <app-button-danger (click)="archiveList()">Archive (close) tracker</app-button-danger>
      <app-button-danger [disabled]="true" (click)="deleteList()">Delete tracker</app-button-danger>
    </div>
  </div>

</div>