import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { filter, Observable, of } from 'rxjs';
import { ProjectsService } from '../projects.service';
import { Projects } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver  {

  constructor(private projectsService: ProjectsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Projects | undefined> {

    const listId = route.paramMap.get("listId")

    if (listId == null) return of(undefined)
    this.projectsService.loadProject(+listId)

    return this.projectsService.project$.pipe(filter(p => p?.id == +listId));

  }
}
