<div class="container mx-auto p-2">
    <div class="space-y-4">
        <h1 class="text-lg">Shareholder Resolution AI</h1>

        <div >
            <input #fileInput (change)="onFileChange($event)" type="file" [accept]="acceptMimeTypes">
        </div>

        <div>
            <app-button-primary [loading]="isLoading"  (click)="send()" [disabled]="uploadFile == null">Generate Shareholder Resolution</app-button-primary>
        </div>
        <pre>{{res | json}}</pre>
    </div>
</div>