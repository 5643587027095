import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

@Pipe({
    name: 'sanitize',
    standalone: true
})
export class SanitizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): SafeHtml {
    return sanitizeHtml(value)
  }

}
