<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">Are you sure?</div>
    <div class="p-5" *ngIf="message">{{message}}</div>

    <div class="p-5 flex justify-between" *ngIf="message == null">
        <app-button-secondary (click)="activeModal.dismiss('cancel')">Cancel</app-button-secondary>
        <app-button-danger (click)="deleteProfile()">Delete profile</app-button-danger>
    </div>

    <div class="p-5 flex justify-end" *ngIf="message">
        <app-button-primary (click)="activeModal.close('logout')">Done</app-button-primary>
        
    </div>

</div>