import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Email, File } from 'annex-tracker-backend';
import { TimeAgoPipe } from '../../../../../../pipes/time-ago.pipe';
import { FileWorkstreamBadgeComponent } from '../../../../../../common/file-workstream-badge/file-workstream-badge.component';
import { FileIconComponent } from '../../../../../../common/file-icon/file-icon.component';
import { EmailContactCircleComponent } from '../../../../../../common/email-contact-circle/email-contact-circle.component';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';


@Component({
    selector: '[app-mail-preview]',
    templateUrl: './mail-preview.component.html',
    styleUrls: ['./mail-preview.component.scss'],
    standalone: true,
    imports: [NgIf, EmailContactCircleComponent, NgFor, FileIconComponent, FileWorkstreamBadgeComponent, AsyncPipe, DatePipe, TimeAgoPipe]
})
export class MailPreviewComponent {

  @Input()
  mail!: Email;

  @Input()
  isLast = false

  @Input()
  currentWorkstreamId?: number | null

  @Output()
  fileClicked = new EventEmitter<File>()

  clampLines = true

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

}
