<div class="h-full divide divide-y flex flex-col">


  <div class="p-5 flex justify-end gap-2">

    <app-show-if-query-url [query]="'debug'" [value]="'true'">
      <app-debug-spa-update></app-debug-spa-update>
    </app-show-if-query-url>


    @if (!isApplying) {
      <app-button-secondary (click)="close()">Cancel</app-button-secondary>
    }
    <app-button-primary [disabled]="isApplying" [loading]="isApplying" (click)="apply()">Apply changes</app-button-primary>
    

  </div>

  <div class="overflow-hidden flex-1 relative">
    <router-outlet class="hidden"></router-outlet>
    @if (isApplying) {
      <div class="absolute inset-0 bg-slate-100 opacity-80"></div>
    }
  </div>
</div>