import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, map } from 'rxjs';
import { Exports } from 'annex-tracker-backend';
import { makeObservableList } from './helpers/observable-service';
import { ProjectsService } from './projects.service';
import { sortDescending } from './model/base-entity';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ExportedTrackersService {

  exporting = false
  exports: Observable<Exports[]>

  constructor(
    private backend: BackendService,
    private project: ProjectsService,
    private datePipe: DatePipe,
    private auth: AuthenticationService) {
    
    this.exports = makeObservableList(backend.exportedTrackers, async x => x, project.projectIdQuery, project.projectIdFilter)
      .pipe(map(exports => exports.sort(sortDescending)))

  }

  async export(inComparisonWith?: Exports) {
    try {
      this.exporting = true
      const exportItem = await this.backend.exportedTrackers.create({
        projectId: this.project.currentProject!.id,
        exportAt: Date.now(),
        comparedWith: inComparisonWith?.exportAt || null
      })
      console.log(exportItem)

      await this.exportPDF(exportItem, inComparisonWith)
      this.exporting = false
    } catch (e) {
      console.error(e)
    } finally {
    }
  }

  async exportPDF(exportItem: Exports, inComparisonWith?: Exports) {

    const user = await this.auth.awaitableUser()

    const [_, organization] = user?.email.split("@")

    const project = this.project.currentProject
    const res = await this.backend.pdfTable.create({
      projectId: project?.id,
      organization: organization?.toLowerCase()
    }, {
      query: {
        firstDate: inComparisonWith?.exportAt || exportItem.exportAt,
        secondDate: exportItem.exportAt
      } as any
      
    })


    const filename = inComparisonWith == null ? 
      `${this.datePipe.transform(exportItem.exportAt, 'YYYYMMdd_HH.mm')}_${project?.name}.pdf` :
      `${project?.name}_compare_${this.datePipe.transform(inComparisonWith.exportAt, 'YYYYMMdd_HH.mm')}_vs_${this.datePipe.transform(exportItem.exportAt, 'YYYYMMdd_HH.mm')}.pdf`

    const file = new File([new Blob([res.buffer])], filename, {
      type: 'application/pdf'
    })

    URL.createObjectURL(file)

    const aElement = document.createElement("a")
    document.body.appendChild(aElement)

    aElement.href = URL.createObjectURL(file)
    aElement.download = file.name
    aElement.click()

    document.body.removeChild(aElement);

  }
}
