import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService, ModalStackItem } from 'src/app/modal/modal.service';
import { NgFor, NgClass, NgComponentOutlet } from '@angular/common';

@Component({
    selector: 'app-modal-outlet',
    templateUrl: './modal-outlet.component.html',
    styleUrls: ['./modal-outlet.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, NgComponentOutlet]
})
export class ModalOutletComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) entry: ViewContainerRef | unknown;

  constructor(public modalService: ModalService) { }

  ngOnInit(): void {
    this.modalService.viewContainer = this.entry;
  }

  async maybeClose(modal: ModalStackItem) {
    
    if (modal.modalOptions.escapeCloses) {
      modal.activeModal.dismiss('backdrop')
    }

  }

}
