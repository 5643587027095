import { Component } from '@angular/core';
import { HeaderButtonsService } from 'src/app/header-buttons.service';
import { EmailService } from 'src/app/services/email.service';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgIf, NgTemplateOutlet, AsyncPipe]
})
export class HeaderComponent {

  constructor(
    public headerButtons: HeaderButtonsService,
    public emailsService: EmailService,
  ) { }

}
