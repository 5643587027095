import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Email, Workstream } from 'annex-tracker-backend';
import { EmailService } from 'src/app/services/email.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical'
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-add-workstream-label',
    templateUrl: './add-workstream-label.component.html',
    styleUrls: ['./add-workstream-label.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, AsyncPipe]
})
export class AddWorkstreamLabelComponent {

  open = false

  @Input()
  workstreams?: HWorkstream[]

  @Input()
  email?: Email

  constructor(
    public workstreamsService: WorkstreamsService,
    public emailService: EmailService
  ) { }

  workstreamClicked(workstream: Workstream) {
    console.log(this.workstreams)
    console.log(workstream)
    if (this.workstreams?.find(ws => ws.id == workstream.id) != null) {
      console.log("removing workstream from email")
      this.emailService.removeWorkstreamFromEmail(workstream.id, this.email!.id)
    } else {
      console.log("adding workstream from email")
      this.emailService.addWorkstreamToEmail(workstream.id, this.email!.id)
    }
  }

}
