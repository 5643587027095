<header class="w-full shadow-md relative z-10">
    <div class="relative z-10 flex h-16 flex-shrink-0  bg-white">
        <div class="flex flex-1 justify-between items-center px-4 sm:px-6">
            <nav aria-label="Global" class="flex space-x-10">
                
                <a routerLink='doclist' routerLinkActive="underline" class="text-sm font-medium text-gray-900">
                    Tracker
                </a>
         
                <a routerLink='inbox' routerLinkActive="underline" class="text-sm font-medium text-gray-900 relative">
                    Inbox <span *ngIf="(emailsService.unassignedAttachmentsCount$ | async) || 0 > 0" class="absolute inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">{{emailsService.unassignedAttachmentsCount$ | async}}</span>
                </a>
                    
                <!-- <a routerLink='reporting' routerLinkActive="underline" class="text-sm font-medium text-gray-900">
                    Summary
                </a> -->
                    
                <!-- <a routerLink='participants' routerLinkActive="underline" class="text-sm font-medium text-gray-900">
                    Participants
                </a> -->

            </nav>
            <ng-container *ngTemplateOutlet="headerButtons.template"></ng-container>
        </div>
    </div>
</header>