import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
    selector: '[appAutoFocus]',
    standalone: true
})
export class AutoFocusDirective {

  private inputElement: HTMLInputElement

  @Input()
  selectAll = false

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement
  }

  ngOnInit(): void {
    this.inputElement.focus()

    if (this.selectAll) {
      setTimeout(() => {
        this.inputElement.select()
      }, 1)
    }
  
  }
}
