import { Component } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { AutoFocusDirective } from '../../../common/auto-focus.directive';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, FormsModule, AutoFocusDirective]
})
export class ForgotComponent {

  sent = false

  email?: string

  constructor(private backend: BackendService) {

  }

  async createForgotPasswordRequest() {
    this.sent = true
    const res = await this.backend.passwordReset.create({
      email: this.email!
    })

    console.log(res)
  }

}
