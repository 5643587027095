<div class="absolute inset-0 bg-gray-500 bg-opacity-75 z-10" tabindex="-1" appAutoFocus>
  <div class="absolute inset-5 rounded-lg overflow-hidden bg-white flex flex-col">
    <div class="relative flex flex-col">

      <div class="absolute top-0 pl-2 py-4 left-0 rounded-full text-gray-600">
        <span (click)="download()" class="px-2 hover:text-gray-900 cursor-pointer"><fa-icon [icon]="iconDownload"></fa-icon></span>
        <span (click)="print()" class="px-2 hover:text-gray-900 cursor-pointer"><fa-icon [icon]="iconPrint"></fa-icon></span>
      </div>

      <div class="absolute top-0 right-0 p-4 px-5 rounded-full text-gray-600 hover:text-gray-900 cursor-pointer"
        (click)="close()">
        <fa-icon size="lg" [icon]="iconClose"></fa-icon>
      </div>


      <nav class="p-4 flex justify-center gap-6">
        <span class="truncate text-sm">
          <app-file-icon class="mr-1" [file]="file" size="lg"></app-file-icon> <span title="{{file?.filename}}">{{file?.filename}}</span>
      </span>
      <app-file-workstream-badge *ngIf="file != null" class="text-sm" [file]="file"></app-file-workstream-badge>
      </nav>
    </div>
      <ngx-extended-pdf-viewer *ngIf="pdfUrl$ | async" 
      zoom="page-fit"
      backgroundColor="#ffffff"
      [src]="(pdfUrl$ | async)!"
      [showToolbar]="false" 
      [sidebarVisible]="false"
      [textLayer]="true"
      [handTool]="false"
      [showBorders]="false" >
    </ngx-extended-pdf-viewer>
  </div>
</div>