<td class="w-0 py-3 px-2 whitespace-nowrap text-right">{{index}}</td>

<ng-container *ngIf="workstream.parentId != null">
    <td [style.padding-left.px]="(workstream.depth || 0) * 10" class="box-border w-0 px-1 whitespace-nowrap text-left">{{workstream.properties?.['prefix'] || ''}}</td>
    <td class="box-border w-0 px-1 whitespace-nowrap font-medium rounded focus:outline-gray-900 outline-gray-400 hover:outline outline-1 -outline-offset-1 cursor-text overflow-visible" 
        appCursorChangeField  
        (changeFieldRight)="focus('name', 'left')"
        (focus)="focusedColumn.emit('reference')"
        [appWorkstreamInlineEdit]="workstream" 
        propertyName="reference" 
        [contentEditable]="editable"
        [innerText]="workstream.properties?.['reference'] || ''"
        [class.cursor-pointer]="editable"></td>
    <td class="box-border w-64 px-2 py-4 whitespace-pre-wrap rounded focus:outline-gray-900 outline-gray-400 hover:outline outline-1 -outline-offset-1 cursor-text overflow-visible"
        appCursorChangeField
        (changeFieldLeft)="focus('reference', 'right')"
        (changeFieldRight)="focus('comments', 'left')"
        (focus)="focusedColumn.emit('name')"
        [appWorkstreamInlineEdit]="workstream"
        propertyName="name"
        [contentEditable]="editable"
        [innerText]="workstream.properties?.['name'] || ''"
        [class.cursor-pointer]="editable"></td>
</ng-container>

<ng-container *ngIf="workstream.parentId == null">
    <td class="box-border w-0  px-1      whitespace-nowrap text-left">Main</td>
    <td class="box-border w-0  px-1      whitespace-nowrap font-medium">-</td>
    <td class="box-border w-64 px-2 py-4 whitespace-pre-wrap rounded focus:outline-gray-900 outline-gray-400 hover:outline outline-1 -outline-offset-1 cursor-text overflow-visible"
        appCursorChangeField
        (changeFieldRight)="focus('comments', 'left')"
        [appWorkstreamInlineEdit]="workstream"
        propertyName="name"
        (focus)="focusedColumn.emit('name')"
        [contentEditable]="editable"
        [innerText]="workstream.properties?.['name'] || ''"
        [class.cursor-pointer]="editable">
    </td>
</ng-container>

<ng-container *ngFor="let column of columnService.columns | async">

    <!-- switch statement -->
    <ng-container [ngSwitch]="column.type">

        <td *ngSwitchCase="'text'" 
            class="box-border w-64 px-2 py-4 whitespace-pre-wrap rounded focus:outline-gray-900 outline-gray-400 hover:outline outline-1 -outline-offset-1 cursor-text overflow-visible"
            (focus)="focusedColumn.emit(column.name)"
            [appWorkstreamInlineEdit]="workstream"
            [propertyName]="column.name"
            [contentEditable]="editable"
            appCursorChangeField
            [class.cursor-pointer]="editable"
            (changeFieldLeft)="focus('title', 'right')"
            (changeFieldRight)="focus('comment', 'right')"
            [innerText]="workstream.properties?.[column.name] || ''">
        </td>
        <!-- <td *ngSwitchCase="'text'" 
            app-editable-table-field tabindex="-1"
            appCursorChangeField
            [html]="workstream.properties?.[column.name]"
            [workstream]="workstream"
            [propertyName]="column.name"
            class="box-border w-64 px-2 py-4 whitespace-pre-wrap rounded focus:outline-gray-900 outline-gray-400 hover:outline outline-1 -outline-offset-1 cursor-text relative overflow-visible">
        </td> -->

        
        <td *ngSwitchCase="'enum-dropdown'" 
            class="box-border w-0 text-center p-2">
            <app-badge-dropdown [badges]="column.configuration" [value]="workstream.properties?.[column.name]"
                (valueChanged)="workstreamProperty.setTo(column.name, $event, workstream)"></app-badge-dropdown>
        </td>
    

    </ng-container>
    
</ng-container>

<td class="box-border w-0" app-row-context [workstream]="workstream"></td>
