import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Exports } from 'annex-tracker-backend';
import { Subscription } from 'rxjs';
import { ExportedTrackersService } from 'src/app/services/exported-trackers.service';
import { ButtonPrimaryComponent } from '../../../common/tailwind/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from '../../../common/tailwind/button-secondary/button-secondary.component';
import { SelectMenuComponent } from '../../../common/select-menu/select-menu.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectColumnComponent } from './select-column/select-column.component';

@Component({
    selector: 'app-export-diff-modal',
    templateUrl: './export-diff-modal.component.html',
    styleUrls: ['./export-diff-modal.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, SelectMenuComponent, ButtonSecondaryComponent, ButtonPrimaryComponent, AsyncPipe, SelectColumnComponent]
})
export class ExportDiffModalComponent implements OnDestroy {

  selectedExport?: Exports | null

  subscription: Subscription

  choice: 'clean' | 'compare' = 'clean'

  constructor(
    public exportService: ExportedTrackersService, 
    private router: Router
  ) {
    this.subscription = exportService.exports.subscribe(exports => {
      console.log("exports", exports)
      this.selectedExport = exports[0]
    })
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  export() {

    if (this.choice == 'clean') {
      this.exportService.export()
    } else if (this.choice == 'compare') {
      this.exportService.export(this.selectedExport!)
    }

    this.close()
  }

  close() {
    this.router.navigate([{ outlets: { modal: null } }])
  }

}
