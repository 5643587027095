import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Directive({
    selector: '[appStackedBarChart]canvas',
    standalone: true
})
export class StackedBarChartDirective implements OnInit {


  @Input()
  title!: string

  @Input()
  labels!: string[]

  @Input()
  colors: string[] = ['#00ff00']

  @Input()
  data!: number[][]

  chart?: Chart<'bar'>

  constructor(
    private elementRef: ElementRef<HTMLCanvasElement>,
  ) { }

  async ngOnInit() {
    console.log(this.data)
    this.chart = new Chart(this.elementRef.nativeElement, {
      type: 'bar',
      data: {
        labels: ['Before'],
        datasets: this.data[0].map((d,i) => ({
          label: this.labels[i],
          backgroundColor: this.colors[i],
          data: [d],
          barThickness: 20
        })),
      },
      options: {
        animation: false,
        maintainAspectRatio: false,
        responsive: false,
        layout: {
          padding:  {
            left: 0
          }
        },
        plugins: {
          title: {
            display: true,
            text: 'Owners'
          },
          legend: {
            display: true,
            position: 'right',
            // title: {
            //   display: true,
            //   text: 'Owners'
            // }
          },
        },
        scales: {
          x: {
            stacked: true,
            display: false
          },
          y: {
            stacked: true,
            display: false

          }
        }
      },
      
    }
    )
  }

}
