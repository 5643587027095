import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { PalettePipe } from '../../pipes/palette.pipe';


@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: true,
    imports: [PalettePipe]
})
export class BadgeComponent implements OnChanges {

  @Input()
  color?: string

  @HostBinding("style.--text-color")
  textColor?: string

  @HostBinding("style.--bg-color")
  bgColor?: string

  constructor() { }

  ngOnChanges() {

    if(this.color == undefined) {
      return
    }
    
  }

}
