import { Component, Input, OnChanges } from '@angular/core';
import { PalettePipe } from '../../../../pipes/palette.pipe';
import { DecimalPipe } from '@angular/common';

export interface BarData {
  values: number[]
  colors: string[]
}

@Component({
    selector: 'app-bars',
    templateUrl: './bars.component.html',
    styleUrl: './bars.component.scss',
    standalone: true,
    imports: [DecimalPipe, PalettePipe]
})
export class BarsComponent implements OnChanges {

  @Input()
  data!: BarData

  colors: string[] = []
  widths: number[] = []

  ngOnChanges() {

    // calculate total value
    const total = this.data.values.reduce((acc, cur) => acc + cur, 0)

    this.colors = this.data.colors
    this.widths = this.data.values.map(value => value * 100 / total)

  }

}
