import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { makeObservableList, ServiceEvent } from './helpers/observable-service';

import { ProjectsService } from './projects.service';
import { ProjectsToUsers } from 'annex-tracker-backend';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  currentUsers = new BehaviorSubject<ProjectsToUsers[]>([])
  loggedInUser = new BehaviorSubject<ProjectsToUsers | undefined>(undefined)

  constructor(
    private backend: BackendService,
    projectService: ProjectsService,
    authentication: AuthenticationService) {

    const projectIdQuery = projectService.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return undefined
      } else {
        return { query: { projectId: projectId } }
      }
    }))

    const projectIdFilter = projectService.projectIds.pipe(map(projectId => {
      if (projectId == null) {
        return (_: ServiceEvent<any>) => false
      } else {
        return (ws: ServiceEvent<any>) => ws.entity.projectId == projectId
      }
    }))

    makeObservableList(backend.projectsToUsers, x => Promise.resolve(x), projectIdQuery, projectIdFilter).subscribe(this.currentUsers)
    
    combineLatest([this.currentUsers, authentication.user$]).pipe(map(([projectUsers, authenticatedUser]) => {
      return projectUsers.find(projectUser => projectUser.userId == authenticatedUser?.id)
    })).subscribe(this.loggedInUser)

  }

  async leaveList() {
    const user = this.loggedInUser.value
    if (user == null) {
      throw new Error("User not logged in")
    }
    await this.backend.projectsToUsers.remove(user.id)
  }





}
