<div class="flex-1 overflow-y-auto my-scrollbar p-5">
  <table class="w-full divide-y text-sm">
    <thead>
      <tr>
        <th colspan="2" class="py-1 text-left">Reference</th>
        <th colspan="1" class="py-1 text-left">Title</th>
        <th colspan="1" class="py-1 text-left">Action</th>
      </tr>
    </thead>
    <tbody class="divide-y">
      <tr *ngFor="let mapping of docxAnalysis.mappings | async; let index = index" tabindex="-1"
        [class.bg-slate-200]="index == currentIndex" [class.bg-stripes]="index == currentIndex"
        class="hover:bg-slate-100 transition-colors cursor-pointer" (focusin)="show(mapping)">

        <!-- creation -->
        <ng-container *ngIf="mapping.leftIndex == -1">

          <td class="py-1 text-green-600">{{mapping.rightMatch?.prefix}}</td>
          <td class="py-1 text-green-600 font-medium">{{mapping.rightMatch?.reference}}</td>
          <td class="py-1 "><input type="text" [(ngModel)]="docxAnalysis.titles[index]"
              class="text-green-600 bg-transparent border-0 text-sm p-0 focus:ring-0" placeholder="Title (optional)">
          </td>
          <td class="py-1"><fa-icon class="text-green-600" [icon]="iconCreation"></fa-icon> will be created</td>

        </ng-container>

        <!-- deletion -->
        <ng-container *ngIf="mapping.rightIndex == -1">

          <td class="py-1 text-red-600 line-through">{{mapping.leftMatch?.['prefix']}}</td>
          <td class="py-1 text-red-600 line-through font-medium">{{mapping.leftMatch?.['reference']}}
          </td>
          <td class="py-1 text-red-600 line-through">{{mapping.leftWorkstream?.properties?.['name']}}</td>
          <td class="py-1"><fa-icon class="text-red-600" [icon]="iconRemoval"></fa-icon> will be removed</td>

        </ng-container>

        <!-- update / nothing to do -->
        <ng-container *ngIf="mapping.rightIndex != -1 && mapping.leftIndex != -1">

          <td class="py-1">{{mapping.leftMatch?.['prefix']}}</td>
          <td class="py-1 font-medium">{{mapping.leftMatch?.['reference']}}</td>
          <td class="py-1">{{mapping.leftWorkstream?.properties?.['name']}}</td>
          <ng-container *ngIf="mapping.leftMatch?.reference == mapping.rightMatch?.reference">
            <td class="py-1 text-slate-400">
              <fa-icon [icon]="iconCheck"></fa-icon> no action required
            </td>
          </ng-container>

          <ng-container *ngIf="mapping.leftMatch?.reference != mapping.rightMatch?.reference">
            <td class="py-1">
              <fa-icon class="text-amber-400" [icon]="iconNewReference"></fa-icon> will be known as
              {{mapping.rightMatch?.reference}}
            </td>
          </ng-container>

        </ng-container>

      </tr>
    </tbody>
  </table>

</div>

<div class="flex-1 py-1 overflow-y-auto my-scrollbar relative">
  <section *ngIf="(docxAnalysis.email$ | async) != null">
    <header class="py-2">Related Email</header>

    <ul>
      <li app-mail-preview [mail]="(docxAnalysis.email$ | async)!"
        [currentWorkstreamId]="workstreamsService.currentWorkstreamId | async"
        [isLast]="true" (fileClicked)="fileClicked($event)">
      </li>
    </ul>
  </section>
  
  <section class="p-5">
    <header class="text-sm font-medium">Comparison</header>

    <div class="p-2 bg-slate-100 whitespace-pre-line text-sm" [innerHTML]="leftString | diff:rightString"></div>
  </section>
  <!-- <div class="absolute inset-0"> -->
    <router-outlet></router-outlet>
  <!-- </div> -->
  
</div>