<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-24 w-auto" src="/assets/logos/annex-tracker.textmark.svg">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset password</h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" (submit)="resetPassword()">
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">New password</label>
                    <div class="mt-1">
                        <input appAutoFocus [(ngModel)]="newPassword" id="password" name="password" type="password"
                            autocomplete="new-password" required
                            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    </div>
                    <app-password-strength [password]="newPassword"></app-password-strength>
                </div>

                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Confirm new password</label>
                    <div class="mt-1">
                        <input [(ngModel)]="confirmPassword" id="password-repeat" name="password-repeat" type="password"
                            autocomplete="new-password" required
                            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    </div>
                </div>
                <div>
                    <button type="submit" [attr.disabled]="((newPassword == null) || (newPassword != confirmPassword)) ? true : null"
                        class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-500">Set password</button>
                </div>
            </form>
        </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" *ngIf="sent">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-sm prose">
            <p>
                Thanks, we've sent you an email with a link to reset your password. If you don't see it, please check
                your spam folder.
            </p>
            <p>
                You can close this tab now.
            </p>
        </div>
    </div>
</div>