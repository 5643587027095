<div class="flex gap-1">
    <span *ngFor="let ws of workstreams" class="bg-slate-200 rounded-full px-1 text-xs" [attr.title]="ws?.emailToWorkstream?.reason">
        {{ws.properties?.['reference']}} {{ws.properties?.['name']}}
    </span>
</div>
<div class="relative inline-block" (click)="open = false">

    <div class="cursor-pointer p-2 hover:bg-gray-50" (click)="open = !open;$event.stopPropagation()">
        +
    </div>
    <div *ngIf="open"
        class="transition ease-out duration-100 absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <span *ngFor="let ws of workstreamsService.workstreams$ | async"
                (click)="workstreamClicked(ws)" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem" tabindex="-1" id="menu-item-0">{{ws.properties?.['reference']}} {{ws.properties?.['name']}}</span>
        </div>
    </div>

</div>