import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { ActiveModal, ModalService } from 'src/app/modal/modal.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BackendService } from 'src/app/services/backend.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { DeleteUserModalComponent } from '../delete-user-modal/delete-user-modal.component';
import { AvInfo, TwoFa } from 'annex-tracker-backend';
import { ActivateTwoFaComponent } from './user-profile-modal/activate-two-fa/activate-two-fa.component';
import { environment } from 'src/environments/environment';
import { ChangelogComponent } from 'src/app/pages/changelog/changelog.component';
import { ButtonDangerComponent } from '../../../common/tailwind/button-danger/button-danger.component';
import { ButtonSecondaryComponent } from '../../../common/tailwind/button-secondary/button-secondary.component';
import { NgIf } from '@angular/common';
import { ImanageConnectComponent } from './imanage-connect/imanage-connect.component';

@Component({
    selector: 'app-user-profile-modal',
    templateUrl: './user-profile-modal.component.html',
    styleUrls: ['./user-profile-modal.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonSecondaryComponent, ButtonDangerComponent, ImanageConnectComponent]
})
export class UserProfileModalComponent implements OnInit {

  email?: string = '';
  iconClose = faClose

  avInfo?: AvInfo
  twoFa?: TwoFa

  environment = environment

  constructor(
    private modalService: ModalService,
    private auth: AuthenticationService,
    private router: Router,
    private backend: BackendService,
    private authService: AuthenticationService
  ) { }


  async changePassword() {
    try {
      await this.modalService.openModal(ChangePasswordModalComponent)
    } catch { }
  }

  async ngOnInit() {

    const user = await this.auth.awaitableUser()
    this.email = user.email

    this.avInfo = await this.backend.avInfo.find()

    this.twoFa = await this.auth.getCurrentTwoFa()
  }

  async deleteUserModal() {
    try {
      const res = await this.modalService.openModal(DeleteUserModalComponent)
      const user = await this.auth.awaitableUser()
      if (res == 'logout') {
        await this.backend.users.remove(user.id)
        await this.auth.logout()
        await this.router.navigateByUrl('/')
      }
    } catch { }
  }

  async logout() {

    await this.authService.logout()
    await this.router.navigateByUrl('/')
  }

  async activateTwoFa() {
    try {
      await this.modalService.openModal(ActivateTwoFaComponent)
    } catch {
      // no op
    } finally {
      console.log("refreshing 2fa")
      this.twoFa = await this.auth.getCurrentTwoFa()
    }


  }

  async showChangelog() {
    try {
      await this.modalService.openModal(ChangelogComponent)
    } catch {
      // no op
    }
  }

}
