import { Component } from '@angular/core';
import { ButtonPrimaryComponent } from '../button-primary/button-primary.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-button-danger',
    templateUrl: './button-danger.component.html',
    styleUrls: ['./button-danger.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FaIconComponent]
})
export class ButtonDangerComponent extends ButtonPrimaryComponent {

}
