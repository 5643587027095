import { Component } from '@angular/core';

@Component({
  selector: 'app-column-new',
  standalone: true,
  imports: [],
  templateUrl: './column-new.component.html',
  styleUrl: './column-new.component.scss'
})
export class ColumnNewComponent {

}
