<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">New Annex List</div>
    <dl>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Project title</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input appAutoFocus [selectAll]="true" [(ngModel)]="projectName" type="text" name="name" maxlength="20"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Annex List 1">
            </dd>
        </div>
        
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Main documents</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200" *ngIf="files">
                    <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm" *ngFor="let file of files">
                        <div class="flex w-0 flex-1 items-center">

                            <fa-icon class="h-5 w-5 flex-shrink-0 text-gray-400" [icon]="iconFile"></fa-icon>
                            <span class="ml-2 w-0 flex-1 truncate">{{file.name}}</span>
                        </div>
                        <span class="cursor-pointer" (click)="removeFile(file)">
                            Remove
                        </span>

                    </li>
                </ul>
                <ul *ngIf="!files" class="cursor-pointer mt-2 rounded-md border border-dashed border-gray-200" (click)="fileUpload.click()">
                    <li class="flex justify-center py-3 pl-3 pr-4 text-sm">
                        <span>
                            <fa-icon class="h-5 w-5 flex-shrink-0 text-gray-400" [icon]="iconUpload"></fa-icon>
                            <span class="ml-2 w-0 flex-1 truncate">Upload file</span>
                            <input #fileUpload (change)="onFileChanged($event)"
                                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                type="file" class="hidden">
                        </span>
                    </li>
                </ul>
                <ul *ngIf="!files">
                    <a id="example-file" class="pt-4 text-gray-600 cursor-pointer float-right text-xs hover:underline" (click)="useTestFile()">Use our example</a>
                </ul>
            </dd>
        </div>

        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 pb-5">
            <dt class="text-sm font-medium text-gray-500">Tracker language</dt>
            <app-language-selector [(language)]="language"></app-language-selector>
        </div>

        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Cover page</dt>
            <app-cover-page-selector [(currentCoverPage)]="currentCoverPage"></app-cover-page-selector>
        </div>

        <div class="flex gap-2 justify-end px-4 py-5">
            <app-button-secondary [loading]="submitted" [disabled]="submitted"
                (click)="close()">Cancel</app-button-secondary>
            <app-button-primary id="next-button" [loading]="submitted" [disabled]="submitted"
                (click)="submit()">Next</app-button-primary>
        </div>

        <div>
            <div class="w-full bg-gray-200 h-1" *ngIf="uploadProgress">
                <div class="bg-blue-600 h-1" [style.width.%]="(uploadProgress || 0) * 100"></div>
            </div>
        </div>
    </dl>
</div>