"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // Notifications
    'draft_provided': 'Entwurf übermittelt von ${from} an ${to} per E-Mail am ${date} um ${time}.',
    'workstream_created': '${prefix} erstellt am ${date}.',
    'workstream_renamed': 'Zuvor bekannt als ${prefix} ${reference}, umbenannt am ${date}.',
    'workstream_deleted': '${prefix} gelöscht am ${date}.',
    // Column labels
    'owner_column_label': 'Zuständigkeit',
    'status_column_label': 'Status',
    'comments_column_label': 'Anmerkungen',
    'notifications_column_label': 'Benachrichtigungen',
    'print_executive_summary_header': 'Aktueller Stand',
    'reference_column_label': 'Referenz',
    'title_column_label': 'Titel',
    // Print page
    'overall_label': 'Gesamt',
    'tracker_compare_label': 'Vergleich',
    'added_label': 'Hinzugefügt',
    'removed_label': 'Entfernt',
    'renamed_label': 'Geändert',
    'sum_of_workstreams_explanation': 'Gesamtanzahl der Workstreams einschließlich "Main"',
    // Project Setup columns
    'column_owner_tbd': 'tbd',
    'column_owner_seller': 'Verkäufer',
    'column_owner_buyer': 'Käufer',
    'column_owner_notary': 'Notariat',
    'column_owner_client': 'Mandant',
    'column_status_open': 'Offen',
    'column_status_draft': 'Entwurf',
    'column_status_agreed': 'Final',
    'column_status_signed': 'Unterzeichnet',
    'column_status_notarized': 'Beurkundet',
};
