import { Component, Input, inject } from '@angular/core';
import { Columns } from 'annex-tracker-backend';
import { ColumnsService } from 'src/app/services/columns.service';

@Component({
  selector: 'app-column',
  standalone: true,
  imports: [],
  templateUrl: './column.component.html',
  styleUrl: './column.component.scss'
})
export class ColumnComponent {

  @Input() column!: Columns

  columnService = inject(ColumnsService)

  toggle() {
    this.columnService.setColumnHidden(this.column.id, !this.column.hidden)
  }

}
