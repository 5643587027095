import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { lastValueFrom } from 'rxjs';
import { ActiveModal } from 'src/app/modal/modal.service';

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
    standalone: true
})
export class ChangelogComponent implements OnInit {

  html?: SafeHtml

  constructor(
    private httpClient: HttpClient, 
    private domSanitizer: DomSanitizer,
    public activeModal: ActiveModal
  ) {
    
  }

  async ngOnInit() {
    const md = await lastValueFrom(this.httpClient.get('assets/changelog.md', { responseType: 'text'}))

    const parsedMarkdown = await marked.parse(md)

    this.html = this.domSanitizer.bypassSecurityTrustHtml(parsedMarkdown)

  }

}
