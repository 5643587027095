import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Contact } from 'annex-tracker-backend';
import { ownerOptions } from 'src/app/multi-column/list-detail/doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { ColorService } from 'src/app/services/color.service';

@Component({
    selector: 'app-email-contact-circle',
    templateUrl: './email-contact-circle.component.html',
    styleUrls: ['./email-contact-circle.component.scss'],
    standalone: true
})
export class EmailContactCircleComponent implements OnChanges {

  @Input()
  contact?: Contact

  @HostBinding('style.--bg-color')
  bgColor?: string

  @HostBinding('style.--text-color')
  textColor?: string

  initials: string = ''

  ownerOptions = ownerOptions

  constructor(private colorService: ColorService) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['contact']) {
      this.initials = this.contact?.name.split(' ').map(word => word[0]).join('').toUpperCase() ?? ''

      if (this.initials.length < 2) {
        this.initials = this.contact?.email[0].toUpperCase().slice(0, 1) ?? ''
      }

      const color = ownerOptions.find(option => this.contact?.domain == option.value)
      this.bgColor = this.colorService.getColor(color?.color ?? 'gray', 100)
      this.textColor = this.colorService.getColor(color?.color ?? 'gray', 900)
      
    }
  }


}
