import { Pipe, PipeTransform } from '@angular/core';
import resolveConfig from 'tailwindcss/resolveConfig';
// @ts-ignore
import tailwindConfig from '../../../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig)

@Pipe({
    name: 'palette',
    standalone: true,
})
export class PalettePipe implements PipeTransform {

  colors = fullConfig.theme!["colors"] as {
    [key: string]: {
      [key: number]: string
    }
  }

  bgColors = fullConfig.theme!["backgroundColor"]! as {
    [key: string]: {
      [key: number]: string
    }
  }

  transform(color: string | undefined, ...args: number[]): string {

    if (color == undefined) {
      color = "gray"
    }

    let shade = 500
    if (args.length == 0) {
      return this.colors[color][shade]
    } else {
      shade = args[0]
    }

    return this.colors[color][shade]
  }

}
