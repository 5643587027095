<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">You're about to delete the following label</div>
    <div class="p-5">
        <app-badge [color]="config.color">{{config.label}}</app-badge>
    </div>

    <div class="p-5 flex justify-between">
        <app-button-secondary (click)="activeModal.dismiss('cancel')">Cancel</app-button-secondary>
        <app-button-danger (click)="activeModal.close('delete')">Delete</app-button-danger>
    </div>

</div>