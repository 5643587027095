<div class="divide divide-y">

    <div class="p-5">
        <h1>2-Factor-Authentication</h1>
    </div>

    <div *ngIf="currentTwoFa?.enabled; else: otherTemplate" class="p-5 text-sm text-gray-800">
        Two Factor Authentication is enabled. If you'd like to disable it, enter your 6-digit code from the
        Authenticator App
        <div class="flex justify-center mt-4">
            <ng-otp-input class="mx-auto" (onInputChange)="code = $event; checkCodeDisable()"
                [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
        </div>

    </div>

    <ng-template #otherTemplate>

        <div class="p-5">

            <p class="text-sm text-gray-800">
                Scan the QR-Code with your Authenticator App and enter the 6-digit code to activate 2FA
            </p>
            <img class="mx-auto" [src]="qrCodeDataUrl">

            <div class="flex justify-center mt-4">
                <ng-otp-input class="mx-auto" (onInputChange)="code = $event; checkCodeActivate()"
                    [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
            </div>

        </div>

    </ng-template>
    <div class="p-5 flex justify-end">
        <app-button-secondary (click)="activeModal.dismiss()">Cancel</app-button-secondary>
    </div>
</div>