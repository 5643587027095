import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ColumnsService } from 'src/app/services/columns.service';
import { ColumnComponent } from './column/column.component';

@Component({
  selector: 'app-select-column',
  standalone: true,
  imports: [AsyncPipe, JsonPipe, ColumnComponent],
  templateUrl: './select-column.component.html',
  styleUrl: './select-column.component.scss'
})
export class SelectColumnComponent {

  columnsService = inject(ColumnsService)


}
