import { HttpClient, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Id, Paginated } from '@feathersjs/feathers';
import { lastValueFrom, Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { ProjectsService } from './projects.service';
import { blobify } from './blobify';
import { FilePart, File as FileRecord } from 'annex-tracker-backend';

export interface FileUpload {
  file?: File
  id?: Id
  type?: string
  transmittedBytes: number
  progress?: number,
  fileRecord?: FileRecord
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private backend: BackendService,
    private httpClient: HttpClient,
    private auth: AuthenticationService,
    private projectService: ProjectsService
  ) { }

  /**
   * Uploads a file to the current project.
   * 
   * @param file The file to upload
   * @returns The file record promise
   */
  async simpleUpload(file: File): Promise<FileRecord> {
    
    // get current project id
    const projectId = await this.projectService.getCurrentProjectId()

    if (projectId == null) throw new Error("This upload only works if there is a currently active project")

    const descriptor = await lastValueFrom(this.upload(file, null, projectId))

    return descriptor.fileRecord!
  }

  /**
   * 
   * @param file The file to upload
   * @returns An observable that emits the upload progress
   */
  upload(file: File, workstreamId: number | null, projectId?: number, userId?: number) {

    // Return an observable that will emit the upload progress of the file. Important: 
    // this observable is "shared" (see end of method) so that multiple subscribers can
    // subscribe to it without making multiple uploads.
    return new Observable<FileUpload>(subscribe => {

      const fileDescriptor = {
        file,
        transmittedBytes: 0,
        progress: 0,
        key: "",
      } as FileUpload

      // That's a self invoking function so that the subscriber is not asynchronous
      (async () => {

        // If no userId given, get the current user
        if (userId == null) {
          let user = await this.auth.awaitableUser()
          userId = user.id
        }

        // if no projectId given, get the current project
        if (projectId == null) {
          const res = await this.projectService.getCurrentProjectId()
          if (res == null) {
            throw "No project selected"
          }
          projectId = res
        }

        // Create a new file entry in the database. Because we also specify the size, 
        // file parts are also created automatically that we can retrieve after.
        const newFile = await this.backend.files.create({
          filename: file.name!,
          type: file.type,
          size: file.size,
          workstreamId,
          projectId,
          userId,
          emailId: null
        })

        fileDescriptor.id = newFile.id
        fileDescriptor.fileRecord = newFile

        // File parts have been created as a result of creating a file with a known size.
        // We can now upload the file parts to S3 using presigned URLs.
        const fileParts = (await this.backend.fileParts.find({ query: { fileId: newFile.id } }) as Paginated<FilePart>)

        const fileWiseProgress = fileParts.data.map(() => 0)

        // Emit file upload state right away to the subscriber so we can show this in the UI.
        subscribe.next(fileDescriptor)

        const stream = file.stream()

        let partCounter = 0
        for await (const blob of blobify(stream)) {
          const thisCount = partCounter;

          const event = await lastValueFrom(this.httpClient.put(fileParts.data[partCounter].presignedPartUrl, blob, {
            reportProgress: true,
            observe: 'events'
          }).pipe(tap({
            next: async event => {
              if (event.type == HttpEventType.UploadProgress) {
                const e = event as HttpProgressEvent
                fileWiseProgress[thisCount] = e.loaded
                const totalProgress = fileWiseProgress.reduce((a, b) => a + b, 0)

                let newDescriptor = Object.assign(
                  {},
                  fileDescriptor,
                  {
                    progress: totalProgress / file.size * 100,
                    transmittedBytes: totalProgress,
                  })
                this.backend.files.patch(newFile.id, { progress: newDescriptor.progress }).then(file => {
                  newDescriptor.fileRecord = file
                })
                subscribe.next(newDescriptor)
              }
            },
            complete: () => { },
            error: err => console.log(err)
          })
          ))

          // the last event should be the response. So let's wait for that and patching the file part
          // before we start the new upload
          if (event.type == HttpEventType.Response) {
            const e = event as HttpResponse<any>
            const ETag = e.headers.get('ETag')!
            await this.backend.fileParts.patch(fileParts.data[thisCount].id, {
              ETag,
            })
          }

          partCounter++;
        }

        subscribe.complete()
      })()

      return () => {
        // Teardown logic
      }
    }).pipe(
      share() // We don't want to upload this multiple times when subscribed multiple times
    )

  }
}
