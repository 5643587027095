import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'button[app-toggle]',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    standalone: true
})
export class ToggleComponent {

  @Input()
  enabled = false

  @Output()
  enabledChange = new EventEmitter<boolean>()

  @HostBinding("class")
  classes = "group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

  @HostListener("click")
  onClick() {
    this.enabled = !this.enabled
    this.enabledChange.next(this.enabled)
  }

}
