<div class="flex w-full flex-col items-center overflow-x-hidden h-full bg-slate-50">
    <div class="flex flex-shrink-0 items-center p-2">
        <img class="h-12 w-auto" src="assets/logos/annex-tracker.textmark.svg">
    </div>
    <div class="w-full flex-1 min-h-0 overflow-y-auto flex flex-col gap-2 px-3 py-2 pt-6 my-scrollbar">

        <div class="relative flex justify-between items-center">
            <span class="font-medium text-gray-800">Lists</span>
            <span id="add-list-button" (click)="showModal()"
                class="bg-indigo-600 px-1.5 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 rounded-lg cursor-pointer">
                <fa-icon [size]="'sm'" class="text-white" [icon]="iconAdd"></fa-icon>
            </span>
        </div>

        <a *ngFor="let project of projects.activeProjects | async" [routerLink]="[project.id]"
            [routerLinkActive]="'bg-gray-200 text-gray-800 group-active'"
            class="cursor-pointer text-gray-800 hover:bg-gray-200 hover:text-gray-900 group w-full p-2 rounded-md flex flex-col items-start text-xs font-medium relative group">
            <span>{{project.name}}</span>
            <span class="my-auto px-2 right-0 text-gray-500 hover:text-gray-900 absolute" [class.invisible]="projects.currentProject?.id != project.id" (click)="showListSettings()">
                <fa-icon [icon]="iconDots"></fa-icon>
            </span>
        </a>

    </div>
    <div class="p-4 w-full">
        <div (click)="openUserProfile()"
            class="truncate text-xs font-medium w-full cursor-pointer text-gray-600 hover:text-gray-900">
            <fa-icon size="xl" [icon]="iconUser"></fa-icon> Your profile
        </div>
    </div>
</div>