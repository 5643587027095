<div class="relative z-10 ease-out duration-300 transition-opacity" 
  [class.pointer-events-none]="!active"
  [class.opacity-0]="!active"
  [class.opacity-100]="active"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <!--
      Background backdrop, show/hide based on modal state.
  
      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <!--
          Modal panel, show/hide based on modal state.
  
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div [class.bg-green-300]="isEmail" (dragleave)="internalDragleave($event)" (dragenter)="internalDragEnter($event)" [class.bg-white]="!isEmail"
          class="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div class="mx-auto flex h-12 w-12 items-center justify-center">
              <fa-icon [icon]="iconEmail" [size]="'4x'"></fa-icon>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Drop Email here</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Try files with an .eml extension. Usually, you can just drag and email
                  out of your email program onto the desktop and drag it here after that.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>