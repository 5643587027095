<div class="relative inline-block text-left" #dropdownParent>
  <div (click)="dropdownService.toggle(dropdownTemplate, dropdownParent, $event)">
    <a class="cursor-pointer block">
      <app-badge [color]="currentDescription?.color">{{currentDescription?.label || value}}</app-badge>
    </a>
  </div>

</div>

<ng-template #dropdownTemplate>
  <div class="rounded-md bg-white shadow-lg border focus:outline-none py-1">
        <a (click)="setBadge(null)" class="cursor-pointer text-center text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100">
            <app-badge [color]="'gray'">-</app-badge>
        </a>
        <a *ngFor="let badge of badges" (click)="setBadge(badge)" class="cursor-pointer text-center text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100">
            <app-badge [color]="badge.color">{{badge.label}}</app-badge>
        </a>
        
    </div>
</ng-template>