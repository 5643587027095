<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-24 w-auto" src="/assets/logos/annex-tracker.textmark.svg">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Forgot password</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
            Or
            <a routerLink="/signin" class="font-medium text-indigo-600 hover:text-indigo-500">sign-in</a>
          </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" *ngIf="!sent">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" (submit)="createForgotPasswordRequest()">
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
                    <div class="mt-1">
                        <input appAutoFocus [(ngModel)]="email" id="email" name="email" type="email"
                            autocomplete="email" required
                            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                    <button type="submit"
                        class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Send
                        reset email</button>
                </div>
            </form>
        </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" *ngIf="sent">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-sm prose">
            <p>
                Thanks, we've sent you an email with a link to reset your password. If you don't see it, please check
                your spam folder.
            </p>
            <p>
                You can close this tab now.
            </p>
        </div>
    </div>
</div>