<div class="p-5" *ngIf="column">
    <label class="block text-sm font-medium text-gray-700">Dropdown elements</label>

    <div class="mt-1 flex"
        *ngFor="let element of column.configuration; let index = index; let first = first; let last = last; trackBy: trackBy">
        <div class="shrink flex items-center px-2 text-gray-600">
            <button (click)="move(index, -1)" [attr.disabled]="first ? 'true': null"
                class="disabled:text-gray-300 disabled:hover:text-gray-300">
                <fa-icon [icon]="iconUp"></fa-icon>
            </button>
            <button (click)="move(index, 1)" [attr.disabled]="last ? 'true': null"
                class="disabled:text-gray-300 disabled:hover:text-gray-300">
                <fa-icon [icon]="iconDown"></fa-icon>
            </button>
        </div>
        <div class="flex flex-1 rounded-md border border-gray-300 items-stretch focus-within:border-blue-500 divide-x">
            <input #labelInput type="text" (blur)="changeLabel(labelInput.value, index)" [ngModel]="element.label" name="label"
                class="flex-1 rounded-md border-none block appearance-none px-3 py-2 placeholder-gray-400 sm:text-sm focus:ring-0">
            <div class="text-sm px-2 font-medium flex items-center">
                <app-color-selector (colorChange)="changeColor($event, index)"
                    [color]="element.color"></app-color-selector>
            </div>
        </div>
        <div (click)="removeElement(index)" class="shrink flex items-center px-2 cursor-pointer text-gray-500 hover:text-gray-900">
            <fa-icon size="sm" [icon]="iconDelete"></fa-icon>
        </div>
    </div>

    <div class="mt-4 flex" *ngIf="addElementMode; else elseTemplate">
        <div class="shrink flex items-center justify-center px-2 text-green-500 hover:text-green-800 w-12">
            <button (click)="addElement($event)" [attr.disabled]="(newElementName.length > 0) ? null : ''" class="disabled:text-gray-400 cursor-pointer disabled:cursor-not-allowed">
                <fa-icon [icon]="iconAdd"></fa-icon>
            </button>
        </div>
        <div class="flex flex-1 rounded-md border border-gray-300 items-stretch focus-within:border-blue-500 divide-x">
            <input #labelInput type="text" [(ngModel)]="newElementName" placeholder="New element" name="label" tabindex="1" (keydown.enter)="addElement($event, true)" appAutoFocus
                class="flex-1 block rounded-md appearance-none border-none px-3 py-2 placeholder-gray-400 sm:text-sm focus:ring-0">
            <div class="text-sm px-2 font-medium flex items-center">
                <app-color-selector 
                    [(color)]="newElementColor"></app-color-selector>
            </div>
        </div>
        <div (click)="addElementMode = false" class="shrink flex items-center px-2 cursor-pointer text-gray-500 hover:text-gray-900">
            <fa-icon size="sm" [icon]="iconDelete"></fa-icon>
        </div>

    </div>
    <ng-template #elseTemplate>
        <div class="text-blue-600 text-sm p-4 hover:underline cursor-pointer flex justify-center"
            (click)="addElementMode = true">
            Add new element
        </div>
    </ng-template>

</div>