import { BadgeDescription } from "../../../../../common/badge-dropdown/badge-dropdown.component"

export const ownerOptions: BadgeDescription[] = [
  {
    color: 'gray',
    label: 'tbd',
    value: 'tbd'
  }, {
    color: 'green',
    label: 'Seller',
    value: 'seller'
  }, {
    color: 'blue',
    label: 'Buyer',
    value: 'buyer'
  }, {
    color: 'purple',
    label: 'Notary',
    value: 'notary'
  }, {
    color: 'yellow',
    label: 'Client',
    value: 'client'
  }
]

export const statusOptions: BadgeDescription[] = [{
  color: 'orange',
  label: 'Open',
  value: 'open'
}, {
  color: 'gray',
  label: 'In\xa0progress',
  value: 'draft'
}, {
  color: 'emerald',
  label: 'Agreed',
  value: 'agreed'
}, {
  color: 'purple',
  label: 'Signed',
  value: 'signed'
}, {
  color: 'indigo',
  label: 'Notarized',
  value: 'notarized'
}]