import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleDown, faCodeCompare, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { DropdownOutletService } from 'src/app/common/dropdown-outlet.service';
import { ExportedTrackersService } from 'src/app/services/exported-trackers.service';

@Component({
    selector: 'app-export-compare-button',
    templateUrl: './export-compare-button.component.html',
    styleUrls: ['./export-compare-button.component.scss'],
    standalone: true
})
export class ExportCompareButtonComponent {
  iconAngle = faAngleDown
  iconDownload = faDownload
  iconCompare = faCodeCompare

  @Output()
  exportXLSX = new EventEmitter<void>()

  constructor(public dropdown: DropdownOutletService, public exportService: ExportedTrackersService, private router: Router) { }

  async openExportModal() {
    console.log("navigate to modal")
    this.router.navigate([{ outlets: { modal: ['center-panel', 'exports']}}])
  }
}
