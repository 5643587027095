import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-button-primary',
    templateUrl: './button-primary.component.html',
    standalone: true,
    imports: [NgClass, NgIf, FaIconComponent]
})
export class ButtonPrimaryComponent {

  iconLoading = faSpinner

  @Input()
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs' = 'xs'

  @Input()
  loading = false;

  @Input()
  disabled?: boolean

}
