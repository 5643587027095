import { Component } from '@angular/core';
import { ActiveModal } from 'src/app/modal/modal.service';
import { ButtonPrimaryComponent } from '../../../common/tailwind/button-primary/button-primary.component';
import { ButtonDangerComponent } from '../../../common/tailwind/button-danger/button-danger.component';
import { ButtonSecondaryComponent } from '../../../common/tailwind/button-secondary/button-secondary.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-delete-user-modal',
    templateUrl: './delete-user-modal.component.html',
    styleUrls: ['./delete-user-modal.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonSecondaryComponent, ButtonDangerComponent, ButtonPrimaryComponent]
})
export class DeleteUserModalComponent {

  message?: string

  constructor(public activeModal: ActiveModal) {}

  async deleteProfile() {
    this.message = "Your profile was flagged for deletion. We'll get in touch with you."
  }

}
