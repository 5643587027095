<ng-template #template>
    <div class="flex gap-4 items-center">

        <app-list-email-display></app-list-email-display>

        <div class="flex items-center">
            <span class="mr-2 text-sm text-gray-500" id="annual-billing-label">
                Show removed
            </span>
            <button app-toggle [(enabled)]="showDeleted"></button>
        </div>

        <app-add-main-button (fileSelected)="addMain($event)"></app-add-main-button>

        <app-export-compare-button></app-export-compare-button>

        <app-zip-export></app-zip-export>

    </div>
</ng-template>

<div class="flex items-stretch h-full divide-x">
    <main class="flex-1 flex flex-col h-full">

        <div class="p-0 pt-0 flex-1 overflow-y-auto my-scrollbar relative">
            <app-ws-table #wstable (workstreamSelected)="wsSelected($event)" [showDeleted]="showDeleted"
                [autoselect]="false" [editable]="true">
            </app-ws-table>
        </div>

    </main>
    <router-outlet></router-outlet>
</div>