import { Component, HostListener } from '@angular/core';
import { ModalOutletComponent } from './modal/modal-outlet/modal-outlet.component';
import { DropOverlayComponent } from './modals/drop-overlay/drop-overlay.component';
import { DropdownOutletComponent } from './common/dropdown-outlet/dropdown-outlet.component';
import { RouterOutlet } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, DropdownOutletComponent, DropOverlayComponent, ModalOutletComponent]
})
export class AppComponent {

  constructor() {

    registerLocaleData(localeDe, 'de', localeDeExtra)

  }

  dropActive = false

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    console.log(`dragenter includes files: ${event.dataTransfer?.types?.includes('Files')}`)

    if (event.dataTransfer?.types?.includes('Files')) {
      this.dropActive = true
    }
    
  }
  
}
