import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BackendService } from 'src/app/services/backend.service';
import { NgIf } from '@angular/common';
import { PasswordStrengthComponent } from '../../../password-strength/password-strength.component';
import { AutoFocusDirective } from '../../../common/auto-focus.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss'],
    standalone: true,
    imports: [FormsModule, AutoFocusDirective, PasswordStrengthComponent, NgIf]
})
export class ResetComponent {

  sent = false

  newPassword?: string
  confirmPassword?: string

  token?: string

  constructor(
    activatedRoute: ActivatedRoute, 
    private backend: BackendService,
    private router: Router,
    private auth: AuthenticationService) {
    activatedRoute.paramMap.pipe(map(params => params.get('token'))).subscribe(token => {
      if (token) {
        this.token = token
      }
    })
  }

  async resetPassword() {

    if (this.newPassword == null) {
      return
    }
    
    try {
      const removed = await this.backend.passwordReset.remove(this.token!, {
        query: {
          newPassword: this.newPassword
        }
      })

      this.auth.authenticate({
        email: removed.email,
        password: this.newPassword
      })
      this.router.navigate(['lists'])
    } catch (e) {
      console.log(e)
    }
    

  }

}
