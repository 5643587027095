<div class="flex px-4 py-4 items-start">
    <div class="flex gap-4 min-w-0 flex-1 flex-col md:flex-row">
        <div>
            <p class="flex text-sm text-gray-500">
                <span class="truncate"><app-badge [color]="fromColor">{{mail.actor}}</app-badge></span>
                <fa-icon class="mx-2" [icon]="iconTo"></fa-icon>
                <span class="truncate"><app-badge [color]="toColor">{{mail.to}}</app-badge></span>
            </p>
        </div>
        <div>
            <p class="text-sm text-gray-900">
                {{mail.subject}}
            </p>
            <p class="mt-2 flex items-center text-sm text-gray-500 whitespace-pre-wrap"
                [innerText]="mail.text">
            </p>
            <ul class="mt-2 border-t pt-2">
                <li *ngFor="let attachment of attachments" class="text-sm text-gray-500">
                    <app-file-icon [file]="attachment"></app-file-icon> {{attachment.filename}}
                </li>
            </ul>
        </div>
    </div>

    <button (click)="sendMail.emit()" type="button"
        class="rounded-full bg-white py-1.5 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <span class="mr-2" *ngIf="mail.sent"><fa-icon class="text-green-400"
                [icon]="iconCheck"></fa-icon></span>Send
    </button>

</div>