<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">Debug Info</div>
    <div class="p-5 py-2">
        <a class="hover:underline" [href]="txtFileUrl">TXT File Download</a>
    </div>
    <div class="p-5 py-2">
        <h1 class="cursor-pointer hover:underline" (click)="showTxt = !showTxt">Show TXT File</h1>
        @if(showTxt) {
        <pre class="text-xs max-h-50 overflow-y-auto">{{completeText}}</pre>
        }
    </div>
    <div class="p-5 py-2">
        <h1 class="cursor-pointer hover:underline" (click)="showMatches = !showMatches">Matches</h1>
        @if(showMatches) {
            <pre class="text-xs max-h-50 overflow-y-auto">{{references | json}}</pre>
        }
    </div>

</div>