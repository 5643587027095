import { Injectable, inject } from '@angular/core';
import { BackendService } from './backend.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ImanageService {

  private backend = inject(BackendService)
  private user = inject(AuthenticationService)

  constructor() { }

  async sendOauthCode(code: string) {

    await this.user.awaitableUser()

    console.log("sending code:", code)
    try {
      await this.backend.iManageCredentials.create({
        code
      })
    }
    catch (error) {
      console.error("Error sending code")
    }
  }

}
