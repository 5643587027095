<dialog id="printServiceDialog" style="min-width: 200px">
  <div class="row">
    <span data-l10n-id="pdfjs-print-progress-message">Preparing document for printing…</span>
  </div>
  <div class="row">
    <progress value="0" max="100"></progress>
    <span data-l10n-id="pdfjs-print-progress-percent" data-l10n-args='{ "progress": 0 }' class="relative-progress">0%</span>
  </div>
  <div class="buttonRow">
    <button id="printCancel" class="dialogButton" type="button">
      <span data-l10n-id="pdfjs-print-progress-close-button">Cancel</span>
    </button>
  </div>
</dialog>
