import { Component, OnDestroy } from '@angular/core';
import { faRight } from '@fortawesome/pro-regular-svg-icons';
import { Workstream } from 'annex-tracker-backend';
import { map, Observable, Subscription } from 'rxjs';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { Match } from 'src/app/services/model/docx-analysis';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical'
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, JsonPipe, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
    standalone: true,
    imports: [NgFor, FaIconComponent, JsonPipe, DecimalPipe]
})
export class PreviewComponent implements OnDestroy {

  iconRight = faRight

  subscription?: Subscription

  affectedWorkstreams$: Observable<HWorkstream[] | undefined>

  myLeftMatch?: Match
  myRightMatch?: Match

  constructor(
    public docxAnalysisService: DocxAnalysisService,
    public workstreamService: WorkstreamsService
  ) { 

    this.affectedWorkstreams$ = workstreamService.currentWorkstream.pipe(map(workstreams => workstreams?.children))

  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

}
