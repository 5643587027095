import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCrown, faUserSlash, faX } from '@fortawesome/pro-regular-svg-icons';
import { ProjectsToUsers } from 'annex-tracker-backend';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/modal/modal.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BackendService } from 'src/app/services/backend.service';
import { validateEmail } from 'src/app/services/helpers/validate-email';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import { DeleteListModalComponent } from '../../delete-list-modal/delete-list-modal.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BadgeComponent } from '../../../common/badge/badge.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-collaboration',
    templateUrl: './collaboration.component.html',
    styleUrls: ['./collaboration.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, BadgeComponent, FaIconComponent, AsyncPipe]
})
export class CollaborationComponent implements OnInit {

  iconRemoveUser = faUserSlash
  iconLeaveList = faX
  iconCreator = faCrown

  invitee?: string

  inviteePlaceholder?: string

  isMail = validateEmail

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private backend: BackendService,
    public usersService: UsersService,
    private projectService: ProjectsService, 
    private modalService: ModalService,
    private router: Router) { }

  async ngOnInit() {
    const user = await this.authService.awaitableUser()

    if (user != null) {
      this.inviteePlaceholder = ['coworker', user.email.split("@")[1]].join("@")
    }
  }


  async invite() {

    try {

      await this.backend.projectsToUsers.create({
        projectId: this.projectService.currentProject!.id,
        userId: this.invitee!,
        state: 'active'
      })

      this.invitee = undefined

      this.toastr.success('Added user')

    } catch (e) {

      this.toastr.error('Failed to add user')
      console.error(e)
    }
  }

  async removeUser(user: ProjectsToUsers) {


    const res = await this.modalService.openModal(DeleteListModalComponent, 'Remove user from this tracker')

    await this.backend.projectsToUsers.remove(user.id)
    this.toastr.success('Removed user')
  }

  async leaveList() {

    const res = await this.modalService.openModal(DeleteListModalComponent, 'Leave this tracker')
    
    await this.usersService.leaveList()

    // close modal
    await this.router.navigate([{ outlets: { modal: null }}])
    // show list overview
    this.router.navigate(['/lists'])
  }

}
