<div class="divide divide-y">
    <div class="space-y-6 p-5">
        <div>
            <label for="pw-1" class="block text-sm font-medium text-gray-700">Password</label>
            <div class="mt-1">
                <input id="pw-1" [(ngModel)]="password" name="password" type="password" autocomplete="new-password" required
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            </div>
            <app-password-strength [password]="password"></app-password-strength>
        </div>
        <div>
            <label for="pw-2" class="block text-sm font-medium text-gray-700">Password (repeat)</label>
            <div class="mt-1">
                <input id="pw-2" [(ngModel)]="passwordRepeat" name="email" type="password" autocomplete="new-password" required
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            </div>
        </div>

    </div>

    <div class="p-5">
        <app-button-primary [disabled]="!canChange" (click)="changePassword()" class="float-right">Change</app-button-primary>
        <app-button-secondary (click)="activeModal.dismiss('cancel')">Cancel</app-button-secondary>
    </div>

</div>