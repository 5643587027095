import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { faRemove } from '@fortawesome/pro-regular-svg-icons';
import { File } from 'annex-tracker-backend';
import { EmailService } from 'src/app/services/email.service';
import { MailPreviewComponent } from '../doc-list/subview/inbox/mail-preview/mail-preview.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { AutoFocusDirective } from '../../../common/auto-focus.directive';

@Component({
    selector: 'app-global-inbox',
    templateUrl: './global-inbox.component.html',
    host: {
        class: 'flex-1 min-h-0'
    },
    styleUrls: ['./global-inbox.component.scss'],
    standalone: true,
    imports: [AutoFocusDirective, NgFor, MailPreviewComponent, RouterOutlet, AsyncPipe]
})
export class GlobalInboxComponent {

  iconRemove = faRemove

  @HostListener('keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.router.navigate(['./'], {relativeTo: this.route})
  }

  constructor(
    public emailService: EmailService,
    public route: ActivatedRoute,
    public router: Router) { }

  fileClicked(file: File) {
    this.router.navigate(['file', file.id], {relativeTo: this.route})
  }

}
