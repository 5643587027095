<div class="divide divide-y">
    <div class="p-5 font-medium text-lg">Export tracker</div>
    <div class="p-5">

        <div class="space-y-5">
            <div class="relative flex items-start">
                <div class="flex h-6 items-center">
                    <input id="small" aria-describedby="small-description" name="plan" type="radio" checked value="clean"
                        [(ngModel)]="choice"
                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                </div>
                <div class="ml-3 text-sm leading-6">
                    <label for="small" class="font-medium text-gray-900">Clean</label>
                    <p id="small-description" class="text-gray-500">Shows current tracker status</p>
                </div>            
            </div>

            <ng-container *ngIf="(exportService.exports | async)?.length != 0; else disabledTemplate">

                <div class="relative flex items-start">
                    <div class="flex h-6 items-center">
                        <input [disabled]="(exportService.exports | async)?.length == 0" id="small" aria-describedby="small-description" name="plan" type="radio" value="compare"
                        [(ngModel)]="choice"
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed">
                    </div>
                    <div class="ml-3 text-sm leading-6 text-gray-900">
                        <label for="small" class="font-medium">Compare</label>
                        <p id="small-description" class="text-gray-500">Shows progress to previously exported tracker:</p>
                        <app-select-menu [disabled]="choice != 'compare'" [(selectedExport)]="selectedExport" [exports]="exportService.exports | async"></app-select-menu>
                    </div>                
                </div>
            </ng-container>

            <ng-template #disabledTemplate>
                <div class="relative flex items-start">
                    <div class="flex h-6 items-center">
                        <input [disabled]="(exportService.exports | async)?.length == 0" id="small" aria-describedby="small-description"
                            name="plan" type="radio" value="compare" [(ngModel)]="choice"
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed">
                    </div>
                    <div class="ml-3 text-sm leading-6 text-gray-300">
                        <label for="small" class="font-medium">Compare</label>
                        <p class="text-xs text-gray-500 bg-blue-100 border border-blue-200 px-1.5 py-1 rounded">
                            You can select this option as soon as you have previously exported a tracker.
                        </p>
                    </div>
                </div>
            </ng-template>

        </div>

        
    </div>

    <div class="p-5">
        
        <app-select-column></app-select-column>
    </div>
    <div class="p-5 flex justify-end gap-2">
        <app-button-secondary (click)="close()">Cancel</app-button-secondary>
        <app-button-primary [disabled]="exportService.exporting" (click)="export()">Export {{choice}}</app-button-primary>
    </div>

</div>