import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, switchMap, map, from } from 'rxjs';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { FilesService } from 'src/app/services/files.service';
import { sortDescending } from 'src/app/services/model/base-entity';
import { AnalysisResult } from 'src/app/services/model/docx-analysis';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { AsyncPipe } from '@angular/common';
import { PdfViewerComponent } from '../../pdf-viewer/pdf-viewer.component';
import { getRootWorkstream } from 'annex-tracker-backend/lib/helpers/workstreamHelpers'

@Component({
    selector: 'app-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.scss'],
    standalone: true,
    imports: [PdfViewerComponent, AsyncPipe]
})
export class ContractComponent {

  searchTerm$?: Observable<string | null>
  pdfUrl$?: Observable<string | null>
  analysisResult$?: Observable<AnalysisResult>

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workstreamsService: WorkstreamsService,
    private filesService: FilesService,
    private docxAnalysisService: DocxAnalysisService
  ) {}

  ngOnInit(): void {

    const latestFile$ = this.workstreamsService.currentWorkstream.pipe(
      switchMap(workstream => {

        if (workstream == null) {
          return from([null])
        }
        
        const rootWorkstream = getRootWorkstream(workstream)

        const basedOnAnalysis = parseInt(rootWorkstream.properties?.['basedOnAnalysis'])

        if (!isNaN(basedOnAnalysis)) {
          return this.workstreamsService.files.pipe(map(files => files.find(file => file.id == basedOnAnalysis)))
        } else {
          return this.workstreamsService.files.pipe(map(files => files.filter(file => file.workstreamId == rootWorkstream.id).sort(sortDescending)[0]))
        }
          
      }
    )) 

    this.analysisResult$ = latestFile$.pipe(
      switchMap(file => this.docxAnalysisService.getOrCreateAnalysis(file?.id || null))
    )

    this.pdfUrl$ = latestFile$.pipe(switchMap(file => {
        if (file == null) {
          return from([null])
        }
        return from(this.filesService.fileToPdfUrl(file))
      }
    ))

    this.searchTerm$ = this.workstreamsService.currentWorkstream.pipe(map(ws => `${ws?.properties?.['prefix']} ${ws?.properties?.['reference']}`))

    this.route.paramMap.pipe(
      map(params => (Number(params.get("workstreamId")) || null))).subscribe(term => console.log(term)) 
  }

  async close() {
    console.log("close")
    // navigate to parent route
    await this.router.navigate(['../../..'], { relativeTo: this.route })
  }

}
