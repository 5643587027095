<ng-container [ngSwitch]="data.organization">

    <!-- default -->
    <ng-container *ngSwitchDefault>
        <img class="h-3/4 w-full" src="assets/print/backgrounds/pexels-aleksandar-pasaric-325185_reduced.jpg">
        <div class="mt-6">
            <div class="text-2xl">{{data.project.name}}</div>
            <div class="text-base italic">{{data.secondDate | myDate}}</div>
        </div>

    </ng-container>

    <!-- Hogan Lovells -->
    <ng-container *ngSwitchCase="'hoganlovells.com'">

        <img class="h-1/4 absolute top-0 right-0" src="assets/print/organizations/hoganlovells.com/logo.svg">
        <div class="absolute bottom-1/4">
            <div class="text-6xl">{{data.project.name}}</div>
            <div class="text-lg mt-2 text-gray-600">{{data.secondDate | myDate}}</div>
        </div>

    </ng-container>

    <!-- Linklaters -->
    <ng-container *ngSwitchCase="'linklaters.com'">

        <img class="h-8 absolute top-0 right-0" src="assets/print/organizations/linklaters.com/logo.svg">
        <div class="absolute bottom-1/4">
            <div class="text-6xl">{{data.project.name}}</div>
            <div class="text-lg mt-2 text-gray-600">{{data.secondDate | myDate}}</div>
        </div>

    </ng-container>

    <!-- Ebner Stolz -->
    <ng-container *ngSwitchCase="'ebnerstolz.de'">

        <img class="h-1/4 absolute top-0 right-0" src="assets/print/organizations/ebnerstolz.de/logo.svg">
        <div class="absolute bottom-1/4">
            <div class="text-6xl">{{data.project.name}}</div>
            <div class="text-lg mt-2 text-gray-600">{{data.secondDate | myDate}}</div>
        </div>

    </ng-container>

    <!-- trlegal -->
    <ng-container *ngSwitchCase="'trlegal.de'">

        <!-- This design was easer to implement in SVG and is prepended to the resulting PDF. -->

    </ng-container>

</ng-container>