import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Email, File } from 'annex-tracker-backend';
import { map, Observable, Subscription, switchMap } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { MailPreviewComponent } from './mail-preview/mail-preview.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    host: {
        class: 'flex-1 overflow-y-auto my-scrollbar'
    },
    styleUrls: ['./inbox.component.scss'],
    standalone: true,
    imports: [NgFor, MailPreviewComponent, AsyncPipe]
})
export class InboxComponent implements OnDestroy{

  filteredMails$: Observable<Email[]>

  subscription?: Subscription

  constructor(
    mailService: EmailService,
    public workstreamsService: WorkstreamsService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.filteredMails$ = workstreamsService.currentWorkstreamId.pipe(
      switchMap(
        workstreamId => {
          
          return mailService.populatedEmails$.pipe(
            map(mails => {
              const res = mails.filter(mail => mail.workstreams?.find(ws => ws.id == workstreamId) != null)
              return res
            }))
        })
    )

  }

  fileClicked(file: File) {
    //[routerLink]="['..', {outlets: { subviewmodal: ['view', file.id]}}]"
    this.router.navigate(['..', {outlets: { subviewmodal: ['view', file.id]}}], {relativeTo: this.route})
  }

  ngOnDestroy(): void {
      this.subscription?.unsubscribe()
  }
}
