import { Component, OnInit, inject } from '@angular/core';
import { config } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-imanage-connect',
  standalone: true,
  imports: [],
  templateUrl: './imanage-connect.component.html',
  styleUrl: './imanage-connect.component.scss',
  host: {
    class: 'block'
  }
})
export class ImanageConnectComponent implements OnInit {

  configService = inject(ConfigService)

  iManageLoginUrl?: string

  async ngOnInit() {
      const config = await this.configService.awaitableConfig()
      console.log(config)

      this.iManageLoginUrl = `${config.iManage.authorizationURL}?client_id=${config.iManage.clientID}&response_type=code&redirect_uri=${config.iManage.callbackURL}`
  }

}
