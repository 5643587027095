import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowDown, faArrowUp, faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { File } from 'annex-tracker-backend';
import { Subscription, filter, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { DocxAnalysisService } from 'src/app/services/docx-analysis.service';
import { FilesService } from 'src/app/services/files.service';
import { Match } from 'src/app/services/model/docx-analysis';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { PdfViewerComponent } from '../../list-detail/doc-list/pdf-viewer/pdf-viewer.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CheckInsertPositionComponent } from './check-insert-position/check-insert-position.component';
import { SelectAnalysisLanguageComponent } from './select-analysis-language/select-analysis-language.component';
import { LanguageSelectorComponent } from 'src/app/common/language-selector/language-selector.component';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical';

@Component({
    selector: 'app-add-main-modal',
    templateUrl: './add-main-modal.component.html',
    host: {
        class: 'h-full'
    },
    styleUrls: ['./add-main-modal.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent, NgFor, PdfViewerComponent, AsyncPipe, CheckInsertPositionComponent, SelectAnalysisLanguageComponent, LanguageSelectorComponent]
})
export class AddMainModalComponent implements OnDestroy {

  iconLoading = faSpinnerThird
  iconNew = faPlus
  iconUp = faArrowUp
  iconDown = faArrowDown

  matches?: Match[]

  pdfUrl: string | null = null
  searchTerm?: string

  step: 'check-insert-position' | 'select-language' = 'select-language'

  insertItems?: {
    label: string
    index: number | null
    id: number | null
    hasControls: boolean
  }[]

  loading = false

  subscription?: Subscription

  selectedLanguage?: 'de' | 'en'

  analysis: any

  languageStrings = {
    "de": "German",
    "en": "English"
  } as { [key: string]: string }

  file?: File

  workstreams?: HWorkstream[]

  constructor(
    private workstreamsService: WorkstreamsService,
    private route: ActivatedRoute,
    private router: Router,
    private docxService: DocxAnalysisService,
    private fileService: FilesService
  ) {

    this.subscription = this.route.paramMap.pipe(
      map(params => Number(params.get("fileId"))),
      switchMap(fileId => this.workstreamsService.files.pipe(map(
        files => files.find(file => file.id == fileId)
      ))),
      filter(file => file != null),
      map(file => file!),
      tap(async file => this.pdfUrl = await this.fileService.fileToPdfUrl(file)),
      tap(file => this.file = file),
    ).subscribe(() => this.process())

    this.subscription.add(
      this.workstreamsService.workstreams$.subscribe(workstreams => this.workstreams = workstreams)
    )

  }

  async process() {

    if (this.file == null) {
      return
    }

    this.analysis = await this.docxService.getOrCreateAnalysis(this.file.id)

    if (this.analysis.languages.length == 1) {
      this.selectedLanguage = this.analysis.languages[0]
    }

    if (this.selectedLanguage == null) {
      return
    }
    
    this.matches = this.analysis.analysis.filter((match: any) => match.language == this.selectedLanguage)

    const workstreams = await firstValueFrom(this.workstreamsService.workstreams$)

    this.insertItems = workstreams.filter(ws => ws.parentId == null).map(ws => ({
      label: ws.properties['name'],
      hasControls: false,
      index: ws.index,
      id: ws.id
    }))

    // get filename without extension. Note that there could be more than one dot in the filename
    const filenameWithoutExtension = this.file.filename.split('.').slice(0, -1).join('.')

    this.insertItems.push({
      label: filenameWithoutExtension,
      hasControls: true,
      index: null,
      id: null
    })

    if (workstreams.length == 0) {
      this.apply()
    }


  }

  async apply() {

    if (this.file == null) {
      return 
    }

    if (this.selectedLanguage == null) {
      return
    }

    this.subscription?.unsubscribe()
    
    this.loading = true
        
    const toCreateIndex = this.insertItems!.findIndex(item => item.id == null)

    if (toCreateIndex == 0) {
      await this.docxService.addMain(this.file, -1, this.selectedLanguage) // add at beginning
    } else if (toCreateIndex == this.insertItems!.length - 1) {
      await this.docxService.addMain(this.file, this.insertItems!.length, this.selectedLanguage) // add at end
    } else {
      // add in between. So find items  before and after
      const before = this.insertItems![toCreateIndex - 1]
      const after = this.insertItems![toCreateIndex + 1]
      // calculate index as average of before and after. Decimal index is allowed
      const index = (before.index! + after.index!) / 2

      await this.docxService.addMain(this.file, index, this.selectedLanguage)
    }

    await this.close()

  }

  async close() {
    // close modal route
    await this.router.navigate([{ outlets: { modal: null } }])
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }


}
