import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, from, lastValueFrom, map, Observable, ReplaySubject, shareReplay, switchMap, take } from 'rxjs';
import { BackendService } from './backend.service';
import { makeObservableList } from './helpers/observable-service';
import { sortDescending } from './model/base-entity';
import { ProjectsService } from './projects.service';
import { WorkstreamsService } from './workstreams.service';
import { uniqueElementsBy } from './helpers/text-helpers';
import { distance } from 'fastest-levenshtein'
import { WorkstreamPropertyService } from './workstream-property.service';
import { AnalysisResult, Match } from './model/docx-analysis';
import { DocxAnalysis, Email, File as FileRecord, Workstream } from 'annex-tracker-backend';
import { EmailService } from './email.service';
import { DatePipe } from '@angular/common';
import { sortElementWise } from 'annex-tracker-backend/lib/helpers/sortByReference';
import { atomize } from 'annex-tracker-backend/lib/helpers/docx-analysis';
import { getLocalizedString, i18nLanguage } from 'annex-tracker-backend/lib/helpers/i18n-messages/messages';
import { HttpClient } from '@angular/common/http';
import { UploadService } from './upload.service';

export interface Mapping {
  leftIndex: number
  rightIndex: number 
  distance?: number
  done: boolean
  leftMatch?: Match
  rightMatch?: Match
  leftWorkstream?: Workstream
  rightWorkstream?: Workstream
}

@Injectable({
  providedIn: 'root',
})
export class DocxAnalysisService {

  language$ = new BehaviorSubject<'en' | 'de'>("de")
  mappings = new ReplaySubject<Mapping[]>(1)

  leftFile?: FileRecord
  rightFile?: FileRecord

  leftAnalysis?: Match[]
  rightAnalysis?: Match[]

  email$?: Observable<Email | undefined>

  analyses$: Observable<DocxAnalysis[]>
  matrix?: any[][]
  titles: string[] = []

  private analysisId$ = new BehaviorSubject<number | null>(null)

  constructor(
    private backend: BackendService,
    private projectService: ProjectsService,
    private workstreamService: WorkstreamsService,
    private propertyService: WorkstreamPropertyService,
    private emailService: EmailService,
    private datePipe: DatePipe
  ) {

    this.analyses$ = makeObservableList(this.backend.docxAnalysis, async x => x, this.projectService.projectIdQuery, this.projectService.projectIdFilter).pipe(shareReplay(1))

  }

  async loadAnalysis(analysisId: number | null) {

    this.titles = []

    this.analysisId$.next(analysisId)

    const currentWorkstreamId = await firstValueFrom(this.workstreamService.currentWorkstreamId)
    const currentWorkstream = await firstValueFrom(this.workstreamService.currentWorkstream)
    const project = this.projectService.currentProject!
    
    const childWorkstreams = (await firstValueFrom(this.workstreamService.workstreams$)).filter(ws => ws.parentId == currentWorkstreamId)

    // check if child workstreams contain prefixes "Anlage" or "Anhang"
    let language = project.language

    if (childWorkstreams.some(ws => /(Schedule|Annex|Exhibit|Appendix)/.test(ws.properties?.['prefix']))) {
      language = "en"
    } else if (childWorkstreams.some(ws => /(Anlage|Anhang)/.test(ws.properties?.['prefix']))) {      
      language = "de"
    }


    const wsDocxFiles = await lastValueFrom(this.workstreamService.files.pipe(
      map(files => files.filter(file => file.workstreamId == currentWorkstreamId)),
      map(files => files.sort(sortDescending)),
      map(files => files.filter(file => file.filename!.endsWith(".docx"))),
      take(1))
    )

    const currentFileIndex = wsDocxFiles.findIndex(file => file.id == analysisId)

    const currentFile = wsDocxFiles[currentFileIndex]
    const previousFile = wsDocxFiles.find(file => file.id == currentWorkstream?.properties?.['basedOnAnalysis'])

    if (currentFile == null || previousFile == null) {
      console.log("no two files found")
      return
    }
    this.leftFile = previousFile
    this.rightFile = currentFile

    this.leftAnalysis = (await this.getOrCreateAnalysis(this.leftFile.id)).analysis.filter(match => match.language == language)
    this.rightAnalysis = (await this.getOrCreateAnalysis(this.rightFile.id)).analysis.filter(match => match.language == language)

    this.email$ = this.emailService.populatedEmails$.pipe(map(mails => mails.find(mail => mail.id == this.rightFile?.emailId)))

    this.matrix = this.leftAnalysis.map(leftMatch =>
      this.rightAnalysis!.map(rightMatch => {

        const left = leftMatch.fullMatches.join(" ").replace(/[^a-zA-Z0-9]/g, "").trim()
        const right = rightMatch.fullMatches.join(" ").replace(/[^a-zA-Z0-9]/g, "").trim()

        return distance(left, right) / Math.max(left.length, right.length) * 100

      })
    )

    const leftIndices = this.leftAnalysis.map((_, i) => i)
    const rightIndices = this.rightAnalysis.map((_, i) => i)

    const getMatrixRow = (i: number) => this.matrix![i]
    const getMatrixColumn = (i: number) => this.matrix!.map(row => row[i])

    const getIndexOfMin = (array: number[]) => {
      let min = array[0]
      let minIndex = 0
      for (let i = 1; i < array.length; i++) {
        if (array[i] < min) {
          min = array[i]
          minIndex = i
        }
      }
      return minIndex
    }

    const leftMapping = leftIndices.map((leftIndex, i) => {
      const row = getMatrixRow(leftIndex)
      const minIndex = getIndexOfMin(row)
      const col = getMatrixColumn(minIndex)
      const minColIndex = getIndexOfMin(col)

      if (minColIndex == i) {
        // I'm also the others minimum, ITS A MATCH!!
        return {
          leftIndex,
          rightIndex: minIndex,
          distance: this.matrix![leftIndex][minIndex],
          leftWorkstream: childWorkstreams.find(ws => ws.properties?.['prefix'] == this.leftAnalysis![leftIndex].prefix && ws.properties?.['reference'] == this.leftAnalysis![leftIndex].reference),
          leftMatch: this.leftAnalysis![leftIndex],
          rightMatch: this.rightAnalysis![minIndex],
          done: false
        } as Mapping
      } else {
        // I'm not the others minimum, ITS NOT A MATCH, so I will be deleted
        return {
          leftIndex,
          rightIndex: -1,
          leftWorkstream: childWorkstreams.find(ws => ws.properties?.['prefix'] == this.leftAnalysis![leftIndex].prefix && ws.properties?.['reference'] == this.leftAnalysis![leftIndex].reference),
          leftMatch: this.leftAnalysis![leftIndex],
          done: false
        }
      }
    })

    // Same for the right side
    const rightMapping = rightIndices.map((rightIndex, i) => {
      const col = getMatrixColumn(rightIndex)
      const minIndex = getIndexOfMin(col)
      const row = getMatrixRow(minIndex)
      const minRowIndex = getIndexOfMin(row)

      if (minRowIndex == i) {
        // I'm also the others minimum, ITS A MATCH!!
        return {
          leftIndex: minIndex,
          rightIndex,
          distance: this.matrix![minIndex][rightIndex],
          leftWorkstream: childWorkstreams.find(ws => ws.properties?.['prefix'] == this.leftAnalysis![minIndex].prefix && ws.properties?.['reference'] == this.leftAnalysis![minIndex].reference),
          leftMatch: this.leftAnalysis![minIndex],
          rightMatch: this.rightAnalysis![rightIndex],
          done: false
        } as Mapping
      } else {
        // I'm not the others minimum, ITS NOT A MATCH, so I will be deleted
        return {
          leftIndex: -1,
          rightIndex,
          rightMatch: this.rightAnalysis![rightIndex],
          done: false
        } as Mapping
      }
    })

    // Now we have to merge the two mappings
    const mapping = uniqueElementsBy([...leftMapping, ...rightMapping], (a, b) => a.rightIndex == b.rightIndex && a.leftIndex == b.leftIndex)
    this.sort(mapping)
    this.mappings.next(mapping)

  }

  sort(mapping: Mapping[]) {

    const prepareElement = (element: Mapping) => {

      const e = this.rightAnalysis![element.rightIndex] || this.leftAnalysis![element.leftIndex]
      return atomize(e.reference!)
    }

    mapping.sort((a, b) => sortElementWise(prepareElement(a), prepareElement(b)))

  }

  async getOrCreateAnalysis(fileId: number | null): Promise<AnalysisResult> {

    if (fileId == null) { 
      throw new Error("No file id given")
    }

    try {
      await this.backend.docxAnalysis.create({ id: fileId }) as DocxAnalysis
    } catch {
      // ignore
    }

    return firstValueFrom(this.analyses$.pipe(
      map(analysis => analysis.find(a => a.id == fileId)),
      filter(analysis => analysis != null),
      map(analysis => analysis as DocxAnalysis),
      filter(analysis => analysis.analysis != null),
      map(analysis => JSON.parse(analysis.analysis as unknown as string || 'null') as AnalysisResult),
      take(1)
    ))
  }

  async apply() {

    const mappings = await firstValueFrom(this.mappings)
      
    const language = this.projectService.currentProject?.language as i18nLanguage

    const currentWorkstreamId = await firstValueFrom(this.workstreamService.currentWorkstreamId)
    const workstreams = (await firstValueFrom(this.workstreamService.workstreams$)).filter(ws => ws.parentId == currentWorkstreamId)
    const dateString = this.datePipe.transform(new Date(), 'short', undefined, 'de-DE')!

    const toDelete = mappings
      .filter(mapping => mapping.rightIndex == -1)
      .map(mapping => {
        const left = this.leftAnalysis![mapping.leftIndex]

        const wsToDelete = workstreams.find(ws =>
          ws.properties?.['reference'] == left.reference)

        return wsToDelete
      })
      .filter(ws => ws != null)

    const toRename = mappings
      .filter(mapping => mapping.rightIndex != -1 && mapping.leftIndex != -1)
      .map(mapping => {
        const left = this.leftAnalysis![mapping.leftIndex]
        const right = this.rightAnalysis![mapping.rightIndex]

        let wsToRename = workstreams.find(ws =>
          ws.properties?.['reference'] == left.reference)

        // Reference stayed the same, so we don't have to rename
        if (wsToRename?.properties?.['reference'] == right.reference) {
          wsToRename = undefined
        }

        return {
          workstream: wsToRename,
          newReference: right.reference
        }
      })
      .filter(r => r.workstream != null)

    const parentWorkstreamId = await firstValueFrom(this.workstreamService.currentWorkstreamId)

    const toCreate = mappings
    .map((mapping, index) => ({
      reference: this.rightAnalysis![mapping.rightIndex]?.reference,
      prefix: this.rightAnalysis![mapping.rightIndex]?.prefix,
      parentId: parentWorkstreamId,
      title: this.titles ? this.titles[index] : undefined,
      index: index
    }))
    .filter(newOnes => mappings[newOnes.index].leftIndex == -1)

    await Promise.all(toRename.map(async r => {
      
      await this.propertyService.setTo('notifications', getLocalizedString(language, 'workstream_renamed', { ...r.workstream!.properties, date: dateString }), r.workstream!)
      await this.propertyService.setTo('reference', r.newReference, r.workstream!)
    }))
    await Promise.all(toDelete.map(async ws => {
      try {
        await this.propertyService.setTo('notifications', getLocalizedString(language, 'workstream_deleted', { ...ws!.properties, date: dateString }), ws!)
        await this.propertyService.setTo('deleted', 'true', ws!)
      } catch (e) {
        console.log("Could not delete workstream.")
        console.error(e)
      }
    }))

    await Promise.all(toCreate.map(async r => {
      const ws = await this.workstreamService.createWorkstream(parentWorkstreamId)
      await this.propertyService.setTo('reference', r.reference, ws)
      await this.propertyService.setTo('prefix', r.prefix, ws)
      await this.propertyService.setTo('notifications', getLocalizedString(language, 'workstream_created', { prefix: r.prefix, reference: r.reference, date: dateString }), ws)
      if (r.title != null) {
        await this.propertyService.setTo('name', r.title, ws)
      } 
    }))

    try {
      await this.propertyService.setToWsId('basedOnAnalysis', `${this.rightFile!.id}`, parentWorkstreamId!)
    } catch {
      console.error("Could not set basedOnAnalysis property.")
      console.error("Right file: ", this.rightFile)
      console.error("Parent workstream id: ", parentWorkstreamId)
    }


  }

  async addMain(file: FileRecord, index: number, language?: 'de' | 'en' | null) {

    const projectId = await this.projectService.getCurrentProjectId()
    const project = this.projectService.currentProject!

    if (projectId == null) {
      throw "No project selected"
    }

    let newWorkstream = await this.workstreamService.createWorkstream(null, index)
    
    this.propertyService.setTo('name', 'Main', newWorkstream)

    await this.backend.files.patch(file.id, { workstreamId: newWorkstream.id })

    let results = await this.getOrCreateAnalysis(file.id)

    // language is project.language if it is in the results.languages, otherwise it is the first language in the results.languages
    if (language == null) {
      language = results.languages.includes(project.language) ? project.language : results.languages[0] as 'de' | 'en'
    }
  
    let languageResults = results.analysis.filter(a => a.language == language)

    const preparedWorkstreams = languageResults.map((match, index) => ({
      projectId: projectId,
      index,
      parentId: newWorkstream.id
    }))

    const newWorkstreams = await this.backend.workstream.create(preparedWorkstreams)

    const preparedProperties = newWorkstreams.map((ws, index) => ([{
      key: 'reference',
      value: languageResults[index].reference,
      workstreamId: ws.id,
      projectId: projectId
    }, {
      key: 'prefix',
      value: languageResults[index].prefix,
      workstreamId: ws.id,
      projectId: projectId
    }])
    ).flat()

    // Also add the properties for the parent workstream
    preparedProperties.push({
      key: 'name',
      value: file.filename.replaceAll(/\.docx$/g, ""),
      workstreamId: newWorkstream.id,
      projectId: projectId
    })

    this.propertyService.setProperty(preparedProperties)

    try {
      await this.propertyService.setTo('basedOnAnalysis', `${file.id}`, newWorkstream)
    } catch (e) {
      console.log("Could not set basedOnAnalysis property.")
      console.error(e)
    }

  }

  async exportForDebugging() {

    return {
        language: await firstValueFrom(this.language$),
        mappings: await firstValueFrom(this.mappings),
        leftAnalysis: this.leftAnalysis,
        rightAnalysis: this.rightAnalysis,
        matrix: this.matrix
    }
    
  }

}
