import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ColorService } from 'src/app/services/color.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class ColorSelectorComponent implements OnChanges {

  @Input()
  color!: string

  bgColors?: string[]
  bgColor?: string

  @Output()
  colorChange = new EventEmitter<string>()

  constructor(private colorService: ColorService) {
    
    this.bgColors = colorService.colorList.map(colorName => colorService.bgColors[colorName][400])
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['color']) {
      this.bgColor = this.colorService.getColor(changes['color'].currentValue, 400)
    }

  }

  changeColor(colorIndex: number) {
    this.colorChange.emit(this.colorService.colorList[colorIndex])
    this.blur() 
  }
  blur() {

    // blur currently selected element
    const activeElement = document.activeElement
    if (activeElement) {
      (activeElement as any).blur()
    }


  }

}
