import { Component } from '@angular/core';
import { ownerOptions, statusOptions } from '../doc-list/workstreams-table/workstreams-table-row/optionsDropdown';

import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { WorkstreamPropertyService } from 'src/app/services/workstream-property.service';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { ColorService } from 'src/app/services/color.service';
import { ColumnsService } from 'src/app/services/columns.service';
import { Columns } from 'annex-tracker-backend';
import { PieChartDirective } from '../../../common/pie-chart.directive';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, PieChartDirective, AsyncPipe]
})
export class ReportingComponent {
  ownerOptions = ownerOptions
  statusOptions = statusOptions

  data: Observable<{ ownerData: number[], statusData: number[] }>

  filteredColumns: Observable<{
    column: Columns,
    data: any[]
    statusLabels: string[]
    statusColors: string[]
  }[]>

  constructor(
    workstreamsService: WorkstreamsService,
    workstreamProperty: WorkstreamPropertyService,
    public columnsService: ColumnsService,
    colorService: ColorService
  ) {

    this.data = combineLatest([
      workstreamProperty.properties,
      workstreamsService.workstreams$]).pipe(map(([properties, workstreams]) => {

        const ownerData = ownerOptions.map(o => properties.filter(p => p.value == o.value).length)
        const statusData = statusOptions.map(o => properties.filter(p => p.value == o.value).length)

        return { ownerData, statusData }

      }))

    this.filteredColumns = columnsService.columns
      .pipe(
        map(columns => columns.filter(c => c.type == 'enum-dropdown')),
        switchMap(columns => {
          
          return workstreamProperty.properties.pipe(map(properties => {

            return columns.map(column => {

              const data = column.configuration.map((o: any) => properties.filter(p => p.value == o.value).length)
              return {
                column,
                data, 
                statusLabels: column.configuration.map((o: any) => o.label as string),
                statusColors: column.configuration.map((o: any) => colorService.getColor(o.color, 300) as string)
              }
            }) 
          }))


        })
      )

  }

}
