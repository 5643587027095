import { Component } from '@angular/core';
import { Contact } from 'annex-tracker-backend';
import { Observable, map } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { ownerOptions } from '../doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { BackendService } from 'src/app/services/backend.service';
import { BadgeDropdownComponent } from '../../../common/badge-dropdown/badge-dropdown.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-domains',
    templateUrl: './domains.component.html',
    host: {
        class: 'flex-1 min-h-0 bg-slate-100 overflow-y-auto my-scrollbar'
    },
    styleUrls: ['./domains.component.scss'],
    standalone: true,
    imports: [NgFor, BadgeDropdownComponent, AsyncPipe]
})
export class DomainsComponent {

  ownerOptions = ownerOptions

  categories = ownerOptions.map(x => ({
    label: x.label,
    value: x.value,
    contacts$: null as Observable<Contact[]> | null
  }))

  constructor(private emailService: EmailService, private backend: BackendService) {

    this.categories.forEach(category => {
      category.contacts$ = this.emailService.contacts$.pipe(map(contacts => contacts.filter(contact => contact.domain == category.value).filter(contact => contact.hidden != true)))
    })

  }

  changeDomain(contact: Contact, newDomain: string) {
    this.backend.emailContact.patch(contact.id, { domain: newDomain })
  }

}
