<table #table class="min-w-full table-fixed overflow-hidden text-sm">
  <thead>
    <!-- <tr class="bg-white text-sm" app-statistics-header></tr> -->
    <tr class="text-sm sticky top-0">
      <th colspan="1" class="bg-slate-100 box-border w-0  text-center  p-2">#</th>
      <th colspan="2" class="bg-slate-100 box-border w-0  text-center px-2">{{'reference_column_label' |
        translate:projectService.currentProject?.language}}</th>
      <th colspan="1" class="bg-slate-100 box-border w-64 text-center px-2 group">
        {{'title_column_label' | translate:projectService.currentProject?.language}}
        <app-guess-title-ai></app-guess-title-ai>
      </th>

      <ng-container *ngFor="let column of columnsService.columns | async">

        <ng-container [ngSwitch]="column.type">

          <th *ngSwitchDefault class="bg-slate-100 box-border text-center px-2">
            {{ column.label }}
          </th>
          <th *ngSwitchCase="'enum-dropdown'" class="bg-slate-100 box-border w-0 text-center p-2 relative">
            <a class="cursor-pointer hover:underline" (click)="openColumnEditor(column)">{{ column.label }}</a>
            <app-horizontal-bar-chart class="absolute bottom-0 w-full left-0 px-1"
              [columnName]="column.name"></app-horizontal-bar-chart>
          </th>

        </ng-container>

      </ng-container>

      <th colspan="1" class="bg-slate-100 box-border p-2 text-left cursor-pointer">
        <!-- <fa-icon (click)="openColumnSettings()" [icon]="iconDots"></fa-icon> -->
      </th>
    </tr>
  </thead>

  <tbody class="divide-gray-200 bg-white cursor-default divide-y">

    <tr *ngFor="let workstream of workstreams | async; let index = index; trackBy: trackByFn" app-workstreams-table-row
      class="relative text-slate-900 hover:bg-slate-100 transition-colors cursor-pointer"
      [class.bg-stripes]="workstream.id == selectedWorkstream?.id" [class.font-medium]="workstream.parentId == null"
      [active]="workstream.id == selectedWorkstream?.id"
      [class.line-through]="workstream.properties?.['deleted'] == 'true'" [editable]="editable"
      [workstream]="workstream" [index]="index + 1" (focusedColumn)="focusin($event, workstream)"
      (click)="workstreamSelected.emit(workstream)">
    </tr>

  </tbody>
</table>