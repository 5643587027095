import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TwoFa, TwoFaData } from 'annex-tracker-backend';
import { ActiveModal } from 'src/app/modal/modal.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import QRCode from 'qrcode'
import { ToastrService } from 'ngx-toastr';
import { ButtonSecondaryComponent } from '../../../../../common/tailwind/button-secondary/button-secondary.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-activate-two-fa',
    templateUrl: './activate-two-fa.component.html',
    styleUrls: ['./activate-two-fa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgOtpInputModule, ButtonSecondaryComponent]
})
export class ActivateTwoFaComponent implements OnInit {

  currentTwoFa?: TwoFa

  code?: string

  qrCodeDataUrl?: string

  constructor(
    public activeModal: ActiveModal,
    public auth: AuthenticationService,
    private toastr: ToastrService) {

  }

  async ngOnInit() {

    try {
      this.currentTwoFa = await this.auth.getCurrentTwoFa()
    } catch (e) {
      console.log(e)
      console.log("No 2FA")
    }

    if (this.currentTwoFa && this.currentTwoFa.enabled == false) {
      console.log("2FA is disabled but exists. Deleting.")
      await this.auth.disableTwoFa()
      this.currentTwoFa = undefined
    }

    if (this.currentTwoFa == null) {
      this.currentTwoFa = await this.auth.createTwoFa()
      console.log("created new 2FA")
      const user = await this.auth.awaitableUser()
      const secretData = `otpauth://totp/Annex%20Tracker:${user.email}?secret=${this.currentTwoFa.secret}`
      this.qrCodeDataUrl = await QRCode.toDataURL(secretData)
    }

  }

  async activate() {
    await this.auth.enableTwoFa(this.code!)
  }

  async checkCodeDisable() {
    if (this.code?.length == 6) {

      try {
        await this.auth.disableTwoFa(this.code)
        this.toastr.success("Two-factor authentication disabled")
        this.activeModal.close()
      } catch (e) {
        this.toastr.error("Two-factor authentication incorrect")
        console.error(e)
      }

    }
  }

  async checkCodeActivate() {

    if (this.code?.length == 6) {
      try {
        await this.auth.enableTwoFa(this.code!)
        this.toastr.success("Two-factor authentication enabled")
        this.activeModal.close()
      } catch {
        this.toastr.error("Two-factor authentication incorrect")
      }
    }

  }


}
