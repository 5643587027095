<div class="overflow-hidden rounded-md h-6 flex items-stretch">
    @for(color of colors; track color; let idx = $index) {
    <span [style.width.%]="this.widths[idx]" [style.background-color]="color | palette:100"
        [style.color]="color | palette:900"
        class="bg-yellow-200 flex text-xs justify-center items-center overflow-clip whitespace-nowrap @container">
        <span class="@[1.0cm]:visible invisible">
            {{this.widths[idx] | number:"1.0-0"}} %
        </span>
    </span>
    }
</div>