import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AutoFocusDirective } from '../../common/auto-focus.directive';

@Component({
    selector: 'app-center-panel',
    templateUrl: './center-panel.component.html',
    styleUrls: ['./center-panel.component.scss'],
    standalone: true,
    imports: [AutoFocusDirective, RouterOutlet]
})
export class CenterPanelComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  @HostListener("keydown.escape", ['$event'])
  close() {
    this.router.navigate([{ outlets: { modal: null } }])
  }
  
}
