<figure (click)="openModal()" class="aspect-[4/3] overflow-clip relative rounded-md cursor-pointer w-48 group">
    @if (currentCoverPage != null) {
    <img class="h-full w-full object-cover" src="{{currentCoverPage.preview}}">

    <span class="bg-[rgba(255,255,255,0.7)] px-1.5 py-px w-full absolute left-0 bottom-0 block text-sm leading-6 text-gray-900">{{currentCoverPage.name}}
    </span>
    } @else {
    <img class="h-full w-full object-cover" src="assets/print/backgrounds/pexels-aleksandar-pasaric-325185_reduced.jpg">

    <span
        class="bg-[rgba(255,255,255,0.7)] px-1.5 py-px w-full absolute left-0 bottom-0 block text-sm leading-6 italic text-gray-900">Default
    </span>
    }
    <span
        class="bg-[rgba(255,255,255,0.7)] transition-transform -translate-y-full group-hover:translate-y-0 px-1.5 py-px w-full absolute left-0 top-0 block text-sm leading-6 text-gray-900">
        Click to change
    </span>

</figure>