import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { sortReferences } from 'annex-tracker-backend/lib/helpers/sortByReference';
import { ButtonPrimaryComponent } from 'src/app/common/tailwind/button-primary/button-primary.component';

@Component({
  selector: 'app-select-analysis-language',
  standalone: true,
  imports: [ButtonPrimaryComponent, FaIconComponent],
  templateUrl: './select-analysis-language.component.html',
  styleUrl: './select-analysis-language.component.scss'
})
export class SelectAnalysisLanguageComponent implements OnChanges {

  iconLoading = faSpinnerThird

  languagesOverview?: any[]
  languages: ('de' | 'en')[] = []

  languageStrings = {
    "de": "German",
    "en": "English"
  } as {[key: string]: string}

  @Input()
  selectedLanguage?: 'de' | 'en' 

  @Input()
  data?: any

  @Input()
  searchTerm?: string

  @Output()
  searchTermChange = new EventEmitter<string>()

  ngOnChanges(changes: SimpleChanges): void {

    if (this.data != null) {
      this.process()
    }
  }

  process() {
    this.languages = this.data.languages
    const analysis = this.data.analysis

    const allReferences = [...new Set<string>(analysis.map((entry: any) => entry.reference))]

    const sorted = allReferences.sort(sortReferences)

    this.languagesOverview = sorted.map(reference => this.data.languages.map((language: string) => 
        analysis.find((entry: any) => entry.reference == reference && entry.language == language)
      )) 

    try {

      // get prefixes for all languages
      const orderedUniquePrefixes = this.languages.map((language, index) => {
  
        const prefixes = [... new Set(this.languagesOverview!.map((entry: any) => entry[index]?.prefix))].filter((entry: any) => entry != null)
  
        // first out which prefix occures first in the list
        const firstPrefixForLanguage = this.languagesOverview?.find((entry: any) => entry[index]?.prefix != null)[index].prefix;
  
        return [firstPrefixForLanguage, ... prefixes.filter(p => p != firstPrefixForLanguage)]
  
      })
  
      // sort the languages overview
      this.languagesOverview = this.languagesOverview.sort((a, b) => {
  
        const prefixA = a.find((entry: any) => entry != null).prefix
        const prefixB = b.find((entry: any) => entry != null).prefix
  
        const indexA = Math.min(...a.map((languageEntry: any, index: number) => {
          const i = orderedUniquePrefixes[index].findIndex((entry: any) => entry == languageEntry?.prefix)
          if (i == -1) {
            return 10000
          } else {
            return i
          }
        }))
        const indexB = Math.min(...b.map((languageEntry: any, index: number) => {
          const i = orderedUniquePrefixes[index].findIndex((entry: any) => entry == languageEntry?.prefix)
          if (i == -1) {
            return 10000
          } else {
            return i
          }
        }))
  
        console.log(prefixA, prefixB)
  
        if (indexA == indexB) {
  
          const referenceA = a.find((entry: any) => entry != null).reference
          const referenceB = b.find((entry: any) => entry != null).reference
  
          return sortReferences(referenceA, referenceB)
        } else {
          return indexA - indexB
        }
        
    
      })
    } catch {
      console.warn("error sorting languages overview")
    }

  }
  
  changeSearchTerm(event: any) {
    console.log("search term changed", event)
    this.searchTermChange.emit(`${event.prefix} ${event.reference}`)
  }

}

const dummyData = {
  "languages": [
    "de",
    "en"
  ],
  "analysis": [
    {
      "prefix": "Anlage",
      "reference": "4.2.1",
      "language": "de",
      "fullMatches": [
        "EUR [___] (in Worten: Euro [___]) werden auf die einzelnen Verkäufer wie in Anlage 4.2.1 näher definiert aufgeteilt."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "4.4",
      "language": "de",
      "fullMatches": [
        "Der Sicherheitseinbehalt bleibt bis zum [___] („Treuhandfrist 1“) in voller Höhe auf dem Treuhandkonto und danach bis zum [___] („Treuhandfrist 2“) in Höhe von EUR [___] (in Worten: Euro [___]). Nach Ablauf der jeweiligen Treuhandfrist soll der Treuhänder, soweit nicht nachfolgend anders bestimmt, und ohne weitere Erklärung der Parteien, den Sicherheitseinbehalt entsprechend des in Anlage 4.4 näher definierten Verhältnisses an die Verkäufer auskehren, soweit er nicht nach Satz 1 auf dem Treuhandkonto verbleiben soll.",
        "Erhebt der Käufer nicht binnen einer Frist von [___] Monaten ab dem Zugang der Anzeige bei den Verkäufern Klage über den geltend gemachten und nicht anerkannten (Teil-)Anspruch, hat der Treuhänder den entsprechenden Betrag an die Verkäufer entsprechend des in Anlage 4.4 näher definierten Verhältnisses auszukehren; ",
        "Entscheidet im Falle eines Gerichtsverfahrens das zuständige Gericht, dass dem Käufer ein von ihm angezeigter Anspruch ganz oder in Teilen zusteht bzw. nicht zusteht, hat der Treuhänder den entsprechenden Betrag in Höhe des obsiegenden Teils an den Käufer und ggf. in Höhe des unterliegenden Teils an die Verkäufer entsprechend des in Anlage 4.4 näher definierten Verhältnisses auszukehren."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "4.7",
      "language": "de",
      "fullMatches": [
        "EUR [___] (in Worten: Euro [___]) auf das in der Treuhandvereinbarung gemäß Anlage 4.7 bezeichnete Treuhandkonto. Der auf das Treuhandkonto gezahlte Teil hiernach „Sicherheitseinbehalt“.",
        "Die Parteien haben dafür zu sorgen, dass die Bestimmungen der Ziffern 4.3 bis 4.6 in einer Treuhandvereinbarung („Treuhandvereinbarung“) mit dem Notar [___] („Treuhänder“) umgesetzt werden; die Treuhandvereinbarung hat dem als Anlage 4.7 beigefügten Entwurf zu entsprechend. Die Parteien haben die Treuhandvereinbarung spätestens am Vollzugstag abzuschließen. Die Unterschrift des Treuhänders ist von dem Käufer einzuholen. Die Parteien haben gemeinsame Anweisungen, zu deren Erteilung sie gemäß Ziffern 4.3 bis 4.6 und nach der Treuhandvereinbarung verpflichtet sind, unverzüglich zu erteilen."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "6.2",
      "language": "de",
      "fullMatches": [
        "„EBITDA“ bezeichnet das Nettoergebnis vor Zinsen, außerordentlichen Erträgen und Aufwendungen, Steuern und Abschreibungen (und umfasst insbesondere die in Anlage 6.2 aufgeführten Posten). Die Auswirkungen der folgenden Faktoren werden bei der Berechnung des EBITDA nicht berücksichtigt:"
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "7.1",
      "language": "de",
      "fullMatches": [
        "Alle Zahlungen an die Verkäufer im Rahmen dieses Vertrags sind auf die in Anlage 7.1 für die jeweiligen Verkäufer definierten Kontos zu leisten."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "7.2",
      "language": "de",
      "fullMatches": [
        "Alle Zahlungen an den Käufer im Rahmen dieses Vertrags sind auf das in Anlage 7.2 definierte Konto zu leisten."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "10.3",
      "language": "de",
      "fullMatches": [
        "Am Vollzugsdatum werden die Verkäufer und der Käufer ein Vollzugsprotokoll ausfertigen, welches im Wesentlichen Anlage 10.3 entspricht („Vollzugsprotokoll“). Das Vollzugsprotokoll bestätigt die Erfüllung oder den Verzicht auf die Vollzugsbedingungen nach Ziffer 8.3 dieses Vertrags und die Durchführung oder den Verzicht auf die Vollzugshandlungen nach Ziffer 10.1 dieses Vertrags."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "11.2",
      "language": "de",
      "fullMatches": [
        "Jeder der Gründer garantiert dem Käufer hiermit einzeln im Wege eines selbstständigen Garantieversprechens gemäß § 311 Abs. 1 BGB, dass die in Anlage 11.2 aufgeführten Angaben zum Unterzeichnungsstichtag wahr und richtig sind, soweit nicht ausdrücklich ein anderer Zeitpunkt maßgeblich ist (die „Geschäftsgarantien“)."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "16",
      "language": "de",
      "fullMatches": [
        "Der Käufer ist nicht berechtigt, Ansprüche gemäß den Ziffern11.4.1 bis 11.4.2 geltend zu machen, wenn die zugrunde liegenden Tatsachen oder Umstände, auf die sich der Anspruch bezieht, dem Käufer bekannt waren oder hätten bekannt sein müssen, wobei zu berücksichtigen ist, dass der Käufer vor Abschluss dieses Vertrags die Möglichkeit hatte, eine gründliche Prüfung des Zustands und der Lage der Gesellschaft und ihres jeweiligen Geschäfts in kommerzieller, finanzieller und rechtlicher Hinsicht vorzunehmen, unter anderem durch eine Prüfung der in Anlage 16 genannten oder im Datenraum offengelegten Dokumente („Offengelegte Dokumente“). Tatsachen und Umstände, die vernünftigerweise aus den Offengelegten Dokumenten geschlossen werden können, die in einer Weise und in einem solchen Detailgrad offengelegt wurden, dass ein vernünftigerweise sorgfältiger Käufer, der die Standards eines ordentlichen Geschäftsmannes anwendet, auf der Grundlage der relevanten Dokumente eine fundierte Beurteilung der Art und des Umfangs der offengelegten Angelegenheit und der daraus resultierenden Konsequenzen vornehmen kann, sowie Tatsachen und Umstände, die im Informationsmemorandum vom [___], in der am [___] übergebenen Managementpräsentation oder in diesem Vertrag oder seinen Anhängen genannt werden, werden als dem Käufer bekannt vorausgesetzt. Die Kenntnis der Geschäftsführer, Berater und Mitarbeiter des Käufers, die mit der vor Abschluss dieses Vertrages durchgeführten Due Diligence befasst und vertraut waren, wird dem Käufer zugerechnet."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "22.12",
      "language": "de",
      "fullMatches": [
        "Jeder der Verkäufer garantiert dem Käufer hiermit einzeln im Wege eines selbstständigen Garantieversprechens gemäß § 311 Abs. 1 BGB, dass die in Anlage 22.12  aufgeführten Angaben zum Unterzeichnungsstichtag wahr und richtig sind, soweit nicht ausdrücklich ein anderer Zeitpunkt maßgeblich ist (die „Steuergarantien“)."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "23.1",
      "language": "de",
      "fullMatches": [
        "Jeder Verkäufer verpflichtet sich als Teilschuldner, den Käufer oder - nach Wahl des Käufers - die Gesellschaft (im Wege eines Vertrags zugunsten Dritter, § 328 Abs. 1 BGB) von allen Kosten, Verlusten und Verbindlichkeiten, die dem Käufer oder der Gesellschaft im Zusammenhang mit den in Anlage 23.1 aufgeführten Gegenständen entstehen (ausgenommen Folgeschäden, entgangener Gewinn, Wertminderung (z.B. durch entgangenen Gewinn oder verminderten Cashflow) oder sonstige besondere Schäden) freizustellen."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "24.4",
      "language": "de",
      "fullMatches": [
        "Die Verkäufer sind verpflichtet, dafür zu sorgen, dass jede Vereinbarung zwischen der Gesellschaft und einem Verkäufer oder einer Nahestehenden Person zum Zeitpunkt des Vollzuges beendet wird, mit Ausnahme der in Anlage 24.4 enthaltenen Vereinbarungen."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "25.2",
      "language": "de",
      "fullMatches": [
        "„Permitted Leakage“ bezeichnet alle Zahlungen oder die Übernahme von Verpflichtungen oder Haftungen durch die Gesellschaft an/zu Gunsten eines Verkäufers oder eines Verbundenen Unternehmens eines Verkäufers, wenn eine solche Zahlung für Dienstleistungen oder aufgrund einer in Anlage 25.2 aufgeführten Beziehung oder Verpflichtung erfolgt."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "30.2",
      "language": "de",
      "fullMatches": [
        "Alle Mitteilungen, die den Verkäufern oder einem der Verkäufer im Rahmen dieses Vertrags zugehen, sind wie in Anlage 30.2 näher definiert an die Verkäufer zu richten."
      ]
    },
    {
      "prefix": "Anlage",
      "reference": "30.3",
      "language": "de",
      "fullMatches": [
        "Jede Mitteilung an den Käufer ist wie in Anlage 30.3 definiert, zu adressieren."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "4.2.1",
      "language": "en",
      "fullMatches": [
        "EUR [___] (in words: Euro [___]) shall be allocated to the individual Sellers as further defined in Annex 4.2.1."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "4.4",
      "language": "en",
      "fullMatches": [
        "The Security Deposit shall remain in the escrow account in full until [___] (\"Escrow Period 1\") and thereafter until [___] (\"Escrow Period 2\") in the amount of EUR [___] (in words: Euro [___]). After the expiry of the respective escrow period, the Escrow Agent shall, unless otherwise provided below and without further declaration by the Parties, distribute the Security Deposit to the Sellers in accordance with the ratio defined in more detail in Annex 4.4 accordingly, to the extent that it shall not remain in the escrow account pursuant to sentence 1.",
        "If the Acquirer does not file an action within a period of [___] months starting with the receipt of the notification regarding the asserted and unacknowledged (partial) claim by the Sellers, the Escrow Agent shall distribute the corresponding amount to the Sellers in accordance with the ratio defined in more detail in Annex 4.4;",
        "If, in the event of court proceedings, the competent court decides that the Acquirer is or is not entitled to a claim asserted by him, in whole or in part, the Escrow Agent shall distribute the corresponding amount to the Acquirer in the amount of the winning part and, if applicable, to the Sellers in the amount of the losing part in accordance with the ratio defined in more detail in Annex 4.4."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "4.7",
      "language": "en",
      "fullMatches": [
        "EUR [___] (in words: Euro [___]) to the escrow account designated in the Escrow Agreement pursuant to Annex 4.7. The portion paid into the escrow account hereafter \"Security Deposit\".",
        "The Parties shall ensure that the provisions of Sections 4.3 to 4.6 are implemented in an escrow agreement (\"Escrow Agreement\") with the notary [___] (\"Escrow Agent\"); the Escrow Agreement shall correspond to the draft attached as Annex 4.7. The Parties shall enter into the Escrow Agreement no later than the Closing Date. The signature of the Escrow Agent shall be obtained by the Acquirer. The Parties shall give joint instructions which they are obliged to give pursuant to Sections 4.3 to 4.6 and pursuant to the Trust Agreement without undue delay."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "6.2)",
      "language": "en",
      "fullMatches": [
        "“EBITDA” shall mean the net earnings before interest, extraordinary income and expenses, taxes and depreciation/amortization (including but not limited to the items set forth in Annex 6.2). The effects of the following matters shall be eliminated in calculating the EBITDA:"
      ]
    },
    {
      "prefix": "Annex",
      "reference": "7.1",
      "language": "en",
      "fullMatches": [
        "All Payments to the Sellers under this Agreement shall be made to the accounts defined in Annex 7.1 for the respective Seller."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "7.2",
      "language": "en",
      "fullMatches": [
        "All Payments to the Acquirer under this Agreement shall be made to the account defined in Annex 7.2."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "10.3",
      "language": "en",
      "fullMatches": [
        "On the Closing Date, the Sellers and the Acquirer shall execute a closing memorandum essentially in the form as set out in Annex 10.3 (“Closing Memorandum”). The Closing Memorandum shall confirm the satisfaction or waiver of the Closing Conditions and the completion or waiver of the Closing Actions as set out under Sections 8.3 and 10.1 of this Agreement."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "11.2",
      "language": "en",
      "fullMatches": [
        "Each of the Founders hereby individually guarantees to the Acquirer by way of an independent guarantee (selbstständiges Garantieversprechen) pursuant to section 311 (1) of the German Civil Code (BGB) that the statements set forth in Annex 11.2 are true and correct as of the Signing Date, unless specifically provided for that a different date shall be decisive (the “Business Guarantees”."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "16",
      "language": "en",
      "fullMatches": [
        "The Acquirer shall not be entitled to bring any claim under Section 11.4.1 through Section 11.4.2 if the underlying facts or circumstances to which the claim relate were known, or could have been known, by the Acquirer, taking into account that the Acquirer, prior to entering into this Agreement, has been given the opportunity to conduct a thorough review of the condition and status of the Company and their respective business from a commercial, financial and legal perspective, inter alia, to a review of the documents identified in Annex 16 or disclosed in the data room (“Disclosed Documents”). Facts and circumstances that could reasonably be concluded from the Disclosed Documents that have been fairly disclosed in such a manner and in such detail that allows a reasonably diligent purchaser applying the standards of a prudent businessman to, on the face of relevant documents, make an informed assessment of the nature and scope of the matter disclosed and the consequences thereof, as well as facts and circumstances identified in the information memorandum of [___], the management presentation delivered on [___], or in this Agreement or its Schedules are deemed to be known by the Acquirer. The knowledge of the Acquirer`s managing directors, advisors and those of its employees who were engaged in and familiar with the due diligence exercise undertaken before the conclusion of this Agreement, shall be imputed to the Acquirer."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "23.1",
      "language": "en",
      "fullMatches": [
        "Each Seller agrees to indemnify and hold harmless, as severally liable debtor (Teilschuldner), the Acquirer or – at the election of the Acquirer – the Company (by way of agreement for the benefit of third parties (Vertrag zugunsten Dritter), section 328 (1) of the German Civil Code (BGB)) from any and all costs, losses and liabilities incurred by the Acquirer or the Company (but excluding any consequential damages (Folgeschäden), loss of profits (entgangener Gewinn), any value reduction (due to, for example, lost earnings or decreased cash flow) or any other special damages), in connection with the items listed in Annex 23.1."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "24.4",
      "language": "en",
      "fullMatches": [
        "The Sellers procure that any agreement between the Company and any Seller or any Related Person shall be terminated as of Closing, except for the agreements contained in Annex 24.4."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "25.2",
      "language": "en",
      "fullMatches": [
        "“Permitted Leakage” shall mean any payments or assumption of obligation or liability by the Company to / for the benefit of a Seller or an Affiliate of a Seller if any such payment is made for services or on the grounds of a relationship or obligation as listed in Annex 25.2 hereto."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "30.2",
      "language": "en",
      "fullMatches": [
        "Any Notice to be given to the Sellers or any of the Sellers hereunder shall be addressed to the Sellers as defined in Annex 30.2."
      ]
    },
    {
      "prefix": "Annex",
      "reference": "30.3",
      "language": "en",
      "fullMatches": [
        "Any Notice to be given to the Acquirer hereunder shall be addressed as defined in Annex 30.3."
      ]
    }
  ]
} 