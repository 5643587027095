import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { validateEmail } from 'src/app/services/helpers/validate-email';
import { PasswordStrengthComponent } from '../../../password-strength/password-strength.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [RouterLink, FormsModule, NgIf, PasswordStrengthComponent]
})
export class RegisterComponent {

  email?: string 
  password?: string 
  passwordRepeat?: string 

  accept = false
  oauthAvailable = false;

  showPasswordsDontMatch = false
  showEmailInvalid = false
  accountAlreadyExists = false
  loading = false

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {}

  async signup() {
    
    this.showPasswordsDontMatch = this.password != this.passwordRepeat
    this.showEmailInvalid = !validateEmail(this.email)
    
    if (this.showEmailInvalid || this.showPasswordsDontMatch) {
      return
    }

    this.loading = true
    try {

      await this.auth.signup({
        email: this.email!.toLocaleLowerCase(),
        password: this.password!,
      })
      await this.auth.authenticate({
        email: this.email,
        password: this.password
      })
      this.router.navigate(['lists'])

    } catch (e) {
      console.error(e)
      this.accountAlreadyExists = true
      return
    } finally {
      this.loading = false
    }
    
  }

}
