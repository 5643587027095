import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/services/upload.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-drop-overlay',
    templateUrl: './drop-overlay.component.html',
    host: {
        class: 'block'
    },
    styleUrls: ['./drop-overlay.component.scss'],
    standalone: true,
    imports: [FaIconComponent]
})
export class DropOverlayComponent {

  iconEmail = faEnvelope

  isEmail = false

  @Input()
  active: boolean = false

  @Output()
  leave = new EventEmitter<void>()

  constructor(private uploadService: UploadService, private toastr: ToastrService) { }

  @HostListener('dragover', ['$event'])
  dragOver(event: DragEvent) {
    console.log("dragover")
    event.preventDefault()
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    console.log("drag leave host listener")

    event.preventDefault();
    this.leave.emit()
  }

  @HostListener("dragging")

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    
    if (event.dataTransfer?.files.length == 0) {
      this.toastr.warning("No file dropped")
      this.leave.emit()
    } else {

      for (let i = 0; i < (event.dataTransfer?.files.length || 0); i++) {
        const file = event.dataTransfer?.files.item(i)
        if (file == null) {
          continue
        }
        const fileExt = file.name.split('.').pop()

        if (fileExt != null && !['eml', 'msg'].includes(fileExt)) {
          this.toastr.warning("Dropped file is not an email (.eml)")
          this.leave.emit()
          return
        }

        this.toastr.info("Email uploading")
        this.uploadService.upload(file, null).subscribe({
          complete: () => {
            console.log('complete');
            this.toastr.success("Email uploaded")
            this.leave.emit()
          },
          next: (value) => { console.log(value) },
          error: (error) => {
            console.log(error)
            this.toastr.error("Email upload failed")
            this.leave.emit()
          }
        })
      }
    }
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent) {
    event.preventDefault();
    this.leave.emit()
  }

  internalDragleave(event: DragEvent) {
    console.log("internal drag leave")
    console.log(event.target)
    event?.stopPropagation()
    event?.preventDefault()
  }

  internalDragEnter(event: DragEvent) {
    console.log("internal drag enter")
    // event?.stopPropagation()
    event?.preventDefault()
  }

}
