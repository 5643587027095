import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintComponent } from './print/print.component';
import { PrintTestComponent } from './print-test/print-test.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommonLibraryModule } from '../common/common-library.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SanitizePipe } from '../pipes/sanitize.pipe';
import { CoverSheetComponent } from './print/cover-sheet/cover-sheet.component';
import { TrackerDiffComponent } from './print/tracker-diff/tracker-diff.component';
import { ExecutiveSummaryComponent } from './print/executive-summary/executive-summary.component';
import { WorkstreamTableComponent } from './print/workstream-table/workstream-table.component';
import { BarsComponent } from './print/executive-summary/bars/bars.component';


@NgModule({
    imports: [
        CommonModule,
        CommonLibraryModule,
        NgxExtendedPdfViewerModule,
        FontAwesomeModule,
        PrintComponent,
        PrintTestComponent,
        CoverSheetComponent,
        TrackerDiffComponent,
        ExecutiveSummaryComponent,
        WorkstreamTableComponent,
        BarsComponent,
    ],
    providers: [
        SanitizePipe,
    ]
})
export class PrinterModule { }
