import { Injectable, inject } from '@angular/core';
import { BackendService } from './backend.service';
import { AuthenticationService } from './authentication.service';
import { Observable, firstValueFrom, map, switchMap } from 'rxjs';
import { CoverPageToProject, CoverPages } from 'annex-tracker-backend';
import { ProjectsService } from './projects.service';
import { makeObservableList } from './helpers/observable-service';

@Injectable({
  providedIn: 'root'
})
export class CoverPageService {

  backend = inject(BackendService)
  userService = inject(AuthenticationService)
  projectService = inject(ProjectsService)

  coverPages$: Observable<CoverPages[]>
  currentCoverPage$: Observable<CoverPages | undefined>
  currentCoverPageToProject$: Observable<CoverPageToProject | undefined>
  

  constructor() { 

    this.coverPages$ = this.userService.user$.pipe(switchMap(() => this.backend.coverPages.find()))
    this.currentCoverPageToProject$ = makeObservableList(this.backend.coverPageToProjects, async x => x, this.projectService.projectIdQueryWithoutDate, this.projectService.projectIdFilter)
      .pipe(map(x => x[0]))

    this.currentCoverPage$ = this.currentCoverPageToProject$.pipe(
      switchMap(coverPage => this.coverPages$.pipe(map(coverPages => coverPages.find(x => x.id === coverPage?.coverPageId)))
    ))

  }

  /**
   * 
   * Sets the current cover page for the currently active project
   * 
   * @param coverPage 
   * @returns 
   */
  async changeCoverPageOfCurrentProject(coverPage?: CoverPages | null) {
    const projectId = this.projectService.currentProject?.id
    if (projectId == null) throw new Error("No project selected")

    // get cover page to project 
    const currentCoverPageToProject = await firstValueFrom(this.currentCoverPageToProject$)

    if (currentCoverPageToProject != null) {
      // we should patch
      await this.backend.coverPageToProjects.patch(currentCoverPageToProject.id, { 
        coverPageId: coverPage?.id || null
      })
    } else {
      // there is no cover page, we should create one, but only if coverPage is not null
      if (coverPage == null) return

      await this.backend.coverPageToProjects.create({ 
        projectId, 
        coverPageId: coverPage.id} 
      )
    }
  }

}
