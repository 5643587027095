import { Component, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgIf } from '@angular/common';
import { AutoFocusDirective } from '../../../common/auto-focus.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [RouterLink, FormsModule, AutoFocusDirective, NgIf, NgOtpInputModule]
})
export class SignInComponent {

  iconWorking = faSpinnerThird

  email?: string
  password?: string
  twoFa?: string

  isWorking = false

  showPasswordWrong = false
  showTwoFa = false
  oauthAvailable = false


  constructor(private authentication: AuthenticationService, private router: Router, private toastr: ToastrService) {}

  async login() {
    
    this.isWorking = true
    try {
      await this.authentication.authenticate({
        email: this.email?.toLocaleLowerCase(),
        password: this.password
      }, this.twoFa)
      this.router.navigate(['lists'])
    } catch (e: any) {

      if (e.message?.includes("2FA code is required")) {
        this.toastr.info('Two-factor authentication required')  
        this.showTwoFa = true
        return
      }

      if (e.message?.includes("2FA code is incorrect")) {
        this.toastr.error('Two-factor authentication incorrect')
        this.showTwoFa = true
        return
      }
        

      this.toastr.error('Email or password wrong')
      console.error(e)
    } finally {
      this.isWorking = false
    }
    
  }
}
