<button [ngClass]="{
    'px-2.5 py-1.5 text-xs': size == 'xs',
    'px-3 py-2 text-sm ': size == 'sm',
    'px-4 py-2 text-sm': size == 'md',
    'px-4 py-2 text-base': size == 'lg',
    'px-6 py-3 text-base': size == 'xl'
    }" 
    [attr.disabled]="disabled ? '': null" 
    type="button" 
    class="font-medium disabled:bg-gray-500 disabled:cursor-not-allowed inline-flex items-center rounded-md border border-transparent bg-red-600 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
    <fa-icon *ngIf="loading" class="mr-2" [spin]="true" [icon]="iconLoading"></fa-icon>
    <ng-content></ng-content>
</button>
