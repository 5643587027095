<div class="page-break-after">
    <!-- <div class="mb-4 text-base">Executive Summary</div> -->
    <div class="grid grid-cols-1 gap-2 justify-items-center h-[400px] md:grid-cols-2">
        <div *ngFor="let columnData of filteredColumns | async">
            <canvas appPieChart *ngIf="(data | async) != null"
                [title]="columnData.column.label"
                [data]="columnData.data"
                [labels]="columnData.statusLabels"
                [colors]="columnData.statusColors">    
            </canvas>
        </div>

    </div>
</div>
<!-- <div class="inline p-5">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Executive summary</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 h-[400px]">
        <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <canvas appPieChart *ngIf="(data | async) != null" [title]="'Owner'" [data]="(data | async)!.ownerData"
                [labels]="ownerLabels" [colors]="ownerColors">
            </canvas>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <canvas appPieChart *ngIf="(data | async) != null"
                [title]="'Status'"
                [data]="(data | async)!.statusData"
                [labels]="statusLabels"
                [colors]="statusColors">    
            </canvas>
        </div>

    </dl>
</div> -->