import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-check-login',
    templateUrl: './check-login.component.html',
    styleUrls: ['./check-login.component.scss'],
    standalone: true
})
export class CheckLoginComponent implements OnInit {

  constructor(private auth: AuthenticationService, private router: Router) { 0 }

  async ngOnInit() {
    console.log("Checking user login")
    if (this.auth.hasStoredAuthenticationToken()) {
      try {
        await this.auth.awaitableUser()
        this.router.navigateByUrl("lists")
      } catch {
        this.router.navigateByUrl("signin")
      }
    } else {
      this.router.navigateByUrl("register")
    }
  }
}
