import { Pipe, PipeTransform } from '@angular/core';
import { Columns } from 'annex-tracker-backend';

@Pipe({
  name: 'filterHidden',
  standalone: true
})
export class FilterHiddenPipe implements PipeTransform {

  transform(value: Columns[]): Columns[] {
    return value.filter(column => !column.hidden);
  }

}
