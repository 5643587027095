import { Component } from '@angular/core';
import { faEnvelope, faEye, faFile, faMessage } from '@fortawesome/pro-regular-svg-icons';
import { WorkstreamsService } from 'src/app/services/workstreams.service';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PrettyPrintWorkstreamComponent } from '../../../../../common/pretty-print-workstream/pretty-print-workstream.component';

@Component({
    selector: 'app-subview-header',
    templateUrl: './subview-header.component.html',
    styleUrls: ['./subview-header.component.scss'],
    standalone: true,
    imports: [PrettyPrintWorkstreamComponent, RouterLink, RouterLinkActive, FaIconComponent, AsyncPipe]
})
export class SubviewHeaderComponent {

  iconEmail = faEnvelope
  iconPreview = faEye
  iconFiles = faFile

  constructor(public workstreamService: WorkstreamsService) { }

}
