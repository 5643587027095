import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderButtonsService {

  public template: TemplateRef<any> | null = null

  constructor() { }
}
