import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myDate',
    standalone: true
})
export class MyDatePipe implements PipeTransform {

  transform(value: null | undefined | number | string | Date, timeZone: string = 'Europe/Berlin'): string {

    if (value == null) {
      return ''
    }

    if (typeof value == 'string') {
      const millis = Date.parse(value)
      if (isNaN(millis)) {
        return ''
      }
      value = millis
    }

    if (value instanceof Date) {
      value = value.getTime()
    }

    const dateString = new Intl.DateTimeFormat('de-DE', {timeStyle: 'short', dateStyle: 'medium', timeZone }).format(value)

    return dateString

  }

}
