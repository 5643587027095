const MIN_FILE_SIZE = 6 * 1024 * 1024;

export async function* blobify(stream: ReadableStream): AsyncGenerator<Blob> {
  let reader = stream.getReader();

  const bufferLength = MIN_FILE_SIZE;

  let a = await reader.read();
  let arr = a.value as Uint8Array;
  let buffer = new Uint8Array(bufferLength)
  let bufferCurrentOffset = 0;
  let readableCurrentOffset = 0;

  let bufferBytesLeft = 0;
  let readableDataLeft = 0;

  while (!a.done) {

    arr = a.value as Uint8Array;

    bufferBytesLeft = (bufferLength - bufferCurrentOffset);
    readableDataLeft = arr.length - readableCurrentOffset;

    let thisCopyLength = Math.min(bufferBytesLeft, readableDataLeft)

    // this makes sure that neither the buffer nor the readable data is over-read
    buffer.set(arr.slice(readableCurrentOffset, readableCurrentOffset + thisCopyLength), bufferCurrentOffset)
    bufferCurrentOffset += thisCopyLength;
    readableCurrentOffset += thisCopyLength;

    if (bufferBytesLeft == 0) {
      yield new Blob([buffer])
      buffer = new Uint8Array(bufferLength)
      bufferBytesLeft = bufferLength;
      bufferCurrentOffset = 0
    }

    if (readableDataLeft == 0) {
      a = await reader.read();
      readableDataLeft = arr.length;
      readableCurrentOffset = 0;
    }

  }

  if (bufferCurrentOffset > 0) {
    yield new Blob([buffer]).slice(0, bufferCurrentOffset)
  }

}