import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOutletComponent } from './modal-outlet/modal-outlet.component';
import { ModalService } from './modal.service';

@NgModule({
    imports: [
        CommonModule,
        ModalOutletComponent
    ],
    providers: [
        ModalService
    ],
    exports: [ModalOutletComponent]
})
export class ModalModule { }
