import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { diffChars } from 'diff';

@Pipe({
    name: 'diff',
    standalone: true
})
export class DiffPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(value?: string, compare?: string): SafeHtml {

    value = value || ''
    compare = compare || ''
    
    const res = diffChars(value, compare)

    const html = res.map((part) => {
      const classes = part.added ? 'bg-green-200' :
        part.removed ? 'bg-red-200 line-through' : 'black';

      return `<span class="${classes}">${part.value}</span>`
    }).join('')

    // Sanitize using the angular dom sanitizer
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

}
