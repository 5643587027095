import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { ownerOptions } from '../multi-column/list-detail/doc-list/workstreams-table/workstreams-table-row/optionsDropdown';
import { ColorService } from '../services/color.service';

@Directive({
    selector: '[appStackedLineChart]canvas',
    standalone: true
})
export class StackedLineChartDirective {

  @Input()
  title!: string

  @Input()
  labels!: string[]

  @Input()
  colors: string[] = ['#00ff00']

  @Input()
  data!: any[][]

  chart?: Chart<'line'>

  constructor(
    private elementRef: ElementRef<HTMLCanvasElement>,
    private colorService: ColorService
  ) { }

  async ngOnInit() {

    

    const t = (new Array(10)).fill(undefined).map((_,i) => new Date(1000 * i))
    
    // Some random 2D array of numbers
    this.data = [
      t.map(d => Math.random() * 100),
      t.map(d => Math.random() * 100),
      t.map(d => Math.random() * 100),
    ]

    const ownerColorsBright = ownerOptions.map(o => this.colorService.getColor(o.color, 100))
    const ownerColorsDim = ownerOptions.map(o => this.colorService.getColor(o.color, 400))
    // Chart.defaults.color = '#000'

    this.chart = new Chart(this.elementRef.nativeElement, {
      type: 'line',

      data: {
        labels: t,
        datasets: this.data.map((d,i) => 
          ({
            label: ownerOptions[i].label,
            borderColor: ownerColorsDim[i],
            backgroundColor: ownerColorsBright[i],
            data: d, 
            fill: true
          }))
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Chart.js Line Chart'
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month'
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Value'
            }
          }
        }
      }
    })

    this.chart.update()

  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (!changes["data"].firstChange && this.chart != null) {
    //   (this.chart.options as any).animation = true
    // }
    // this.chart?.data.datasets.forEach(dataset => {
    //   dataset.data = this.data
    // })
    // this.chart?.update()
  }

}
